import { useEffect, useRef, useState } from 'react';
import { 
    Avatar,
    IconButton
 } from 
'@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import DeleteIcon from '@mui/icons-material/Delete';
import "./drop-file.css";

export const DropFileInput = ({ onFileChange,previousImage }) => {

    const wrapperRef = useRef(null);
    const [images, setImages] = useState(previousImage);
    const [uploads, setUploads] = useState([]);
    const [isMounted, setIsMounted] = useState(false);

    const onDragEnter = () =>  wrapperRef.current.classList.add('dragover');
    
    const onDragLeave = () =>  wrapperRef.current.classList.remove('dragover');

    const onDrop = () =>  wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (event) => {
        const files = event.target.files;
        if (files.length === 0) return;
        for( let i = 0; i < files.length; i++) {
            if (files[i].type.split('/')[0] !== "image") continue;
            if (!images.some((e) => e.name === files[i].name)) {
                setImages((prevImages) => [
                    ...prevImages,
                    {
                        name: files[i].name,
                        url: URL.createObjectURL(files[i]),
                        file: files[i]
                    }
                ])
            }
        }
    }

    const fileRemove = (index) => {
        setImages((prevImages) =>
           prevImages.filter((_, i) => i !== index) 
        );
    }
    useEffect(() => {
        onFileChange(images)
    }, [images])

    return(
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input mx-auto"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <CollectionsIcon 
                        fontSize='large'
                        className=' text-blue-800'
                        sx={{
                            height: "150px",
                            width: "150px"
                        }}
                    />
                    <p>Drag & Drop your files here</p>
                </div>
                <input type="file" value="" multiple onChange={onFileDrop}/>
            </div>
            <p className='mb-8 text-black relative left-1/3 mt-4 text-xl font-bold'>Ready to upload Images.</p>
            <div className='mt-8 flex flex-wrap '>
            {
                images?.length > 0 ? (                        
                            images?.map((item, index) => (
                                <div
                                    key={index} 
                                    className='flex'
                                >
                                    <Avatar 
                                        src={item.url??item}
                                        alt={item.name}
                                        variant='square'
                                        sx={{
                                            width: "120px",
                                            height: "120px"
                                        }}
                                        className=' absolute'
                                    />
                                    <span 
                                        className='relative text-xl right-4' 
                                    >
                                        <IconButton onClick={() => fileRemove(index)}>
                                            <DeleteIcon 
                                                className=' text-red-500'
                                            />
                                        </IconButton>
                                    </span>
                                </div>
                            ))
                ) : null
            }
            </div>
        </>
    )
}