const MedCard = ({img, def, working, uses, know, sideEffect}) => {
    return(
        <div className="pl-5">
            <div className="flex space-y-10 justify-start items-center space-x-10">
                <div>
                    <div className=" flex justify-center w-[400px] h-[350px] " style={{background: "#F4F5F7"}}>
                       <img
                            src={img}
                            alt="med"
                        />
                    </div>
                </div>
                <div>
                    <p className=" text-gray-700 font-bold mb-4  px-2">{def}</p>
                    <h1 className="text-white font-bold mb-2 text-sm bg-blue-800 px-2 w-36 rounded-r-full border-l-8 border-l-yellow-500">How it works: </h1>
                    <p className=" text-gray-700 font-semibold text-sm mb-4 w-full px-2">{working}</p>
                    <h1 className="text-white font-bold mb-2 font-se text-sm bg-blue-800 px-2 w-36 rounded-r-full border-l-8 border-l-yellow-500">How to use: </h1>
                    <div className="px-2 text-gray-700 text-sm font-semibold w-full mb-4">
                        {uses.map((use) => (
                            <p key={use}>{use}</p>
                        ))}
                    </div>
                    <h1 className="text-white font-bold mb-2 text-sm bg-blue-800 px-2 w-36 rounded-r-full border-l-8 border-l-yellow-500">Good to know: </h1>
                    <p className=" text-gray-700 mb-4 font-semibold px-2 text-sm w-full">{know}</p>
                    <h1 className="text-white font-bold mb-2 text-sm bg-blue-800 px-2 w-56 rounded-r-full border-l-8 border-l-yellow-500">Rare side effects include: </h1>
                    <p className=" text-gray-700 mb-4 font-semibold px-2 text-sm w-full">{sideEffect}</p>
                </div>
            </div>
        </div>
    )
}

export default MedCard;