import React, {useRef } from 'react';
import './editor.css'
import JoditEditor from 'jodit-react';
const TextEditor = ({content,setContent,config}) => {
    const editor=useRef(null)
    return (
        <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} 
        onBlur={newContent => setContent(newContent)} 
        onChange={newContent => {}}
    />
    );
}

export default TextEditor;
