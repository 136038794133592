import { createSlice } from "@reduxjs/toolkit"

import { AddNewPage, deletePage, getAllPages } from "../actions/pageaction";


const initialState = {
    loading:false,
    pagesdata:[],
    error:null,
    success:false,


}


const pagesSlice = createSlice({
    name:'page',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllPages.pending] : (state) => {
        
        state.loading = true;
        state.error = null;
     },
     [getAllPages.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.pagesdata = payload;
        state.success = true;
     },
     [getAllPages.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddNewPage.pending] : (state) => {
        
        state.loading = true;
        state.error = null;
     },
     [AddNewPage.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.pagesdata = payload;
        state.success = true;
     },
     [AddNewPage.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [deletePage.pending] : (state) => {
        
        state.loading = true;
        state.error = null;
     },
     [deletePage.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.pagesdata = payload;
        state.success = true;
     },
     [deletePage.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
    
    
    
    
    }
})



export default pagesSlice.reducer;

