import * as yup from "yup";
import { useFormik } from "formik";
import TopHeader from "../../../Ui/topHeader";
import { Button, FormControl, Paper, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { successAlert } from "../../../Ui/Alert";

const FaqSchema = yup.object().shape({
    question: yup
            .string()
            .required("Question Required"),
    answer: yup
           .string()
           .required("Answer Requried")
})

const backendUrl = process.env.REACT_APP_API_BASE_URL;

const UpdateFaq = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const onSubmit = async(values, actions) => {
        const question = {
            question:values.question,
            answer: values.answer
        }
        await axios({
            method: "put",
            url: `${backendUrl}/api/faqs/${state.faqId}`,
            headers: {
                'Content-Type' : 'application/json'
            },
            data: question
        })
        .then((data) => {
            actions.resetForm();
            successAlert("Faq Updated Successfully!!")
            navigate(-1);
        })
        .catch((error) => console.log(error))

    }

    const { values, errors, isSubmitting, handleBlur, handleChange, handleSubmit, } = useFormik({
        initialValues: {
            question: state.faqQuestion,
            answer: state.faqAnswer
        },
        validationSchema: FaqSchema,
        onSubmit,
    })

    return(
        <div>
            <TopHeader />
            <div className=" mt-6 pl-5 mb-8 mr-4">
                <Paper className=" mt-4 p-5">
                    <p className=" text-2xl font-bold text-blue-900">Update Faq</p>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className=" mb-4 mt-4">
                            <FormControl fullWidth>
                                <TextField 
                                    id="question"
                                    name="question"
                                    type="text"
                                    label="FAQ Question"
                                    multiline
                                    value={values.question}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.question}
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                        <div className=" mb-4 mt-4">
                            <FormControl fullWidth>
                                <TextField 
                                    id="answer"
                                    name="answer"
                                    type="text"
                                    label="FAQ Answer"
                                    variant="standard"
                                    multiline
                                    minRows={2}
                                    value={values.answer}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.answer}
                                />
                            </FormControl>
                        </div>
                        <div className="ml-5 flex gap-4 mt-4">
                            <Button 
                                variant="contained" 
                                size="large"
                                disabled={isSubmitting}
                                type="submit"
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Update</Button>
                               <Button 
                                variant="outlined" 
                                size="large"
                                disabled={isSubmitting}
                                sx={{
                                    "&.MuiButton-root": {
                                        fontWeight: "bold"
                                    },
                                }}
                                onClick={() => navigate(-1)}
                            >Back</Button>
                        </div>
                    </form>
                </Paper>
            </div>
        </div>
    )
}

export default UpdateFaq;