import * as yup from "yup";
import { useFormik } from "formik";
import TopHeader from "../../../Ui/topHeader";
import { Button, FormControl, Paper, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddFaq, getAllFaqs } from "../../../store/actions/faqaction";

const FaqSchema = yup.object().shape({
    question: yup
            .string()
            .required("Question Required"),
    answer: yup
           .string()
           .required("Answer Requried")
})

const NewFaq = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async(values, actions) => {
        const question = {
            question:values.question,
            answer: values.answer
        }
        await dispatch(AddFaq(question))

       await dispatch(getAllFaqs());

       navigate(-1);

        actions.resetForm();
    }

    const { values, errors, isSubmitting, handleBlur, handleChange, handleSubmit, } = useFormik({
        initialValues: {
            question: '',
            answer: ''
        },
        validationSchema: FaqSchema,
        onSubmit,
    })

    return(
        <div>
            <TopHeader />
            <div className=" mt-6 pl-5 mb-8 mr-4">
                <Paper className=" mt-4 p-5">
                    <p className=" text-2xl font-bold text-blue-900">Add New Faq</p>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className=" mb-4 mt-4">
                            <FormControl fullWidth>
                                <TextField 
                                    id="question"
                                    type="text"
                                    label="FAQ Question"
                                    variant="standard"
                                    multiline
                                    value={values.question}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.question}
                                />
                            </FormControl>
                        </div>
                        <div className=" mb-4 mt-4">
                            <FormControl fullWidth>
                                <TextField 
                                    id="answer"
                                    type="text"
                                    label="FAQ Answer"
                                    variant="standard"
                                    multiline
                                    value={values.answer}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.answer}
                                />
                            </FormControl>
                        </div>
                        <div className="ml-5 flex gap-4 mt-4">
                            <Button 
                                variant="contained" 
                                size="large"
                                disabled={isSubmitting}
                                type="submit"
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Submit</Button>
                               <Button 
                                variant="outlined" 
                                size="large"
                                disabled={isSubmitting}
                                sx={{
                                    "&.MuiButton-root": {
                                        fontWeight: "bold"
                                    },
                                }}
                                onClick={() => navigate(-1)}
                            >Back</Button>
                </div>
                    </form>
                </Paper>
            </div>
        </div>
    )
}

export default NewFaq;