import { createSlice } from "@reduxjs/toolkit"
import { deleteSubscriber, getAllSubscribers } from "../actions/subscriberaction";
const initialState = {
   loading: false,
   subscribersdata: [],
   error: null,
   success: false,
}

const subscriberSlice = createSlice({
   name: 'subscriber',
   initialState,
   reducers: {},
   extraReducers: {
      [getAllSubscribers.pending]: (state) => {
         state.loading = true;
         state.error = null;
      },
      [getAllSubscribers.fulfilled]: (state, { payload }) => {
         state.loading = false;
         state.subscribersdata = payload;
         state.success = true;
      },
      [getAllSubscribers.error]: (state, { payload }) => {
         state.loading = false;
         state.error = payload;
      },
      [deleteSubscriber.pending]: (state) => {
         state.loading = true;
         state.error = null;
      },
      [deleteSubscriber.fulfilled]: (state) => {
         state.loading = false;
         state.success = true;
      },
      [deleteSubscriber.error]: (state, { payload }) => {
         state.loading = false;
         state.error = payload;
      },

   }
})



export default subscriberSlice.reducer;