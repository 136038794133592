import { createSlice } from "@reduxjs/toolkit"
import { AddFaq, deleteFaq, getAllFaqs, updateFaq } from "../actions/faqaction";




const initialState = {
    loading:false,
    faqdata:[],
    error:null,
    success:false,


}
 

const FaqsSlice = createSlice({
    name:'faqs',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllFaqs.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllFaqs.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [getAllFaqs.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [deleteFaq.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [deleteFaq.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [deleteFaq.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddFaq.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [AddFaq.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [AddFaq.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [updateFaq.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [updateFaq.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [updateFaq.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default FaqsSlice.reducer;

