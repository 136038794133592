import { useLocation } from "react-router-dom";
import TopHeader from "../../../Ui/topHeader";
import MedCard from "../../../Ui/MedCard";
import medImp from "./Assets/m1.png"
import m2 from "./Assets/m2.png"

const med = {
    img: medImp,
    def: "This combination of Topical Spray and finasteride is best for reducing overall hair loss and helping with hair regrowth across the entire scalp.",
    working: "Finasteride blocks the action of an enzyme called type II 5-alpha-reductase, which is responsible to convert testosterones to another hormone called dihydrotestosterone (DHT). DHT is the enzyme that is responsible for hair thinning/ hair loss",
    uses: ["1 tablet daily", "Recommended duration of treatment to see results would be around 6-9 months*"],
    know: "Finasteride 1mg can be taken with or without food",
    sideEffect: "Mood changes, decrease libido, erectile disorder, ejaculation disorder.  If you do experience any of the above, please contact doctor"
}

const ViewProduct = () => {

    const { state } = useLocation();

    return(
        <div>
            <TopHeader />
            <div>
                <div className=" pt-4 pl-5 pb-4">
                    <h1 className=" text-3xl text-blue-900 font-bold">{state.name}</h1>
                </div>
                <div>
                    <MedCard 
                        img={med.img}
                        def={med.def}
                        working={med.working}
                        uses={med.uses}
                        sideEffect={med.sideEffect}
                        know={med.know}
                    />
                </div>
                {/* med cards */}
                <div className=" flex justify-start pl-5 space-x-4">
                    <div className=" border-2 p-3 z-0">
                        <img 
                        src={medImp}
                        alt="med"
                        className=" w-[45px] h-[80px] flex-shrink-0"
                        />
                    </div>
                    <div className="p-2">
                        <img 
                        src={m2}
                        alt="med"
                        className=" pt-3"
                        />
                    </div>
                    <div className="p-2">
                        <img 
                        src={m2}
                        alt="med"
                        className=" pt-3"
                        />
                    </div>
                    <div className="p-2">
                        <img 
                        src={m2}
                        alt="med"
                        className=" pt-3"
                        />
                    </div>
                    <div className="p-2">
                        <img 
                        src={m2}
                        alt="med"
                        className=" pt-3"
                        />
                    </div>
                </div>
                <div className="pl-5 pt-4 pb-16">
                    <p className=" text-blue-600 font-bold text-xl pb-2">Description</p>
                    <p className="text-sm font-semibold">Nam tempus turpis at metus scelerisque placerat nulla deumantos solicitud felis. Pellentesque diam dolor, elementum etos lobortis des mollis ut risus. Sedcus faucibus an sullamcorper mattis drostique des commodo pharetras loremos.Donec pretium egestas sapien et mollis.</p>
                    <p className=" font-bold pt-4">Lorem ipsum dolor sit amet</p>
                    <p className=" font-semibold pt-2 text-sm">Sonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
            </div>
        </div>
    )
}

export default ViewProduct;