import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  Step,
  Stepper,
  StepLabel,
  TextField,
  Typography,
  Paper
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ImageUpload } from "../../../../Ui/imageUpload";
import { DropFileInput } from "../../../../Ui/DroFile/drop-file-input";
import { successAlert } from "../../../../Ui/Alert";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { measurements } from '../../../../utils/constant';
const newProductSchema = yup.object().shape({
  photo: yup.mixed().nullable(),
  name: yup.string().required("Name is required."),
  price: yup.string().required("Price is required."),
  description: yup.string().required("Description is required."),
  category: yup.string().required("Category is required."),
  quantity: yup.string().required("Quantity required."),
  unit: yup.string().required("Enter Unit Measurement"),
  expiry: yup.date().required("Expiry required."),
  supplier: yup.string().required("Supplier required."),
  mfg: yup.string().required("Manufacturer name required."),
  mfgDate: yup.date().nullable().required("Manufacturer Date required."),
  batchNumber: yup.string().required("Batch No. required."),
  minQuantity: yup.string().required("Minimum Quantity required."),
  availableQuantity: yup.string().required("Available Quantity required."),
  power: yup.string().required("Power Required.")
})

const steps = ['Basic Details', 'Batch Details', 'Showcase'];
const apiUrl = process.env.REACT_APP_API_BASE_URL

export default function LinearStepper() {
  const [category, setCategory] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [activeStep, setActiveStep] = React.useState(0);
  const [image, setImage] = useState(null);
  const [showcase, setShowcase] = useState([]);
  const [img, setimg] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate()

  useEffect(() => {
    getCategories()
  }, [])


  const onSubmit = async (values, actions) => {
    values.photo = image;
    let batch = {
      batchNumber: values.batchNumber,
      supplier: values.supplier,
      mfg: values.mfg,
      mfgDate: values.mfgDate,
      expiry: values.expiry,
    }
    const showCaseArray = showcase.map((item) => {
      return item.file;
    })

    const productsData = new FormData();
    productsData.append('name', values.name);
    productsData.append('unit', values.unit);
    productsData.append('power', values.power);
    productsData.append('description', values.description);
    productsData.append('price', values.price);
    productsData.append('category', values.category);
    productsData.append('quantity', values.quantity);
    productsData.append('minQuantity', values.minQuantity);
    productsData.append('image', img[0]);
    showCaseArray.forEach(img => {
      productsData.append("showcase", img)
    })
    productsData.append('batches', JSON.stringify(batch));
    await axios.post(`${apiUrl}/api/inventory`, productsData).then((res) => {
      if (res && res.data && res.data.inventory && Object.keys(res.data.inventory).length > 0) {
        successAlert("Product Added Successfully")
        navigate('/ivm')
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      photo: "",
      name: "",
      price: "",
      category: "",
      description: "",
      quantity: "",
      unit: "",
      expiry: null,
      supplier: "",
      mfg: "",
      mfgDate: null,
      minQuantity: "",
      availableQuantity:'',
      batchNumber: "",
      power: "",
    },
    validationSchema: newProductSchema,
    onSubmit,
  })

  const selectFile = () => {
    fileInputRef.current.click();
  }

  const onFileSelect = (event) => {
    const files = event.target.files;
    setimg(event.target.files[0])
    if (files[0]) {
      setimg(files)
      setImage(
        {
          name: files[0].name,
          url: URL.createObjectURL(files[0]),
          file: files[0]
        }
      )
    }
  }

  const onFileChange = (files) => {
    setShowcase(files);

  }
  const getCategories = async () => {
    await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
      if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
        setCategory(res.data)
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Paper className='p-6 mb-12 w-full'>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps?.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => setActiveStep(0)}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {
                activeStep === 0
                &&
                <>
                  <h1 className=' text-blue-800 font-bold mt-2'>Fill the basic product details.</h1>
                  <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                    <ImageUpload
                      image={image}
                      onRemove={() => setImage("")}
                    />
                    <input
                      id="photo"
                      name="photo"
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      ref={fileInputRef}
                      className="hidden"
                      onChange={onFileSelect}
                    />
                    <div
                      className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                      onClick={selectFile}
                    >
                      <p className=" font-extrabold text-sm ">Product image</p>
                      <AddPhotoAlternateIcon className="" fontSize="small" />
                    </div>
                  </div>
                  <div className=" grid grid-cols-3 space-x-12 mb-8">
                    <FormControl>
                      <TextField
                        id="name"
                        name="name"
                        label="Product Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.name && touched.name)}
                        helperText={
                          errors.name &&
                          touched.name &&
                          String(errors.name)
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="price"
                        name="price"
                        label="Product Price"
                        value={values.price}
                        type='number'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.price && touched.price)}
                        helperText={
                          errors.price &&
                          touched.price &&
                          String(errors.price)
                        }
                        required={true}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="category"
                        name="category"
                        select
                        label="Category"
                        onChange={handleChange}
                        value={values.category}
                        onBlur={handleBlur}
                        error={Boolean(errors.category && touched.category)}
                        helperText={
                          errors.category &&
                          touched.category &&
                          String(errors.category)
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      >
                        {category.map((option, index) => (
                          <MenuItem key={index} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </div>
                
                  <div className=" grid grid-cols-3 space-x-12 mb-8">
                    <FormControl>
                      <TextField
                        id="quantity"
                        name="quantity"
                        type='number'
                        label="Product Quantity"
                        value={values.quantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.quantity && touched.quantity)}
                        helperText={
                          errors.quantity &&
                          touched.quantity &&
                          String(errors.quantity)
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="unit"
                        name="unit"
                        select
                        label="Units Measurement"
                        onChange={handleChange}
                        value={values.unit}
                        onBlur={handleBlur}
                        error={Boolean(errors.unit && touched.unit)}
                        helperText={
                          errors.unit &&
                          touched.unit &&
                          String(errors.unit)
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      >
                        {measurements.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="power"
                        name="power"
                        type='number'
                        label="Power"
                        value={values.power}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.power && touched.power)}
                        helperText={
                          errors.power &&
                          touched.power &&
                          String(errors.power)
                        }
                        required={true}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">mg</InputAdornment>,
                        }}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className=" grid grid-cols-3 space-x-12 mb-8">
                    <FormControl>
                      <TextField
                        id="minQuantity"
                        name="minQuantity"
                        label="Minimum Purchase Quantity"
                        value={values.minQuantity}
                        onChange={handleChange}
                        type='number'
                        onBlur={handleBlur}
                        error={Boolean(errors.minQuantity && touched.minQuantity)}
                        helperText={
                          errors.minQuantity &&
                          touched.minQuantity &&
                          String(errors.minQuantity)
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="availableQuantity"
                        name="availableQuantity"
                        label="Available Quantity"
                        type='number'
                        value={values.availableQuantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.availableQuantity && touched.availableQuantity)}
                        helperText={
                          errors.availableQuantity &&
                          touched.availableQuantity &&
                          String(errors.availableQuantity)
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className=" mt-4 mb-8">
                    <FormControl fullWidth>
                      <TextField
                        id="description"
                        name="description"
                        multiline
                        label="Product Description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.description && touched.description)}
                        helperText={
                          (errors.description &&
                            touched.description) ?
                            String(errors.description)
                            :
                            "Please enter the description of the product of the specified category."
                        }
                        required={true}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                </>
              }

              {
                activeStep === 1
                &&
                <>
                  <div className=" grid grid-cols-3 space-x-12 mt-8">
                    <FormControl>
                      <TextField
                        id="batchNumber"
                        name="batchNumber"
                        label="Batch No."
                        value={values.batchNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                        required={true}
                        error={Boolean(errors.batchNumber && touched.batchNumber)}
                        helperText={
                          errors.batchNumber &&
                          touched.batchNumber &&
                          String(errors.batchNumber)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="supplier"
                        name="supplier"
                        label="Supplier"
                        value={values.supplier}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                        required={true}
                        error={Boolean(errors.supplier && touched.supplier)}
                        helperText={
                          errors.supplier &&
                          touched.supplier &&
                          String(errors.supplier)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="mfg"
                        name="mfg"
                        label="Manufacturer"
                        value={values.mfg}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{
                          fieldset: {
                            borderWidth: "3px",
                            borderColor: "#526D82"
                          },
                          input: {
                            fontWeight: "medium",
                          },
                          label: {
                            color: "#526D82"
                          }
                        }}
                        required={true}
                        error={Boolean(errors.mfg && touched.mfg)}
                        helperText={
                          errors.mfg &&
                          touched.mfg &&
                          String(errors.mfg)
                        }
                      />
                    </FormControl>
                  </div>
                  <div className=' grid grid-cols-3 space-x-12 mt-8'>
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={4} >
                          <DatePicker
                            label='MFG.'
                            sx={{
                              fieldset: {
                                borderWidth: "3px",
                                borderColor: "#526D82"
                              },
                              input: {
                                fontWeight: "medium",
                              },
                              label: {
                                color: "#526D82"
                              }

                            }}
                            className=" h-13 bg-white"
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            error={Boolean(errors.mfgDate && touched.mfgDate)}
                            helperText={errors.mfgDate && touched.mfgDate && String(errors.mfgDate)}
                            views={['year', 'month', 'day']}
                            value={dayjs(values.mfgDate)}
                            required={true}
                            emptyLabel="MM/DD/YYYY"
                            onChange={(value) => setFieldValue("mfgDate", dayjs(value.$d).format('YYYY-MM-DD'))}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={4} className=" w-full" >
                          <DatePicker
                            label='Expiry'
                            sx={{
                              fieldset: {
                                borderWidth: "3px",
                                borderColor: "#526D82"
                              },
                              input: {
                                fontWeight: "medium",
                              },
                              label: {
                                color: "#526D82"
                              }
                            }}
                            className=" h-13 bg-white"
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            views={['year', 'month', 'day']}
                            value={dayjs(values.expiry)}
                            emptyLabel="MM/DD/YYYY"
                            onChange={(value) => setFieldValue("expiry", dayjs(value.$d).format('YYYY-MM-DD'))}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </>
              }

              {
                activeStep === steps?.length - 1
                &&
                <>
                  <h1 className=' text-blue-800 font-bold mt-2'>Add photos of products.</h1>
                  <div className="mt-4 mb-8 mx-auto">
                    {
                      !image
                      &&
                      <p className=' text-red-500'>Please add the product photo.</p>
                    }
                    {
                      (!values.name || !values.power || !values.minQuantity || !values.category || !values.description || !values.unit || !values.price || !values.quantity)
                      &&
                      <p className=' text-red-500'>Please enter the basic details</p>

                    }
                    {
                      (errors.name || errors.power || errors.category || errors.minQuantity || errors.description || errors.unit || errors.price || errors.quantity)
                      &&
                      <p className=' text-red-500'>{errors.name || errors.minQuantity || errors.power || errors.category || errors.description || errors.unit || errors.price || errors.quantity}</p>

                    }
                    {
                      (!values.supplier || !values.batchNumber || !values.expiry || !values.mfg || !values.mfgDate)
                      &&
                      <p className=' text-red-500'>Please enter the batch details</p>

                    }
                    {
                      (!values.supplier || !values.batchNumber || !values.expiry || !values.mfg || !values.mfgDate)
                      &&
                      <p className=' text-red-500 mb-4'>{errors.supplier || errors.batchNumber || errors.expiry || errors.mfg || errors.mfgDate}</p>

                    }
                    <DropFileInput
                      onFileChange={(files) => onFileChange(files)}
                    
                    />
                  </div>
                </>
              }
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {
                  activeStep === steps.length - 1
                  &&
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                }
                {
                  activeStep !== steps.length - 1
                  &&
                  <Button
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                  >
                    Next
                  </Button>
                }
              </Box>
            </React.Fragment>
          )}
        </Box>
      </form>
    </Paper>
  );
}