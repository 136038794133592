import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Avatar,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../../Ui/table";
import TopHeader from "../../../Ui/topHeader";
import edit from "./Assets/edit.png";
import { deleteProduct, getAllProducts } from "../../../store/actions/productaction";
import LoadingBackdrop from "../../../Ui/LoadinSpinner";
import { successAlert } from "../../../Ui/Alert";
import exportFromJSON from 'export-from-json'
import InventoryTable from "../../../Ui/CustomTable/InventoryTable";
import * as XLSX from "xlsx";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;


function CRUDProducts() {
    const [productsData, setProductsData] = useState();
    const [open, setOpen] = useState(false);
    const [deleteProductId, setProductId] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);

    useEffect(() => {
        getProducts();
    }, [dispatch]);

    const handleNewProduct = () => {
        navigate("/ivm/newProduct")
    }
    const handleClickOpen = (id) => {
        setProductId(id);
        setOpen(true);
    }

    const handleClickClose = () => {
        setOpen(false);
    }

    const loading = useSelector(
        (state) => state.products.loading
    )

    const getProducts = async () => {
        const fetchedData = await dispatch(getAllProducts());
        if (fetchedData && fetchedData.payload && Array.isArray(fetchedData.payload)) {
            const data = fetchedData.payload.map(product => {
                return {
                    pid: product._id,
                    photo: backendUrl + product.imageUrl,
                    name: product.name,
                    price: product.price,
                    availableQuantity: product?.availableQuantity,
                    minQuantity: product?.minQuantity,
                    power: product.power,
                    category: product.category,
                    description: product.description,
                    quantity: product.quantity,
                    unit: product.unit,
                    showcase: product.showcaseImageUrls.map(showcaseUrl => backendUrl + showcaseUrl),
                    batches: product.batches

                }
            })
            setProductsData(data);
        }
    }

    const handleDeleteProduct = async () => {
        await dispatch(deleteProduct(deleteProductId));
        dispatch(getAllProducts());
        setOpen(false);
        await getProducts();
        successAlert("deleted successfully!!");
    }

    const handleEdit = (data) => {
        // navigate('editProduct', {state:{ pid, photo, name, price, description, quantity, unit, category, showcase }})
        navigate('editProduct', { state: data })
    }

    const getSeverity = (inventoryStatus) => {
        switch (inventoryStatus) {
            case 'IN STOCK':
                return 'success';

            case 'LOW STOCK':
                return 'warning';

            case 'OUT OF STOCK':
                return 'error';

            default:
                return null;
        }
    };

    const status = (minQuantity, availableQuantity) => {
        const status = availableQuantity > minQuantity ? "IN STOCK" : "OUT OF STOCK"
        return (
            <Chip
                label={status}
                color={getSeverity(status)}
                style={{ minWidth: '4rem' }}

            />
        )
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row?.name,
            width: "180px",
            center: "true"
        },
        {
            name: 'Photo',
            cell: (row) => (
                <div className="mt-3 mb-3">
                    <Avatar
                        src={row.photo}
                        alt={row.name}
                        className=" shadow-md rounded-xl"
                        sx={{
                            width: 80,
                            height: 80,
                        }}
                        variant="rounded"
                    />
                </div>
            ),
            center: "true"
        },
        {
            name: 'Price',
            selector: row => "$" + row?.price,
            center: "true",
            width: "80px",
        },
        {
            name: 'Product Category',
            selector: row => row?.category?.name,
            width: "200px",
            wrap: "true",
            // center:"true"
        },
        {
            name: 'Units',
            selector: row => row?.unit,
            width: "100px",
            wrap: "true",

        },
        {
            name: 'Available Quantity',
            selector: row => row?.availableQuantity,
            center: "true"

        },
        {
            name: 'Status',
            cell: (row) => (
                status(row.minQuantity, row.availableQuantity)
            ),
            center: "true",
            width: '170px'
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="">
                    <IconButton
                        style={{
                            color: "#39BFAF",
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <img src={edit} alt=""></img>
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row.pid)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),

        },
    ]

    const exportData = () => {
        const fileName = 'All Products'
        const exportType = exportFromJSON.types.csv
        const serializedData = productsData.map((product, index) => ({
            "Sr.No": index + 1,
            Product: product?.name,
            Category: product?.category?.name,
            Price: product?.price,
            Unit: product?.unit,
            "Available Quantity": product?.availableQuantity,
            Status: product?.status,
            "Batch Number": product?.batches[0]?.batchNumber,
            "Expiry Date": product?.batches[0]?.expiry,
            "MFG Date": product?.batches[0]?.mfgDate,
            Supplier: product?.batches[0]?.supplier,
            Description: product?.description
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }

    const selectFile = () => {
        fileInputRef.current.click();
    }
    const handleImportFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            const files = event.target.files[0];
            const reader = new FileReader();
            const inventoryData = []
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.readFile(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const processedData = XLSX.utils.sheet_to_json(sheet);
                processedData.forEach((data) => {
                    inventoryData.push({
                        name: data?.Product ?? "",
                        description: data?.Description ?? "",
                        price: data?.Price ?? 0,
                        power: data?.Power ?? "",
                        quantity: data?.Quantity ?? "",
                        minQuantity: data['Minimum Quantity'] ?? "",
                        availableQuantity: data['Available Quantity'] ?? "",
                        unit: data?.Unit,
                        batches: [
                            {
                                mfgDate: (data['MFG Date'] ?? "")?.split('T')[0] ?? "",
                                expiry: (data['MFG Date'] ?? "")?.split('T')[0] ?? "",
                                mfg: data?.Manufacturer ?? "",
                                supplier: data?.Supplier ?? "",
                                batchNumber: data['Batch Number'] ?? ""

                            }
                        ]

                    })
                })
                importInventory(inventoryData)

            }
            reader.readAsArrayBuffer(files);
        }
    }
    const importInventory = async (inventoryData) => {
        await axios.post(`${backendUrl}/api/inventory/import`, inventoryData).then((res) => {
            if (res && res.data && res.data.success) {
                successAlert("Product imported Successfully")
                getProducts()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-2 pl-5 pb-4 items-center">
                    <p className=" text-4xl font-bold pt-4 pl-5 pb-4 text-blue-950">Products</p>
                    <div className=" flex gap-x-4">
                        <Button
                            onClick={handleNewProduct}
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                marginLeft: "20px"
                            }}
                        >+ New Product</Button>
                        <input
                            id="importFile"
                            name="importFile"
                            ref={fileInputRef}
                            type="file"
                            accept=".csv, .xlsx"
                            onChange={handleImportFile}
                            className="hidden"

                        />
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#2e7d32",
                                    color: "white",
                                    fontWeight: "bold"
                                },
                                marginLeft: "5px"
                            }}
                            onClick={selectFile}

                        >IMPORT</Button>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#19398B",
                                    color: "white",
                                    fontWeight: "bold"
                                },
                                marginLeft: "5px"
                            }}
                            onClick={exportData}

                        >EXPORT</Button>
                    </div>
                </div>
            </div>
            <div className="ml-5 mr-5 mb-10">
                {/* Table */}
                {loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}
                <InventoryTable columns={columns}
                    data={productsData}
                    placeholder="Search Products" />
                <Dialog
                    open={open}
                    onClose={() => handleClickClose()}
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to delete the Product?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>

                        <Button onClick={() => handleClickClose()} variant="outlined">No</Button>
                        <Button
                            onClick={() => handleDeleteProduct()}
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                            }}
                        >Yes</Button>

                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default CRUDProducts;