import { Avatar, Divider, IconButton, Paper } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ImageOptionCard = ({ value, nextQuestion, deleteOption }) => {

    var url = value;

    return(
        <Paper
            className="w-[950px]"
            sx={{
                background: "#DDE6ED"
            }}
        >
            <div className=" flex justify-end">
                <IconButton
                   onClick={deleteOption}
                   style={{
                        color: "#EE4B4B"
                   }}
                >
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
             </div>
            <h1 className=" mb-2 text-blue-900 font-bold text-2xl text-center">Selected Option</h1>
            <Divider 
                variant="middle"
                sx={{
                    height: 4
                }}
                color="#146C94"
                className=""
            />
            <div className="pl-4 mt-1 items-center mb-2">
                <h1 className=" text-blue-900 font-bold text-xl">Option :</h1>
                <Avatar 
                    src={url}
                    variant="square"
                    alt="pro"
                    sx={{ height: 120, width: 120 }}
                />
            </div>
            <div className="pl-4 flex gap-6 items-center mb-3">
                <h1 className=" text-blue-900 font-bold text-xl">Next Question :</h1>
                <h1>{nextQuestion.value}</h1>
            </div>
        </Paper>
    )
}

export default ImageOptionCard;