import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllFaqs = createAsyncThunk(
    'faqs/getAllFaqs',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/faqs`,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)



export const AddFaq = createAsyncThunk(
    'faqs/AddFaq',
    async (question) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            const { data } = await axios.post(
                `${backendUrl}/api/faqs`, question,
                config
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }

        }


    }
)



export const deleteFaq = createAsyncThunk(
    'faqs/deleteFaq',
    async (fid) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const { data } = await axios.delete(
                `${backendUrl}/api/faqs/` + fid,


                config
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)

export const updateFaq = createAsyncThunk(
    'faqs/updateFaq',
    async (fid, question) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.put(
                `${backendUrl}/api/faqs/` + fid,
                question,
                config
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)