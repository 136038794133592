import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllSubscribers = createAsyncThunk(
    'subscribers/getAllSubscribers',
    async () => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.get(
                `${backendUrl}/subcribers`,
               
                config
            )        
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)



export const deleteSubscriber = createAsyncThunk(
    'subscribers/deleteSubscriber',
    async (sid) => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.delete(
                `${backendUrl}/deletesubscriber`,
                {

                    params: {
                         sid:sid
                    }
                },
               
                config
            )        
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)