import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllBogs = createAsyncThunk(
    'blogs/getAllBlogs',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const { data } = await axios.get(
                `${backendUrl}/api/blog`,

                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)

export const AddNewBlog = createAsyncThunk(
    'blogs/AddNewBlog',
    async (blog) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            const { data } = await axios.post(
                `${backendUrl}/api/blog`, blog,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)


export const deleteBlog = createAsyncThunk(
    'blogs/deleteBlog',
    async (bid) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const { data } = await axios.delete(
                `${backendUrl}/api/blog/` + bid,
                config
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const updateBlog = createAsyncThunk(
    'blogs/updateBlog',
    async (blogFormData) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            const { data } = await axios.put(
                `${backendUrl}/api/blog/` + blogFormData.id,
                blogFormData.blogFormData,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)