import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../shared/auth-context';

const TopHeader = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        auth.logout();
        localStorage.clear()
        sessionStorage.clear()
        navigate("/")
    }
    
    return(
        <div className=' bg-white m-0 top-0 z-1 p-4'>
            <div className=' flex items-center justify-end'>
                <div className=' flex items-center p-1 cursor-pointer hover:scale-105 active:scale-95 ease-in-out duration-500'  onClick={handleLogout}>
                    <PowerSettingsNewSharpIcon 
                        fontSize='large'
                        className=' text-blue-700'
                    />
                        <p className=' text-blue-700 font-bold text-xl'>Logout</p>
                </div>
            </div>
        </div>
    )
}

export default TopHeader;