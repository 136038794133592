import { useFormik } from "formik";
import * as yup from "yup";
import {
    Button
} from '@mui/material';
import IntroPage from "../../Ui/introPage";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogin } from "../../store/actions/authaction";
import { errorAlert, successAlert } from "../../Ui/Alert";
import { useContext } from "react";
import { AuthContext } from "../../shared/auth-context";

const loginSchema = yup.object().shape({
    username: yup
        .string()
        .required("Username Required"),
    password: yup
        .string()
        .required("Password Required")
});


const Login = () => {

    const auth = useContext(AuthContext);

    const dispatch = useDispatch();
    // nikhil Code
    const onSubmit = async (values, actions) => {
        const { username, password } = values;
        const response = await dispatch(userLogin(values))
        if (response.type === 'auth/login/fulfilled') {
            auth.login(username, password);
            if (response.payload && response?.payload?.user && response?.payload?.user?.userType) {
                localStorage.setItem("userType", response?.payload?.user?.userType?._id)
                localStorage.setItem("userId", response?.payload?.user?._id)
            }
            successAlert("Logged In Successfully!!!");
        }
        else if (response.type === 'auth/login/rejected') {
            errorAlert("Wrong Email or Password!!");
        }
    }

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        validationSchema: loginSchema,
        onSubmit,
    })

    return (
        <>
            <div className=" flex">
                <div className=" w-[40%] flex items-center">
                    <div className=" pl-20">
                        <h1 className=" text-blue-900 font-bold text-5xl font-roboto">Login</h1>
                        <div className=" h-6 w-full border-b-4 border-b-blue-900"></div>
                        <div className=" mt-4">
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className=" flex flex-col mb-8">
                                    <label className=" text-blue-900 font-semibold mb-2" htmlFor="username">Username</label>
                                    <input
                                        id="username"
                                        type="text"
                                        placeholder="Enter your username"
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={`${errors.username && touched.username ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 w-96 p-3 rounded-md border `}
                                    />
                                    {errors.username && touched.username && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.username}</p>}
                                </div>
                                <div className=" flex flex-col mb-8">
                                    <label className=" text-blue-900 font-semibold mb-2" htmlFor="password">Password</label>
                                    <input
                                        id="password"
                                        type="password"
                                        placeholder="Enter your password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={`text-blue-900 ${errors.password && touched.password ? 'border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} w-96 p-3 rounded-md border`}
                                    />
                                    {errors.password && touched.password && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.password}</p>}
                                </div>
                                <div className=" flex items-center justify-between">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        disabled={isSubmitting}
                                        type="submit"
                                        sx={{
                                            "&.MuiButton-root": {
                                                backgroundColor: "#E9B84A"
                                            },
                                            marginLeft: "20px"
                                        }}
                                    >Log In</Button>
                                    <NavLink to={'/fp'} underline="hover" fontWeight="bold">Forgot Password?</NavLink>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <IntroPage />
            </div>
        </>
    )
}

export default Login;