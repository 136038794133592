import {
    useRef,
    useEffect,
    useState,
    useCallback
} from "react";

import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import TopHeader from "../../Ui/topHeader";
import edit from "./Assets/editlogo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getallusers } from "../../store/actions/usersActions";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { errorAlert, successAlert } from "../../Ui/Alert";
import axios from "axios";
import exportFromJSON from 'export-from-json'
import UserTable from "../../Ui/CustomTable/UserTable";
const backendUrl = process.env.REACT_APP_API_BASE_URL;


function AllUsers() {
    const [open, setOpen] = useState(false);
    const [openSuspend, setSuspend] = useState(false);
    const [userData, setUserData] = useState([]);
    const [suspendReason, setSuspendReason] = useState("");
    const [deleteUserId, setDeleteUserId] = useState();
    const [deactivateUserId, setDeactivateUserId] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(
        (state) => state.users.loading
    )

    const usersData = useSelector(
        (state) => state.users.usersdata
    )

    const dataFetchedRef = useRef(false);

    const handleClickOpen = (userId) => {
        setDeleteUserId(userId);
        setOpen(true);
    }

    const handleSuspend = (userId) => {
        setSuspend(true);
        setDeactivateUserId(userId)
    }

    const handleCloseSuspend = () => {
        setSuspendReason("");
        setDeactivateUserId("");
        setSuspend(false);
    }

    const handleClickClose = () => {
        setOpen(false);
    }

    const handleEdit = (data) => {
        const userId = data._id;
        const photo = backendUrl + data.imageUrl;
        const username = data?.username;
        const email = data?.email;
        const usertype = data?.userType;
        const dob = data.personalInfo?.dob;
        const firstname = data?.personalInfo?.first_name;
        const lastname = data.personalInfo?.last_name;
        const nationality = data.personalInfo?.nationality;
        const passport = data.personalInfo?.nric_passport_no;
        const contact = data.personalInfo?.phone_number;
        const sex = data.personalInfo?.sex;
        const allergies = data.personalInfo?.drug_allergies;
        const g6pd = data.personalInfo?.g6pd;
        const deliveryAddress = data?.deliveryAddress;
        const department = data?.department;
        const description = data?.description;
        const isDisplay = data?.isDisplay;
        const countryCode = data.personalInfo?.country_code;

        navigate('edituser', { state: { userId, photo, username, dob, firstname, lastname, contact, email, usertype, passport, nationality, sex, g6pd, allergies, deliveryAddress, description, department, isDisplay, countryCode } })
    }

    const suspendSubmit = async () => {
        await axios.patch(`${backendUrl}/api/users/deactivate/${deactivateUserId}`, { reasonForInActive: suspendReason }).then(async (res) => {
            if (res && res.data && res.data.success == true) {
                successAlert("User is suspended successfully")
                handleCloseSuspend()
                await getAllUsers();
            }
        }).catch((error) => {
            errorAlert(error.message)
        })

    }

    const columns = [
        {
            name: 'Photo',
            selector: (row) => (
                <div>
                    <Avatar
                        src={backendUrl + row?.imageUrl}
                    />
                </div>
            ),
            center: 'true'
        },
        {
            name: 'Name',
            selector: row => ` ${row?.personalInfo?.first_name} ${row?.personalInfo?.last_name} `,
            sortable: "true",
            center: 'true'
        },
        {
            name: 'Email Address',
            selector: row => row?.email,
            wrap: "true",
            center: 'true'
        },
        {
            name: 'Contact No',
            selector: row => `${row.personalInfo?.country_code} ${row?.personalInfo?.phone_number}`,
            center: 'true'
        },
        {
            name: 'User Role',
            selector: row => row?.userType?.role,
            center: 'true'
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex items-center">
                    <IconButton
                        style={{
                            // height: "32px",
                            // width: "41px",
                            color: "#39BFAF",
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <img src={edit} alt=""></img>
                    </IconButton>
                    <IconButton
                        onClick={() => handleSuspend(row._id)}
                        style={{
                            color: "#EE4B4B",
                        }}
                    >
                        <PersonOffOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
        },

    ]

    const arrayBufferTob64 = (buffer) => {
        var arrayBufferView = new Uint8Array(buffer);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        return imageUrl;
    }

    const getAllUsers = useCallback(async () => {
        const fetchData = await dispatch(getallusers());
        let base64Flag = 'data:image/jpeg;base64,'
        const data = fetchData.payload?.map(user => {
            return {
                id: user._id,
                username: user.username,
                email: user.email,
                firstname: user.personalInfo?.first_name,
                lastname: user.personalInfo?.last_name,
                password: user.password,
                contact: user.personalInfo?.phone_number,
                countryCode: user.personalInfo?.country_code,
                userId: user._id,
                dob: user.personalInfo?.dob,
                userType: user?.userType?.role,
                photo: base64Flag + arrayBufferTob64(user.imageUrl.data),
                passport: user?.personalInfo?.nric_passport_no,
                nationality: user?.personalInfo?.nationality,
                sex: user?.personalInfo?.sex,
                postcode: user?.deliveryAddress.postcode,
                city: user?.deliveryAddress.city,
                address: user?.deliveryAddress.address,
                g6pd: user?.personalInfo?.g6pd,
                allergies: user?.personalInfo?.drug_allergies,
                department: user?.department,
                description: user?.description
            }
        })
        setUserData(data);
    }, [dispatch])

    const handleDeleteUser = async () => {
        await dispatch(deleteUser(deleteUserId));
        dispatch(getallusers());
        setOpen(false);
        await getAllUsers();
        successAlert("Deleted Successfully!!")
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getAllUsers();
    }, [dispatch, getAllUsers]);

    const exportData = () => {
        const fileName = 'All users'
        const exportType = exportFromJSON.types.csv
        const serializedData = usersData.map((item, index) => ({
            sr: index + 1,
            Name: `${item?.personalInfo?.first_name ?? "--"} ${item?.personalInfo?.last_name ?? "--"}`,
            Email: item.email ?? '---',
            UserType: item?.userType?.role,
            Contact: `=${item?.personalInfo?.phone_number ? `TEXT("${item?.personalInfo?.phone_number}", "0")` : '""'}`,
            Gender: `${item?.personalInfo?.sex ?? "---"}`,
            DOB: `=TEXT("${item?.personalInfo?.dob?.split('T')[0]}", "yyyy-mm-dd")`,
            Address: `${item?.deliveryAddress ? item?.deliveryAddress[0]?.address : "---"}`,
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between mt-8 pl-5 mb-10 items-center">
                    <p className=" text-4xl font-bold text-blue-950 ">All Users</p>
                    <div className=" flex gap-x-4">
                        <Button
                            onClick={() => navigate("newUser")}
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                marginLeft: "5px"
                            }}
                        >ADD User</Button>
                        <Button

                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#19398B",
                                    color: "white",
                                    fontWeight: "bold"
                                },
                                marginLeft: "5px"
                            }}
                            onClick={exportData}

                        >EXPORT ALL</Button>
                    </div>
                </div>
                <div className=" pl-5 pr-5">

                    {loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}

                    <UserTable
                        columns={columns}
                        data={usersData}
                        placeholder="Search User"
                    />
                    <Dialog
                        open={open}
                        onClose={handleClickClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the user?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>

                            <Button onClick={handleClickClose} variant="outlined">No</Button>
                            <Button
                                onClick={() => handleDeleteUser()}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>

                        </DialogActions>
                    </Dialog>

                    {/* Suspend user */}
                    <Dialog
                        open={openSuspend}
                        onClose={handleCloseSuspend}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <div className=" flex flex-col mb-8 p-3">
                            <label className=" text-blue-800 font-semibold mb-2" htmlFor="suspendReason">Enter the reason for suspension</label>
                            <TextField
                                id="suspendReason"
                                type="text"
                                multiline
                                placeholder="Enter User Suspension Reason"
                                value={suspendReason}
                                required
                                onChange={(event) => setSuspendReason(event.target.value)}
                                className={`border border-gray-400  outline-blue-500 text-blue-900 w-96 p-2 rounded-md `}
                            />
                        </div>
                        <div className=" flex justify-center gap-4 pb-5">
                            <Button onClick={handleCloseSuspend} variant="outlined">No</Button>
                            <Button
                                onClick={suspendSubmit}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default AllUsers;