import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";


ChartJS.register(ArcElement, Tooltip);

const Chart = ({ heading, fields, levels, pieData }) => {

  const data = {
    labels: levels,
    datasets: [
      {
        data: pieData,
        backgroundColor: ["#A060FC", "#FF8D39", "#8FC743", "#5BC3F5"],
        hoverBackgroundColor: ["#A060FC", "#FF8D39", "#8FC743", "#5BC3F5"],
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setInterval(() => setDate(new Date()), 30000)
  }, [])


  return (
    <div className="p-1 rounded-lg p-5 first:mr-2 shadow-md h-full text-center border-2 bg-white">
      <div className="flex flex-row justify-between">
        <h2 className="text-xl font-bold text-gray-500">{heading}</h2>
        <p className="text-sm text-gray-500">
          {
            date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })
          }
          {"  "}
          {
            date.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          }
        </p>
      </div>
      <div className="flex flex-col sm:flex-row justify-center p-3 pr-5 items-center pt-5 ">
        <div className="flex justify-between items-center w-full h-80">
          <div>
            <Pie data={data} options={options} style={{ height: '13rem', width: '13rem' }} />
          </div>
          <div>
            <ul className="list-disc text-left pr-10">
              <li className="text-[#A060FC] mb-3">Today</li>
              <li className="text-[#FF8D39] mb-3">Yesterday</li>
              <li className="text-[#8FC743] mb-3">Last Month</li>
              <li className="text-[#8FC743] mb-3">Tomorrow</li>
            
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-between">
              <div className="text-gray-500">{field.name}</div>
              <div className="text-gray-500">{field.value}</div>
            </div>
            <div className="w-full mb-5 h-2 bg-gray-300 rounded-full">
              <div
                className={`h-full  rounded-full ${index === 0
                  ? "bg-[#A060FC]"
                  : index === 1
                    ? "bg-[#FF8D39]"
                    : index === 2
                      ? "bg-[#8FC743]"
                      : "bg-[#5BC3F5]"
                  }`}
                style={{ width: `${field.value}%` }}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Chart;