import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TopHeader from "../../Ui/topHeader";
import building from "./Assets/building.png";
import location from "./Assets/location.png";
import wallet from "./Assets/wallet.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '../../Ui/table';

const backendUrl = process.env.REACT_APP_API_BASE_URL;

const Wallet = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [transactionData, setTransactionData] = useState([]);
    const [totalCreditedPoints,setTotalCreditedPoints]=useState(0)
    const [totalDebitedPoints,setTotalDebitedPoints]=useState(0)
    const userId = state.userId;

    useEffect(() => {
        getTransactions();
    }, [state.userId])



    const getTransactions = async () => {
        await axios.get(`${backendUrl}/api/users/wallet/filter?tUser=${userId}`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setTransactionData(res.data?.reverse())
                let totalDebited = 0;
                let totalCredited = 0;
                res.data.forEach(transaction => {
                    if (transaction.eType === 'Debited') {
                        totalDebited += transaction.aAmount;
                    } else if (transaction.eType === 'Credited') {
                        totalCredited += transaction.aAmount;
                    }
                })
                setTotalDebitedPoints(totalDebited)
                setTotalCreditedPoints(totalCredited)
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const formattedDate = (dateString) => {
        return (
            new Date(dateString).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
        )
    }

    const transactionColumn = [
        {
            name: ' Transaction Id  ',
            selector: transactionData => `${transactionData?.transactionId}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Points',
            selector: transactionData => `${transactionData?.aAmount}`,
            width: "80px",
            center: "true"
        },
        {
            name: ' Date ',
            selector: transactionData => `${formattedDate(transactionData?.dTransactionDate)}`,
            width: "180px",
            center: "true"
        },
        {
            name: ' Type ',
            selector: transactionData => `${transactionData?.eType}`,
            width: "100px",
            center: "true"
        },
        {
            name: ' Message ',
            selector: transactionData => `${transactionData?.sMessage}`,
            width: "150px",
            center: "true",
            wrap:true
        },

    ]

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex items-center pt-4 pb-4 pl-8">
                    <IconButton
                        size='large'
                        className='bg-white shadow-sm'
                        sx={{
                            background: "white"
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon
                            fontSize='inherit'
                        />
                    </IconButton>
                    <p className=' text-blue-800 font-black text-3xl drop-shadow-sm'>Wallet</p>
                </div>
                <div className='flex pl-2 space-x-2 pb-8'>
                    <div className=''>
                        <div className=' bg-white p-4 rounded-xl shadow-md'>
                            <p className='text-blue-800 font-bold pb-4'>Points</p>
                            <p className=' text-4xl font-extrabold drop-shadow-sm'>{transactionData[0]?.tWallet?.amount ?? 0}</p>
                            <div className='flex space-x-12 pt-8'>
                                <div className='flex items-center space-x-4'>
                                    <div
                                        className='text-white p-2 rounded-md shadow-md'
                                        style={{
                                            background: "#8FC743"
                                        }}
                                    >
                                        <ArrowUpwardIcon />
                                    </div>
                                    <p style={{ color: "#8FC743" }}>{'+'}{totalCreditedPoints} Points</p>
                                </div>
                                <div className='flex items-center space-x-4'>
                                    <div
                                        className='text-white p-2 rounded-md shadow-md'
                                        style={{
                                            background: "#731717"
                                        }}
                                    >
                                        <ArrowUpwardIcon />
                                    </div>
                                    <p style={{ color: "#731717" }}>{'-'}{totalDebitedPoints} Points</p>
                                </div>
                            </div>
                        </div>
                        <div className=' bg-white p-4 mt-8 rounded-xl shadow-md'>
                            <p className='text-blue-800 font-bold pb-4'>Information</p>
                            <div className=' flex items-center p-2'>
                                <img
                                    src={building}
                                    alt='building'
                                />
                                <p className=' text-sm font-bold text-gray-400 pl-4 pr-20'>Location</p>
                                <p className=' text-sm font-bold'>{state?.wallet?.location}</p>
                            </div>
                            <div className=' flex items-center p-2'>
                                <img
                                    src={location}
                                    alt='building'
                                />
                                <p className=' text-sm font-bold mr-1 text-gray-400 pl-4 pr-20'>Address</p>
                                <p className=' text-sm font-bold'>{state?.wallet?.address}</p>
                            </div>
                            <div className=' flex items-center p-2'>
                                <img
                                    src={wallet}
                                    alt='building'
                                />
                                <p className=' text-sm font-bold text-gray-400 pl-4 pr-24'>Wallet</p>
                                <p className=' text-sm font-bold'>{state?.wallet?.wid}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-3/4' >
                        <div className=' bg-white p-4 rounded-xl shadow-md mr-4' style={{marginRight:'3rem'}}>
                            <p className=' font-bold text-blue-800'>Points History</p>
                            <div className='pt-4 '>
                                <Table
                                    columns={transactionColumn}
                                    data={transactionData}
                                    placeholder="Search"
                                />

                                {/* {
                                    state?.wallet?.transaction_history.map((item) => {
                                        if(item.type === 'paid') {
                                            return <PaidTo date={item.date} recieved_from ={item.recieved_from} paid_to={item.paid_to} amount={item.amount}/>
                                        }
                                        else {
                                            return <ReceivedFrom date={item.date} recieved_from ={item.recieved_from} paid_to={item.paid_to} amount={item.amount}/>
                                        }
                                    })
                                } */}


                                {/* <PaidTo />
                                <ReceivedFrom />
                                <ReceivedFrom />
                                <ReceivedFrom />
                                <ReceivedFrom />
                                <ReceivedFrom />
                                <ReceivedFrom /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wallet;