import { createSlice } from "@reduxjs/toolkit"
import { SuspendClient, UnSuspendClient, deleteclient, getAllClients, getSuspendclients, getclientdetails } from "../actions/clientaction";


const initialState = {
    loading:false,
    clientsdata:[],
    suspendclients: [],
    status:'idle',
    error:null,
    success:false,


}


const clientsSlice = createSlice({
    name:'client',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllClients.pending] : (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading'
     },
     [getAllClients.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.clientsdata = payload;
        state.success = true;
        state.status = "suceeded";
     },
     [getAllClients.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
        state.status = 'failed'
     },
     [deleteclient.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },

     [deleteclient.fulfilled]: (state, {payload}) => {
        state.loading = false;
        state.success = true;
     },
     [deleteclient.error] : (state,{payload}) => {
        state.loading = false;
        state.error = payload;
     },
     [getclientdetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
     },
     [getclientdetails.fulfilled]: (state,{payload}) => {
      state.loading = false;
      state.clientsdata = payload;
      state.success = true;
     
     },
     [getclientdetails.error]: (state,{payload}) => {
      state.loading = false;
      state.error = payload;
     } ,
     [getSuspendclients.pending]: (state) => {
      state.loading = true;
      state.error = null;
     },
     [getSuspendclients.fulfilled]: (state,{payload}) => {
      state.loading = false;
      state.suspendclients = payload;
      state.success = true;
     
     },
     [getSuspendclients.error]: (state,{payload}) => {
      state.loading = false;
      state.error = payload;
     } ,
     [SuspendClient.pending]: (state) => {
      state.loading = true;
      state.error = null;
     },
     [SuspendClient.fulfilled]: (state,{payload}) => {
      state.loading = false;
     
      state.success = true;
     
     },
     [SuspendClient.error]: (state,{payload}) => {
      state.loading = false;
      state.error = payload;
     } ,
     [UnSuspendClient.pending]: (state) => {
      state.loading = true;
      state.error = null;
     },
     [UnSuspendClient.fulfilled]: (state,{payload}) => {
      state.loading = false;
     
      state.success = true;
     
     },
     [UnSuspendClient.error]: (state,{payload}) => {
      state.loading = false;
      state.error = payload;
     } 

    }
})



export default clientsSlice.reducer;