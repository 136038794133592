import TopHeader from "../../Ui/topHeader";
import {  Paper  } from "@mui/material";
import { useLocation} from "react-router-dom";

import MedicationStepper from './components/medication';



const AddPrescription = () => {
    const { state: data } = useLocation()
    return (
        <div>
            <TopHeader />
            <div className=" mt-6 pl-5 mb-5 mr-4">
                <Paper className=" mt-4  p-5">
                    <p className="text-2xl font-bold text-blue-900 mb-5">Add Medication</p>
                    <MedicationStepper
                        data={data}
                    />
                </Paper>
            </div>
        </div>
    )
}

export default AddPrescription;