import { useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import MedicationIcon from '@mui/icons-material/Medication';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TopHeader from '../../../Ui/topHeader';
import Table from '../../../Ui/table';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { deletePackage, getAllPackages } from '../../../store/actions/packagesaction';
import LoadingBackdrop from '../../../Ui/LoadinSpinner';
import { getAllProducts } from '../../../store/actions/productaction';
import { successAlert } from '../../../Ui/Alert';
import { Avatar } from 'antd';
import PackageFilterTable from '../../../Ui/CustomTable/PackageFilterTable';
import exportFromJSON from 'export-from-json';

const apiUrl = process.env.REACT_APP_API_BASE_URL
const Packages = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [packagesData, setPackageData] = useState();
    const [open, setOpen] = useState(false);
    const [deletePackageId, setDeletePackageId] = useState();

    const loading = useSelector(
        (state) => state.packages.loading
    )

    useEffect(() => {
        dispatch(getAllProducts());
        getPackages()
    }, [dispatch])

    const getPackages = async () => {
        const response = await dispatch(getAllPackages());
        if (response && response.payload && Array.isArray(response.payload)) {
            const data = response?.payload.map((item, index) => {
                return {
                    srNo: index + 1,
                    ...item,
                }
            })
            setPackageData(data)
        }
    }

    const handleDeletePackage = async () => {
        await dispatch(deletePackage(deletePackageId))
        await dispatch(getAllPackages());
        getPackages()
        successAlert("Package deleted Successfully")
        setOpen(false)
    }
    const handleClickOpen = (id) => {
        setDeletePackageId(id);
        setOpen(true);
    }

    const handleClickClose = () => {
        setOpen(false);
        setDeletePackageId("")
    }

    const handleEdit = (data) => {
        data?.packages_Cost?.map((item) => {
            return {
                tab_quantity: item.tabQuantity,
                package_duration: item.packageDuration,
                per_tab_price: item.perTabPrice,
                discount: item.discount,
                savings: item.savings,

            }
        })
        navigate('/ivm/updatePackage', { state: data })
    }
    const exportData = () => {
        const fileName = "All Packages";
        const exportType = exportFromJSON.types.csv;
        const serializedData = packagesData.map((item, index) => ({
            "SR.No": index + 1,
            Package: item?.name,
            Category: item?.category?.name,
            "No. Of Product": item?.products.length,
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }

    const columns = [
        {
            name: "Sr.No",
            selector: row => row?.srNo,
            center: true
        },
        {
            name: "Package",
            selector: row => row?.name,
            width: '180px',
            wrap: "true",
            center: true
        },
        {
            name: 'Photo',
            cell: (row) => (
                <div className="mt-3 mb-3">
                    <Avatar
                        src={`${apiUrl}/uploads/${row?.image}`}
                        alt={row.name}
                        className="shadow-md rounded-xl"
                        sx={{
                            width: 300,
                            height: 300,
                        }}
                        variant="rounded"
                        style={{
                            minWidth: '5rem',
                            minHeight: '5rem'
                        }}
                    />
                </div>
            ),
            center: "true"
        },
        {
            name: "Category",
            selector: row => row?.category?.name,
            width: '200px',
            wrap: "true",
            center: true
        },
        {
            name: "No. of Product",
            selector: row => row?.products.length,
            width: '200px',
            wrap: "true",
            center: true
        },

        {
            name: "Actions",
            cell: (row) => (
                <div className='flex items-center'>
                    <IconButton
                        onClick={() => handleEdit(row)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                    >
                        <DeleteOutlineOutlinedIcon
                            onClick={() => handleClickOpen(row?._id)}
                        />
                    </IconButton>
                </div>
            ),
            style: { marginLeft: "1.8em" }
        }
    ];

    return (
        <>
            <TopHeader />
            <div className=" ml-5 mt-5">
                <div className=' flex flex-col justify-items-start'>
                    <h1 className=" text-4xl text-blue-800 font-bold">Treatment Packages</h1>
                    <p className=" text-sm text-neutral-600 font-bold ml-1">Packages containg medicines with variable pricing.</p>
                </div>
            </div>
            <div className="ml-5 mr-5 mt-5">
                <div className=" flex justify-end mb-5">
                    <Button
                        variant='contained'
                        size='large'
                        endIcon={<MedicationIcon />}
                        onClick={() => navigate("/ivm/newPackage")}
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            },
                            marginLeft: "20px",
                        }}

                    >Create Package</Button>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#19398B",
                                color: "white",
                                fontWeight: "bold"
                            },
                            marginLeft: "20px",
                        }}
                        onClick={exportData}
                    >
                        EXPORT
                    </Button>
                </div>
                {loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}
                {/* <Table
                    columns={columns}
                    data={packagesData}
                    placeholder="Search Packages"
                /> */}
                <PackageFilterTable
                    columns={columns}
                    data={packagesData}
                    placeholder="Search Packages"
                />
                <Dialog
                    open={open}
                    onClose={() => handleClickClose()}
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to delete the Package?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>

                        <Button onClick={() => handleClickClose()} variant="outlined">No</Button>
                        <Button
                            onClick={() => handleDeletePackage()}
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                            }}
                        >Yes</Button>

                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default Packages;