import { useState } from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import edit from "./Assets/edit.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { deleteBlog, getAllBogs } from "../../store/actions/blogaction";
import { successAlert } from "../../Ui/Alert";

function AllPages() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
   const [pageid,setpageid] = useState();
    const handleClickOpen = (pageid) => {
        setpageid(pageid);
        setOpen(true);
    }

    const handleClickClose = () => {
        setOpen(false);
    }
         
    const loading = useSelector(
        (state) => state.blogs.loading
    )
    const dataFetchedRef = useRef(false);
    const dispatch = useDispatch();



    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
       getallpages();

    }, [dispatch]);

  const [pagesdata,setpagesdata] = useState([]);

    const getallpages = async () => {
        const fetchedata = await dispatch(getAllBogs());

       const backendUrl = 'https://block-api.averps.org'
        

        const data = fetchedata.payload.map(blog => {
            return {
                id: blog._id,
                title:blog.title,
                description:blog.description,
                thumbnailimage:backendUrl+blog.thumbnailImageUrl,
                coverimage: backendUrl+blog.coverImageUrl,
                content:blog.content,
                category:blog.category,
                status:blog.status,

            }
        })


         setpagesdata(data);
    }


    const handledeletepages = async () => {
        await  dispatch(deleteBlog(pageid));

        dispatch(getAllBogs());
          setOpen(false);
        await  getallpages();
        successAlert('Blog Deleted SuccessFully!!')
        navigate('/cm');
      
           console.log("deleted successfully!!");
    }

    const handleEdit = (data) => {
       navigate("editpage",{state:data});
    }

    const columns = [
        {
            name: 'Blog Title',
            selector: row => row.title,
            width: '150px',
            wrap:true
        },
        {
            name: "Image",
            cell: (row) => (
                <Avatar 
                    src={row.thumbnailimage}
                    variant="square"
                    alt={row.title}
                />
            ),
            style: {paddingLeft: "12px"}
        },
        {
            name: 'Blog Description',
            selector: row => row.description.slice(0,100),
            width: "500px",
            wrap: true,
        },
        {
            name: "Action",
            cell: (row) => (
              <div className="flex items-center">
                <IconButton
                  style={{
                    color: "#2C3E50",
                  }}
                  onClick={() => handleEdit(row)}
                >
                  <img src={edit} alt="edit" />
                </IconButton>
                <IconButton
                  style={{
                    color: "#EE4B4B",
                  }}
                  onClick={() => handleClickOpen(row.id)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </div>
            ),
            style: {marginLeft: "2em"}
          },
    ]
    

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                    <div className=" flex flex-col justify-start">
                        <p className=" text-3xl font-bold text-blue-950">All Blogs</p>
                        <p className=" text-neutral-500 font-semibold">Handle all your blogs here.</p>
                    </div>
                    <div className=" flex gap-x-4">
                        <Button 
                            onClick={() => navigate("newblog")}
                            variant="contained" 
                            size="large"
                            sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                    marginLeft: "20px"
                                }}
                        >Create New Blog</Button>
                    </div>
                </div>
                <div className=" pl-5 pr-5">
                { loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}
                    {/* Table */}
                    <Table 
                        columns={columns}
                        data={pagesdata}
                        isSelectable="true"
                        placeholder="All Blogs"
                    />

                    <Dialog 
                      open={open}
                      onClose={handleClickClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the blog?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            
                                <Button onClick={handleClickClose} variant="outlined">No</Button>
                                <Button 
                                    onClick={() =>handledeletepages()}
                                    sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                    }}
                                >Yes</Button>
                            
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default AllPages;