import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            const payload = {
                email: username,
                password: password
            }
            const { data } = await axios.post(
                `${backendUrl}/api/users/login`,
                payload,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return rejectWithValue(error.response.data.message);
            }
            else {
                console.log(error)
                return rejectWithValue(error.message);
            }
        }
    }
)


export const userLogout = createAsyncThunk(
    'auth/logout',
    async ({ username }, { rejectWithValue }) => {
        try {
            const payload = { usname: username };
            const { data } = await axios.get('')
            
        } catch (error) {
            if (error.response && error.response.data.msg) {
                return rejectWithValue(error.response.data.msg);
            }
            else {
                return rejectWithValue(error.msg);
            }
        }
    }
)



export const forgetPassword = createAsyncThunk(
    'auth/forgetpassword',
    async ({ email, url }, { rejectWithValue }) => {
        try {
            const payload = { email: email, url: url };
            const { data } = await axios.post(`${backendUrl}/api/users/forgot-password`, payload);
            sessionStorage.setItem('token', data.token);
            return data;

        } catch (error) {
            if (error.response && error.response.data.msg) {
                return rejectWithValue(error.response.data.msg);
            }
            else {
                return rejectWithValue(error.msg);
            }
        }
    }
)

export const ResetPassword = createAsyncThunk(
    'auth/ResetPassword',
    async ({ password, token }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const payload = { password: password }
            const { data } = await axios.post(
                `${backendUrl}/api/users/reset-password/` + token, payload,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.msg) {
                console.log(error);
                return rejectWithValue(error.response.data.msg);
            }
            else {
                return rejectWithValue(error.msg);
            }
        }
    }
)

