import notfound from "../assets/not-found.mp4";

const NotFound = () => {
    return (
        <div className="grid place-content-center h-screen">
            <div className=" mx-auto">
                <video
                    src={notfound}
                    autoPlay
                    loop
                    muted
                />
                <p className=" text-2xl text-center font-extrabold text-blue-900 mb-4">Page Not Found</p>
            </div>
        </div>
    );
}

export default NotFound;