import { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import { useDispatch } from "react-redux";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import axios from "axios";
import { errorAlert, successAlert } from "../../Ui/Alert";
import { deleteUser } from "../../store/actions/usersActions";
import exportFromJSON from "export-from-json";

const apiUrl = process.env.REACT_APP_API_BASE_URL
const SuspendClients = () => {

    const [suspendedClients, setSuspendedClients] = useState([])
    const [open, setOpen] = useState(false);
    const [isOpenForActive, setIsOpenForActive] = useState(false)
    const [deleteUserId, setDeleteUserId] = useState();
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch();

    useEffect(() => {
        getSuspendedClients()
    }, [])

    const handleClickOpen = (id) => {
        setDeleteUserId(id)
        setOpen(true);
    }

    const getSuspendedClients = async () => {
        await axios.get(`${apiUrl}/api/order/get-client-orders?isActive=${false}`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setSuspendedClients(res.data)
            }
        }).catch((error) => {
            errorAlert(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleOpenForActive = async (userId) => {
        setIsOpenForActive(true)
        setDeleteUserId(userId)
        setOpen(true)
    }
    const handleClickClose = async () => {
        setIsOpenForActive(false)
        setDeleteUserId("")
        setOpen(false)
    }

    const handleDeleteUser = async () => {
        await dispatch(deleteUser(deleteUserId));
        setOpen(false);
        await getSuspendedClients();
        successAlert("Deleted Successfully!!")
    }

    const handleActiveUser = async () => {
        await axios.patch(`${apiUrl}/api/users/activate/${deleteUserId}`).then(async (res) => {
            if (res && res.data && res.data.success == true) {
                successAlert("User is Activated successfully")
                setOpen(false)
                setIsOpenForActive(false)
                setDeleteUserId(null)
                await getSuspendedClients();
            }
        }).catch((error) => {
            errorAlert(error.message)
        })
    }
    const columns = [
        {
            name: 'Photo',
            selector: row => (<Avatar src={apiUrl+"/uploads/"+row?.imageUrl} alt="profile" />),
            center: true
        },
        {
            name: 'Client Name',
            selector: row => `${row?.user?.personalInfo?.first_name} ${row?.user?.personalInfo?.last_name}`,
            center: true
        },
        {
            name: 'Contact No',
            selector: row => `${row?.user?.personalInfo?.country_code??""} ${row?.user?.personalInfo?.phone_number}`,
            center: true,
            width:'200px'
        },
        {
            name: 'Total Treatment',
            selector: row => row?.consultation?.length,
            center: true,
            width: "150px"
        },
        {
            name: <div>Total Purchase<br />Product</div>,
            selector: row => row?.orders?.length,
            center: true,
            width: "150px"
        },
        {
            name: "Suspend Reason",
            selector: row => row?.user?.reasonForInActive,
            center: true,
            width: "220px",
            wrap:true
        },
        {
            name: "Action",
            cell: (row) => (
                <div className=" ml-14 flex items-center">
                    <IconButton
                        style={{
                            color: "red",
                        }}
                        onClick={() => handleOpenForActive(row?.user?._id)}
                    >
                        <PersonOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton

                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row?.user?._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
            center: true
        },
    ]
    const exportData = () => {
        const fileName = 'Suspended users'
        const exportType = exportFromJSON.types.csv
        const serializedData = suspendedClients.map((item, index) => ({
            sr: index + 1,
            Name: `${item?.user?.personalInfo?.first_name ?? "--"} ${item?.user?.personalInfo?.last_name ?? "--"}`,
            Email: item?.user?.email ?? '---',
            Contact: `=${item?.user?.personalInfo?.phone_number ? `TEXT("${item?.user?.personalInfo?.phone_number}", "0")` : '""'}`,
            Gender: `${item?.user?.personalInfo?.sex ?? "---"}`,
            DOB: `=TEXT("${item?.user?.personalInfo?.dob?.split('T')[0]}", "yyyy-mm-dd")`,
            Address: `${item?.user?.deliveryAddress ? item?.user?.deliveryAddress[0]?.address : "---"}`,
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }
    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                    <p className=" text-2xl font-bold pt-4 pl-5 pb-4 text-blue-950">Suspend Clients</p>
                    <div className=" flex gap-x-4">
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                marginLeft: "20px"
                            }}
                            onClick={exportData}
                        >Export All</Button>
                    </div>
                </div>
                <div className=" pl-5 pr-5">
                    {isLoading && <LoadingBackdrop isloading={isLoading}></LoadingBackdrop>}
                    {/* Table */}
                    <Table
                        columns={columns}
                        data={suspendedClients}
                        isSelectable="true"
                        placeholder="Search Suspended Clients"
                    />
                    <Dialog
                        open={open}
                        onClose={handleClickClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {
                                    isOpenForActive ? "Do you want to active this user?" : "Do you want to delete this user?"
                                }

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>

                            <Button onClick={() => setOpen(false)} variant="outlined">No</Button>
                            <Button
                                onClick={isOpenForActive ? handleActiveUser : handleDeleteUser}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default SuspendClients;