import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllPages = createAsyncThunk(
    'pages/getAllPages',
    async () => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.get(
                `${backendUrl}/pages`,
               
                config
            )        
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)




export const AddNewPage = createAsyncThunk(
    'pages/AddNewPage',
    async (pagedetail) => {
        try {
            const config = {
                   headers: {
                    "Content-Type": "multipart/form-data" 
                   }
            }

        
            const {data} = await axios.post(
                `${backendUrl}/addpages`,
                 pagedetail,
                config
            )        
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)


export const deletePage = createAsyncThunk(
    'pages/deletePage',
    async (pageid) => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.delete(
                `${backendUrl}/deletepage`,
                {
                    params: {
                        pid:pageid
                    }
                }
               ,
                config
            )      
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)
