import { useState } from "react";
import { Box } from "@mui/material";

const PreviewImage = ({ file, variant }) => {
    const [preview, setPreview] = useState({});

    if (file) {
        const reader = new FileReader();
        reader.onload = () => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }

    return (
        <Box>
            <img
                alt="profile"
                src={preview}
                className=" rounded-full flex items-center justify-center h-[150px] w-[150px]"
            />
        </Box>
    )
}

export default PreviewImage;