import TopHeader from "../../../Ui/topHeader";
import TreatStepper from "./components/TreatStepper";

const NewWhatWeTreat = () => {
    return(
        <div>
        <TopHeader />
            <div className="pt-6 pl-5 pb-6">
                <h1 className="text-3xl text-blue-900 font-black font-roboto">Add New Content</h1>
                <p className=" text-blue-950 font-bold font-roboto text-sm">Customize Here What We Treat Section</p>
            </div>
            <div className="p-3">
                <TreatStepper />
            </div>
    </div>
    )
}

export default NewWhatWeTreat;