import TopHeader from "../../../Ui/topHeader";
import PackageStepper2 from "./components/package";

const NewPackage = () => {
    return (
        <>
            <TopHeader />
            <div className="pt-6 pl-5 pb-6">
                <h1 className=" text-3xl text-blue-900 font-black font-roboto">Add Package</h1>
                <p className=" text-neutral-500 mt-2 font-bold font-roboto text-sm">Create new packages with variable pricing and duration.</p>
            </div>
            <div className="p-3">
                <PackageStepper2 />
            </div>
        </>
    )
}

export default NewPackage;