import { useEffect, useState } from 'react';

import {
    Avatar,
    IconButton 
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

export const ImageUpload = ({ image, onRemove }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true)
    }, [])

    if (!isMounted) {
        return null;
    }

    return(
        <div className="">
                {
                    image 
                    &&
                    <div className=" flex items-center">
                       <div className="relative overflow-hidden">
                        <div className=" absolute z-10 right-0">
                                <IconButton
                                    onClick={onRemove}
                                >
                                    <DeleteIcon 
                                        className=" text-red-500"
                                        fontSize="medium"
                                    />
                                </IconButton>
                            </div>
                            <div className="">
                                {
                                    image.url?(
                                        <Avatar 
                                            src={image.url}
                                            alt={image.name}
                                            variant="square"
                                            sx={{
                                                width: "200px",
                                                height: "200px"
                                            }}
                                            className=" rounded-md"
                                        />
                                    ):(
                                        <Avatar 
                                        src={image}
                                        alt="image"
                                        variant="square"
                                        sx={{
                                            width: "200px",
                                            height: "200px"
                                        }}
                                        className=" rounded-md"
                                    />  
                                    )
                                }
                            </div>
                       </div>
                    </div>
                }
            </div>
    )
}