import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const backendUrl = process.env.REACT_APP_API_BASE_URL

const config = {
    headers: {
        'Content-Type': 'application/json'
    },
}
const config2 = {
    headers: {
        'Content-Type': "multipart/form-data"
    }
}


export const getallusers = createAsyncThunk(
    'users/getallusers',
    async () => {
        try {
            const { data } = await axios.get(
                `${backendUrl}/api/users/getAllUser`,
                config
            )
            if (data.issuccess === true) {
                localStorage.setItem('userToken', data.usname);
            }
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)


export const addNewUser = createAsyncThunk(
    'users/addnewuser',
    async (userDetails) => {
        try {
            const { data } = await axios.post(
                `${backendUrl}/api/users/register`,
                userDetails,
                config2
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)


export const updateUser = createAsyncThunk(
    'users/updateuser',
    async (userDetails) => {
        try {
            const { data } = await axios.put(
                `${backendUrl}/api/users/updateUser/${userDetails.userid}`,
                userDetails.updateUserData,
                config2
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)



export const deleteUser = createAsyncThunk(
    'users/deleteuser',
    async (userId) => {
        try {
            const { data } = await axios.delete(
                `${backendUrl}/api/users/deleteUser/${userId}`,
                config2
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)


export const getSuspendedUsers = createAsyncThunk(
    'users/getsuspendUsers',
    async () => {
        try {
            const { data } = await axios.get(
                `${backendUrl}/api/users/suspendedUser`,
                config2
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)



export const suspendUser = createAsyncThunk(
    'users/suspendUser',
    async (id) => {
        try {
            const { data } = await axios.put(
                `${backendUrl}/suspendUser`,
                {
                    params: {
                        uid: id
                    }
                },
                config2
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)



export const unSuspendUser = createAsyncThunk(
    'users/unSuspendUser',
    async (id) => {
        try {
            const { data } = await axios.put(
                `${backendUrl}/unsuspendUser`,
                {
                    params: {
                        uid: id
                    }
                },
                config2
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)