import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CountryCodes from "../../assets/CountryCodes.json"

import {
    Button,
    FormControl,
    FormLabel,
    InputAdornment,
    MenuItem,
    Switch,
    TextField
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import TopHeader from "../../Ui/topHeader";
import AuthContext from "../../Ui/authContext";
import { ImageUpload } from "../../Ui/imageUpload";
import { errorAlert, successAlert } from "../../Ui/Alert";
import moment from "moment";
import { roleTag } from "../../utils/constant";
import countryCallingCodes from 'country-calling-codes';
const sex = [
    {
        value: "male",
        label: "Male",
    },
    {
        value: "female",
        label: "Female",
    },
    {
        value: "other",
        label: "Other"
    }
]

const g6pd = [
    {
        value: "positive",
        label: "Positive"
    },
    {
        value: "negative",
        label: "Negative"
    }
]

const newUserSchema = yup.object().shape({
    photo: yup.mixed().nullable(),
    username: yup.string().required("Username Required"),
    firstname: yup.string().required("First name Required"),
    lastname: yup.string().required("Last name Required"),
    email: yup.string().required("Email Required").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, {
        message: "Enter a valid email address",
    }),
    password: yup.string().required("Password Required"),
    contact: yup.string().required("Phone number is required"),
    usertype: yup.string().required("User Type is required"),
    // passport: yup.string().required("NCIR/Passport required"),
    dob: yup.date().required("Date is required"),
    nationality: yup.string().required("Nationality required"),
    sex: yup.string().required("Sex Required"),
    postCode: yup.string().required("Post Code Required").matches(/^\d{6}$/, {
        message: "Enter valid postcode",
    }),
    city: yup.string().required("City Requried"),
    address: yup.string().required("Address required"),
    // g6pd: yup.string().when(['usertype'], {
    //     is: (usertype) => usertype == 'patient',
    //     then: yup.string().required("g6pd required"),
    //     otherwise: yup.string().notRequired("g6pd required"),
    // }),
    // allergies: yup.string().when('usertype', {
    //     is:(usertype) => usertype == 'patient',
    //     then: yup.string().required("allergies required"),
    //     otherwise: yup.string().notRequired("allergies is not required"),
    // }),

})



const NewUser = () => {
    const [base64, setBase64] = useState("");
    const [img, setImg] = useState("");
    const [image, setImage] = useState("");
    const [roles, setRoles] = useState([])
    const fileInputRef = useRef(null);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isPatient, setIsPatient] = useState(false)
    const [isDoctor, setIsDoctor] = useState(false)
    const [isMember, setIsMember] = useState(false)
    const [callingCodes, setCallingCodes] = useState([]);
    const [countryCodeData, setCountryCodeData] = useState([]);


    useEffect(() => {
        getAllRoles()
        setCountryCodeData(CountryCodes);
    }, [])

    const getAllRoles = async () => {
        await axios.get(`${backendUrl}/api/roles`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setRoles(res.data)
            }
        }).catch((error) => {
            errorAlert("Unable get roles")
        })
    }
    const selectFile = () => {
        fileInputRef.current.click();
    }

    const onFileSelect = (event) => {
        const files = event.target.files;
        setImg(event.target.files)
        if (files[0]) {
            setImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            );
        }
    }

    // Registering the languages
    countries.registerLocale(enLocale);
    const countryObj = countries.getNames("en", { select: "official" });
    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        }
    });

    const backendUrl = process.env.REACT_APP_API_BASE_URL

    const onSubmit = async (values, actions) => {
        values.photo = image;
        let pic = base64;
        setBase64("");
        const { username, email, firstname, lastname, dob, contact, password, postCode, city, address, g6pd, sex, passport, allergies, nationality, usertype, department, description, isDisplay, country_code} = values;
        var personalInfo = {
            first_name: firstname,
            last_name: lastname,
            dob: dob,
            phone_number: contact,
            sex: sex,
            nationality: nationality,
            g6pd: g6pd,
            nric_passport_no: passport,
            drug_allergies: allergies,
            country_code: country_code
        }

        let newAddress = [
            {
                postcode: postCode,
                city: city,
                address: address
            }
        ]

        const newUser = {
            username: username,
            email: email,
            password: password,
            image: image.file,
            userType: usertype,
            department: department,
            description: description,
            isActive: true,
            personalInfo: personalInfo,
            deliveryAddress: newAddress.map((place) => {
                return {
                    postcode: place.postcode,
                    city: place.city,
                    address: place.address
                }
            }),
            resetPasswordToken: "",
            resetPasswordExpires: null,
            isDisplay: isDisplay
        }

        await axios({
            method: "post",
            url: `${backendUrl}/api/users/register`,
            data: newUser,
            headers: { 'Content-Type': "multipart/form-data" }
        })
            .then(() => {
                successAlert("User created successfully!!")
                actions.resetForm();
                navigate(-1);
            })
            .catch((error) => {
                errorAlert(error.response.data.error)
            })
    }

    const handleBackClick = () => {
        auth.login();
        navigate("/user")
    }

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            photo: "",
            username: "",
            firstname: "",
            lastname: "",
            email: "",
            dob: "",
            password: "",
            contact: "",
            countryCode: "+65",
            usertype: roles[0]?._id ?? "---",
            passport: "",
            nationality: "SG",
            sex: "male",
            postCode: "",
            city: "",
            address: "",
            g6pd: "",
            allergies: "",
            department: "",
            description: "",
            isDisplay: true,
        },
        validationSchema: newUserSchema,
        onSubmit,
    })

    return (
        <div>
            <TopHeader />
            <div>
                {/* secondHeader */}
                <div className=" pt-6 pl-5 pb-6">
                    <h1 className=" text-3xl text-blue-900 font-bold">Add New User</h1>
                </div>
                <div className=" mt-4 pl-5">
                    <form className="" onSubmit={handleSubmit} autoComplete="off">
                        <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                            <ImageUpload
                                image={image}
                                onRemove={() => setImage("")}
                            />
                            <input
                                id="photo"
                                name="photo"
                                type="file"
                                accept=".jpg, .png, .jpeg"
                                ref={fileInputRef}
                                className="hidden"
                                onChange={onFileSelect}
                            />
                            <div
                                className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                onClick={selectFile}
                            >
                                <p className=" font-extrabold text-sm ">Profile Photo</p>
                                <AddPhotoAlternateIcon className="" fontSize="small" />
                            </div>
                        </div>
                        <div className=" grid grid-cols-3 mt-4 mb-4 mr-4 space-x-12">
                            <FormControl>
                                <TextField
                                    id="username"
                                    type="text"
                                    placeholder="Enter Username"
                                    label="Username"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.username && touched.username)}
                                    helperText={
                                        errors.username &&
                                        touched.username &&
                                        String(errors.username)
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    placeholder="Enter First name"
                                    label="First Name"
                                    value={values.firstname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.firstname && touched.firstname)}
                                    helperText={
                                        errors.firstname &&
                                        touched.firstname &&
                                        String(errors.firstname)
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    placeholder="Enter Last name"
                                    label="Last Name"
                                    value={values.lastname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.lastname && touched.lastname)}
                                    helperText={
                                        errors.lastname &&
                                        touched.lastname &&
                                        String(errors.lastname)
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className='grid grid-cols-3 mt-4 mb-4 mr-4 space-x-12'>

                            <FormControl>
                                <TextField
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder="Enter Email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.email && touched.email)}
                                    helperText={
                                        errors.email &&
                                        touched.email &&
                                        String(errors.email)
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="Enter Password"
                                    label="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.password && touched.password)}
                                    helperText={
                                        errors.password &&
                                        touched.password &&
                                        String(errors.password)
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="dob"
                                    name="dob"
                                    type="date"
                                    placeholder="Enter Date of Birth"
                                    label="Date Of Birth"
                                    value={values.dob}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={Boolean(errors.dob && touched.dob)}
                                    helperText={
                                        errors.dob &&
                                        touched.dob &&
                                        String(errors.dob)
                                    }
                                    inputProps={{
                                        max: moment().format('YYYY-MM-DD')
                                    }}
                                />
                            </FormControl>

                        </div>

                        <div className='grid grid-cols-3 mt-4 mb-4 mr-4 space-x-12'>
                            <FormControl>
                                <TextField
                                    id="contact"
                                    name="contact"
                                    type="number"
                                    placeholder="Enter Contact No."
                                    label="Contact"
                                    value={values.contact}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.contact && touched.contact)}
                                    helperText={
                                        errors.contact &&
                                        touched.contact &&
                                        String(errors.contact)
                                    }
                                    // InputProps={{
                                    //     startAdornment: (
                                    //         <InputAdornment position="start">
                                    //             <TextField
                                    //                 id="countryCode"
                                    //                 name="countryCode"
                                    //                 select
                                    //                 onChange={handleChange}
                                    //                 onBlur={handleBlur}
                                    //                 value={values.countryCode}
                                    //                 variant="standard"
                                    //                 required
                                    //                 error={Boolean(errors.countryCode && touched.countryCode)}
                                    //                 helperText={
                                    //                     errors.countryCode &&
                                    //                     touched.countryCode &&
                                    //                     String(errors.countryCode)
                                    //                 }
                                    //                 style={{
                                    //                     minWidth:"70px",
                                    //                     borderBottom:"none"
                                    //                 }}
                                    //             >
                                    //                 {countryCodeData.map((item, index) => {
                                    //                     return (
                                    //                         <MenuItem value={item?.dial_code} key={index}>{item?.dial_code}</MenuItem>
                                    //                     )
                                    //                 })}
                                    //             </TextField>
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                style={{ position: "relative", height: "0rem" }}
                                            >
                                                <select
                                                    style={{
                                                        outline: "none",
                                                        background: "transparent",
                                                        borderRight: "2px solid #868080",
                                                        height: "21px",
                                                        minWidth: "5rem",
                                                        color: "black"
                                                    }}
                                                    id="country_code"
                                                    name="country_code"
                                                    value={values.country_code}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {countryCodeData.map((item, index) => (
                                                        <option value={item?.dial_code} key={index}>
                                                            {item?.dial_code}
                                                        </option>
                                                    ))}
                                                </select>
                                            </InputAdornment>
                                        ),
                                    }}
                                ></TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="passport"
                                    name="passport"
                                    placeholder="Enter Passport"
                                    label="NRIC/Passport No."
                                    value={values.passport}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    // required
                                    error={Boolean(errors.passport && touched.passport)}
                                    helperText={
                                        errors.passport &&
                                        touched.passport &&
                                        String(errors.passport)
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="nationality"
                                    name="nationality"
                                    label="Nationality"
                                    select
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.nationality}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.nationality && touched.nationality)}
                                    helperText={
                                        errors.nationality &&
                                        touched.nationality &&
                                        String(errors.nationality)
                                    }
                                >
                                    {
                                        countryArr?.map(({ label, value }) => {
                                            return (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>


                        </div>

                        <div className=" grid grid-cols-3 mt-4 mb-4 mr-4 space-x-12">
                            <FormControl>
                                <TextField
                                    id="sex"
                                    name="sex"
                                    label="Gender"
                                    select
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.sex}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.sex && touched.sex)}
                                    helperText={
                                        errors.sex &&
                                        touched.sex &&
                                        String(errors.sex)
                                    }
                                >
                                    {
                                        sex?.map(({ label, value }) => {
                                            return (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="usertype"
                                    name="usertype"
                                    label="User Type"
                                    select
                                    onChange={(e) => {
                                        setFieldValue('usertype', e.target.value)
                                        const selectedOption = roles.find(item => (item._id === e.target.value));
                                        if (selectedOption && selectedOption.tag === roleTag.Patient) {
                                            setIsPatient(true)
                                            setIsMember(false)
                                            setIsDoctor(false)
                                        }
                                        else if (selectedOption && selectedOption.tag === roleTag.Member) {
                                            setIsMember(true)
                                            setIsDoctor(false)
                                            setIsPatient(false)
                                        }
                                        else if (selectedOption && selectedOption.tag === roleTag.Doctor) {
                                            setIsMember(false)
                                            setIsPatient(false)
                                            setIsDoctor(true)
                                        }
                                        else {
                                            setIsMember(false)
                                            setIsPatient(false)
                                            setIsDoctor(false)
                                        }

                                    }}
                                    onBlur={handleBlur}
                                    value={values.usertype}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.usertype && touched.usertype)}
                                    helperText={
                                        errors.usertype &&
                                        touched.usertype &&
                                        String(errors.usertype)
                                    }
                                >
                                    {
                                        roles?.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={item?._id}
                                                >
                                                    {item?.role}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="postCode"
                                    name="postCode"
                                    type="number"
                                    placeholder="Enter Postcode"
                                    label="Postcode"
                                    value={values.postCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.postCode && touched.postCode)}
                                    helperText={
                                        errors.postCode &&
                                        touched.postCode &&
                                        String(errors.postCode)
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className="grid grid-cols-2 mt-4 mb-4 mr-4 space-x-12" >
                            <FormControl>
                                <TextField
                                    id="city"
                                    name="city"
                                    placeholder="Enter City"
                                    label="City"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.city && touched.city)}
                                    helperText={
                                        errors.city &&
                                        touched.city &&
                                        String(errors.city)
                                    }
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    id="address"
                                    name="address"
                                    placeholder="Enter Address"
                                    label="Address"
                                    multiline
                                    minRows={1}
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="standard"
                                    required
                                    error={Boolean(errors.address && touched.address)}
                                    helperText={
                                        errors.address &&
                                        touched.address &&
                                        String(errors.address)
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className="grid grid-cols-3 mt-4 mb-4 mr-4 space-x-12">
                            {
                                (isDoctor || isMember) && (
                                    <>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="department"
                                                name="department"
                                                placeholder={isMember ? "Enter Designation" : "Enter Specialization"}
                                                label={isMember ? "Designation" : "Specialist On"}
                                                value={values.department}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="standard"
                                                required
                                                error={Boolean(errors.department && touched.department)}
                                                helperText={
                                                    errors.department &&
                                                    touched.department &&
                                                    String(errors.department)
                                                }
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="description"
                                                name="description"
                                                placeholder="Enter Description"
                                                label="Description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="standard"
                                                required
                                                error={Boolean(errors.description && touched.description)}
                                                helperText={
                                                    errors.description &&
                                                    touched.description &&
                                                    String(errors.description)
                                                }
                                            />
                                        </FormControl>
                                        <div style={{ marginTop: "1.5rem", marginLeft: "4rem" }}>
                                            <FormLabel>Display in Frontend</FormLabel>
                                            <Switch
                                                checked={values?.isDisplay}
                                                onChange={(event) => setFieldValue("isDisplay", event.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </>
                                )}
                            {
                                isPatient && (
                                    <>
                                        <FormControl>
                                            <TextField
                                                id="g6pd"
                                                name="g6pd"
                                                label="g6pd"
                                                select
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.g6pd}
                                                variant="standard"
                                                required
                                                error={Boolean(errors.g6pd && touched.g6pd)}
                                                helperText={
                                                    errors.g6pd &&
                                                    touched.g6pd &&
                                                    String(errors.g6pd)
                                                }
                                            // className=" w-96"
                                            >
                                                {
                                                    g6pd?.map(({ label, value }) => {
                                                        return (
                                                            <MenuItem
                                                                key={value}
                                                                value={value}
                                                            >
                                                                {label}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="allergies"
                                                name="allergies"
                                                placeholder="Enter Allergies"
                                                label="Allergies"
                                                multiline
                                                minRows={1}
                                                value={values.allergies}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="standard"
                                                required
                                                error={Boolean(errors.allergies && touched.allergies)}
                                                helperText={
                                                    errors.allergies &&
                                                    touched.allergies &&
                                                    String(errors.allergies)
                                                }
                                            />
                                        </FormControl>
                                    </>

                                )
                            }

                        </div>

                        <div className=" flex gap-5 pl-5 pb-8 mt-12">
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={isSubmitting}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Add User</Button>
                            <Button
                                variant="text"
                                color="info"
                                onClick={handleBackClick}
                                sx={{
                                    "&.MuiButton-root": {
                                        fontWeight: "bold"
                                    },
                                }}
                            >Back</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewUser;