import { useRef, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

import { 
    Button,
    Divider,
    MenuItem,
    FormControl,
    Paper,
    TextField 
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

import TopHeader from "../../Ui/topHeader";
import { ImageUpload } from "../../Ui/imageUpload";

const NewMemberSchema = yup.object().shape({
    photo: yup
          .mixed()
          .nullable(),
    username: yup.string().required("Username required."),
    email: yup.string().required("Email required."),
    firstname: yup.string().required("Firstname required"),
    lastname: yup.string().required("Lastname requried."),
    contact: yup.string().required("Contact required."),
    usertype: yup.string().required("Usertype required."),
    passport: yup.string().required("Passport No. required"),
    nationality: yup.string().required("Nationality required."),
    sex: yup.string().required("Sex required."),
    dob: yup.string().required("Date Of Birth required"),
    phone_number: yup.string().required("Phone number requied."),
    deliveryAddress: yup.array().of(
        yup.object().shape({
            city: yup.string().required("City name requried."),
            postcode: yup.string().required("Post code requried"),
            address: yup.string().required("Address required")
        })
    )

})

const onSubmit = async(values, actions) => {
    console.log(values);
}

const NewMember = () => {

    const [image, setImage] = useState("");
    const fileInputRef = useRef(null);

    const selectFile = () => {
        fileInputRef.current.click();
      }

    const onFileSelect = (event) => {
        const files = event.target.files;
        
        if (files[0]) {
            setImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            );
        }
    }

    let sex = [
        {
            label: "Male",
            value: "male"
        },
        {
            label: "Female",
            value: "female"
        }
    ];

    countries.registerLocale(enLocale);
    const countryObj = countries.getNames("en", {select: "official"});

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        }
    });

    return(
        <>
            <TopHeader />
            <div className=" flex flex-col justify-start pt-4 pl-5 pb-4">
                <h1 className=" text-3xl text-blue-900 font-bold">Add Member</h1>
                <p className=" text-neutral-500 font-semibold">Add a new member to the team either doctor or manager.</p>
            </div>
            <div className=" mt-4 pl-5 pb-8">
                <Paper className=" p-6 mb-12 max-w-6xl">
                    <Formik
                      initialValues={{
                            photo: "",
                            username: "",
                            email: "",
                            firstname: "",
                            lastname: "",
                            contact: "",
                            usertype: "",
                            passport: "",
                            nationality: "",
                            sex: "",
                            dob: "",
                            phone_number: "",
                            deliveryAddress: [{ city: "", postcode: "", address: ""}],
                        }}
                        validationSchema={NewMemberSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <>
                                    <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                                        <ImageUpload 
                                            image={image}
                                            onRemove={() => setImage("")}
                                        />
                                        <input 
                                            id="photo"
                                            name="photo" 
                                            type="file"
                                            accept=".jpg, .png, .jpeg"
                                            ref={fileInputRef} 
                                            className="hidden" 
                                            onChange={onFileSelect}
                                        />
                                        <div 
                                            className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                            onClick={selectFile}
                                        >
                                            <p className=" font-extrabold text-sm ">Profile Photo</p>
                                            <AddPhotoAlternateIcon className="" fontSize="small" />
                                        </div>
                                    </div>
                                    <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 w-fit mb-4">
                                        <div className=" flex items-center">
                                            <AccountCircleIcon />
                                            <p className=" text-neutral-500 ml-1">Personal Information</p>
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                        <FormControl>
                                            <TextField 
                                                id="username"
                                                name="username"
                                                label="Username"
                                                variant="filled"
                                                value={formik.values.username}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.username && formik.touched.username)}
                                                helperText={
                                                    formik.errors.username &&
                                                    formik.touched.username &&
                                                    String(formik.errors.username)
                                                }
                                                required={true}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField 
                                                id="firstname"
                                                name="firstname"
                                                label="First Name"
                                                variant="filled"
                                                value={formik.values.firstname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.firstname && formik.touched.firstname)}
                                                helperText={
                                                    formik.errors.firstname &&
                                                    formik.touched.firstname &&
                                                    String(formik.errors.firstname)
                                                }
                                                required={true}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField 
                                                id="lastname"
                                                name="lastname"
                                                label="Last Name"
                                                variant="filled"
                                                value={formik.values.lastname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.lastname && formik.touched.lastname)}
                                                helperText={
                                                    formik.errors.lastname &&
                                                    formik.touched.lastname &&
                                                    String(formik.errors.lastname)
                                                }
                                                required={true}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                        <FormControl>
                                            <TextField 
                                                id="dob"
                                                name="dob"
                                                label="Date of Birth"
                                                variant="filled"
                                                type="date"
                                                value={formik.values.dob}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ shrink: true, required: true }}
                                                error={Boolean(formik.errors.dob && formik.touched.dob)}
                                                helperText={
                                                    formik.errors.dob &&
                                                    formik.touched.dob &&
                                                    String(formik.errors.dob)
                                                }
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                id="sex"
                                                name="sex"
                                                select
                                                label="Sex"
                                                value={formik.values.sex}
                                                onChange={(e) => formik.setFieldValue("sex", e.target.value)}
                                                onBlur={formik.handleBlur}
                                                variant="filled"
                                                required={true}
                                                error={Boolean(formik.errors.sex && formik.touched.sex)}
                                                helperText={
                                                    formik.errors.sex &&
                                                    formik.touched.sex &&
                                                    String(formik.errors.sex)
                                                }
                                            >
                                                {sex.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                id="nationality"
                                                name="nationality"
                                                select
                                                label="Nationality"
                                                value={formik.values.nationality}
                                                onChange={(e) => formik.setFieldValue("nationality", e.target.value)}
                                                onBlur={formik.handleBlur}
                                                variant="filled"
                                                required={true}
                                                error={Boolean(formik.errors.nationality && formik.touched.nationality)}
                                                helperText={
                                                    formik.errors.nationality &&
                                                    formik.touched.nationality &&
                                                    String(formik.errors.nationality)
                                                }
                                            >
                                                {countryArr?.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <Divider 
                                        sx={{ borderBottomWidth: "4px" }}
                                    />
                                    <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 mt-4 w-fit mb-4">
                                        <div className=" flex items-center">
                                            <ContactsOutlinedIcon />
                                            <p className=" text-neutral-500 ml-1">Contact Information</p>
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                        <FormControl>
                                            <TextField 
                                                id="phone_number"
                                                name="phone_number"
                                                label="Phone Number"
                                                variant="filled"
                                                value={formik.values.phone_number}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.phone_number && formik.touched.phone_number)}
                                                helperText={
                                                    formik.errors.phone_number &&
                                                    formik.touched.phone_number &&
                                                    String(formik.errors.phone_number)
                                                }
                                                required={true}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField 
                                                id="email"
                                                name="email"
                                                label="Email"
                                                variant="filled"
                                                placeholder="abc@email.com"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.email && formik.touched.email)}
                                                helperText={
                                                    formik.errors.email &&
                                                    formik.touched.email &&
                                                    String(formik.errors.email)
                                                }
                                                required={true}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className=" flex justify-end">
                                        <Button
                                            type='submit'
                                            disabled={formik.isSubmitting}
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </div>
        </>
    )
}

export default NewMember;