import { createSlice } from "@reduxjs/toolkit"
import { deletePermission, getAllPermissions } from "../actions/permissionaction";




const initialState = {
    loading:false,
    permissiondata:[],
    error:null,
    success:false,


}
 

const PermissionsSlice = createSlice({
    name:'permissions',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllPermissions.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllPermissions.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.permissiondata = payload;
        state.success = true;
     },
     [getAllPermissions.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [deletePermission.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [deletePermission.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.permissiondata = payload;
        state.success = true;
     },
     [deletePermission.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default PermissionsSlice.reducer;


