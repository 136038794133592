import { createSlice } from "@reduxjs/toolkit"
import { AddNewProduct, EditProduct, deleteProduct, getAllProducts } from "../actions/productaction";




const initialState = {
    loading:false,
    productsdata:[],
    error:null,
    success:false,


}
 

const ProductsSlice = createSlice({
    name:'products',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllProducts.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllProducts.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.productsdata = payload;
        state.success = true;
     },
     [getAllProducts.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddNewProduct.pending] : (state) => {
      state.loading = true;
      state.error = null;
   },
   [AddNewProduct.fulfilled]:(state, {payload}) => {
      state.loading = false;
      state.productsdata = payload;
      state.success = true;
   },
   [AddNewProduct.error]: (state,{payload}) =>  {
      state.loading = false;
      state.error = payload;
   },
   [EditProduct.pending] : (state) => {
      state.loading = true;
      state.error = null;
   },
   [EditProduct.fulfilled]:(state, {payload}) => {
      state.loading = false;
      state.productsdata = payload;
      state.success = true;
   },
   [EditProduct.error]: (state,{payload}) =>  {
      state.loading = false;
      state.error = payload;
   },



     [deleteProduct.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [deleteProduct.fulfilled]:(state, {payload}) => {
        state.loading = false;
      
        state.success = true;
     },
     [deleteProduct.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default  ProductsSlice.reducer;


