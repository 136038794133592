import { useRef, useState } from "react";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
    FieldArray,
    getIn,
    Formik
} from "formik";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';

import TopHeader from "../../Ui/topHeader";
import { ImageUpload } from "../../Ui/imageUpload";
import DataTable from "../../Ui/OrderTable/orderTable";
import { errorAlert, successAlert } from "../../Ui/Alert";
import moment from "moment";
import { useEffect } from "react";
import { roleTag, sex } from "../../utils/constant";
import CountryCodes from "../../assets/CountryCodes.json";

const backendUrl = process.env.REACT_APP_API_BASE_URL;
const UserEditSchema = yup.object().shape({
    photo: yup
        .mixed()
        .nullable(),
    username: yup.string().required("Username required."),
    email: yup.string().required("Email required."),
    firstname: yup.string().required("Firstname required"),
    lastname: yup.string().required("Lastname required."),
    usertype: yup.string().required("Usertype required."),
    passport: yup.string().required("Passport No. required"),
    nationality: yup.string().required("Nationality required."),
    sex: yup.string().required("Sex required."),
    dob: yup.string().required("Date Of Birth required"),
    phone_number: yup.string().required("Phone number required."),
    allergies: yup.string().required("Allergies required"),
    g6pd: yup.string().required("G6PD required"),
    deliveryAddress: yup.array().of(
        yup.object().shape({
            city: yup.string().required("City name required."),
            postcode: yup.string().required("Post code required"),
            address: yup.string().required("Address required")
        })
    ),
    country_code: yup.string().required("Country Code required."),

})

const UpdateUser = () => {
    const { state } = useLocation();
    const fileInputRef = useRef();
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [image, setImage] = useState({ url: state.photo, name: state.photo });
    const [updated, setUpdated] = useState("");
    const [personalEdit, setPersonalEdit] = useState(true);
    const [contactEdit, setContactEdit] = useState(true);
    const [addressEdit, setAddressEdit] = useState(true);
    const [medicalEdit, setMedicalEdit] = useState(true);
    const [roles, setRoles] = useState([])
    const [isPatient, setIsPatient] = useState(false)
    const [isDoctor, setIsDoctor] = useState(false)
    const [isMember, setIsMember] = useState(false)
    const [countryCodeData, setCountryCodeData] = useState([]);

    useEffect(() => {
        getAllRoles()
        setCountryCodeData(CountryCodes)
    }, [])

    const getAllRoles = async () => {
        await axios.get(`${backendUrl}/api/roles`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setRoles(res.data)
                if (state?.usertype?.tag === roleTag.Patient) {
                    setIsPatient(true)
                }
                else if (state?.usertype?.tag === roleTag.Member) {
                    setIsMember(true)
                }
                else if (state?.usertype?.tag === roleTag.Doctor) {
                    setIsDoctor(true)
                }
            }
        }).catch((error) => {
            errorAlert("Unable get roles")
        })
    }

    const handleTabChange = (event, index) => {
        setIndex(index);
    }

    const selectFile = () => {
        fileInputRef.current.click();
    }

    const onFileSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            );
            setUpdated(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            )
        }
    }

    countries.registerLocale(enLocale);
    const countryObj = countries.getNames("en", { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        }
    });

    let g6pd = [
        {
            label: "Positive",
            value: "positive"
        },
        {
            label: "Negative",
            value: "negative"
        }
    ];

    const onSubmit = async (values, actions) => {

        var personalInfo = {
            first_name: values.firstname,
            last_name: values.lastname,
            dob: values.dob,
            phone_number: values.phone_number,
            country_code: values.country_code,
            sex: values.sex,
            nationality: values.nationality,
            g6pd: values.g6pd,
            nric_passport_no: values.passport,
            drug_allergies: values.allergies
        }

        var photo;
        if (image.url === state.photo) {
            photo = image.url;
        } else {
            photo = updated.file;
        }

        const newUser = {
            username: values.username,
            description: values.description,
            department: values.department,
            email: values.email,
            image: photo,
            userType: values.usertype,
            isActive: true,
            personalInfo: personalInfo,
            deliveryAddress: values.deliveryAddress.map((place) => {
                return {
                    postcode: place.postcode,
                    city: place.city,
                    address: place.address
                }
            }),
            resetPasswordToken: "",
            resetPasswordExpires: null,
            isDisplay: values.isDisplay
        }

        await axios({
            method: "put",
            url: `${backendUrl}/api/users/updateUser/${state.userId}`,
            data: newUser,
            headers: {
                'Content-Type': "multipart/form-data"
            }
        })
            .then(() => {
                successAlert("User Updated Successfully.")
                navigate(-1)
            })
            .catch((error) => {
                console.log(error);
            })

    }
    const date = new Date(state.dob)
    const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).split('/').reverse().join('-');
    return (
        <>
            <TopHeader />
            <div className=" flex flex-col justify-start pt-4 pl-5 pb-4">
                <h1 className=" text-3xl text-blue-900 font-bold">User Details</h1>
                <p className=" text-neutral-500 font-semibold">View and edit the user details.</p>
            </div>
            <div className=" mt-4 pl-5 pb-8">
                <Paper className=" p-6 mb-12 max-w-6xl">
                    <Formik
                        initialValues={{
                            photo: "",
                            username: state.username,
                            email: state.email,
                            firstname: state.firstname,
                            lastname: state.lastname,
                            usertype: state.usertype?._id,
                            passport: state.passport,
                            nationality: state.nationality,
                            sex: state.sex,
                            dob: formattedDate,
                            phone_number: state.contact,
                            country_code: state.countryCode,
                            deliveryAddress: state.deliveryAddress,
                            allergies: state.allergies,
                            department: state?.department,
                            description: state?.description,
                            g6pd: state.g6pd,
                            isDisplay: state.isDisplay,
                        }}
                        validationSchema={UserEditSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <Tabs value={index} onChange={handleTabChange}>
                                    <Tab label="General Details" sx={{ marginLeft: "4px", fontWeight: "bold" }} />
                                    <Tab label="Addresses" sx={{ fontWeight: "bold" }} />
                                    {
                                        isPatient && (
                                            <Tab label="Medical Details" sx={{ fontWeight: "bold" }} />
                                        )
                                    }
                                    {/* <Tab label="Orders" sx={{ fontWeight: "bold" }} /> */}
                                </Tabs>
                                {
                                    index === 0
                                    &&
                                    <>
                                        <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                                            <ImageUpload
                                                image={image}
                                                onRemove={() => setImage("")}
                                            />
                                            <input
                                                id="photo"
                                                name="photo"
                                                type="file"
                                                accept=".jpg, .png, .jpeg"
                                                ref={fileInputRef}
                                                className="hidden"
                                                onChange={onFileSelect}
                                            />
                                            <div
                                                className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                onClick={selectFile}
                                            >
                                                <p className=" font-extrabold text-sm ">Profile Photo</p>
                                                <AddPhotoAlternateIcon className="" fontSize="small" />
                                            </div>
                                        </div>
                                        <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 w-fit mb-4">
                                            <div className=" flex items-center">
                                                <AccountCircleIcon />
                                                <p className=" text-neutral-500 ml-1">Personal Information</p>
                                            </div>
                                            <IconButton
                                                onClick={() => (setPersonalEdit(!personalEdit))}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </div>
                                        <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                            <FormControl>
                                                <TextField
                                                    id="username"
                                                    name="username"
                                                    label="Username"
                                                    variant="filled"
                                                    value={formik.values.username}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={personalEdit}
                                                    error={Boolean(formik.errors.username && formik.touched.username)}
                                                    helperText={
                                                        formik.errors.username &&
                                                        formik.touched.username &&
                                                        String(formik.errors.username)
                                                    }
                                                    required={true}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="firstname"
                                                    name="firstname"
                                                    label="First Name"
                                                    variant="filled"
                                                    value={formik.values.firstname}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={personalEdit}
                                                    error={Boolean(formik.errors.firstname && formik.touched.firstname)}
                                                    helperText={
                                                        formik.errors.firstname &&
                                                        formik.touched.firstname &&
                                                        String(formik.errors.firstname)
                                                    }
                                                    required={true}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="lastname"
                                                    name="lastname"
                                                    label="Last Name"
                                                    variant="filled"
                                                    value={formik.values.lastname}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={personalEdit}
                                                    error={Boolean(formik.errors.lastname && formik.touched.lastname)}
                                                    helperText={
                                                        formik.errors.lastname &&
                                                        formik.touched.lastname &&
                                                        String(formik.errors.lastname)
                                                    }
                                                    required={true}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                            <FormControl>
                                                <TextField
                                                    id="dob"
                                                    name="dob"
                                                    label="Date of Birth"
                                                    variant="filled"
                                                    type="date"
                                                    value={formik.values.dob}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={personalEdit}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    error={Boolean(formik.errors.dob && formik.touched.dob)}
                                                    helperText={
                                                        formik.errors.dob &&
                                                        formik.touched.dob &&
                                                        String(formik.errors.dob)
                                                    }
                                                    inputProps={{
                                                        max: moment().format('YYYY-MM-DD')
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="sex"
                                                    name="sex"
                                                    select
                                                    label="Sex"
                                                    value={formik.values.sex}
                                                    onChange={(e) => formik.setFieldValue("sex", e.target.value)}
                                                    onBlur={formik.handleBlur}
                                                    variant="filled"
                                                    disabled={personalEdit}
                                                    required={true}
                                                    error={Boolean(formik.errors.sex && formik.touched.sex)}
                                                    helperText={
                                                        formik.errors.sex &&
                                                        formik.touched.sex &&
                                                        String(formik.errors.sex)
                                                    }
                                                >
                                                    {sex.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="nationality"
                                                    name="nationality"
                                                    select
                                                    label="Nationality"
                                                    value={formik.values.nationality}
                                                    onChange={(e) => formik.setFieldValue("nationality", e.target.value)}
                                                    onBlur={formik.handleBlur}
                                                    variant="filled"
                                                    disabled={personalEdit}
                                                    required={true}
                                                    error={Boolean(formik.errors.nationality && formik.touched.nationality)}
                                                    helperText={
                                                        formik.errors.nationality &&
                                                        formik.touched.nationality &&
                                                        String(formik.errors.nationality)
                                                    }
                                                >
                                                    {countryArr?.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                        <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                            <FormControl>
                                                <TextField
                                                    id="passport"
                                                    name="passport"
                                                    label="Passport No."
                                                    variant="filled"
                                                    value={formik.values.passport}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={personalEdit}
                                                    error={Boolean(formik.errors.passport && formik.touched.passport)}
                                                    helperText={
                                                        formik.errors.passport &&
                                                        formik.touched.passport &&
                                                        String(formik.errors.passport)
                                                    }
                                                    required={true}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="usertype"
                                                    name="usertype"
                                                    label="User Type"
                                                    select
                                                    onChange={(e) => {
                                                        formik.setFieldValue('usertype', e.target.value)
                                                        const selectedOption = roles.find(item => (item._id === e.target.value));
                                                        if (selectedOption && selectedOption.tag === roleTag.Patient) {
                                                            setIsPatient(true)
                                                            setIsMember(false)
                                                            setIsDoctor(false)
                                                        }
                                                        else if (selectedOption && selectedOption.tag === roleTag.Member) {
                                                            setIsMember(true)
                                                            setIsDoctor(false)
                                                            setIsPatient(false)
                                                        }
                                                        else if (selectedOption && selectedOption.tag === roleTag.Doctor) {
                                                            setIsMember(false)
                                                            setIsPatient(false)
                                                            setIsDoctor(true)
                                                        }
                                                        else {
                                                            setIsMember(false)
                                                            setIsPatient(false)
                                                            setIsDoctor(false)
                                                        }

                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.usertype}
                                                    variant="filled"
                                                    disabled={personalEdit}
                                                    required
                                                    error={Boolean(formik.errors.usertype && formik.touched.usertype)}
                                                    helperText={
                                                        formik.errors.usertype &&
                                                        formik.touched.usertype &&
                                                        String(formik.errors.usertype)
                                                    }
                                                >
                                                    {
                                                        roles?.map((item, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={item?._id}
                                                                >
                                                                    {item?.role}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>
                                            {
                                                (isDoctor || isMember) && (
                                                    <FormControl >
                                                        <TextField
                                                            id="department"
                                                            name="department"
                                                            disabled={personalEdit}
                                                            placeholder={isMember ? "Enter Designation" : "Enter Specialization"}
                                                            label={isMember ? "Designation" : "Specialist On"}
                                                            value={formik.values.department}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            variant="filled"
                                                            required
                                                            error={Boolean(formik.errors.department && formik.touched.department)}
                                                            helperText={
                                                                formik.errors.department &&
                                                                formik.touched.department &&
                                                                String(formik.errors.department)
                                                            }
                                                        />
                                                    </FormControl>
                                                )}
                                        </div>
                                        {
                                            (isDoctor || isMember) && (
                                                <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                                    <>
                                                        <FormControl>
                                                            <TextField
                                                                id="description"
                                                                name="description"
                                                                placeholder="Enter Description"
                                                                label="Description"
                                                                disabled={personalEdit}
                                                                value={formik.values.description}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                variant="filled"
                                                                required
                                                                error={Boolean(formik.errors.description && formik.touched.description)}
                                                                helperText={
                                                                    formik.errors.description &&
                                                                    formik.touched.description &&
                                                                    String(formik.errors.description)
                                                                }
                                                            />
                                                        </FormControl>
                                                        <div style={{ marginTop: "1.5rem", marginLeft: "4rem" }}>
                                                            <FormLabel>Display in Frontend</FormLabel>
                                                            <Switch
                                                                disabled={personalEdit}
                                                                checked={formik.values?.isDisplay}
                                                                onChange={(event) => formik.setFieldValue("isDisplay", event.target.checked)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </div>
                                                    </>
                                                </div>
                                            )}
                                        <Divider
                                            sx={{ borderBottomWidth: "4px" }}
                                        />
                                        <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 mt-4 w-fit mb-4">
                                            <div className=" flex items-center">
                                                <ContactsOutlinedIcon />
                                                <p className=" text-neutral-500 ml-1">Contact Information</p>
                                            </div>
                                            <IconButton
                                                onClick={() => (setContactEdit(!contactEdit))}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </div>
                                        <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                            <FormControl>
                                                <TextField
                                                    id="phone_number"
                                                    name="phone_number"
                                                    label="Phone Number"
                                                    variant="filled"
                                                    value={formik.values.phone_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={contactEdit}
                                                    error={Boolean(formik.errors.phone_number && formik.touched.phone_number)}
                                                    helperText={
                                                        formik.errors.phone_number &&
                                                        formik.touched.phone_number &&
                                                        String(formik.errors.phone_number)
                                                    }
                                                    required={true}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                style={{ position: "relative", height: "0rem" }}
                                                            >
                                                                <select
                                                                    style={{
                                                                        outline: "none",
                                                                        background: "transparent",
                                                                        borderRight: "2px solid #868080",
                                                                        height: "21px",
                                                                        minWidth: "5rem",
                                                                        color: "black"
                                                                    }}
                                                                    id="country_code"
                                                                    name="country_code"
                                                                    disabled={contactEdit}
                                                                    value={formik.values.country_code}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    {countryCodeData.map((item, index) => (
                                                                        <option value={item?.dial_code} key={index}>
                                                                            {item?.dial_code}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    variant="filled"
                                                    placeholder="abc@email.com"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={contactEdit}
                                                    error={Boolean(formik.errors.email && formik.touched.email)}
                                                    helperText={
                                                        formik.errors.email &&
                                                        formik.touched.email &&
                                                        String(formik.errors.email)
                                                    }
                                                    required={true}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className=" flex space-x-2 justify-end">
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                disabled={formik.isSubmitting}
                                                onClick={() => navigate("/user")}
                                            >Back</Button>
                                            <Button
                                                onClick={() => onSubmit(formik.values)}
                                                disabled={formik.isSubmitting}
                                                variant="contained"
                                                color="secondary"
                                            >Update</Button>
                                        </div>
                                    </>
                                }
                                {
                                    index === 1
                                    &&
                                    <>
                                        <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 mt-4 w-fit mb-4">
                                            <div className=" flex items-center">
                                                <FmdGoodOutlinedIcon />
                                                <p className=" text-neutral-500 ml-1">Addresses</p>
                                            </div>
                                            <IconButton
                                                onClick={() => (setAddressEdit(!addressEdit))}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </div>
                                        <div>
                                            <FieldArray
                                                name="deliveryAddress"
                                                render={
                                                    (arrayHelpers, i) => {
                                                        return (
                                                            <div key={i} >
                                                                {formik.values.deliveryAddress?.map((place, index) => {
                                                                    const touchedCity = getIn(formik.touched, `deliveryAddress.${index}.city`)
                                                                    const errorCity = getIn(formik.errors, `deliveryAddress.${index}.city`);

                                                                    const touchedPostcode = getIn(formik.touched, `deliveryAddress.${index}.postcode`)
                                                                    const errorPostcode = getIn(formik.errors, `deliveryAddress.${index}.postcode`);

                                                                    const touchedAddress = getIn(formik.touched, `deliveryAddress.${index}.address`)
                                                                    const errorAddress = getIn(formik.errors, `deliveryAddress.${index}.address`);

                                                                    return (
                                                                        <>
                                                                            <div key={index}>
                                                                                {
                                                                                    <div key={index}>
                                                                                        {index > 0
                                                                                            &&
                                                                                            <div>
                                                                                                <IconButton
                                                                                                    color="error"
                                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                                >
                                                                                                    <DeleteOutlinedIcon />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        }
                                                                                        <div className=" grid grid-cols-2 space-x-12 mb-8">
                                                                                            <FormControl>
                                                                                                <TextField
                                                                                                    name={`deliveryAddress.${index}.city`}
                                                                                                    label="City"
                                                                                                    variant="filled"
                                                                                                    placeholder="Enter your city"
                                                                                                    value={place.city}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                    disabled={addressEdit}
                                                                                                    error={Boolean(errorCity && touchedCity)}
                                                                                                    helperText={
                                                                                                        errorCity &&
                                                                                                        touchedCity &&
                                                                                                        String(errorCity)
                                                                                                    }
                                                                                                    required={true}
                                                                                                />
                                                                                            </FormControl>
                                                                                            <FormControl>
                                                                                                <TextField
                                                                                                    name={`deliveryAddress.${index}.postcode`}
                                                                                                    label="Postcode"
                                                                                                    variant="filled"
                                                                                                    placeholder="Enter your city Poscode"
                                                                                                    value={place.postcode}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                    disabled={addressEdit}
                                                                                                    error={Boolean(errorPostcode && touchedPostcode)}
                                                                                                    helperText={
                                                                                                        errorPostcode &&
                                                                                                        touchedPostcode &&
                                                                                                        String(errorPostcode)
                                                                                                    }
                                                                                                    required={true}
                                                                                                />
                                                                                            </FormControl>
                                                                                        </div>
                                                                                        <div className=" mb-8">
                                                                                            <FormControl fullWidth>
                                                                                                <TextField
                                                                                                    name={`deliveryAddress.${index}.address`}
                                                                                                    label="Address"
                                                                                                    variant="filled"
                                                                                                    placeholder="Enter your Address"
                                                                                                    multiline
                                                                                                    minRows={3}
                                                                                                    value={place.address}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                    disabled={addressEdit}
                                                                                                    error={Boolean(errorAddress && touchedAddress)}
                                                                                                    helperText={
                                                                                                        errorAddress &&
                                                                                                        touchedAddress &&
                                                                                                        String(errorAddress)
                                                                                                    }
                                                                                                    required={true}
                                                                                                />
                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                                <div className=" ml-6">
                                                                    <Button
                                                                        disabled={addressEdit}
                                                                        endIcon={<AddIcon />}
                                                                        variant="contained"
                                                                        onClick={() => arrayHelpers.insert(
                                                                            formik.values.address?.length + 1,
                                                                            { city: "", postcode: "", address: "" }
                                                                        )
                                                                        }
                                                                    >Add Address</Button>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className=" flex space-x-2 justify-end">
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                disabled={formik.isSubmitting}
                                                onClick={() => navigate("/user")}
                                            >Back</Button>
                                            <Button
                                                onClick={() => onSubmit(formik.values)}
                                                disabled={formik.isSubmitting}
                                                variant="contained"
                                                color="secondary"
                                            >Update</Button>
                                        </div>
                                    </>
                                }
                                {
                                    index === 2
                                    &&
                                    <>
                                        <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 w-fit mb-4">
                                            <div className=" flex items-center">
                                                <MedicalInformationOutlinedIcon />
                                                <p className=" text-neutral-500 ml-1">Medical Information</p>
                                            </div>
                                            <IconButton
                                                onClick={() => (setMedicalEdit(!medicalEdit))}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </div>
                                        <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                                            <FormControl>
                                                <TextField
                                                    id="allergies"
                                                    name="allergies"
                                                    label="Drug Allergies"
                                                    variant="filled"
                                                    value={formik.values.allergies}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={medicalEdit}
                                                    error={Boolean(formik.errors.allergies && formik.touched.allergies)}
                                                    helperText={
                                                        formik.errors.allergies &&
                                                        formik.touched.allergies &&
                                                        String(formik.errors.allergies)
                                                    }
                                                    required={true}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="g6pd"
                                                    name="g6pd"
                                                    select
                                                    label="G6PD"
                                                    value={formik.values.g6pd}
                                                    onChange={(e) => formik.setFieldValue("g6pd", e.target.value)}
                                                    onBlur={formik.handleBlur}
                                                    variant="filled"
                                                    disabled={medicalEdit}
                                                    required={true}
                                                    error={Boolean(formik.errors.g6pd && formik.touched.g6pd)}
                                                    helperText={
                                                        formik.errors.g6pd &&
                                                        formik.touched.g6pd &&
                                                        String(formik.errors.g6pd)
                                                    }
                                                >
                                                    {g6pd?.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                        <div className=" flex space-x-2 justify-end">
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                disabled={formik.isSubmitting}
                                                onClick={() => navigate("/user")}
                                            >Back</Button>
                                            <Button
                                                onClick={() => onSubmit(formik.values)}
                                                disabled={formik.isSubmitting}
                                                variant="contained"
                                                color="secondary"
                                            >Update</Button>
                                        </div>
                                    </>
                                }
                            </form>
                        )}
                    </Formik>
                    {
                        index === 3
                        &&
                        <>
                            <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 w-fit mb-4">
                                <div className=" flex items-center">
                                    <ListAltIcon />
                                    <p className=" text-neutral-500 ml-1">Orders</p>
                                </div>
                            </div>

                            <DataTable />
                        </>
                    }
                </Paper>
            </div>
        </>
    )
}

export default UpdateUser;