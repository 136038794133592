import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllBatches = createAsyncThunk(
    'batches/getAllBatches',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/inventory/64aaf8a9c5ae438dcfc99ba0/batch`,

                config
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const AddNewBatch = createAsyncThunk(
    'batches/AddNewBatch',
    async (batchdetails) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.post(
                `${backendUrl}/api/inventory/` + batchdetails.pid + `/batch`, batchdetails.batchesdata,

                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const deleteBatch = createAsyncThunk(
    'batches/deleteBatch',
    async (bid) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            const { data } = await axios.delete(
                `${backendUrl}api/inventory/64aaf8a9c5ae438dcfc99ba0/batch/` + bid,
                config
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const updateBatch = createAsyncThunk(
    'batches/updateBatch',
    async (bid, batch) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.put(
                `${backendUrl}/api/inventory/64aaf8a9c5ae438dcfc99ba0/batch/` + bid,
                batch,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)