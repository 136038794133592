import { useState, useRef } from 'react';
import * as yup from "yup";
import { useFormik } from "formik";
import TopHeader from "../../../Ui/topHeader";
import { Button, FormControl, FormLabel, Paper, Switch, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ImageUpload } from "../../../Ui/imageUpload";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import axios from 'axios';
import { errorAlert, successAlert } from '../../../Ui/Alert';
const apiUrl = process.env.REACT_APP_API_BASE_URL
const validationSchema = yup.object().shape({
    image: yup.mixed().nullable().required("Background Image is required"),
    name: yup.string().required("Category Name is Required"),
    color: yup.string().required("Background Color is Required"),
    description: yup.string().required("Category Description is Required")
})

const AddCategory = () => {
    const fileInputRef = useRef();
    const navigate = useNavigate();
    const [categoryImg, setCategoryImg] = useState(null)
    const onSubmit = async (values, actions) => {
        await axios.post(`${apiUrl}/api/category/create`, values).then((res) => {
            successAlert("Category Created")
            navigate(-1);
            actions.resetForm();
        }).catch((error) => {
            errorAlert(error.message)
        })
    }

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            description: '',
            image: '',
            color: '',
            hasSurvey: true
        },
        validationSchema: validationSchema,
        onSubmit,
    })
    const selectFile = () => {
        fileInputRef.current.click();
    }
    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setCategoryImg(reader.result)
                setFieldValue("image", reader.result)
            };
        }
    }
    return (
        <div>
            <TopHeader />
            <div className=" mt-6 pl-5 mb-8 mr-4">
                <Paper className=" mt-4 p-5">
                    <p className="text-2xl font-bold text-blue-900">Add New Category</p>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="grid grid-cols-2 space-x-12 mb-4 mt-4">
                            <FormControl>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Category Name"
                                    variant="standard"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.name && touched.name)}
                                    helperText={
                                        errors.name &&
                                        touched.name &&
                                        String(errors.name)
                                    }

                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="color"
                                    type="text"
                                    label="Background Color"
                                    variant="standard"
                                    name="color"
                                    value={values.color}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.color && touched.color)}
                                    helperText={
                                        errors.color &&
                                        touched.color &&
                                        String(errors.color)
                                    }

                                />
                            </FormControl>
                        </div>
                        <div className=" mb-4 mt-4">
                            <FormControl fullWidth>
                                <TextField
                                    id="description"
                                    type="text"
                                    label="Category Description"
                                    variant="standard"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.description && touched.description)}
                                    helperText={
                                        errors.description &&
                                        touched.description &&
                                        String(errors.description)
                                    }

                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormLabel >Set Survey</FormLabel>
                            <Switch
                                checked={values.hasSurvey}
                                onChange={(event) => setFieldValue('hasSurvey', event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                            <ImageUpload
                                image={categoryImg}
                                onRemove={() => setCategoryImg("")}
                            />
                            <input
                                id="image"
                                name="image"
                                type="file"
                                accept=".jpg, .png, .jpeg"
                                ref={fileInputRef}
                                className="hidden"
                                onChange={onFileSelect}
                            />

                            <div
                                className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                onClick={selectFile}
                            >
                                <p className=" font-extrabold text-sm ">Category Image</p>
                                <AddPhotoAlternateIcon className="" fontSize="small" />
                            </div>
                            {errors.image && touched.image && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.image}</p>}

                        </div>
                        <div className="ml-5 flex gap-4 mt-4">
                            <Button
                                variant="contained"
                                size="large"
                                disabled={isSubmitting}
                                type="submit"
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Submit</Button>
                            <Button
                                variant="outlined"
                                size="large"
                                disabled={isSubmitting}
                                sx={{
                                    "&.MuiButton-root": {
                                        fontWeight: "bold"
                                    },
                                }}
                                onClick={() => navigate(-1)}
                            >Back</Button>
                        </div>
                    </form>
                </Paper>
            </div>
        </div>
    )
}

export default AddCategory;