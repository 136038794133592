import { createSlice } from "@reduxjs/toolkit";
import { ResetPassword, forgetPassword, userLogin } from "../actions/authaction";
const initialState = {
    loading:false,
    userInfo: null,
    fptoken:null,
    error:null,
    success:false
}

const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
       logout:(state) => {
        localStorage.removeItem('userToken');
        state.loading = false;
        state.userInfo = null;
        state.error = null
       }

    },
    extraReducers: {
       [userLogin.pending]: (state) => {
        state.loading = true;
        state.error = null;

       },
       [userLogin.fulfilled]:(state,{payload}) => {
        state.loading = false;
        state.userInfo = payload;
        
       },
       [userLogin.rejected]: (state,{payload}) => {
        state.loading = false;
        state.error= payload;
       },
       [forgetPassword.pending]: (state) => {
        state.loading = true;
        state.error = null;

       },
       [forgetPassword.fulfilled]:(state,{payload}) => {
        state.loading = false;
        state.fptoken = payload;
        
       },
       [forgetPassword.rejected]: (state,{payload}) => {
        state.loading = false;
        state.error= payload;
       },
       [ResetPassword.pending]: (state) => {
        state.loading = true;
        state.error = null;

       },
       [ResetPassword.fulfilled]:(state,{payload}) => {
        state.loading = false;
         
        
       },
       [ResetPassword.rejected]: (state,{payload}) => {
        state.loading = false;
        state.error= payload;
       }
    }
})

export const {logout} = authSlice.actions;
export default authSlice.reducer;
