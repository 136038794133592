import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useState } from "react";

const CartCard = ({ img, name, description, price }) => {

    const [readMore, setReadMore] = useState(true);
    const [text, setText ] = useState("Read more");

    const handleReadMore = () => {
        setReadMore(true);
        setText("Read More")
    }

    const handleReadLess = () => {
        setReadMore(false);
        setText("Read Less");
    }

    return (
        <div className="bg-white w-64 mt-4">
            <div className="grid grid-cols-1">
                <img
                    src={img}
                    alt="med"
                    className="w-50 mx-auto"
                    style={{ height: '10rem' }}
                />
                <div className="p-5">
                    <p className=" text-blue-800 font-bold text-lg">{name}</p>

                    <p className=" text-gray-600 text-sm">{readMore ? description?.slice(0, 50) : description}</p>
                    <div className="flex space-x-2 mt-2">
                        {
                            (!readMore) ?
                                <RemoveCircleOutlineOutlinedIcon
                                    className="text-blue-600 cursor-pointer"
                                    onClick={handleReadMore}
                                />
                                :
                                <AddCircleOutlineOutlinedIcon
                                    className="text-blue-600 cursor-pointer"
                                    onClick={handleReadLess}
                                />
                        }
                        <p className="text-blue-600 text-sm font-medium">{text}</p>
                    </div>
                    <p className=" font-bold pt-4" style={{ color: "#E9B84A" }}>${price}</p>
                </div>
            </div>
        </div>
    )
}

export default CartCard;