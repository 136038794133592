import { useLocation } from "react-router-dom";

import {
    Chip,
    Divider,
    Paper,
    Avatar
} from "@mui/material";

import TopHeader from "../../Ui/topHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL

const ViewSubscription = () => {

    const { state: data } = useLocation();

    return (
        <>
            <TopHeader />
            <div className=" ml-5 mt-5 mb-8">
                <h1 className=" text-3xl text-blue-800 font-bold">Subscription Details</h1>
                <p className="text-neutral-600 font-bold">Get all the details about the subscription</p>
            </div>
            <Divider
                sx={{
                    borderBottomWidth: 4,
                    borderBottomColor: "ThreeDShadow"
                }}
            />
            <div className="ml-5 mr-5 mt-8">
                <div className=" mt-8">
                    <p className=" text-blue-900 mb-4 underline font-bold text-2xl">Basic Details</p>
                    <Paper elevation={3} className="w-full p-4">
                        <div className="grid grid-cols-4 ">
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Customer Name</p>
                                <p className=" font-bold text-blue-900 pl-2">{`${data?.order?.customer?.personalInfo?.first_name} ${data?.order?.customer?.personalInfo?.last_name}`}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Contact Number</p>
                                <p className=" font-bold text-blue-900 pl-2">{(data?.order?.customer?.personalInfo?.country_code??"")+data?.order?.customer?.personalInfo?.phone_number}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Ordered Date</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.order?.orderedDate?.split("T")[0]}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Subscription Duration</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.package?.packageDetails?.duration} Months</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 mt-8 ">
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Total Price</p>
                                <p className=" font-bold text-blue-900 pl-2">SGD {data?.order?.totalPrice}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Delivery Status</p>
                                <div className=" pl-2">
                                    {
                                        data?.order?.status === "Delivered" && <Chip label={data?.order?.status} color="success" />
                                    }
                                    {
                                        data?.order?.status === "Canceled" && <Chip label={data?.order?.status} color="error" />
                                    }
                                    {
                                        data?.order?.status === "Ongoing" && <Chip label={data?.order?.status} color="primary" />
                                    }
                                    {
                                        data?.order?.status === "Ordered" && <Chip label={data?.order?.status} color="warning" />
                                    }
                                    {
                                        data?.order?.status === "Out of Delivery" && <Chip label={data?.order?.status} color="secondary" />
                                    }
                                </div>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Payment Mode</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.order?.paymentDetails?.paymentMode}</p>
                            </div>
                        </div>
                        <div className="mt-8">
                            <p className=" font-bold text-neutral-700 text-xl">Delivery Address:
                                <span className=" font-bold text-blue-900 pl-2"> {data?.order?.billingDetails?.deliveryAddress}</span>
                            </p>
                        </div>
                    </Paper>
                </div>

                {
                    data?.package?.package?.products?.length > 0 && (
                        <div className="mt-8 mb-5">
                            <p className="text-blue-900 mb-4 font-bold text-2xl mr-2">
                                <span style={{ textDecoration: 'underline' }}>Included Products</span>
                                {data?.package?.package?.products?.length > 0 && (
                                    <span className="text-xl"> ({data?.package?.package?.products?.length})</span>
                                )}
                            </p>
                            <div className="flex flex-wrap justify-start mt-2">
                                {
                                    data?.package?.package?.products?.map((item, index) => {
                                        return (
                                            <div key={index} className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 p-2">
                                                <Paper elevation={3} className="mt-4" style={{ minHeight: '28rem' }}>
                                                    <div className="grid grid-cols-1">
                                                        <Avatar src={apiUrl + "/uploads/" + item?.product?.image} alt={item?.product?.name} variant="square" style={{ height: '160px', width: '160px', marginTop: '2rem' }} className="mx-auto" />
                                                        <div className="p-5">
                                                            <p className=" text-blue-800 font-bold text-lg">{item?.product?.name}</p>
                                                            <p className=" text-gray-600 text-sm">{item?.product?.description}</p>
                                                            <p className=" font-bold pt-4" style={{ color: "#E9B84A" }}>${item?.product?.price}</p>
                                                        </div>
                                                    </div>
                                                </Paper>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div >
        </>
    )
}

export default ViewSubscription;