import TopHeader from "../../../Ui/topHeader"
import EditStepper from "./components/EditStepper";


const EditProduct = () => {

    return (
        <div>
            <TopHeader />
            {/* <div> */}
                <div className="pt-6 pl-5 pb-6">
                    <h1 className="text-3xl text-blue-900 font-black font-roboto">Edit Product</h1>
                    <p className=" text-blue-950 font-bold font-roboto text-sm">Edit your product details here.</p>
                </div>
                <div className="p-3">
                    <EditStepper />
                </div>
            {/* </div> */}
        </div>
    )
}

export default EditProduct;