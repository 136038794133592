import { Button } from "@mui/material";
import email from "../../assets/mail.gif";

const EmailMessage = () => {
    return ( 
        <div className=" grid place-content-center h-screen">
            <div className=" mx-auto">
                <img 
                    src={email}
                    alt="email"
                    className=" h-64 w-64"
                />
            </div> 
            <p className=" text-2xl font-extrabold text-blue-900 mb-4">Please check your email for the reset link.</p>
            <div className=" mx-auto">
                <Button
                    variant="contained" 
                    size="large"
                    href="/"
                    className=" w-40"
                >  
                    Login
                </Button>
            </div>
        </div>
     );
}
 
export default EmailMessage;