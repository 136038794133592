import TopHeader from "../../../Ui/topHeader";
import EditStepper from "./components/EditStepper";

const UpdateWhatWeTreat = () => {
    return(
        <div>
        <TopHeader />
            <div className="pt-6 pl-5 pb-6">
                <h1 className="text-3xl text-blue-900 font-black font-roboto">Edit Content</h1>
                <p className=" text-blue-950 font-bold font-roboto text-sm">Edit the Content for What We Treat</p>
            </div>
            <div className="p-3">
                <EditStepper />
            </div>
    </div>
    )
}

export default UpdateWhatWeTreat;