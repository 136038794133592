import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Search from "../search";
import { Checkbox } from "@mui/material";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

const tableCustomStyles = {
    headRow: {
        style: {
            color: '#FFFFFF',
            backgroundColor: '#526D82',
            fontWeight: "bold"
        },
    },
    rows: {
        style: {
            backgroundColor: "#DDE6ED",
            fontWeight: 600,
        },
        stripedStyle: {
            backgroundColor: "#F0FBFF"
        },
    },
    pagination: {
        style: {
            backgroundColor: "#9DB2BF",
            color: "black",
            fontWeight: "bold",
            fontSize: "15px"
        }
    },
    subHeader: {
        style: {
            backgroundColor: "#F4F9F9",
            display: 'block',
            paddingInline: 0,
            marginInline: 0
        }
    }
}

const AddPrescriptionFilterTable = ({ columns, data, isSelectable, placeholder, isSearch = true, isPagination = true, conditionalRowStyles }) => {
    const [filterText, setFilterText] = useState("");
    const [filterData, setFilterData] = useState([])
    const [filterValue, setFilterValue] = useState("")
    const [filterField, setFilterField] = useState("All")
    const [categories, setCategories] = useState([]);


    useEffect(() => {
        getFilter()
        getCategories();
    }, [data, filterText, filterField]);

    const getFilter = async () => {
        let filteredItems = data?.filter(item =>
            JSON.stringify(item)
                .toLowerCase()
                .includes(filterText.toLowerCase())
        ) || [];
        setFilterData(filteredItems)
    }

    const handleChange = (event) => {
        const { value } = event.target;
        setFilterValue(value);
        getByFilterWithFieldValue(value)
    };

    const getByFilterWithFieldValue = async (filedValue) => {
        let filteredItems = []
        if (filterField === "Category") {
            filteredItems = data.filter(item =>
                item?.selectedCategory?._id  === filedValue
            );
        }
        else if (filterField === "Status") {
            if (("Booked".toLowerCase())?.includes(filedValue?.toLowerCase())) {
                filteredItems = data.filter(item =>
                    String(item?.eStatus).toLowerCase().includes(filedValue?.toLowerCase())
                );
            }
            else if (("Completed".toLowerCase())?.includes(filedValue?.toLowerCase())) {
                filteredItems = data.filter(item =>
                    String(item?.eStatus).toLowerCase().includes(filedValue?.toLowerCase())
                );
            }
            else if (("Confirmed".toLowerCase())?.includes(filedValue?.toLowerCase())) {
                filteredItems = data.filter(item =>
                    String(item?.eStatus).toLowerCase().includes(filedValue?.toLowerCase())
                );
            }
            else if (("Canceled".toLowerCase())?.includes(filedValue?.toLowerCase())) {
                filteredItems = data.filter(item =>
                    String(item?.eStatus).toLowerCase().includes(filedValue?.toLowerCase())
                );
            }
        }
        else if (filterField === "Date") {
            filteredItems = data.filter(item =>
                String(new Date(item?.date).getDate()) === String(new Date(filedValue).getDate())
            );
        }
        else if (filterField === "Month") {
            filteredItems = data.filter(item =>
                String(new Date(item?.date).getMonth()) === String(new Date(filedValue).getMonth()) &&
                String(new Date(item?.date).getFullYear()) === String(new Date(filedValue).getFullYear())
            );
        }
        setFilterData(filteredItems)
    }
    const getCategories = async () => {
        await axios.get(`${backendUrl}/api/category/get-all`).then((res) => {
            if (res && res.data) {
                setCategories(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const subHeaderComponent = (
        <div className="flex justify-end items-center gap-4">
            <div className="flex items-center  rounded-lg p-3 shadow-md h-12 bg-blue-100 mt-4 mb-4">
                <select
                    id="select"
                    autoComplete='off'
                    name="selectedRole"
                    className="outline-none bg-blue-100 text-lg text-gray-600 placeholder-gray-400"
                    style={{ minWidth: '10rem', paddingInlineEnd: '0.5rem' }}
                    value={filterField}
                    onChange={(e) => { setFilterField(e.target.value); setFilterValue("") }}
                    placeholder="Filter By"
                >
                    <option value="All">All</option>
                    <option value="Category">Category</option>
                    <option value="Status">Status</option>
                    <option value="Date">Date</option>
                    <option value="Month">Month</option>
                </select>
            </div>
            <div className="flex items-center rounded-lg p-3 shadow-md h-12 bg-blue-100 mt-4 mb-4">
                {filterField !== "Category" ? (
                    <input
                        type={filterField === "Date" ? "date" : filterField === "Month" ? "month" : "text"}
                        value={filterValue}
                        name="filterValue"
                        onChange={handleChange}
                        placeholder="Enter the Value"
                        className="outline-none bg-blue-100 text-lg text-gray-600 placeholder-gray-400"
                    />
                ) : (
                    <select
                        id="select"
                        autoComplete='off'
                        className="outline-none bg-blue-100 text-lg text-gray-600 placeholder-gray-400"
                        style={{ minWidth: '10rem', paddingInlineEnd: '0.5rem' }}
                        value={filterValue}
                        name="filterValue"
                        onChange={handleChange}
                        placeholder="Filter By"
                    >
                        <option value="#">Select Category</option>
                        {categories.map((item, index) => {
                            return (
                                <option value={item?._id} key={index}>{item?.name}</option>
                            )
                        })}
                    </select>
                )}
            </div>
            {isSearch && (
                <Search
                    onFilter={(event) => setFilterText(event.target.value)}
                    filterText={filterText}
                    placeholder={placeholder}
                    onClear={() => setFilterText('')}
                />
            )}
        </div>
    );

    return (
        <DataTable
            columns={columns}
            data={filterData}
            striped
            selectableRows={isSelectable}
            selectableRowsComponent={Checkbox}
            subHeader
            subHeaderComponent={isSearch ? subHeaderComponent : <></>}
            customStyles={tableCustomStyles}
            pointerOnHover
            highlightOnHover
            selectableRowsHighlight
            pagination={isPagination}
            conditionalRowStyles={conditionalRowStyles ?? []}
        />
    );
}

export default AddPrescriptionFilterTable;
