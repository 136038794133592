import { useState, useEffect } from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { errorAlert, successAlert } from "../../Ui/Alert";

const apiUrl = process.env.REACT_APP_API_BASE_URL
const Subscription = () => {
    const [subscriptionData, setSubscription] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [packageId, setPackageId] = useState("")
    const [orderId, setOrderId] = useState("")
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getSubscription()
    }, []);

    const handleClickOpen = (orderId, packageId) => {
        setOrderId(orderId)
        setPackageId(packageId)
        setOpen(true);
    }

    const handleClickClose = () => {
        setOpen(false);
        setOrderId("")
        setPackageId("")
    }

    const handleDeleteSubscription = async () => {
        await axios.put(`${apiUrl}/api/order/remove-package/${orderId}`, { package: packageId }).then((res) => {
            if (res && res.data && res.data.success) {
                successAlert("Subscription deleted successfully")
                getSubscription()
                setOpen(false)
            }
        }).catch((error) => {
            errorAlert(error.message)
        })
    }

    const columns = [
        {
            name: 'Photo',
            selector: row => (<Avatar src={apiUrl+"/uploads/"+row?.order?.customer?.imageUrl} alt="profile" />),
            center: true
        },
        {
            name: 'Client Name',
            selector: row => `${row?.order?.customer?.personalInfo?.first_name} ${row?.order?.customer?.personalInfo?.last_name}`,
            center: true
        },
        {
            name: 'Treatment',
            selector: row => row?.package?.package?.category?.name,
            center: true,
            wrap: true
        },
        {
            name: 'Package',
            selector: row => row?.package?.package?.name,
            center: true,
            wrap: true
        },
        {
            name: "Subscription Duration",
            selector: row => `${row?.package?.packageDetails?.duration} Months`,
            center: true,
            width: "300px"
        },
        {
            name: "Action",
            cell: (row) => (
                <div className=" ml-14 flex items-center">
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => navigate('/crm/view-subscription', { state: row })}
                    >
                        <VisibilityOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row?.order?._id, row?.package?.package?._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
            center: true
        },
    ]

    const getSubscription = async () => {
        await axios.get(`${apiUrl}/api/order/get-subscription`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) setSubscription(res.data)
        }).catch((error) => {
            errorAlert("Unable to fetch data")
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                    <p className=" text-2xl font-bold pt-4 pl-5 pb-4 text-blue-950">Subscription Management System</p>
                </div>
                <div className=" pl-5 pr-5">
                    {isLoading && <LoadingBackdrop isloading={isLoading}></LoadingBackdrop>}
                    {/* Table */}
                    <Table
                        columns={columns}
                        data={subscriptionData}
                        placeholder="Search Subscription"
                    />
                    <Dialog
                        open={open}
                        onClose={() => handleClickClose()}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the Client?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={() => handleClickClose()} variant="outlined">No</Button>
                            <Button
                                onClick={() => handleDeleteSubscription()}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Subscription;