const Card = ( {color, title, subtitle, icon} ) => {
    return (
        <div
          className={`shadow-md rounded-lg px-4 py-5 mr-2 flex flex-col w-90 h-30 text-white`} style={{backgroundColor: `${color}`,minHeight: "8.5rem",maxWidth:'15rem'}}>
          <div className="mb-3">
            <h3 className="text-3xl font-bold">{title}</h3>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-md w-48">{subtitle}</span>
            <img src={icon} alt="icon" className="h-[7vh] w-[7vh]" />
          </div>
        </div>
    );
}

export default Card;