import { useState } from "react";
import { useEffect, useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { AuthContext } from "./shared/auth-context";
import Dashboard from "./Pages/DashBoard/Dashboard";
import Login from "./Pages/Authentication/login";
import ForgotPassword from "./Pages/Authentication/forgetPassword";
import EmailMessage from "./Pages/Authentication/message";
import AllUsers from "./Pages/UserManagement/All_Users";
import SuspendUsers from "./Pages/UserManagement/SuspendUser";
import Verfication from "./Pages/Authentication/verification";
import NewUser from "./Pages/UserManagement/newUser";
import AllClients from "./Pages/CustomerRelationship/AllClients";
import Subscription from "./Pages/CustomerRelationship/Subscription";
import SuspendClients from "./Pages/CustomerRelationship/SuspendClients";
import ViewClient from "./Pages/CustomerRelationship/viewClient";
import Wallet from "./Pages/CustomerRelationship/wallet";
import AllPages from "./Pages/Content Management/AllPages";
import NewBlog from "./Pages/Content Management/newBlog";
import MedicalCard from "./Pages/Content Management/medicalCard";
import NewCard from "./Pages/Content Management/newCard";
import NewQuetion from "./Pages/Content Management/newQuestion";
import EditQuestion from "./Pages/Content Management/editQuestion";

// FAQS
import AllFaq from "./Pages/Content Management/Faqs/allFaq";
import NewFaq from "./Pages/Content Management/Faqs/newFaq";

import CRUDProducts from "./Pages/InventoryManagement/products/CRUD_Product";
import AddProduct from "./Pages/InventoryManagement/products/addProduct";
import ViewProduct from "./Pages/InventoryManagement/products/viewProduct";
import Questions from "./Pages/Content Management/questions";
import Packages from "./Pages/InventoryManagement/Packages";
import NewPackage from "./Pages/InventoryManagement/Packages/new-package";

import PermissionAndRole from "./Pages/PermissionandRole/PermissionRole";

import Settings from "./Pages/Settings/Settings";

import Orders from "./Pages/OrderManagement";
import ViewOrder from "./Pages/OrderManagement/viewOrder";

import ConsultationBookings from "./Pages/ConsultationManagement/ConsultationBookings";
import AllBookings from "./Pages/ConsultationManagement/AllBookings";
import ViewBookings from "./Pages/ConsultationManagement/viewBookings";
import SetAppointment from "./Pages/ConsultationManagement/setAppointment";

import { successAlert } from "./Ui/Alert";
import { MyProSidebarProvider } from "./Ui/SidebarContext";
import EditPackage from "./Pages/InventoryManagement/Packages/edit-package";
import EditBlog from "./Pages/Content Management/EditPage";
import UpdateUser from "./Pages/UserManagement/edit-user";
import NewMember from "./Pages/CustomerRelationship/new-user";
import UpdateFaq from "./Pages/Content Management/Faqs/update-faq";
import NewPermission from "./Pages/PermissionandRole/newPermission";
import { CookiesProvider } from "react-cookie";
import Category from "./Pages/Content Management/Category/Category";
import AddCategory from "./Pages/Content Management/Category/AddCategory";
import EditCategory from "./Pages/Content Management/Category/EditCategory";
import EditProduct from "./Pages/InventoryManagement/products/EditProduct";
import ViewSubscription from "./Pages/CustomerRelationship/ViewSubscription";
import WhatWeTreat from "./Pages/Content Management/What-We-Treat/WhatWeTreat";
import NewWhatWeTreat from "./Pages/Content Management/What-We-Treat/NewWhatWeTreat";
import UpdateWhatWeTreat from "./Pages/Content Management/What-We-Treat/UpdateWhatWeTreat";
import Prescription from "./Pages/ConsultationManagement/Prescription";
import AddPrescription from "./Pages/ConsultationManagement/AddPrescription";
import ContactDetails from "./Pages/CustomerRelationship/Contact Request/ContactDetails";
import axios from "axios";
import { accessModules } from "./utils/constant";
import NotFound from "./Pages/NotFound";
import PrivacyPolicy from "./Pages/Content Management/PrivacyPolicy";
import EditPrescription from "./Pages/ConsultationManagement/EditPrescription";
import NewsLetterSubscription from "./Pages/NewsLetterSubscription/NewsLetterSubscription";
let logoutTimer;
const backendUrl = process.env.REACT_APP_API_BASE_URL;
function App() {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [moduleAccess, setModuleAccess] = useState([])
  const navigate = useNavigate();
  const roleId = localStorage.getItem('userType')
  const login = useCallback((uid, token, expirationDate) => {
    setToken(token);
    const tokenExpiration =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(tokenExpiration);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpiration.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    successAlert("logged Out Successfully!!");
    setToken(null);
    localStorage.removeItem("userData");
    localStorage.clear()
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  useEffect(() => {
    getAccess()
  }, [roleId])

  const getAccess = async () => {
    if (roleId) {
      await axios.get(`${backendUrl}/api/roles/${roleId}`).then((res) => {
        if (res.data && res.data.accessLevels) {
          setModuleAccess(res.data.accessLevels)
        }
      }).catch((error) => {
        console.log(error.message)
      })
    }
  }

  const checkHaveModuleAccess = (moduleTag) => {
    if (Array.isArray(moduleTag)) {
      return moduleAccess.some(module => moduleTag.includes(module.moduleCode) && module.isAccess);
    }
    else {
      const module = moduleAccess.find(module => module.moduleCode === moduleTag && module.isAccess)
      return !!module;
    }
  }

  let routes;

  if (token) {
    routes = (
      <div style={{ backgroundColor: "#F0FBFF" }}>
        <MyProSidebarProvider>
          <div className="w-full">
            <main>
              <Routes>
                <Route path="/">
                  <Route index element={<Dashboard />} />
                </Route>
                {
                  checkHaveModuleAccess([accessModules.All_Users, accessModules.Suspend_User]) && (
                    <Route path="user">
                      {
                        checkHaveModuleAccess([accessModules.All_Users]) && (
                          <>
                            <Route index element={<AllUsers />} />
                            <Route path="newUser" element={<NewUser />} />
                            <Route path="editUser" element={<UpdateUser />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Suspend_User]) && (
                          <Route path="suspendUser" element={<SuspendUsers />} />
                        )
                      }
                    </Route>
                  )
                }
                {
                  checkHaveModuleAccess([accessModules.All_Clients, accessModules.Suspend_Clients, accessModules.Subscription_Management, accessModules.Contact_Request]) && (
                    <Route path="crm">
                      {
                        checkHaveModuleAccess([accessModules.All_Clients]) && (
                          <>
                            <Route index element={<AllClients />} />
                            <Route path="viewclient" element={<ViewClient />} />
                            <Route path="wallet" element={<Wallet />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Suspend_Clients]) && (
                          <Route path="suspendclients" element={<SuspendClients />} />
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Subscription_Management]) && (
                          <>
                            <Route path="subscription" element={<Subscription />} />
                            <Route path="view-subscription" element={<ViewSubscription />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Contact_Request]) && (
                          <Route path="contact-request" element={<ContactDetails />} />
                        )
                      }
                      {/* <Route path="addMember" element={<NewMember />} /> */}

                    </Route>
                  )
                }
                {
                  checkHaveModuleAccess([accessModules.All_Blogs, accessModules.What_We_Treat, accessModules.Category, accessModules.All_Faqs, accessModules.Questionnaire_Evaluation, accessModules.Privacy_Policy]) && (
                    <Route path="cm">
                      {
                        checkHaveModuleAccess([accessModules.All_Blogs]) && (
                          <>
                            <Route index element={<AllPages />} />
                            <Route path="newblog" element={<NewBlog />} />
                            <Route path="editpage" element={<EditBlog />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.What_We_Treat]) && (
                          <>
                            <Route path="what-we-treat" element={<WhatWeTreat />} />
                            <Route path="new-what-we-treat" element={<NewWhatWeTreat />} />
                            <Route path="edit-what-we-treat" element={<UpdateWhatWeTreat />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Questionnaire_Evaluation]) && (
                          <>
                            <Route path="questionnaire" element={<Questions />} />
                            <Route path="newquestion" element={<NewQuetion />} />
                            <Route path="updatequestion" element={<EditQuestion />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Category]) && (
                          <>
                            <Route path="category" element={<Category />} />
                            <Route path="add-category" element={<AddCategory />} />
                            <Route path="edit-category" element={<EditCategory />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.All_Faqs]) && (
                          <>
                            <Route path="faqs" element={<AllFaq />} />
                            <Route path="newFaq" element={<NewFaq />} />
                            <Route path="updateFaq" element={<UpdateFaq />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Privacy_Policy]) && (
                          <Route path="privacypolicy" element={<PrivacyPolicy />} />
                        )
                      }
                      <Route path="medcard" element={<MedicalCard />} />
                      <Route path="newcard" element={<NewCard />} />
                    </Route>
                  )
                }
                {
                  checkHaveModuleAccess([accessModules.Crud_Products, accessModules.Crud_Packages]) && (
                    <Route path="ivm">
                      {
                        checkHaveModuleAccess([accessModules.Crud_Products]) && (
                          <>
                            <Route index element={<CRUDProducts />} />
                            <Route path="newProduct" element={<AddProduct />} />
                            <Route path="editProduct" element={<EditProduct />} />
                            <Route path="viewProduct" element={<ViewProduct />} />
                          </>
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Crud_Packages]) && (
                          <>
                            <Route path="packages" element={<Packages />} />
                            <Route path="newPackage" element={<NewPackage />} />
                            <Route path="updatePackage" element={<EditPackage />} />
                          </>
                        )
                      }
                    </Route>
                  )
                }
                {
                  checkHaveModuleAccess([accessModules.All_Bookings, accessModules.Add_Prescription, accessModules.Consultation_Booking]) && (
                    <Route path="consult">
                      {
                        checkHaveModuleAccess([accessModules.All_Bookings]) && (
                          <Route index element={<AllBookings />} />
                        )
                      }
                      {
                        checkHaveModuleAccess([accessModules.Add_Prescription]) && (
                          <>
                            <Route path="prescription" element={<Prescription />} />
                            <Route path="add-prescription" element={<AddPrescription />} />
                            <Route path="edit-prescription" element={<EditPrescription />} />
                            <Route path="viewbooking" element={<ViewBookings />} />
                          </>
                        )
                      }

                      {
                        checkHaveModuleAccess([accessModules.Consultation_Booking]) && (
                          <>
                            <Route path="bookings" element={<ConsultationBookings />} />
                            <Route path="newappoints" element={<SetAppointment />} />
                          </>
                        )
                      }

                    </Route>
                  )
                }

                {
                  checkHaveModuleAccess([accessModules.Roles_And_Permissions]) && (
                    <Route path="pr">
                      <Route index element={<PermissionAndRole />} />
                      <Route path="newPermission" element={<NewPermission />} />
                      <Route
                        path="editPermission/:id"
                        element={<NewPermission />}
                      />
                    </Route>
                  )
                }
                {
                  checkHaveModuleAccess([accessModules.Order_Management]) && (
                    <Route path="om">
                      <Route index element={<Orders />} />
                      <Route path="viewOrder" element={<ViewOrder />} />
                    </Route>
                  )
                }
                {

                  checkHaveModuleAccess([accessModules.Settings]) && (
                    <Route path="settings">
                      <Route index element={<Settings />} />
                    </Route>
                  )
                }
                {
                  checkHaveModuleAccess([accessModules.News_Letter_Subscription]) && (
                    <Route path="news">
                      <Route index element={<NewsLetterSubscription />} />
                    </Route>
                  )
                }
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
      </div>
    );
  } else {
    routes = (
      <Routes>
        <Route pqth="/login" index element={<Login />} />
        <Route path="fp" element={<ForgotPassword />} />
        <Route path="verification/:token" element={<Verfication />} />
        <Route path="message" element={<EmailMessage />} />
      </Routes>
    );
  }

  return (
    <CookiesProvider>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          login: login,
          logout: logout,
        }}
      >
        <div>{routes}</div>
      </AuthContext.Provider>
    </CookiesProvider>
  );
}

export default App;
