import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import edit from "./Assets/edit.png";

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField
} from "@mui/material";

import Table from "../../Ui/table";
import TopHeader from "../../Ui/topHeader";
import axios from 'axios';
import { errorAlert, successAlert } from '../../Ui/Alert';
import LoadingBackdrop from '../../Ui/LoadinSpinner';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import moment from 'moment/moment';
import { consultationStatus, orderFor, orderStatus, paymentMode } from '../../utils/constant';
const apiUrl = process.env.REACT_APP_API_BASE_URL
const Orders = () => {

    const [orderData, setOrderData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [deleteOrderId, setDeleteOrderId] = useState();
    const [currentOrder, setCurrentOrder] = useState();
    const [editDialog, setEditDialog] = useState(false);
    const [status, setStatus] = useState();
    const [deliveryDate, setDeliveryDate] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        getAllOrders()
    }, [])


    const getAllOrders = async () => {
        await axios.get(`${apiUrl}/api/order/get-all`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) setOrderData(res.data.reverse())
        }).catch((error) => {
            errorAlert("Unable to fetch data")
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const handleUpdateOrderStatus = async (data) => {
        await axios.put(`${apiUrl}/api/order/orders/${currentOrder?._id}`, data).then(async (res) => {
            if (res.data) {
                setEditDialog(false);
                successAlert("Order Status Updated")
                await getAllOrders();
                setStatus("");
                setDeliveryDate("");
                if (res.data?.orderFor === orderFor.Consultation && (data.status === orderStatus.Delivered)) {
                    await updateConsultation({ _id: res.data?.consultationId, eStatus: consultationStatus.Completed })
                }
                else if (res.data?.orderFor === orderFor.Consultation && (data.status === orderStatus.Canceled)) {
                    await updateConsultation({ _id: res.data?.consultationId, eStatus: consultationStatus.Canceled })
                }
            }
        }).catch((error) => {
            errorAlert(error.message)
        })
    }
    const updateConsultation = async (data) => {
        await axios.put(`${apiUrl}/api/update-appointment/${data._id}`, data).then(async (res) => {
        }).catch((error) => {
            errorAlert("Unable to update")
        })
    }

    const handleClick = (data) => {
        navigate('viewOrder', { state: data })
    }

    const getStatus = (status) => {
        if (status === "Delivered") {
            return "success"
        }
        if (status === "Canceled") {
            return "error"
        }
        if (status === "Ongoing") {
            return "primary"
        }
        if (status === "Ordered") {
            return "warning"
        }
        if (status === "Out of Delivery") {
            return "secondary"
        }
    }
    const handleClickOpen = (id) => {
        setDeleteOrderId(id);
        setShowModal(true);
    }
    const handleClickClose = () => {
        setShowModal(false);
        setDeleteOrderId("")
    }
    const handleDeleteOrder = async () => {
        await axios.delete(`${apiUrl}/api/order/delete/${deleteOrderId}`).then((res) => {
            if (res && res.data && res.data.success) {
                successAlert("Order deleted successfully")
                getAllOrders()
                setShowModal(false)
            }
        }).catch((error) => {
            errorAlert(error.message)
        })
    }
    const columns = [
        {
            name: "Order Id",
            selector: row => row?.orderId,
            center: "true",
            width: '200px'
        },
        {
            name: "Customer Name",
            selector: row => `${row?.customer?.personalInfo?.first_name} ${row?.customer?.personalInfo?.last_name}`,
            width: '200px',
            wrap: true,
            center: true
        },
        {
            name: "Quantity",
            selector: row => (row?.orderProducts?.reduce((total, item) => total + item?.quantity, 0)),
            center: true
        },
        {
            name: "Price",
            selector: row => row?.totalPrice,
            width: '80px',
            center: "true",
        },
        {
            name: "Payment Mode",
            selector: row => paymentMode[row?.paymentDetails?.paymentMode],
            width: '150px',
            center: "true",
            // wrap:"true"
        },
        {
            name: "Status",
            cell: (row) => (
                <Chip label={row.status} color={getStatus(row.status)} />
            ),
            width: '150px',
            center: "true",

        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex items-center">
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => handleClick(row)}
                    >
                        <RemoveRedEyeOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row._id)}

                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => handleEditClick(row)}
                        disabled={row?.status === "Delivered" || row?.status === "Canceled"}
                    >
                        <img src={edit} alt="edit" />
                    </IconButton>
                </div>
            ),
        },
    ]
    const handleEditClick = (order) => {
        setCurrentOrder(order);
        setEditDialog(true);
    }
    const handleUpdateOrder = async () => {
        if (status === "Ongoing") {
            await handleUpdateOrderStatus({ status: status, deliveryDate: deliveryDate });
        } else {
            await handleUpdateOrderStatus({ status: status });
        }

    }

    return (
        <>
            <TopHeader />
            <div className=" ml-5 mt-5">
                <h1 className=" text-3xl text-blue-800 font-bold">Orders</h1>
                <p className=" text-neutral-600 font-bold">Track and view all the order details.</p>
            </div>
            <div className=" ml-5 mr-5 mt-5">
                {
                    isLoading && (
                        <LoadingBackdrop isloading={isLoading} />
                    )
                }
                <Table
                    data={orderData}
                    columns={columns}
                    placeholder="Search Orders"
                />
                <Dialog
                    open={showModal}
                    onClose={() => handleClickClose()}
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to delete the Order?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>

                        <Button onClick={() => handleClickClose()} variant="outlined">No</Button>
                        <Button
                            onClick={() => handleDeleteOrder()}
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                            }}
                        >Yes</Button>

                    </DialogActions>
                </Dialog>
                <Dialog
                    open={editDialog}
                    onClose={() => setEditDialog(false)}
                >
                    <DialogTitle style={{ fontWeight: "bold", padding: "1rem" }}>Update Order Status</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            <Box sx={{ marginTop: "1rem", minWidth: "25rem" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="status">Status</InputLabel>
                                    <Select
                                        labelId="status"
                                        id="eStatus"
                                        name="eStatus"
                                        label="Status"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        style={{ marginBottom: "1rem" }}
                                    >
                                        <MenuItem value="Ongoing">Ongoing</MenuItem>
                                        <MenuItem value="Out For Delivery">Out For Delivery</MenuItem>
                                        <MenuItem value="Canceled">Canceled</MenuItem>
                                        <MenuItem value="Delivered">Delivered</MenuItem>
                                    </Select>
                                </FormControl>
                                {
                                    status === "Ongoing" && (
                                        <FormControl fullWidth>
                                            <TextField
                                                type="Date"
                                                id="deliveryDate"
                                                label="Expected Delivery Date"
                                                name="deliveryTime"
                                                InputLabelProps={{ shrink: true }}
                                                value={deliveryDate}
                                                inputProps={{
                                                    min: moment().add(1, 'days').format('YYYY-MM-DD') // Set the minimum date to tomorrow
                                                }}
                                                // style={{ marginTop: "3rem", minWidth: "25rem" }}
                                                onChange={(e) => setDeliveryDate(e.target.value)}
                                            />
                                        </FormControl>
                                    )
                                }
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                        <Button
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                            }}
                            onClick={handleUpdateOrder}
                        >
                            Update
                        </Button>
                        <Button
                            onClick={() => setEditDialog(false)}
                        >
                            Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default Orders;