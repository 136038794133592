import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import edit from "./Assets/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";
import { deletePermission, getAllPermissions } from "../../store/actions/permissionaction";
import LoadingBackdrop from "../../Ui/LoadinSpinner";

const PermissionAndRole = () => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [permissiondata, setpermissiondata] = useState();
    const [deletepermissionid, setpermissionid] = useState();

    const handleClickOpen = (id) => {
        setpermissionid(id);
        setOpen(true);
    }
    const handleClickClose = () => {
        setOpen(false);
    }
    const loading = useSelector(
        (state) => state.permissions.loading
    )

    const permissionsstatedata = useSelector(
        (state) => state.permissions.permissionsdata
    )
    const dataFetchedRef = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getallpermissions();
    }, [dispatch]);


    const getallpermissions = async () => {
        const fetchedata = await dispatch(getAllPermissions());
        if (fetchedata && fetchedata.payload && fetchedata.payload.length>0) {
            const data = fetchedata.payload.map((permission, index) => {
                return {
                    index: index + 1,
                    _id: permission._id,
                    role: permission.role,
                    tag: permission.tag ?? "---",
                }
            })
            setpermissiondata(data);
        }

    }

    const handledeletepermission = async () => {
        await dispatch(deletePermission(deletepermissionid));
        setOpen(false);
        await getallpermissions();
        navigate('/pr');
    }

    const columns = [
        {
            name: 'Sr. N.',
            selector: row => { return (<p>{row.index}.</p>) },
            // width: "120px"
            center:'true'
        },
        {
            name: 'Role',
            selector: row => row.role,
            center:'true'
        },
        {
            name: 'Tag',
            selector: row => row.tag,
            center:'true'
        },

        {
            name: "Action",
            cell: (row) => (
                <div className=" flex items-center">
                    <IconButton
                        style={{
                            color: "#39BFAF",
                        }}
                        onClick={() => navigate(`/pr/editPermission/${row._id}`)}
                    >
                        <img src={edit} alt=""></img>
                    </IconButton>
                  
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
            center:'true'
        },
    ]


    return (
        <div>
            <TopHeader />
            <div>
                <div className=" flex justify-between pt-2 pl-5 pb-4 items-center">
                    <p className=" text-2xl font-bold pt-4 pl-5 pb-4 text-blue-950">Permission And Role</p>
                    <div className=" flex gap-x-4">
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                marginLeft: "20px"
                            }}
                            onClick={() => navigate("newPermission")}
                        >Add New Role</Button>
                    </div>
                </div>
            </div>
            <div className="pl-5 pr-5">
                {loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}
                <Table
                    columns={columns}
                    data={permissiondata}
                    placeholder="Search Permissions"
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClickClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete the role?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>

                    <Button onClick={handleClickClose} variant="outlined">No</Button>
                    <Button
                        onClick={() => handledeletepermission()}
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            },
                        }}
                    >Yes</Button>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PermissionAndRole;