import Intro from "../Ui/intro";
import logo from "../assets/logo.png"

const IntroPage = () => {
    return(
        <div className=" w-[60%] relative h-screen flex flex-col" style={{backgroundColor: "#F0FBFF"}}>
                <div className=" pt-10 pl-10">
                    <Intro />
                </div>
                <div className=" flex items-center justify-center mt-16">
                    <img 
                        src={logo}
                        alt="Logo"
                        height={220}
                        width={220}
                    />
                </div>
                <div className=" absolute bottom-10 right-10">
                    <Intro />
                </div>
            </div>
    )
}

export default IntroPage;