import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Search from "../search";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { errorAlert } from "../Alert";

const tableCustomStyles = {
  headRow: {
    style: {
      color: '#FFFFFF',
      backgroundColor: '#526D82',
      fontWeight: "bold"
    },
  },
  rows: {
    style: {
      backgroundColor: "#DDE6ED",
      fontWeight: 600,
    },
    stripedStyle: {
      backgroundColor: "#F0FBFF"
    },
  },
  pagination: {
    style: {
      backgroundColor: "#9DB2BF",
      color: "black",
      fontWeight: "bold",
      fontSize: "15px"
    }
  },
  subHeader: {
    style: {
      backgroundColor: "#F4F9F9",
      display: 'block',
      paddingInline: 0,
      marginInline: 0
    }
  }
}

const backendUrl = process.env.REACT_APP_API_BASE_URL;

const UserTable = ({ columns, data, isSelectable, placeholder, isSearch = true, isPagination = true, conditionalRowStyles }) => {
  const [filterText, setFilterText] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("All");
  const [filterData,setFilterData]=useState([])

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    getFilter()
  }, [data,filterText]);

  const getAllRoles = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/roles`);
      setRoles(response.data || []);
    } catch (error) {
      errorAlert("Unable to get roles");
    }
  };

  const getFilter=async()=>{
    let filteredItems = data?.filter(item =>
     JSON.stringify(item)
       .toLowerCase()
       .includes(filterText.toLowerCase())
   ) || [];
   setFilterData(filteredItems)
  }

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedRole(value);
    if(value!=="All"){
      const filteredItems=data?.filter((item)=>item?.userType?._id===value)
      setFilterData(filteredItems)
    }
    else{
      setFilterData(data)
    }
  };


  const subHeaderComponent = (
    <div className="flex justify-between items-center gap-4">
      <div className="flex items-center gap-4 rounded-lg p-3 shadow-md h-12 bg-blue-100 mt-4 mb-4">
        <select
          id="select"
          autoComplete='off'
          name="selectedRole"
          className="outline-none bg-blue-100 text-lg text-gray-600 placeholder-gray-400"
          style={{ minWidth: '10rem', paddingInlineEnd: '0.5rem' }}
          value={selectedRole}
          onChange={handleChange}
        >
          <option value="All">All User Type</option>
          {roles.map((item, index) => (
            <option value={item?._id} key={index}>{item?.role}</option>
          ))}
        </select>
      </div>
      {isSearch && (
        <Search
          onFilter={(event) => setFilterText(event.target.value)}
          filterText={filterText}
          placeholder={placeholder}
          onClear={()=>setFilterText('')}
        />
      )}
    </div>
  );

  return (
    <DataTable
      columns={columns}
      data={filterData}
      striped
      selectableRows={isSelectable}
      selectableRowsComponent={Checkbox}
      subHeader
      subHeaderComponent={isSearch ? subHeaderComponent : <></>}
      customStyles={tableCustomStyles}
      pointerOnHover
      highlightOnHover
      selectableRowsHighlight
      pagination={isPagination}
      conditionalRowStyles={conditionalRowStyles ?? []}
    />
  );
}

export default UserTable;
