import { useState, useRef, Fragment, useEffect } from "react";
import * as yup from "yup";
import { FieldArray, Formik, getIn } from "formik";

import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorAlert, successAlert } from "../../../../Ui/Alert";
import axios from "axios";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ImageUpload } from "../../../../Ui/imageUpload";

const packageSchema = yup.object().shape({
    image: yup.mixed().nullable(),
    name: yup.string().required("Product name required."),
    category: yup.string().required("Product name required."),
    packages: yup.array().of(
        yup.object().shape({
            // tab_quantity: yup.string().required("Quantity of tablets required."),
            packageDuration: yup.string().required("Package Duration required"),
            price: yup.number().required("Price is required."),
            discount: yup.string().required("Discount required."),
            savings: yup.string().required("Savings required."),
        })
    ),
    products: yup.array().of(
        yup.object().shape({
            product: yup.string().required("Product name required"),
            productQuantity: yup.number().required("Quantity is required."),
            howToUse: yup.string().required("How to use the product is required."),
        })
    ),
    benefits: yup.string().required("Benefits required."),
    howToUse: yup.string().required("How to use required."),
    goodToKnow: yup.string().required("Required."),
    description: yup.string().required("Description required")
})

const steps = ['Basic Details', 'Product Details', 'Descriptive Details']
const apiUrl = process.env.REACT_APP_API_BASE_URL
const PackageStepper2 = () => {
    const [category, setCategory] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true)

    const productsdata = useSelector(
        (state) => state.products.productsdata
    )
    const [medicines, setmedicines] = useState([]);
    const [image, setImage] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    useEffect(() => {
        getproductsName();
        getCategories()

    }, [])


    const getproductsName = () => {
        const data = productsdata.map((item) => {
            return {
                title: item.name,
                value: item._id
            }
        })
        setmedicines(data);
    }

    const getCategories = async () => {
        await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
            if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                setCategory(res.data)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    }

    const handleSubmit = async (values, actions) => {
        const formData = new FormData()
        formData.append('image', image?.file);
        formData.append('name', values.name);
        formData.append('category', values.category);
        formData.append('benefits', values.benefits);
        formData.append('howToUse', values.howToUse);
        formData.append('goodToKnow', values.goodToKnow);
        formData.append('description', values.description);

        values.products.forEach((item, index) => {
            formData.append(`products[${index}][product]`, item.product);
            formData.append(`products[${index}][productQuantity]`, item.productQuantity);
            formData.append(`products[${index}][howToUse]`, item.howToUse);
        });

        values.packages.forEach((item, index) => {
            formData.append(`packagesCost[${index}][packageDuration]`, item.packageDuration);
            formData.append(`packagesCost[${index}][price]`, item.price);
            formData.append(`packagesCost[${index}][discount]`, item.discount);
            formData.append(`packagesCost[${index}][savings]`, item.savings);
        });
        await axios.post(`${apiUrl}/api/treatment-packages`, formData).then((res) => {
            if (res && res.data && res.data.success) {
                successAlert('Package Added Successfully')
                navigate(-1)
            }
        }).catch((error) => {
            errorAlert('Some Error Ocurred!!!')
        })
    }

    const selectFile = () => {
        fileInputRef.current.click();
    }

    const onFileSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            )
        }
    }


    return (
        <Paper className="p-6 mt-3 mb-12 w-full">
            <Formik
                initialValues={{
                    name: "",
                    category: "",
                    // productId: "",
                    packages: [{ packageDuration: "", discount: "", savings: "", price: "" }],
                    products: [{ product: "", productQuantity: "", howToUse: "", }],
                    benefits: "",
                    howToUse: "",
                    goodToKnow: "",
                    description: ""
                }}
                validationSchema={packageSchema}
                onSubmit={handleSubmit}
            >
                {
                    (formik) => (
                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                            <Box sx={{ width: "100%" }}>
                                <Stepper activeStep={activeStep}>
                                    {
                                        steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            return (
                                                <Step key={index} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            )
                                        })
                                    }
                                </Stepper>
                                {
                                    activeStep === steps.length
                                        ?
                                        (
                                            <Fragment>
                                                <Typography sx={{ mt: 2, mb: 1 }}>
                                                    All steps completed - you&apos;re finished
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    <Button onClick={handleReset}>Reset</Button>
                                                </Box>
                                            </Fragment>
                                        )
                                        :
                                        (
                                            <Fragment>
                                                {
                                                    activeStep === 0
                                                    &&
                                                    <>
                                                        <h1 className="text-neutral-500 font-bold mt-2">Fill the basic package details.</h1>
                                                        <div className="mt-8 mb-6 w-full">
                                                            <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                                                                <ImageUpload
                                                                    image={image}
                                                                    onRemove={() => setImage("")}
                                                                />
                                                                <input
                                                                    id="photo"
                                                                    name="photo"
                                                                    type="file"
                                                                    accept=".jpg, .png, .jpeg"
                                                                    ref={fileInputRef}
                                                                    className="hidden"
                                                                    onChange={onFileSelect}
                                                                />
                                                                <div
                                                                    className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                                    onClick={selectFile}
                                                                >
                                                                    <p className=" font-extrabold text-sm ">Package image</p>
                                                                    <AddPhotoAlternateIcon className="" fontSize="small" />
                                                                </div>
                                                            </div>
                                                            <div className="grid grid-cols-2 space-x-12 mb-8">
                                                                <FormControl>
                                                                    <TextField
                                                                        name="name"
                                                                        label="Package Name"
                                                                        value={formik.values.name}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={Boolean(formik.touched.name && formik.errors.name)}
                                                                        helperText={
                                                                            formik.touched.name &&
                                                                            formik.errors.name &&
                                                                            String(formik.errors.name)
                                                                        }
                                                                        required
                                                                        sx={{
                                                                            fieldset: {
                                                                                borderWidth: "3px",
                                                                                borderColor: "#526D82"
                                                                            },
                                                                            input: {
                                                                                fontWeight: "medium",
                                                                            },
                                                                            label: {
                                                                                color: "#526D82"
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                <FormControl>
                                                                    <TextField
                                                                        id="category"
                                                                        name="category"
                                                                        select
                                                                        label="Category"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.category}
                                                                        onBlur={formik.handleBlur}
                                                                        error={Boolean(formik.errors.category && formik.touched.category)}
                                                                        helperText={
                                                                            formik.errors.category &&
                                                                            formik.touched.category &&
                                                                            String(formik.errors.category)
                                                                        }
                                                                        required={true}
                                                                        sx={{
                                                                            fieldset: {
                                                                                borderWidth: "3px",
                                                                                borderColor: "#526D82"
                                                                            },
                                                                            input: {
                                                                                fontWeight: "medium",
                                                                            },
                                                                            label: {
                                                                                color: "#526D82"
                                                                            }
                                                                        }}
                                                                    >
                                                                          <MenuItem  value="Select Duration">
                                                                          Select Duration
                                                                            </MenuItem>
                                                                        {category.map((option, index) => (
                                                                            <MenuItem key={index} value={option._id}>
                                                                                {option.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </FormControl>
                                                            </div>
                                                            <Divider variant="" sx={{ borderBottomWidth: "4px", borderBottomColor: "#737373" }} />
                                                            <h1 className=" text-neutral-500 font-bold mt-4 mb-4">Add Package</h1>
                                                            <FieldArray
                                                                name="packages"
                                                                render={
                                                                    (arrayHelpers, i) => {
                                                                        return (
                                                                            <div key={i}>
                                                                                {formik.values.packages?.map((pack, index) => {
                                                                                    const touchedPrice = getIn(formik.touched, `packages.${index}.price`);
                                                                                    const errorPrice = getIn(formik.errors, `packages.${index}.price`);

                                                                                    const touchedDuration = getIn(formik.touched, `packages.${index}.packageDuration`);
                                                                                    const errorDuration = getIn(formik.errors, `packages.${index}.packageDuration`);

                                                                                    const touchedDiscount = getIn(formik.touched, `packages.${index}.discount`);
                                                                                    const errorDiscount = getIn(formik.errors, `packages.${index}.discount`);

                                                                                    const touchedSavings = getIn(formik.touched, `packages.${index}.savings`);
                                                                                    const errorSavings = getIn(formik.errors, `packages.${index}.savings`);
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            <div className="mt-2">
                                                                                                {
                                                                                                    <div key={index}>
                                                                                                        {index > 0 &&
                                                                                                            <div>
                                                                                                                <IconButton
                                                                                                                    color="error"
                                                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                                                >
                                                                                                                    <DeleteOutlinedIcon />
                                                                                                                </IconButton>
                                                                                                            </div>
                                                                                                        }
                                                                                                        <div className="grid grid-cols-2 space-x-12 mb-8">
                                                                                                            <FormControl>
                                                                                                                <TextField
                                                                                                                    name={`packages.${index}.packageDuration`}
                                                                                                                    label="Package Duration"
                                                                                                                    type="number"
                                                                                                                    value={pack.packageDuration}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    error={Boolean(touchedDuration && errorDuration)}
                                                                                                                    helperText={
                                                                                                                        errorDuration &&
                                                                                                                        touchedDuration &&
                                                                                                                        String(errorDuration)
                                                                                                                    }
                                                                                                                    InputProps={{
                                                                                                                        startAdornment: <InputAdornment position="start">Months</InputAdornment>
                                                                                                                    }}
                                                                                                                    required
                                                                                                                    sx={{
                                                                                                                        fieldset: {
                                                                                                                            borderWidth: "3px",
                                                                                                                            borderColor: "#526D82"
                                                                                                                        },
                                                                                                                        input: {
                                                                                                                            fontWeight: "medium",
                                                                                                                        },
                                                                                                                        label: {
                                                                                                                            color: "#526D82"
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormControl>
                                                                                                            <FormControl>
                                                                                                                <TextField
                                                                                                                    name={`packages.${index}.price`}
                                                                                                                    type="number"
                                                                                                                    label="Total Price"
                                                                                                                    value={pack.price}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    error={Boolean(touchedPrice && errorPrice)}
                                                                                                                    helperText={
                                                                                                                        errorPrice &&
                                                                                                                        touchedPrice &&
                                                                                                                        String(errorPrice)
                                                                                                                    }
                                                                                                                    InputProps={{
                                                                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                                                                    }}
                                                                                                                    required
                                                                                                                    sx={{
                                                                                                                        fieldset: {
                                                                                                                            borderWidth: "3px",
                                                                                                                            borderColor: "#526D82"
                                                                                                                        },
                                                                                                                        input: {
                                                                                                                            fontWeight: "medium",
                                                                                                                        },
                                                                                                                        label: {
                                                                                                                            color: "#526D82"
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormControl>
                                                                                                        </div>
                                                                                                        <div className="grid grid-cols-3 space-x-12 mb-8">

                                                                                                            <FormControl>
                                                                                                                <TextField
                                                                                                                    name={`packages.${index}.discount`}
                                                                                                                    label="Discount"
                                                                                                                    value={pack.discount}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    error={Boolean(touchedDiscount && errorDiscount)}
                                                                                                                    helperText={
                                                                                                                        errorDiscount &&
                                                                                                                        touchedDiscount &&
                                                                                                                        String(errorDiscount)
                                                                                                                    }
                                                                                                                    required
                                                                                                                    InputProps={{
                                                                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                                                                    }}
                                                                                                                    sx={{
                                                                                                                        fieldset: {
                                                                                                                            borderWidth: "3px",
                                                                                                                            borderColor: "#526D82"
                                                                                                                        },
                                                                                                                        input: {
                                                                                                                            fontWeight: "medium",
                                                                                                                        },
                                                                                                                        label: {
                                                                                                                            color: "#526D82"
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormControl>
                                                                                                            <FormControl>
                                                                                                                <TextField
                                                                                                                    name={`packages.${index}.savings`}
                                                                                                                    label="Savings"
                                                                                                                    value={pack.savings}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    error={Boolean(touchedSavings && errorSavings)}
                                                                                                                    helperText={
                                                                                                                        errorSavings &&
                                                                                                                        touchedSavings &&
                                                                                                                        String(errorSavings)
                                                                                                                    }
                                                                                                                    required
                                                                                                                    InputProps={{
                                                                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                                                                    }}
                                                                                                                    sx={{
                                                                                                                        fieldset: {
                                                                                                                            borderWidth: "3px",
                                                                                                                            borderColor: "#526D82"
                                                                                                                        },
                                                                                                                        input: {
                                                                                                                            fontWeight: "medium",
                                                                                                                        },
                                                                                                                        label: {
                                                                                                                            color: "#526D82"
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormControl>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                formik.values.packages.length - 1 != index && (
                                                                                                    <Divider variant="" sx={{ borderBottomWidth: "4px", borderBottomColor: "#737373" }} />
                                                                                                )
                                                                                            }
                                                                                        </Fragment>)
                                                                                })}
                                                                                <div className=" ml-6">
                                                                                    <Button
                                                                                        endIcon={<AddIcon />}
                                                                                        variant="contained"
                                                                                        onClick={() => arrayHelpers.insert(
                                                                                            formik.values.packages.length + 1,
                                                                                            { packageDuration: "", discount: "", savings: "", price: "" }
                                                                                        )
                                                                                        }
                                                                                    >Add Package</Button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    activeStep === 1
                                                    &&
                                                    <>
                                                        <h1 className="text-neutral-500 font-bold mt-2">Fill the Product details.</h1>
                                                        <div className="mt-8 mb-6 w-full">
                                                            <FieldArray
                                                                name="products"
                                                                render={
                                                                    (arrayHelpers, i) => {
                                                                        return (
                                                                            <div key={i}>
                                                                                {formik.values.products?.map((product, index) => {

                                                                                    const touchedQuantity = getIn(formik.touched, `products.${index}.productQuantity`);
                                                                                    const errorQuantity = getIn(formik.errors, `products.${index}.productQuantity`);

                                                                                    const touchedProduct = getIn(formik.touched, `products.${index}.product`);
                                                                                    const errorProduct = getIn(formik.errors, `products.${index}.product`);

                                                                                    const touchedHowToUse = getIn(formik.touched, `products.${index}.howToUse`);
                                                                                    const errorHowToUse = getIn(formik.errors, `products.${index}.howToUse`);

                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            <div className="mt-2">
                                                                                                {
                                                                                                    <div key={index}>
                                                                                                        {index > 0 &&
                                                                                                            <div>
                                                                                                                <IconButton
                                                                                                                    color="error"
                                                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                                                >
                                                                                                                    <DeleteOutlinedIcon />
                                                                                                                </IconButton>
                                                                                                            </div>
                                                                                                        }
                                                                                                        <div className="grid grid-cols-2 space-x-12 mb-8">
                                                                                                            <FormControl fullWidth>
                                                                                                                <Autocomplete
                                                                                                                    id={`products.${index}.product`}
                                                                                                                    name={`products.${index}.product`}
                                                                                                                    value={medicines.find((item) => item.value == product.product)}
                                                                                                                    options={medicines}
                                                                                                                    getOptionLabel={(option) => `${option.title} - ${option.value}`}
                                                                                                                    onChange={(e, value) => {
                                                                                                                        formik.setFieldValue(`products.${index}.product`, value?.value || "");
                                                                                                                    }}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    includeInputInList
                                                                                                                    fullWidth
                                                                                                                    renderInput={(params) => (
                                                                                                                        <TextField
                                                                                                                            {...params}
                                                                                                                            fullWidth
                                                                                                                            label="Product Name"
                                                                                                                            name={`products.${index}.product`}
                                                                                                                            onChange={formik.handleChange}
                                                                                                                            value={product.product}
                                                                                                                            error={Boolean(touchedProduct && errorProduct)}
                                                                                                                            helperText={
                                                                                                                                touchedProduct &&
                                                                                                                                errorProduct &&
                                                                                                                                String(errorProduct)
                                                                                                                            }
                                                                                                                            required={true}
                                                                                                                            sx={{
                                                                                                                                fieldset: {
                                                                                                                                    borderWidth: "3px",
                                                                                                                                    borderColor: "#526D82"
                                                                                                                                },
                                                                                                                                input: {
                                                                                                                                    fontWeight: "medium",
                                                                                                                                },
                                                                                                                                label: {
                                                                                                                                    color: "#526D82"
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                />

                                                                                                            </FormControl>
                                                                                                            <FormControl>
                                                                                                                <TextField
                                                                                                                    name={`products.${index}.productQuantity`}
                                                                                                                    label="Product Quantity"
                                                                                                                    value={product.productQuantity}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    error={Boolean(touchedQuantity && errorQuantity)}
                                                                                                                    helperText={
                                                                                                                        errorQuantity &&
                                                                                                                        touchedQuantity &&
                                                                                                                        String(errorQuantity)
                                                                                                                    }
                                                                                                                    required
                                                                                                                    sx={{
                                                                                                                        fieldset: {
                                                                                                                            borderWidth: "3px",
                                                                                                                            borderColor: "#526D82"
                                                                                                                        },
                                                                                                                        input: {
                                                                                                                            fontWeight: "medium",
                                                                                                                        },
                                                                                                                        label: {
                                                                                                                            color: "#526D82"
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormControl>
                                                                                                        </div>
                                                                                                        <div className="grid grid-cols-1 space-x-12 mb-8">
                                                                                                            <FormControl>
                                                                                                                <TextField
                                                                                                                    name={`products.${index}.howToUse`}
                                                                                                                    label="How to Use"
                                                                                                                    value={product.howToUse}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                    error={Boolean(touchedHowToUse && errorHowToUse)}
                                                                                                                    helperText={
                                                                                                                        errorHowToUse &&
                                                                                                                        touchedHowToUse &&
                                                                                                                        String(errorHowToUse)
                                                                                                                    }
                                                                                                                    required

                                                                                                                    sx={{
                                                                                                                        fieldset: {
                                                                                                                            borderWidth: "3px",
                                                                                                                            borderColor: "#526D82"
                                                                                                                        },
                                                                                                                        input: {
                                                                                                                            fontWeight: "medium",
                                                                                                                        },
                                                                                                                        label: {
                                                                                                                            color: "#526D82"
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormControl>


                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                formik.values.packages.length - 1 != index && (
                                                                                                    <Divider variant="" sx={{ borderBottomWidth: "4px", borderBottomColor: "#737373" }} />
                                                                                                )
                                                                                            }
                                                                                        </Fragment>)
                                                                                })}
                                                                                <div className=" ml-6">
                                                                                    <Button
                                                                                        endIcon={<AddIcon />}
                                                                                        variant="contained"
                                                                                        onClick={() => arrayHelpers.insert(
                                                                                            formik.values.products.length + 1,
                                                                                            { product: "", productQuantity: "", howToUse: "" }
                                                                                        )
                                                                                        }
                                                                                    >Add Product</Button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    activeStep === 2

                                                    &&

                                                    <>
                                                        <h1 className=" text-neutral-500 font-bold mt-2">Provide descriptive information.</h1>
                                                        <div className=" mt-4 mb-8">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="benefits"
                                                                    name="benefits"
                                                                    multiline
                                                                    minRows={4}
                                                                    label="Package Benefits"
                                                                    value={formik.values.benefits}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    error={Boolean(formik.errors.benefits && formik.touched.benefits)}
                                                                    helperText={
                                                                        (formik.errors.benefits &&
                                                                            formik.touched.benefits) ?
                                                                            String(formik.errors.benefits)
                                                                            :
                                                                            "Please enter the benefits of the package."
                                                                    }
                                                                    required={true}
                                                                    sx={{
                                                                        fieldset: {
                                                                            borderWidth: "3px",
                                                                            borderColor: "#526D82"
                                                                        },
                                                                        input: {
                                                                            fontWeight: "medium",
                                                                        },
                                                                        label: {
                                                                            color: "#526D82"
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className=" mt-4 mb-8">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="howToUse"
                                                                    name="howToUse"
                                                                    multiline
                                                                    minRows={4}
                                                                    label="How to use?"
                                                                    value={formik.values.howToUse}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    error={Boolean(formik.errors.howToUse && formik.touched.howToUse)}
                                                                    helperText={
                                                                        (formik.errors.howToUse &&
                                                                            formik.touched.howToUse) ?
                                                                            String(formik.errors.howToUse)
                                                                            :
                                                                            "Please provide the directions to use the packages."
                                                                    }
                                                                    required={true}
                                                                    sx={{
                                                                        fieldset: {
                                                                            borderWidth: "3px",
                                                                            borderColor: "#526D82"
                                                                        },
                                                                        input: {
                                                                            fontWeight: "medium",
                                                                        },
                                                                        label: {
                                                                            color: "#526D82"
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className=" mt-4 mb-8">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="goodToKnow"
                                                                    name="goodToKnow"
                                                                    multiline
                                                                    minRows={4}
                                                                    label="Good to know"
                                                                    value={formik.values.goodToKnow}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    error={Boolean(formik.errors.goodToKnow && formik.touched.goodToKnow)}
                                                                    helperText={
                                                                        (formik.errors.goodToKnow &&
                                                                            formik.touched.goodToKnow) &&
                                                                        String(formik.errors.goodToKnow)
                                                                    }
                                                                    required={true}
                                                                    sx={{
                                                                        fieldset: {
                                                                            borderWidth: "3px",
                                                                            borderColor: "#526D82"
                                                                        },
                                                                        input: {
                                                                            fontWeight: "medium",
                                                                        },
                                                                        label: {
                                                                            color: "#526D82"
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className=" mt-4 mb-8">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="description"
                                                                    name="description"
                                                                    multiline
                                                                    minRows={4}
                                                                    label="Package Description"
                                                                    value={formik.values.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    error={Boolean(formik.errors.description && formik.touched.description)}
                                                                    helperText={
                                                                        (formik.errors.description &&
                                                                            formik.touched.description) ?
                                                                            String(formik.errors.description)
                                                                            :
                                                                            "Please provide descriptive details about the product."
                                                                    }
                                                                    required={true}
                                                                    sx={{
                                                                        fieldset: {
                                                                            borderWidth: "3px",
                                                                            borderColor: "#526D82"
                                                                        },
                                                                        input: {
                                                                            fontWeight: "medium",
                                                                        },
                                                                        label: {
                                                                            color: "#526D82"
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </>
                                                }
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                    <Button
                                                        color="inherit"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    {
                                                        activeStep === steps.length - 1
                                                        &&
                                                        <Button
                                                            type='submit'
                                                            disabled={formik.isSubmitting}
                                                        >
                                                            Submit
                                                        </Button>
                                                    }
                                                    {
                                                        activeStep !== steps.length - 1
                                                        &&
                                                        <Button
                                                            onClick={handleNext}
                                                        >
                                                            Next
                                                        </Button>
                                                    }
                                                </Box>
                                            </Fragment>
                                        )
                                }
                            </Box>
                        </form>
                    )
                }
            </Formik>
        </Paper>
    )
}

export default PackageStepper2;


