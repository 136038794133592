import { useLocation } from "react-router-dom";

import {
    Avatar,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Divider,
    Paper,
} from "@mui/material";

import TopHeader from "../../Ui/topHeader";

import med from "./Assets/Med copy.png"
import { orderStatus } from "../../utils/constant";
const apiUrl = process.env.REACT_APP_API_BASE_URL

const ViewOrder = () => {

    const { state: data } = useLocation();

    return (
        <>
            <TopHeader />
            <div className=" ml-5 mt-5 mb-8">
                <h1 className=" text-3xl text-blue-800 font-bold">Order Details</h1>
                <p className="text-neutral-600 font-bold">Get all the details about the order</p>
            </div>
            <Divider
                sx={{
                    borderBottomWidth: 4,
                    borderBottomColor: "ThreeDShadow"
                }}
            />
            <div className=" ml-5 mr-5 mt-8">
                <div className=" flex space-x-8">
                    <p className=" text-xl text-neutral-800 font-bold">Order Id:</p>
                    <p className=" text-xl text-blue-600 font-bold">{data.orderId}</p>
                </div>
                <div className=" mt-8">
                    <p className=" text-blue-900 mb-4 underline font-bold text-2xl">Order Details</p>
                    <Paper elevation={3} className="w-full p-4">
                        <div className="grid grid-cols-4 ">
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Customer Name</p>
                                <p className=" font-bold text-blue-900 pl-2">{`${data?.customer?.personalInfo?.first_name} ${data?.customer?.personalInfo?.last_name}`}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Contact Number</p>
                                <p className=" font-bold text-blue-900 pl-2">{`${data?.customer?.personalInfo?.country_code??""} ${data?.customer?.personalInfo?.phone_number}`}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Ordered Date</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.orderedDate?.split("T")[0]}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Delivery Date</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.deliveryDate?.split("T")[0]}</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 mt-8 ">
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Total Price</p>
                                <p className=" font-bold text-blue-900 pl-2">SGD {data.totalPrice}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Delivery Status</p>
                                <div className=" pl-2">
                                    {
                                        data?.status === orderStatus.Delivered && <Chip label={data.status} color="success" />
                                    }
                                    {
                                        data?.status === orderStatus.Canceled && <Chip label={data.status} color="error" />
                                    }
                                    {
                                        data?.status === orderStatus.Ongoing && <Chip label={data.status} color="primary" />
                                    }
                                    {
                                        data?.status === orderStatus.Ordered && <Chip label={data?.status} color="warning" />
                                    }
                                    {
                                        data?.status === orderStatus.Out_of_Delivery && <Chip label={data?.status} color="secondary" />
                                    }
                                </div>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Payment Mode</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.paymentDetails?.paymentMode}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Transaction Id</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.paymentDetails?.transactionId}</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 mt-8">
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Delivery Address</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.billingDetails?.address + data?.billingDetails?.city + ", " +
                                    data?.billingDetails?.country + ", " + data?.billingDetails?.postalCode}</p>
                            </div>
                            <div>
                                <p className=" font-bold text-neutral-700 text-xl">Order For</p>
                                <p className=" font-bold text-blue-900 pl-2">{data?.orderFor}</p>
                            </div>
                        </div>
                    </Paper>
                </div>
                {
                    data?.orderProducts.length > 0 && (
                        <div className=" mt-8 mb-8">
                            <p className="text-blue-900 mb-4 underline font-bold text-2xl">Products</p>
                            <Paper className="p-5 mb-5">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                                    {data?.orderProducts.map((item, index) => (
                                        // {
                                        item.product && (
                                            <Card key={index} sx={{ display: "flex", justifyContent: 'space-between', border: '2px solid #526D82' }} className="flex-wrap p-3 h-[fit]">
                                                {/* <CardMedia
                                                component="img"
                                                sx={{ width: 180 }}
                                                image={apiUrl + "/uploads/" + item?.product?.image}
                                                alt="Image"
                                                className="mx-auto"
                                            /> */}
                                                <Avatar src={apiUrl + "/uploads/" + item?.product?.image} alt="product" variant="square" style={{ width: '10rem', height: "10rem" }} className="mx-auto" />
                                                <CardContent >
                                                    <div className="flex space-x-4 font-bold text-neutral-500">
                                                        <p>Product Name:</p>
                                                        <p>{item?.product?.name ?? "---"}</p>  {/* Make sure to use the actual product name */}
                                                    </div>
                                                    <div className="flex space-x-4 font-bold text-neutral-500">
                                                        <p>Price:</p>
                                                        <p>SGD {item?.product?.price ?? 0}</p>
                                                    </div>
                                                    <div className="flex space-x-4 font-bold text-neutral-500">
                                                        <p>Quantity:</p>
                                                        <p>{item?.quantity}</p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                        // }

                                    ))}
                                </div>

                            </Paper>
                        </div>
                    )
                }
                {
                    data?.orderPackages.length > 0 && (
                        <div className=" mt-8 mb-8">
                            <p className="text-blue-900 mb-4 underline font-bold text-2xl">Packages</p>
                            <Paper className="p-5 mb-5">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                                    {data?.orderPackages.map((item, index) => (
                                        <Card key={index} sx={{ display: "flex", justifyContent: 'space-between', border: '2px solid #526D82' }} className="flex-wrap p-3 h-[fit]">
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 200 }}
                                                image={med}
                                                alt="Image"
                                            />
                                            <CardContent >
                                                <div className="flex space-x-4 font-bold text-neutral-500">
                                                    <p>Package Name:</p>
                                                    <p>{item?.package?.name}</p>  {/* Make sure to use the actual product name */}
                                                </div>
                                                <div className="flex space-x-4 font-bold text-neutral-500">
                                                    <p>Duration:</p>
                                                    <p>{item?.packageDetails?.duration} Months</p>
                                                </div>
                                                <div className="flex space-x-4 font-bold text-neutral-500">
                                                    <p>Price:</p>
                                                    <p>SGD {item?.packageDetails?.price}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>

                            </Paper>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default ViewOrder;