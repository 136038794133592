import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
const SpeedDail = ({ onclick }) => {

  return (
    <div
      onClick={onclick}
      className="bg-blue-800 rounded-full h-[80px] w-[80px] flex items-center justify-center shadow-2xl cursor-pointer hover:scale-105 active:scale-75 transition-transform"
    >
      <CalendarTodayIcon 
                fontSize='large'
                style={{
                    color: "white"
                }}
            />
      ,
    </div>
  );
};

export default SpeedDail;
