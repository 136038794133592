import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Avatar, Dialog, IconButton } from "@mui/material";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment/moment";
import profile from "../../assets/adminpic.png"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from "axios";
import { errorAlert, successAlert } from "../Alert";

const localizer = momentLocalizer(moment)
const MyCalender = ({ events, refreshBookings, isDoctor }) => {

    useEffect(() => {
    }, [events])

    const apiUrl = process.env.REACT_APP_API_BASE_URL
    const [open, setOpen] = useState(false);
    const [eventData, setEventData] = useState({});

    const selectedEvent = (e) => {
        setEventData(e);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    const deleteAppointment = async (id) => {
        await axios.delete(`${apiUrl}/api/delete-appointment/${id}`).then(async (res) => {
            if (res) {
                successAlert("Consultation is deleted successfully")
                setOpen(false)
                await refreshBookings()
            }
        }).catch((error) => {
            errorAlert("Unable to delete Consultation")
        })
    }

    return (
        <div>
            <Calendar
                onSelectEvent={(e) => {
                    selectedEvent(e);
                }}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, marginTop: 50, fontWeight: "bold" }}
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className=" flex justify-end">
                    <IconButton
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    {
                        !isDoctor && (
                            <IconButton
                                onClick={handleClose}
                                style={{
                                    color: "#EE4B4B"
                                }}
                            >
                                <DeleteOutlineOutlinedIcon onClick={() => deleteAppointment(eventData.id)} />
                            </IconButton>
                        )
                    }
                </div>
                <div className={`${eventData.status === "Pending" ? ' bg-red-400' : ' bg-blue-200'} w-[400px] p-5`}>
                    <div className=" flex justify-between items-center">
                        <div className=" flex items-center gap-4">
                            <Avatar src={profile} alt="profile" />
                            <p className=" font-bold">{eventData.name}</p>
                        </div>
                    </div>
                    <div className=" pt-5">
                        <p className={`${eventData.status === "Pending" ? ' text-red-600 font-bold' : 'text-blue-500 font-bold text-xl'}`}>{eventData.title}</p>
                        <p className="pt-2 text-sm font-semibold">{eventData.description}</p>
                    </div>
                    <div className="flex items-center justify-end gap-2">
                        <CheckCircleOutlineIcon
                            className={`${eventData.status === "Pending" ? 'text-red-500' : 'text-blue-500'}`}
                        />
                        <p className={`${eventData.status === "Pending" ? 'text-red-500' : 'text-blue-500 font-bold'}`}>{eventData.status}</p>
                    </div>
                    <div className=" flex"></div>
                </div>
            </Dialog>
        </div>
    )
}

export default MyCalender;