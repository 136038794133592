import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;
const config2 = {

    headers: {
        "Content-Type": "multipart/form-data"

    }
}


export const getAllProducts = createAsyncThunk(
    'products/getAllProducts',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/inventory`,

                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)


export const AddNewProduct = createAsyncThunk(
    'products/AddNewProduct',
    async (product) => {
        try {
            const { data } = await axios.post(
                `${backendUrl}/api/inventory`, product,

                config2
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)


export const EditProduct = createAsyncThunk(
    'products/EditProduct',
    async (pid) => {
        try {
            const {  data } = await axios.put(
                `${backendUrl}/api/inventory` + pid,

                config2
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)






export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (productid) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const { data } = await axios.delete(
                `${backendUrl}/api/inventory/` + productid,


                config
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)

