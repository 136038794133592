import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl=process.env.REACT_APP_API_BASE_URL

export const getAllPermissions = createAsyncThunk(
    'permissions/getAllPermissions',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/roles`,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }

        }
    }
)
export const getPermissionById = createAsyncThunk(
    'permissions/getPermissionById',
    async (id) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/roles/${id}`,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }

        }
    }
)



export const deletePermission = createAsyncThunk(
    'permissions/deletePermission',
    async (pid) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.delete(
                `${backendUrl}/api/delete-role/${pid}`,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)