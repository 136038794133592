import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllOrders = createAsyncThunk(
    'orders/getAllOrders',
    async () => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.get(
                `${backendUrl}/orders`,
               
                config
            )      
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)



export const deleteOrder = createAsyncThunk(
    'orders/deleteOrder',
    async () => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.get(
                `${backendUrl}/deleteorder`,
               
                config
            )      
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)
