import { Divider, IconButton, Paper } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const OptionCard = ({ value, nextQuestion, deleteOption, correctAnswer }) => {
    return (
        <Paper
            className="p-4 w-[950px]"
            sx={{
                background: "#DDE6ED"
            }}
        >
            <div className="flex justify-end">
                <IconButton
                    onClick={deleteOption}
                    style={{
                        color: "#EE4B4B"
                    }}
                >
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </div>
            <h1 className="mb-2 text-blue-900 font-bold text-2xl text-center">Selected Option</h1>
            <Divider
                variant="middle"
                sx={{
                    height: 4
                }}
                color="#146C94"
                className=""
            />
            <div className="flex gap-6 mt-4 items-center">
                <h1 className=" text-blue-900 font-bold text-xl">Option :</h1>
                <h1>{value}</h1>
            </div>
            <div className="flex gap-6 items-center">
                <h1 className=" text-blue-900 font-bold text-xl">Next Question :</h1>
                <h1>{nextQuestion}</h1>
            </div>
            {
                correctAnswer && (
                    <div className="flex gap-6 items-center">
                        <h1 className=" text-blue-900 font-bold text-xl">Correct Answer</h1>
                        {/* <h1>{nextQuestion}</h1> */}
                    </div>
                )
            }
        </Paper>
    )
}

export default OptionCard;