import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TopHeader from "../../Ui/topHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Paper,
  TextField,
} from "@mui/material";
import Answer from "../../Ui/answerType";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useMemo } from "react";
import OptionCard from "../../Ui/optionCard";
import ImageOptionCard from "../../Ui/imageOptionCard";
import { useSelector } from "react-redux";
import { errorAlert, successAlert } from "../../Ui/Alert";
import { questionType } from "../../utils/constant";

const newQuestionSchema = yup.object().shape({
  title: yup.string().required("Question Title Required"),
});

const NewQuetion = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("Select Input Type");
  const [options, setOptions] = useState([]);
  const [savedOption, setSavedOption] = useState([]);
  const [savedImageOption, setSavedImageOption] = useState([]);
  const [singleChoice, setSingleChoice] = useState(false);
  const [multiChoice, setMultiChoice] = useState(false);
  const [boolean, setBoolean] = useState(false);
  const [inputRequired, setInputRequired] = useState(false);
  const [inputQuestion, setInputQuestion] = useState("");
  const { state } = useLocation();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const handleSingleChoice = (event) => {
    setSingleChoice(event.target.checked);
  };

  const handleMultiChoice = (event) => {
    setMultiChoice(event.target.checked);
  };

  const handleInput = (event) => {
    setInputRequired(event.target.checked);
  };

  const handleInputQuestion = (event) => {
    setInputQuestion(event.target.value);
  };

  const questionsdata = useSelector((state) => state.questions.questionsdata);
  useEffect(() => {
  }, [questionsdata, state.category]);

  const getOptionType = () => {
    if (singleChoice) {
      return "single choice";
    } else if (multiChoice) {
      return "multiple choice";
    }
  };


  const onSubmit = async (values, actions) => {
    var questionFlow = [];
    if (type == "text") {
      questionFlow = savedOption;
    } else if (type == "image") {
      questionFlow = savedImageOption;
    }

    let option_type;
    if (singleChoice) {
      option_type = "single choice";
    } else if (multiChoice) {
      option_type = "multiple choice";
    }
    else if (inputRequired) option_type = "input"

    const formData = new FormData();
    formData.append("question_type", type);
    formData.append("content", values.title);
    formData.append("option_type", option_type);
    formData.append("selectedCategory", state.category);
    formData.append("inputQuestion", inputQuestion);
    formData.append("inputRequired", inputRequired);
    questionFlow.forEach((val, index) => {
      if (type === "image") {
        formData.append(`options[${index}][image]`, val.ans);
        formData.append(`options[${index}][file]`, val.file);
      } else {
        formData.append(`options[${index}][text]`, val.ans);
      }

      formData.append(
        `options[${index}][stopTest]`,
        val.quest.breakPoint ?? false
      );
      formData.append(
        `options[${index}][lastquestion]`,
        val.quest.last ?? false
      );
      formData.append(
        `options[${index}][isAnswer]`,
        val.quest.isAnswer ?? false
      );
      if (val.quest.question.id) {
        formData.append(
          `options[${index}][nextQuestion]`,
          val.quest.question.id || null
        );
      }
    });
    try {
      const response = await fetch(`${apiUrl}/api/quiz/questions`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        navigate("/cm/questionnaire");
        successAlert("Question Added successfully!!");
      }
      else {
        errorAlert("Question not added!!");
      }
    } catch (error) {
      console.error("Error creating question:", error.message);
    }
  };

  const addOptions = (type) => {
    if (options.length === 0) {
      setOptions([...options, type]);
      setType(type);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: newQuestionSchema,
    onSubmit,
  });


  const menuItems = useMemo(
    () =>
      state?.questions?.map((question) => {
        return {
          option: question.content,
          identity: {
            id: question._id,
            value: question.content,
          },
        };
      }),
    [questionsdata]
  );

  const handleOption = async (values) => {
    savedOption.push(values);
    setSavedOption([...savedOption]);
  };

  const handleImageOption = async (values) => {
    const { ans, quest } = values;
    const nextQuestion = quest.question ?? null;
    savedImageOption.push({
      ans,
      quest: {
        question: nextQuestion,
        breakPoint: quest.breakPoint ?? false,
        last: quest.last ?? false,
        isAnswer: quest.isAnswer ?? false,
      },
    });
    setSavedImageOption([...savedImageOption]);

  };

  const handleDelete = (value) => {
    const remOption = savedOption.filter((option) => option.ans !== value);
    setSavedOption(remOption);
  };

  const handleImageDelete = (value) => {
    const remOption = savedImageOption.filter((option) => option.ans !== value);
    setSavedImageOption(remOption);
  };

  return (
    <div>
      <TopHeader />
      <div className="mt-6 pl-5 mb-8 w-50">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="flex flex-col mb-4 mt-4 w-75">
            <label
              className="text-blue-800 text-3xl font-black mb-2"
              htmlFor="title"
            >
              Question Title
            </label>
            <input
              id="title"
              type="text"
              placeholder="Enter Question Title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`${errors.title && touched.title
                ? " border border-red-600 outline-red-600"
                : "border border-gray-400  outline-blue-500"
                } font-bold text-blue-900 p-3 rounded-md border mr-5 `}
            />
            {errors.title && touched.title && (
              <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">
                {errors.title}
              </p>
            )}
          </div>

          <Paper className=" mt-4 mb-4 pl-4 pt-4 pb-2 mr-5">
            <h1 className=" text-3xl text-blue-800 font-bold">
              Select Question Preferences
            </h1>
            <div className=" flex gap-4 mt-4 mb-4">
              <div className=" flex gap-x-2 items-center">
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                  checked={singleChoice}
                  onChange={handleSingleChoice}
                  disabled={boolean || multiChoice || inputRequired}
                />
                <p className=" text-blue-800 font-bold">Single Option</p>
              </div>
              <div className=" flex gap-x-2 items-center">
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                  checked={multiChoice}
                  onChange={handleMultiChoice}
                  disabled={boolean || singleChoice || inputRequired}
                />
                <p className=" text-blue-800 font-bold">Multiple Option</p>
              </div>
              <div className=" flex gap-x-2 items-center">
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                  checked={inputRequired}
                  onChange={handleInput}
                  disabled={boolean || singleChoice || multiChoice}
                />
                <p className=" text-blue-800 font-bold">Input</p>
              </div>
            </div>
          </Paper>

          {singleChoice || multiChoice ? (
            <Paper className="mr-5">
              <div className=" flex items-center p-4 gap-8">
                <p className=" text-blue-800 font-bold text-2xl">Set Options</p>
                <div className=" flex text-blue-800">
                  <IconButton
                    sx={{ color: "#19398B" }}
                    onClick={() => addOptions("text")}
                    disabled={type === "image"}
                  >
                    <QuestionAnswerIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    sx={{ color: "#19398B" }}
                    onClick={() => addOptions("image")}
                    disabled={type === "text"}
                  >
                    <AddAPhotoIcon fontSize="large" />
                  </IconButton>
                </div>
              </div>
              <div>
                {savedOption &&
                  savedOption.map((option) => {
                    return (
                      <div className=" pl-4 mb-4 pr-4">
                        <OptionCard
                          key={option.quest.id}
                          value={option.ans}
                          nextQuestion={option.quest.question.value}
                          deleteOption={() => handleDelete(option.ans)}
                          correctAnswer={option.quest.isAnswer ?? false}
                        />
                      </div>
                    );
                  })}
                {savedImageOption &&
                  savedImageOption.map((option) => {
                    return (
                      <div className=" pl-4 mb-4 pr-4">
                        <ImageOptionCard
                          key={option.quest.id}
                          value={option.ans}
                          nextQuestion={option.quest.question}
                          deleteOption={() => handleImageDelete(option.ans)}
                          correctAnswer={option.quest.isAnswer ?? false}
                        />
                      </div>
                    );
                  })}
              </div>
              {options &&
                options.map((option, i) => {
                  return (
                    <Answer
                      key={i}
                      type={option}
                      menuItems={menuItems}
                      handleAdd={handleOption}
                      handleImageAdd={handleImageOption}
                      optionType={getOptionType()}
                      options={type === "text" ? savedOption : savedImageOption}
                    />
                  );
                })}
            </Paper>
          ) : null}
          {
            inputRequired && (
              <Paper className="mr-5">
                <div className="items-center p-4 gap-8">
                  <p className="text-blue-800 font-bold text-2xl mb-4">Set Answer</p>
                  <div className="flex items-center gap-4 mt-4 mb-2">
                      <Select
                        id="inputQuestion"
                        label="Enter Input Type"
                        variant="standard"
                        onChange={(event) => setType(event.target.value)}
                        className="mt-3"
                        fullWidth
                        sx={{width:'30%'}}
                        value={type}
                      >
                        <MenuItem value="Select Input Type" disabled selected>Select Input Type</MenuItem>
                        {
                          Object.entries(questionType).map(([key, value], index) => {
                            return (
                              <MenuItem key={index} value={value}>{value?.toUpperCase()}</MenuItem>
                            )
                          })
                        }

                      </Select>
                    <TextField
                      id="inputQuestion"
                      label="Set Answer"
                      variant="standard"
                      value={inputQuestion}
                      onChange={handleInputQuestion}
                      className="w-3/4"
                      multiline
                    />
                  </div>

                </div>
              </Paper>
            )
          }

          <div className="ml-5 flex gap-4 mt-4">
            <Button
              variant="contained"
              size="large"
              disabled={isSubmitting}
              type="submit"
              sx={{
                "&.MuiButton-root": {
                  backgroundColor: "#E9B84A",
                  color: "black",
                  fontWeight: "bold",
                },
              }}
            >
              Save And Continue
            </Button>
            <Button
              variant="outlined"
              size="large"
              disabled={isSubmitting}
              sx={{
                "&.MuiButton-root": {
                  fontWeight: "bold",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewQuetion;
