import React, { useEffect, useState } from 'react'
import TopHeader from '../../Ui/topHeader'
import Table from '../../Ui/table'
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { errorAlert, successAlert } from '../../Ui/Alert';

const backendUrl = process.env.REACT_APP_API_BASE_URL;

const NewsLetterSubscription = () => {

    const [newsLetterData, setNewsLetterData] = useState([]);
    const [deleteId, setDeleteId] = useState();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getAllNewsLetterData()
    }, [])

    const getAllNewsLetterData = async () => {
        await axios.get(`${backendUrl}/api/subscribe`).then((res) => {
            if (res && res.data) {
                setNewsLetterData(res.data);
            }

        }).catch((error) => {
            console.log(error);
        })
    }

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setShowModal(true);
    }

    const handleDelete = async () => {
        await axios.delete(`${backendUrl}/api/subscribe/delete/${deleteId}`).then((res) => {
            successAlert("Deleted Successfully!!!");
            getAllNewsLetterData();
            setShowModal(false)

        }).catch((error) => {
            console.log(error);
            errorAlert("Something went wrong!!");
        })
    }
    const columns = [
        {
            name: "Sr.No",
            selector: (row, index) => index + 1,
            center: true
        },
        {
            name: "Email",
            selector: row => row?.email,
            center: true
        },
        {
            name: "Subscribed Date",
            selector: row => row?.createdAt?.split("T")[0],
            center: true
        },
        {
            name: "Action",
            cell: row => (
                <div className="flex items-center" >
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row?._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
            center: true
        },
    ]

    return (
        <div>
            <TopHeader />
            <div>
                <p className=" text-4xl font-bold  pt-4 pl-4 pb-5 text-blue-800">NewsLetter Subscription</p>
            </div>
            <div>
                <Table align="center"
                    columns={columns}
                    data={newsLetterData}
                    placeholder="Search NewsLetter"
                />
                <Dialog
                    open={showModal}
                    onClose={() => setShowModal(false)}
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to delete this subscription?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button onClick={() => setShowModal(false)} variant="outlined">No</Button>
                        <Button
                            onClick={() => handleDelete()}
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                            }}
                        >Yes</Button>

                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default NewsLetterSubscription
