import { useFormik } from "formik";
import * as yup from "yup";
import {
    Button,
    Link
} from '@mui/material';
import IntroPage from "../../Ui/introPage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../store/actions/authaction";

const forgetSchema = yup.object().shape({
    email: yup
        .string()
        .email("Enter valid email")
        .required("Username Required"),
});


const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onSubmit = async (values, actions) => {
        await dispatch(forgetPassword({ ...values, url: `${window.location.origin}/verification` }));
        await new Promise((resolve) => setTimeout(resolve, 1000));
        actions.resetForm();
        navigate('/message')
    }


    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: forgetSchema,
        onSubmit,
    })

    return (
        <div className=" flex">
            <div className=" w-[40%] flex items-center">
                <div className=" pl-20">
                    <h1 className=" text-blue-900 font-bold text-5xl font-roboto mb-6">Forgot Password</h1>
                    <p className=" text-gray-300 font-roboto text-xs">Let Us Help You</p>
                    <div className="mt-8">
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className=" flex flex-col mb-8">
                                <label className=" text-blue-900 font-semibold mb-2" htmlFor="email">Enter Your Registered Email Address</label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Enter your email address"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={`${errors.email && touched.email ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 w-96 p-3 rounded-md border `}
                                />
                                {errors.email && touched.email && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.email}</p>}
                            </div>
                            <div className=" flex items-center justify-between">
                                <Button
                                    variant="contained"
                                    size="large"
                                    disabled={isSubmitting}
                                    type="submit"
                                    sx={{
                                        "&.MuiButton-root": {
                                            backgroundColor: "#E9B84A",
                                        },
                                        marginLeft: "20px"
                                    }}
                                >Reset Password</Button>
                                <Link href="/" underline="hover" fontWeight="bold">Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <IntroPage />
        </div>
    )
}

export default ForgotPassword;