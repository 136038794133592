import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import {
    IconButton,
} from "@mui/material";
import TopHeader from "../../Ui/topHeader";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import axios from "axios";
import { errorAlert } from "../../Ui/Alert";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { AddBoxOutlined } from "@mui/icons-material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddPrescriptionFilterTable from "../../Ui/CustomTable/AddPrescriptionFilterTable";
import { roleTag } from "../../utils/constant";

const apiUrl = process.env.REACT_APP_API_BASE_URL

const Prescription = () => {
    const navigate = useNavigate();
    const [bookingData, setBookingData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const roleId = localStorage.getItem('userType')
    const userId = localStorage.getItem('userId')
    
    useEffect(() => {
        checkUserIsDoctorOrNot()
    }, [roleId]);

    const handleEdit = (data) => {
        const name = `${data.user?.personalInfo?.first_name} ${data.user?.personalInfo?.last_name}`;
        const email = data?.user?.email;
        const contact = data?.user?.personalInfo?.phone_number;
        const country_code = data?.user?.personalInfo?.country_code;
        const treatment = data.treatment;
        const consultation = data.consultation;
        const history = data.history;
        const results = data.results;
        const status = data.status;
        const notes = data.notes
        const category = data.selectedCategory?.name
        const meetingLink = data.meetingLink
        const profile = data?.user?.imageUrl
        const prescriptionId = data?.prescription?._id
        const survey = data?.survey

        navigate('/consult/viewbooking', {
            state: {
                name,
                email,
                contact,
                country_code,
                treatment,
                consultation,
                history,
                results,
                status,
                notes,
                category,
                meetingLink,
                profile,
                prescriptionId,
                survey
            }
        })
    }
    const addPrescription = (data) => {
        navigate('/consult/add-prescription', {
            state: data
        })
    }
    const handlePrescriptionEdit = async(data) => {
        navigate('/consult/edit-prescription', {
            state: data
        })
    }
    const formatDateAndTime = (date, time) => {
        const dateTimeString = `${date} ${time}`;
        const parsedDate = moment(dateTimeString, 'YYYY-MM-DD HH:mm');

        if (parsedDate.isValid()) {
            return parsedDate.format('hh:mm A');
        } else {
            return "Invalid Date";
        }
    };
    const checkMeetingLink = (meetingLink) => {
        return (
            meetingLink !== "---" ? (
                <a
                    href={meetingLink}
                    target="_blank"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    rel="noreferrer"
                >
                    {meetingLink}
                </a>
            ) : (
                'null'
            )
        )
    }
    const columns = [
        {
            name: 'Client Name',
            selector: row => `${row?.user?.personalInfo?.first_name} ${row?.user?.personalInfo?.last_name}`,
            width: "195px",
            wrap: "true",
            center: "true"
        },
        {
            name: "Meeting Link",
            selector: row => checkMeetingLink(row.meetingLink),
            wrap: "true",
            width: "300px",
            center: "true"
        },

        {
            name: "Consultation Date",
            selector: (row) => row.date,
            width: "180px",
            center: "true"
        },
        {
            name: "Category",
            selector: (row) => row?.selectedCategory?.name,
            width: "180px",
            center: "true"
        },
        {
            name: "Start Time",
            selector: (row) => formatDateAndTime(row?.date, row?.startTime),
            width: "180px",
            center: "true"
        },
        {
            name: "Status",
            selector: row => row?.eStatus,
            wrap: "true",
            center: "true"
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex items-center justify-center" style={{ marginLeft: "0em" }}>
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <RemoveRedEyeOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => handlePrescriptionEdit(row)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => addPrescription(row)}
                    >
                        <AddBoxOutlined />
                    </IconButton>
                </div>
            ),
            style: { textAlign: "center" }, 
        },
    ]
    const data = bookingData.map((item, index) => {
        return {
            ...item,
            srNo: index + 1,
            clientId: `${item.user?.email ?? "--"}`,
            meetingLink: item.meetingLink ?? "---",
        }
    })

    const getAllBookings = async () => {
        await axios.get(`${apiUrl}/api/confirmed-appointments`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setBookingData(res.data.reverse())
            }
        }).catch((error) => {
            errorAlert("Unable to fetch data!!");
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const getAllBookingsByDoctor = async () => {
        await axios.get(`${apiUrl}/api/confirmed-appointments?doctor=${userId}`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setBookingData(res.data.reverse())
            }
        }).catch((error) => {
            errorAlert("Unable to fetch data!!");
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const checkUserIsDoctorOrNot = async () => {
        if (roleId) {
            await axios.get(`${apiUrl}/api/roles/${roleId}`).then((res) => {
                if (res.data && res.data.tag && res.data.tag === roleTag.Doctor) {
                    getAllBookingsByDoctor()
                }
                else getAllBookings()
            }).catch((error) => {
                console.log(error.message)
            })
        }
    }

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-2 pl-5 pb-4 items-center">
                    <div className=" ml-5 mt-5">
                        <div className=' flex flex-col justify-items-start'>
                            <h1 className=" text-4xl text-blue-800 font-bold">Consultation</h1>
                            <p className=" text-sm text-neutral-600 font-bold ml-1">View all your consultation and add medication.</p>
                        </div>
                    </div>
                </div>
                <div className=" pl-5 pr-5">
                    {
                        isLoading && (
                            <LoadingBackdrop isloading={isLoading} />
                        )
                    }
                    {/* <Table align="center"
                        columns={columns}
                        data={data}
                        placeholder="Search Consultation"
                    /> */}
                    <AddPrescriptionFilterTable
                        columns={columns}
                        data={data}
                        placeholder="Search Consultation"
                    />
                </div>
            </div>

        </div>
    );
}

export default Prescription;