import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import { useDispatch } from "react-redux";
import { deleteclient } from "../../store/actions/clientaction";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import axios from "axios";
import { errorAlert, successAlert } from "../../Ui/Alert";
import exportFromJSON from "export-from-json";
import { roleTag } from "../../utils/constant";
const apiUrl = process.env.REACT_APP_API_BASE_URL


function AllClients() {

    const [open, setOpen] = useState(false);
    const [openSuspend, setSuspend] = useState(false);
    const [suspendReason, setSuspendReason] = useState("");
    const [deactivateUserId, setDeactivateUserId] = useState("");
    const [patientData, setPatientData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isDoctor, setIsDoctor] = useState(false)
    const [deleteUserId, setUserId] = useState();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const roleId = localStorage.getItem('userType')
    const userId = localStorage.getItem('userId')

    useEffect(() => {
        checkUserIsDoctorOrNot()
    }, []);

    const handleClickOpen = (id) => {
        setUserId(id);
        setOpen(true);
    }

    const handleClickClose = () => {
        setOpen(false);
    }

    const handleDeleteUser = async () => {
        await dispatch(deleteclient(deleteUserId));
        setOpen(false);
        successAlert("Client deleted successfully!!");
        getUsers()

    }

    const getUsers = async () => {
        await axios.get(`${apiUrl}/api/order/get-client-orders?isActive=${true}`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setPatientData(res.data)
            }
        }).catch((error) => {
            errorAlert(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const getClientsByDoctor = async () => {
        await axios.get(`${apiUrl}/api/order/get-client-orders/${userId}`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setPatientData(res.data)
            }
        }).catch((error) => {
            errorAlert(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const checkUserIsDoctorOrNot = async () => {
        if (roleId) {
            await axios.get(`${apiUrl}/api/roles/${roleId}`).then((res) => {
                if (res.data && res.data.tag && res.data.tag === roleTag.Doctor) {
                    getClientsByDoctor()
                    setIsDoctor(true)
                }
                else getUsers()
            }).catch((error) => {
                console.log(error.message)
            })
        }
    }

    const suspendClient = async (clientId) => {
        setSuspend(true)
        setDeactivateUserId(clientId)
    }

    const handleEdit = (data) => {
        navigate('/crm/viewclient', { state: {...data,isDoctor} })
    }

    const columns = [
        {
            name: 'Photo',
            selector: row => (<Avatar src={apiUrl + "/uploads/" + row?.user?.imageUrl} alt="profile" />),
            width: "100px",
            center: true
        },
        {
            name: 'Client Name',
            selector: row => `${row?.user?.personalInfo?.first_name} ${row?.user?.personalInfo?.last_name}`,
            width: "200px",
            center: true,
            wrap: true
        },

        {
            name: 'Contact No',
            selector: row => (row?.user?.personalInfo?.country_code??"")+row?.user?.personalInfo?.phone_number,
            center: true
        },
        {
            name: <div>Number of<br />Consultation</div>,
            selector: row => row?.consultation?.length,
            width: "120px",
            center: true
        },
        {
            name: <div>Total Purchase<br />Product</div>,
            selector: row => row?.totalPurchaseProduct,
            center: true
        },
        {
            name: <div>Total Purchase<br />Package</div>,
            selector: row => row?.totalOrderPackages,
            center: true
        },
        {
            name: "Action",
            cell: (row) => (
                <div className=" flex items-center">
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <VisibilityOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={
                            () => suspendClient(row?.user?._id)
                        }
                        style={{
                            color: "#EE4B4B",
                        }}
                    >
                        <PersonOffOutlinedIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row?.user?._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
        },
    ]

    const suspendSubmit = async () => {
        await axios.patch(`${apiUrl}/api/users/deactivate/${deactivateUserId}`, { reasonForInActive: suspendReason }).then(async (res) => {
            if (res && res.data && res.data.success == true) {
                successAlert("User is suspended successfully")
                handleCloseSuspend()
                await getUsers();
            }
        }).catch((error) => {
            errorAlert(error.message)
        })

    }
    const handleCloseSuspend = () => {
        setSuspendReason("");
        setDeactivateUserId("");
        setSuspend(false);
    }
    const exportData = () => {
        const fileName = 'All users'
        const exportType = exportFromJSON.types.csv
        const serializedData = patientData.map((item, index) => ({
            sr: index + 1,
            Name: `${item?.user?.personalInfo?.first_name ?? "--"} ${item?.user?.personalInfo?.last_name ?? "--"}`,
            Email: item?.user?.email ?? '---',
            Contact: `=${item?.user?.personalInfo?.phone_number ? `TEXT("${item?.user?.personalInfo?.phone_number}", "0")` : '""'}`,
            Gender: `${item?.user?.personalInfo?.sex ?? "---"}`,
            DOB: `=TEXT("${item?.user?.personalInfo?.dob?.split('T')[0]}", "yyyy-mm-dd")`,
            Address: `${item?.user?.deliveryAddress ? item?.user?.deliveryAddress[0]?.address : "---"}`,
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }
    return (
        <div>
            <TopHeader />
            <div>
                <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                    <p className=" text-2xl font-bold pt-4 pl-5 pb-4 text-blue-950">All Clients</p>
                    <div className=" flex gap-x-4">
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                marginLeft: "20px"
                            }}
                            onClick={exportData}
                        > EXPORT ALL </Button>
                    </div>
                </div>
                <div className=" pl-5 pr-5">
                    {isLoading && <LoadingBackdrop isloading={isLoading}></LoadingBackdrop>}
                    <Table
                        columns={columns}
                        data={patientData}
                        placeholder="Search Clients"
                    />
                    <Dialog
                        open={open}
                        onClose={() => handleClickClose()}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the Client?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>

                            <Button onClick={() => handleClickClose()} variant="outlined">No</Button>
                            <Button
                                onClick={() => handleDeleteUser()}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>

                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openSuspend}
                        onClose={handleCloseSuspend}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <div className=" flex flex-col mb-8 p-3">
                            <label className=" text-blue-800 font-semibold mb-2" htmlFor="suspendReason">Enter the reason for suspension</label>
                            <TextField
                                id="suspendReason"
                                type="text"
                                multiline
                                placeholder="Enter User Suspension Reason"
                                value={suspendReason}
                                required
                                onChange={(event) => setSuspendReason(event.target.value)}
                                className={`border border-gray-400  outline-blue-500 text-blue-900 w-96 p-2 rounded-md `}
                            />
                        </div>
                        <div className=" flex justify-center gap-4 pb-5">
                            <Button onClick={handleCloseSuspend} variant="outlined">No</Button>
                            <Button
                                onClick={suspendSubmit}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default AllClients;