import { createSlice } from "@reduxjs/toolkit"
import { getAllCards } from "../actions/medicalCardaction";


const initialState = {
    loading:false,
    cardsdata:[],
    error:null,
    success:false,
}
 
const medicalCardsSlice = createSlice({
    name:'cards',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllCards.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllCards.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.cardsdata = payload;
        state.success = true;
     },
     [getAllCards.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default  medicalCardsSlice.reducer;


