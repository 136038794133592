import TopHeader from "../../Ui/topHeader";
import Card from "./components/card";
import Dollar from "./Assets/Dollar.png";
import Book from "./Assets/Book.png";
import Headset from "./Assets/Headset.png";
import People from "./Assets/people-outline.png";
import Chart from "./components/chart";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { roleTag } from "../../utils/constant";

const cardData = [
    {
        color: "#4EA2EF",
        title: "350K",
        subtitle: "Total Revenue OTC Medicine",
        sKey: "totalOTCRevenue",
        isVisibleForDoctor:false,
        icon: Dollar
    },
    {
        color: "#19398B",
        title: "1.5K",
        subtitle: "Total Bookings",
        sKey: "totalBookings",
        icon: Book
    },
    {
        color: "#E9B84A",
        title: "1K",
        subtitle: "Total Revenue From Consultation ",
        sKey: "totalRevenueFromOther",
        icon: Headset
    },
    {
        color: "#FFA843",
        title: "2K",
        subtitle: "New Clients",
        sKey: "newClient",
        icon: People
    }
]


const backendUrl = process.env.REACT_APP_API_BASE_URL;
function Dashboard() {
    const [dashboardData, setDashboardData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [graphData, setGraphData] = useState([])
    const [isDoctor, setIsDoctor] = useState(false)
    const roleId = localStorage.getItem('userType')
    const userId = localStorage.getItem('userId')
    useEffect(() => {
        checkUserIsDoctorOrNot()
    }, [roleId])

    const getDashboardData = async () => {
        await axios.get(`${backendUrl}/api/dashboard/get-count`).then((res) => {
            if (res.data) {
                if (res.data && Object.keys(res.data).length > 0) {
                    let data = []
                    Object.entries(res.data).map(([key, value]) => {
                        cardData.forEach((item) => {
                            if (item.sKey == key) {
                                data.push({
                                    ...item,
                                    title: value,

                                })
                            }
                        })
                    })
                    setDashboardData(data)
                }
            }
        }).catch((error) => {
            console.log(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const getDashboardDataByDoctor = async () => {
        await axios.get(`${backendUrl}/api/dashboard/get-count/${userId}`).then((res) => {
            if (res.data) {
                if (res.data && Object.keys(res.data).length > 0) {
                    let data = []
                    Object.entries(res.data).map(([key, value]) => {
                        cardData.forEach((item) => {
                            if (item.sKey == key) {
                                data.push({
                                    ...item,
                                    title: value,

                                })
                            }
                        })
                    })
                    setDashboardData(data)
                }
            }
        }).catch((error) => {
            console.log(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getGraphData = async () => {
        await axios.get(`${backendUrl}/api/dashboard/get-graph`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setGraphData(res.data)
            }
        }).catch((error) => {
            console.log(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const getGraphDataForDoctor = async () => {
        await axios.get(`${backendUrl}/api/dashboard/get-graph/${userId}`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setGraphData(res.data)
            }
        }).catch((error) => {
            console.log(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const checkUserIsDoctorOrNot = async () => {
        if (roleId) {
            await axios.get(`${backendUrl}/api/roles/${roleId}`).then((res) => {
                if (res.data && res.data.tag && res.data.tag === roleTag.Doctor) {
                    setIsDoctor(true)
                    getGraphDataForDoctor()
                    getDashboardDataByDoctor()
                }
                else {
                    getGraphData()
                    getDashboardData()
                }
            }).catch((error) => {
                console.log(error.message)
            })
        }
    }
    return (
        <div >
            <TopHeader />
            <div className="w-full" >
                {
                    isLoading && (
                        <LoadingBackdrop isloading={isLoading} />
                    )
                }
                <div className="flex flex-wrap justify-start ml-2 mt-2">
                    {
                        dashboardData.map((card, index) => {
                            return (
                                <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-1">
                                    <Card
                                        color={card?.color}
                                        title={card?.title}
                                        subtitle={card?.subtitle}
                                        icon={card?.icon}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="flex p-2 flex-wrap justify-center">
                    {
                        graphData.map((chart, index) => {
                            return (
                                <div key={index} className="w-full sm:w-1/1 md:w-1/1 lg:w-1/2 p-1">
                                    <Chart
                                        key={chart?.heading}
                                        heading={chart?.heading}
                                        fields={chart?.fields}
                                        labels={chart?.labels}
                                        pieData={chart?.pieData}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Dashboard;