import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Avatar
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../../Ui/topHeader";
import edit from "../Assets/edit.png";
import Table from "../../../Ui/table";
import LoadingBackdrop from "../../../Ui/LoadinSpinner";
import { errorAlert, successAlert } from "../../../Ui/Alert";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL

const WhatWeTreat = () => {

    const [contentData, setContentData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [contentId, setContentId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getContents()
    }, [])



    const getContents = async () => {
        await axios.get(`${apiUrl}/api/template1/categoryCard`).then((res) => {
            if (res && res.data && Array.isArray(res.data?.categoryCards)) setContentData(res.data?.categoryCards?.reverse())
        }).catch((error) => {
            errorAlert(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }


    const handleClose = () => {
        setOpen(!open)
    }

    const handleOpen = (id) => {
        setContentId(id);
        setOpen(true);
    }

    const handleDelete = async () => {
        await axios.delete(`${apiUrl}/api/template1/categoryCard/${contentId}`).then(async(res) => {
            if (res && res.data && res.data?.success) {
                setOpen(false)
                await getContents();
                successAlert("Deleted Successfully!!");
            }
        }).catch((error) => {
            errorAlert(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
        
    }

    const handleEdit = (data) => {
        navigate('/cm/edit-what-we-treat', { state: data })
    }


    const columns = [
        {
            name: "Title",
            selector: row => row?.title ?? "---",
            center: 'true',
            wrap: 'true'
        },
        {
            name: "Cover Image",
            selector: row => (<Avatar src={apiUrl+"/uploads/"+row?.bgCoverImage} alt="Cover Image" variant="square" style={{ height: '60px', width: '60px' ,marginTop:'0.25rem',marginBottom:'0.25rem'}} className="mx-auto"/>),
            center: 'true',
            wrap: 'true'
        },
        {
            name: "Category",
            selector: row => row?.category?.name ?? "---",
            center: 'true',
            wrap: 'true'
        },
        {
            name: "Subtitle",
            selector: row => row?.subTitle ?? "---",
            center: 'true',
            wrap: 'true'
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="">
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <img src={edit} alt="edit" />
                    </IconButton>
                    <IconButton
                        style={{ color: "#EE4B4B" }}
                        onClick={() => handleOpen(row?._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            )
        }
    ]

    return (
        <div>
            <TopHeader />
            <div>
                <div className=" flex justify-between items-center pt-4 pl-5 pb-4">
                    <div className=" flex flex-col justify-start">
                        <p className=" text-3xl font-bold text-blue-950">What We Treat</p>
                        <p className=" text-neutral-500 font-semibold">Customize the What We Treat Section here.</p>
                    </div>
                    <Button
                        onClick={() => navigate("/cm/new-what-we-treat")}
                        variant="contained"
                        size="large"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            }
                        }}
                    >Create New Content</Button>
                </div>
                <div className=" pl-5 pr-5">
                    {isLoading && <LoadingBackdrop isloading={isLoading}></LoadingBackdrop>}
                    <Table
                        columns={columns}
                        data={contentData}
                        placeholder="Search Content"
                    />
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the Content?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleClose} variant="outlined">No</Button>
                            <Button
                                onClick={() => handleDelete()}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default WhatWeTreat;