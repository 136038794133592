import { Fragment, useRef ,useEffect} from "react";
import TopHeader from "../../Ui/topHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Checkbox,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditCard from "../../Ui/editCard";
import { errorAlert, successAlert } from "../../Ui/Alert";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import blankProfile from "../../assets/blank-profile.jpg";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  width: 600,
  p: 4,
};

function EditQuestion() {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [questionsData, setQuestionsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [optionsId, setOptionId] = useState("");
  const [breakpoint, setBreakpoint] = useState(false);
  const [last, setLast] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionValue, setOptionValue] = useState("");
  const [nextQuestion, setNextQuestion] = useState("");
  const [optionImage, setOptionImage] = useState("");

  const [menu, setMenu] = useState("");
  const [edited, setEdited] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const fileRef = useRef();
  const pickImageHandler = () => {
    fileRef.current.click();
  };
  const [img, setImg] = useState("");
  const handleOpen = () => setOpen(true);
  const handleOption = (event) => {
    setOptionValue(event.target.value);
  };

  useEffect(() => {
    setData(state?.question);
    getQuestions()
  }, [state]);


  const getQuestions = async () => {
    await axios.get(`${apiUrl}/api/quiz/questions/Category/${state?.category}`).then((res) => {
      if (res && res.data && Array.isArray(res.data)) {
        setQuestionsData(res.data)
      }
    }).catch((error) => {
      errorAlert("Unable to fetch question")
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleValues = async (value) => {
    const { lastQuestion, stopTest, text, _id, isAnswer, image, nextQuestion } = value;
    setOptionValue(text);
    setOptionImage(image)
    setNextQuestion(nextQuestion)
    setOptionId(_id);
    setMenu(nextQuestion)
    setBreakpoint(stopTest);
    setLast(lastQuestion);
    setIsAnswer(isAnswer);
    setOptions([...options, _id]);
    handleOpen();
  };

  const handleMenuValue = (event) => {
    setMenu(event.target.value);
  };

  const handleClose = () => {
    setOptionValue("");
    setMenu("");
    setOptionId("");
    setOptionImage("")
    setBreakpoint(false);
    setLast(false);
    setIsAnswer(false);
    setOpen(false);
  };

  const submitUpdate = async () => {
    let nextQuestionId = menu;
    const updated = {
      _id: optionsId,
      text: optionValue,
      image: optionImage,
      nextQuestion: nextQuestionId,
      stopTest: breakpoint,
      lastQuestion: last,
      isAnswer: isAnswer,
    };
    if (!updated._id || updated._id == "") delete updated._id
    if (!updated.nextQuestion || updated.nextQuestion == ""||updated.nextQuestion==="No Next Question") delete updated.nextQuestion
    await axios.put(`${apiUrl}/api/quiz/questions/option/${state.id}`, updated).then((res) => {
      if (res.data) {
        setData(res.data)
        handleClose();
      }
    }).catch((error) => {
      console.log(error)
    })
    setEdited([...edited, updated]);
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImg(reader.result);
        setOptionImage(reader.result);
      };
    }
  };

  const removeOption = async (optionId) => {
    if (data.options.length > 2) {
      await axios.put(`${apiUrl}/api/quiz/questions/remove-option/${state.id}`, { optionId: optionId }).then((res) => {
        if (res.data) {
          setData(res.data)
          successAlert("Option is deleted")
        }
      }).catch((error) => {
        console.log(error)
      })
    }
    else {
      errorAlert("Question has at least two options")
    }
  };

  const getQuestion = (id) => {
    let question = questionsData.find((question) => question._id == id);
    if (!question) {
      return "No Next Question"
    }
    return question?.content;
  }

  return (
    <Fragment>
      <TopHeader />
      <main className=" ml-5 mt-5 mb-8 p-5 max-w-6xl">
        <h1 className=" text-blue-800 font-bold text-4xl">Edit Question</h1>
        <Paper className=" p-5 mt-4">
          <h1 className=" text-blue-800 mb-4 font-blod text-4xl">
            {data.content ?? "---"}
          </h1>
          <h1 className=" text-blue-800 font-extrabold mb-4 text-2xl">
            Options
          </h1>
          <div className="mt-4">
            {data.options?.map((option, i) => {
              return (
                <EditCard
                  key={i}
                  id={option._id}
                  option={option.text}
                  image={option.image ?? ""}
                  nextQuestion={getQuestion(option.nextQuestion)}
                  handleOpen={() => handleValues(option)}
                  isCorrectAnswer={option.isAnswer}
                  isBreakPoint={option.stopTest}
                  deleteOption={() => removeOption(option._id)}
                />
              );
            })}
          </div>
          <div className=" ml-5 mt-4 flex gap-4">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >
              Add Options
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </Paper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper sx={style}>
            <div className=" absolute top-0 right-0">
              <IconButton onClick={handleClose} color="error">
                <CloseIcon />
              </IconButton>
            </div>
            <div className="mt-4">
              {
                data.question_type == "image" ? (
                  <div className="">
                    <input
                      id="photo"
                      name="photo"
                      type="file"
                      accept=".jpg, .png, .jprg"
                      ref={fileRef}
                      hidden
                      onChange={handleImageChange}
                    />
                    <div className="flex justify-center items-center gap-16">
                      <div>
                        {optionImage ? (
                          <Avatar
                            src={optionImage}
                            alt="preview"
                            sx={{ height: 150, width: 150 }}
                          />
                        ) : (
                          <Avatar
                            src={blankProfile}
                            alt="Blank Profile"
                            sx={{ height: 150, width: 150 }}
                          />
                        )}
                      </div>
                      <IconButton onClick={pickImageHandler}>
                        <AddAPhotoIcon sx={{ fontSize: "50px" }} color="primary" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className=" mb-4">
                      <TextField
                        id="optionValue"
                        value={optionValue}
                        onChange={handleOption}
                        variant="outlined"
                        label="Set Option"
                        fullWidth
                      />
                    </div>
                  </>
                )
              }

              <div className="mb-4">
                <InputLabel id="demo-simple-select-standard-label">
                  Next Question
                </InputLabel>
                <Select
                  value={menu}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Next Question"
                  onChange={handleMenuValue}
                  fullWidth
                  variant="filled"
                >
                  <MenuItem value="No Next Question">
                    No Next Question
                  </MenuItem>
                  {questionsData.map((item, index) => (
                    item._id !== state.id && (
                      <MenuItem value={item._id} key={index}>
                        {item?.content}
                      </MenuItem>
                    )
                  ))}
                </Select>

              </div>
              <div className="mb-4 flex items-center flex-space-between">
                <Checkbox
                  checked={isAnswer}
                  onChange={(e) => setIsAnswer(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                />
                <p className=" text-blue-800 font-bold">Set As Answers</p>
                <Checkbox
                  checked={breakpoint}
                  onChange={(e) => setBreakpoint(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                />
                <p className=" text-blue-800 font-bold">Breakpoint</p>
                <Checkbox
                  checked={last}
                  onChange={(e) => setLast(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                />
                <p className=" text-blue-800 font-bold">Last Question</p>
              </div>

              <div className=" ml-5 mt-6">
                <Button variant="contained" onClick={submitUpdate}>
                  Update
                </Button>
              </div>
            </div>
          </Paper>
        </Modal>
      </main>
    </Fragment>
  );
}

export default EditQuestion;
