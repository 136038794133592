import { createSlice } from "@reduxjs/toolkit"
import { AddNewBlog, deleteBlog, getAllBogs, updateBlog } from "../actions/blogaction";





const initialState = {
    loading:false,
    blogdata:[],
    error:null,
    success:false,


}
 

const BlogSlice = createSlice({
    name:'blogs',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllBogs.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllBogs.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.blogdata = payload;
        state.success = true;
     },
     [getAllBogs.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [deleteBlog.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [deleteBlog.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.blogdata = payload;
        state.success = true;
     },
     [deleteBlog.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddNewBlog.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [AddNewBlog.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.blogdata = payload;
        state.success = true;
     },
     [AddNewBlog.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [updateBlog.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [updateBlog.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.blogdata = payload;
        state.success = true;
     },
     [updateBlog.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default BlogSlice.reducer;

