import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";




const apiUrl = process.env.REACT_APP_API_BASE_URL


export const getQuestionByid = createAsyncThunk(
    'questions/questionbyid',
    async (id) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }
            const {data } = await axios.get(
                `${apiUrl}/api/quiz/questions/` + id,

                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)


export const getQuestionByCategory = createAsyncThunk(
    'questions/questionbyCategory',
    async (category) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }


            const { data } = await axios.get(
                `${apiUrl}/api/quiz/questions/Category/${category}`,

                config
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)




export const AddNewQuestion = createAsyncThunk(
    'questions/AddNewQuestion',
    async (Question) => {
        try {
            const config = {
                headers: { 'Content-Type': "multipart/form-data" }
            }


            const { data } = await axios.post(
                `${apiUrl}/api/quiz/questions`, Question,

                config
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)


export const DeleteQuestion = createAsyncThunk(
    'questions/DeleteQuestion',
    async (id) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const {  data } = await axios.delete(
                `${apiUrl}/api/quiz/questions/` + id,

                config
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)



export const UpdateQuestion = createAsyncThunk(
    'questions/UpdateQuestion',
    async (Question) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }
            const {  data } = await axios.put(
                `${apiUrl}/api/quiz/questions/` + Question.id, Question.updatedquestion,
                config
            )

            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)
