import { useFormik } from "formik";
import * as yup from "yup";
import {
    Button,
    Link
} from '@mui/material';
import IntroPage from "../../Ui/introPage";
import code from "../../assets/cuate.png";
import { useDispatch } from "react-redux";
import { ResetPassword } from "../../store/actions/authaction";
import { errorAlert, successAlert } from "../../Ui/Alert";
import { useNavigate, useParams } from "react-router-dom";

const verifySchema = yup.object().shape({
    username: yup
        .string()
        .required("username Required"),
    newPassword: yup
        .string()
        .required("New Password Required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Password don't match.")
        .required("Confirm Password Required"),
});



const Verfication = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const params=useParams()
    const onSubmit = async (values, actions) => {
        const password = values.newPassword;
        const token=params?.token||""
        const response = await dispatch(ResetPassword({password,token}));
        if (response.type === 'auth/ResetPassword/fulfilled') {
            successAlert(response.payload.message);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            actions.resetForm();
            navigate('/')
        }
        else {
            errorAlert("Unable to Reset Password")
        }
    }
    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            username: "",
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: verifySchema,
        onSubmit,
    })


    return (
        <div className=" flex">
            <div className=" w-[40%] flex items-center">
                <div className=" pl-20">
                    <div className=" flex flex-col items-center justify-center">
                        <img
                            src={code}
                            alt="code"
                            width={150}
                            height={100}
                        />
                        <h1 className=" text-blue-900 font-bold text-4xl font-roboto mb-4 mt-2">Reset Password</h1>
                        <p className=" text-gray-500 font-roboto text-sm">Thanks for keeping your account secure</p>
                    </div>
                    <div className="mt-8">
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className=" flex flex-col mb-4">
                                <label className=" text-blue-900 font-semibold mb-2" htmlFor="username">Enter your Email</label>
                                <input
                                    id="username"
                                    type="text"
                                    placeholder="Enter Email"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={`${errors.username && touched.username ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 w-96 p-3 rounded-md border `}
                                />
                                {errors.username && touched.username && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.username}</p>}
                            </div>
                            <div className=" flex flex-col mb-4">
                                <label className=" text-blue-900 font-semibold mb-2" htmlFor="newPassword">Enter New Password</label>
                                <input
                                    id="newPassword"
                                    type="password"
                                    placeholder="New Password"
                                    disabled={!values.username}
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={`${errors.newPassword && touched.newPassword ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 w-96 p-3 rounded-md border `}
                                />
                                {errors.newPassword && touched.newPassword && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.newPassword}</p>}
                            </div>
                            <div className=" flex flex-col mb-4">
                                <label className=" text-blue-900 font-semibold mb-2" htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    id="confirmPassword"
                                    type="password"
                                    disabled={!values.username}
                                    placeholder="Confirm Password"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={`${errors.confirmPassword && touched.confirmPassword ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 w-96 p-3 rounded-md border `}
                                />
                                {errors.confirmPassword && touched.confirmPassword && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.confirmPassword}</p>}
                            </div>
                            <div className=" flex flex-col items-center gap-y-4">
                                <Button
                                    variant="contained"
                                    size="large"
                                    disabled={isSubmitting}
                                    type="submit"
                                    sx={{
                                        "&.MuiButton-root": {
                                            backgroundColor: "#E9B84A",
                                        },
                                        marginLeft: "2rem"
                                    }}
                                >Reset Password</Button>
                                <Link href="/" underline="hover" fontWeight="bold">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <IntroPage />
        </div>
    )
}

export default Verfication;