import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'productName', headerName: 'Product name', width: 130 },
  { field: 'date', headerName: 'Date', width: 130 },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    width: 90,
  },
  {
    field: "paymentMode",
    headerName: "Patment Mode",
    width: 130
  },
];

const rows = [
  { id: 1, productname: 'Medcine-1', date: '25-04-2020', price: 35, paymentMode: "Credit card" },
  { id: 2, productname: 'Medcine-2', date: '25-04-2020', price: 35, paymentMode: "Credit card" },
  { id: 3, productname: 'Medcine-3', date: '25-04-2020', price: 35, paymentMode: "Credit card" },
  { id: 4, productname: 'Medcine-4', date: '25-04-2020', price: 35, paymentMode: "Credit card" }
];

export default function DataTable() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}