import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    IconButton 
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../../Ui/topHeader";
import edit from "../Assets/edit.png";
import Table from "../../../Ui/table";
import { useDispatch, useSelector } from "react-redux";
import { deleteFaq, getAllFaqs } from "../../../store/actions/faqaction";
import LoadingBackdrop from "../../../Ui/LoadinSpinner";
import { successAlert } from "../../../Ui/Alert";

const AllFaq = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [faqdata,setfaqdata] = useState([]);
    const [open, setOpen ] = useState(false);
    const [faqId, setFaqId] = useState('');
    const dataFetchedRef = useRef(false);

    const handleClose = () => {
        setOpen(!open)
    }

    const handleOpen = (id) => {
        setFaqId(id);
        setOpen(true);
    } 


    const loading = useSelector(
        (state) => state.faqs.loading
    )

const getFaqs = useCallback(async () => {
    let fetchedata = await dispatch(getAllFaqs());
    const data = fetchedata.payload.map(faq => {
        return {
            faqId:faq._id,
            faqQuestion: faq.question,
            faqAnswer: faq.answer
        }
    })
    setfaqdata(data)
}, [dispatch])

  const handleDelete = async() => {
    await dispatch(deleteFaq(faqId))
    await dispatch(getAllFaqs());
    setOpen(!open)
    await getFaqs()
    successAlert("Deleted Successfully!!");
  } 

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getFaqs()
     
   },[dispatch, getFaqs])

   const handleEdit = (data) => {
    navigate('/cm/updateFaq', { state: data })
   }
  

    const columns = [
        {
            name: "Faq ID",
            selector: row => row.faqId,
            center:'true'
        }, 
        {
            name: "Faq Question",
            selector: row => row.faqQuestion,
            center:'true'
        },
        {
            name: "Faq Answer",
            selector: row => row.faqAnswer?.substr(0, 80),
            wrap: true,
            width:'400px',
            center:'true'
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="ml-2">
                    <IconButton
                  style={{
                    color: "#2C3E50",
                  }}
                  onClick={() => handleEdit(row)}
                >
                  <img src={edit} alt="edit" />
                </IconButton>
                <IconButton
                    style={{ color: "#EE4B4B" }}
                    onClick={() => handleOpen(row.faqId)}
                >
                    <DeleteOutlinedIcon />
                </IconButton>
                </div>
            ),
            center:'true'
        }
    ]

    return (
        <div>
            <TopHeader />

            <div>
                <div className=" flex justify-between items-center pt-4 pl-5 pb-4">
                    <p className=" text-2xl font-bold pt-4 pl-5 text-blue-950">All Faqs</p>
                    
                    <Button 
                        onClick={() => navigate("/cm/newFaq")}
                        variant="contained"
                        size="large"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            }
                        }}
                    >Create New Faq</Button>
                </div>
                <div className=" pl-5 pr-5">
                { loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}
                    <Table
                        columns={columns} 
                        data={faqdata}
                        placeholder="Search Faqs"
                    />
                    <Dialog
                       open={open}
                       onClose={handleClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            Do you want to delete the Faq?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleClose} variant="outlined">No</Button>
                            <Button 
                                onClick={() => handleDelete()}
                                sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default AllFaq;