import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;
const config = {
    headers: {
     'Content-Type': 'application/json'

    },
}

export const getCurrentUser = async() => {
   
    const {data} = await axios.get(
        `${backendUrl}/api/users/getAllUser`,
    
        config
    )

    return data;

}