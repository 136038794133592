import { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Paper,
    Select,
    TextField,
    TextareaAutosize,
} from "@mui/material";
import TopHeader from "../../Ui/topHeader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../Ui/Alert";
import { roleTag } from "../../utils/constant";

const appointmentSchema = yup.object().shape({
    clientId: yup.string().email("Enter valid email", { excludeEmptyString: true })
        .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address"),
    date: yup.date().required(" Date of appointment required "),
    startTime: yup.string().required("Start time required"),
    endTime: yup.string()
        .required("End time required")
        .test('is-greater', 'End time must be greater than Start time', function (value) {
            const { startTime } = this.parent;
            return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
        }),
})


const SetAppointment = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL
    const [doctorData, setDoctorData] = useState([])
    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isRegisteredEmail, setIsRegisteredEmail] = useState(true)
   
    const navigate = useNavigate()
    useEffect(() => {
        getAllDoctors()
        getCategories()
    }, [])


    const handleMeet = async (values) => {
        // let meetLink = `${apiUrl}/auth/google?date=${dateTime}&time=${startTime}&clientId=${clientId}&user=${user}&end=${endTime}&notes=${notes}&selectedCategory=${selectedCategory}`
        let meetLink = `${apiUrl}/api/auth/google?date=${values.date}&time=${values.startTime}&clientId=${values.clientId}&user=${values.user}&end=${values.endTime}&notes=${values.notes}&selectedCategory=${values.selectedCategory}&doctor=${values.doctor}`
        window.location.href = meetLink
        // window.open(meetLink, '_blank');
    }

    const onSubmit = async (values, action) => {
        if (!isRegisteredEmail) {
            errorAlert("Email is not registered!");
            return;
        }
        await handleMeet(values)
    }

    const getAllDoctors = async () => {
        await axios.get(`${apiUrl}/api/users/getUserByUserType?tag=${roleTag.Doctor}`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setDoctorData(res.data)
            }
        }).catch((error) => {
            errorAlert("Unable to fetch data!!");
        })
    }
    const checkRegisteredEmail = async (email, values) => {
        await axios.get(`${apiUrl}/api/users/filter?email=${email}`).then((res) => {
            if (res && res.data && Array.isArray(res.data) && res.data.length === 0) {
                setIsRegisteredEmail(false)
            }
            else {
                values.user = res.data[0]._id
                setIsRegisteredEmail(true);
            }
        }).catch((error) => {
            console.log(error)
            errorAlert("Unable to fetch data!!");
        })
    }

    const getCategories = async () => {
        await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
            if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                setCategory(res.data)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            <TopHeader />
            <div className=" ml-5 mt-5">
                <div className=' flex flex-col justify-items-start'>
                    <h1 className=" text-4xl text-blue-800 font-bold">Set Appointments</h1>
                    <p className=" text-sm text-neutral-600 font-bold ml-1">Set your appointments for consultation.</p>
                </div>
                <div className=" mt-6">
                    <Paper className=" p-6 mb-12 w-full">
                        <Formik
                            initialValues={{
                                clientId: "",
                                date: "",
                                startTime: "",
                                endTime: "",
                                meetingLink: "",
                                selectedCategory: "",
                                doctor: "",
                                notes: "",
                                user: ""
                            }}
                            validationSchema={appointmentSchema}
                            onSubmit={onSubmit}
                        >
                            {
                                (formik) => (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Box sx={{ width: "100%" }}>
                                            <div className=" grid grid-cols-2 space-x-12 mb-8">
                                                <FormControl>
                                                    <TextField
                                                        id="clientName"
                                                        name="clientId"
                                                        label="Client Email"
                                                        variant="filled"
                                                        value={formik.values.clientId}
                                                        onChange={formik.handleChange}
                                                        onBlur={(e) => {
                                                            formik.handleBlur(e);
                                                            checkRegisteredEmail(e.target.value, formik.values);
                                                        }}
                                                        error={Boolean(formik.errors.clientId && formik.touched.clientId && !isRegisteredEmail)}
                                                        helperText={
                                                            formik.errors.clientId &&
                                                            formik.touched.clientId &&
                                                            String(formik.errors.clientId) && !isRegisteredEmail
                                                        }
                                                        required={true}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Select
                                                        id="selectType1"
                                                        name="selectedCategory"
                                                        label="Choose Disease"
                                                        variant="filled"
                                                        value={formik.values.selectedCategory}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        displayEmpty
                                                        error={Boolean(formik.errors.selectedCategory && formik.touched.selectedCategory)}
                                                        required
                                                    >
                                                        <MenuItem value="" disabled selected>Choose Category</MenuItem>
                                                        {
                                                            category.map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item._id} key={index}>{item?.name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>

                                            </div>
                                            <div className="grid grid-cols-2 space-x-12 mb-8">
                                                <FormControl>
                                                    <Select
                                                        id="selectType"
                                                        name="doctor"
                                                        label="Choose Doctor"
                                                        variant="filled"
                                                        value={formik.values.doctor}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        displayEmpty
                                                        error={Boolean(formik.errors.doctor && formik.touched.doctor)}
                                                        required
                                                    >
                                                        <MenuItem value="" disabled selected>Choose Doctor</MenuItem>
                                                        {
                                                            doctorData.map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item._id} key={index}>{item.personalInfo.first_name} {item.personalInfo.last_name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <TextField
                                                        id="date"
                                                        name="date"
                                                        label="Date"
                                                        variant="filled"
                                                        type="date"
                                                        value={formik.values.date}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        InputLabelProps={{ shrink: true, required: true }}
                                                        error={Boolean(formik.errors.date && formik.touched.date)}
                                                        helperText={
                                                            formik.errors.date &&
                                                            formik.touched.date &&
                                                            String(formik.errors.date)
                                                        }
                                                        inputProps={{
                                                            min: moment().add(1, 'days').format('YYYY-MM-DD') // Set the minimum date to tomorrow
                                                        }}
                                                        required
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="grid grid-cols-2 space-x-12 mb-8">
                                                <FormControl>
                                                    <TextField
                                                        id="startTime"
                                                        name="startTime"
                                                        label="Start Time"
                                                        variant="filled"
                                                        type="time"
                                                        value={formik.values.startTime}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        InputLabelProps={{ shrink: true, required: true }}
                                                        error={Boolean(formik.errors.startTime && formik.touched.startTime)}
                                                        helperText={
                                                            formik.errors.startTime &&
                                                            formik.touched.startTime &&
                                                            String(formik.errors.startTime)
                                                        }
                                                        required
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <TextField
                                                        id="endTime"
                                                        name="endTime"
                                                        label="End Time"
                                                        variant="filled"
                                                        type="time"
                                                        value={formik.values.endTime}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        InputLabelProps={{ shrink: true, required: true }}
                                                        error={Boolean(formik.errors.endTime && formik.touched.endTime)}
                                                        helperText={
                                                            formik.errors.endTime &&
                                                            formik.touched.endTime &&
                                                            String(formik.errors.endTime)
                                                        }
                                                        required
                                                    />
                                                </FormControl>

                                            </div>
                                            <div className="grid grid-cols-1 space-x-12 mb-8">
                                                <FormControl fullWidth>
                                                    <TextareaAutosize
                                                        id="textArea"
                                                        name="notes"
                                                        aria-label="minimum height"
                                                        rowsMin={5}
                                                        placeholder="Type Note here..."
                                                        value={formik.values.notes}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        style={{ width: "100%", resize: "vertical", minHeight: '5rem', backgroundColor: 'rgb(243,243,243)', borderBottomWidth: '0.1rem', outline: 'none', borderBottomColor: '#9b9595' }}
                                                        required
                                                        variant="filled"
                                                    />
                                                </FormControl>
                                            </div>

                                            {/* <div className="mb-8 pl-4">
                                                <Button
                                                    startIcon={<VideoChatOutlinedIcon />}
                                                    onClick={() => handleMeet(formik.values.date, formik.values.startTime, formik.values.clientName)}
                                                >
                                                    Set meet
                                                </Button>
                                            </div> */}
                                            <div className="flex space-x-5 items-center justify-center">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Submit
                                                </Button>
                                                <Button
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </form>
                                )
                            }

                        </Formik>
                    </Paper>
                </div>
            </div>
        </>
    )
}

export default SetAppointment;