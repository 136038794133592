import { useLocation } from "react-router-dom";
import TopHeader from "../../Ui/topHeader";
import ClientField from "../../Ui/clientField";
import CartCard from "../../Ui/cartCard";
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { Avatar } from "antd";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ViewBookings = () => {

    const [productsData, setProductData] = useState([]);
    const [questionnaireData, setQuestionnaireData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();

    useEffect(() => {
        if (state.prescriptionId) getPrescriptionData();
        if (state.survey) getSurvey()
    }, [state])

    const getPrescriptionData = async () => {
        setIsLoading(true)
        await axios
            .get(`${apiUrl}/api/prescription/prescription/${state.prescriptionId}`)
            .then((res) => {
                const product = [];
                if (res.data) {
                    res?.data?.products.forEach((item, index) => {
                        product.push({
                            name: item?.tProduct?.name,
                            description: item?.tProduct?.description,
                            image: item?.tProduct?.image,
                            price: item?.tProduct?.price
                        })
                    });
                    res?.data?.packages.forEach((item, index) => {
                        product.push({
                            name: item?.tPackage?.name,
                            description: item?.tPackage?.description,
                            image: item?.tPackage?.image,
                            price: item?.price
                        })
                    });
                }

                setProductData(product);
            })
            .catch((error) => {
                console.log("Error message");
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const getSurvey = async () => {
        setIsLoading(true)
        await axios
            .get(`${apiUrl}/api/survey/getById/${state.survey}`)
            .then((res) => {
                if (res.data) {
                    setQuestionnaireData(res.data)
                }
            }).catch((error) => {
                console.log(error.message)
            }).finally(() => {
                setIsLoading(false)
            })
    }
    return (
        <div>
            <TopHeader />
            {
                isLoading && (
                    <LoadingBackdrop isloading={isLoading} />
                )
            }
            <p className=" text-3xl mt-4 pl-5 font-bold text-blue-800">Booking Details</p>
            <div className=" flex pl-5 mt-8">
                <div className=" grid grid-cols-1 justify-items-center bg-white p-8 shadow-md">
                    <div className=" pb-4">
                        <img
                            src={state.profile}
                            alt="person"
                            height={100}
                            width={100}
                            className=" shadow-black shadow-sm"
                        />
                    </div>
                    <p className=" font-black text-xl text-blue-800 ">{state.name}</p>
                    <p className=" font-thin text-sm text-blue-800">{state.category} Problem</p>
                </div>
                <div>
                    <div className="grid grid-cols-3 gap-6 pt-2 pl-8">
                        <ClientField
                            coloured={true}
                            label="Name"
                            value={state.name}
                        />
                        <ClientField
                            coloured={true}
                            label="Phone No."
                            value={`${state?.country_code??""} ${state?.contact}`}
                        />
                        <ClientField
                            coloured={true}
                            label="Email Address"
                            value={state.email}
                        />
                      
                    </div>
                    <div className="grid grid-cols-2 gap-6 pt-2 pl-8">
                    <ClientField
                            style={{ paddingLeft: '3rem' }}
                            coloured={true}
                            label="Category"
                            value={state.category}
                        />
                        <ClientField
                            coloured={true}
                            label="Meet with Us"
                            value={state.meetingLink !== "---" ? state.meetingLink : 'null'}
                        />
                    </div>
                    {
                        state?.doctorNotes && (
                            <div className="pl-8 ">
                            <ClientField
                                coloured={true}
                                label="Doctor Notes"
                                value={state?.doctorNotes}
                            />
                        </div>
                        )
                    }
                   
                </div>
            </div>
            {
                questionnaireData?.userResponse?.length > 0 && (
                    <Paper className="m-[1.3rem] pb-7">
                        <p className=" text-2xl mt-4 pt-3 pl-4 pb-5 font-bold text-blue-800 ">Questionnaire Details</p>
                        <div className="gap-x-3 p-4">
                            {
                                questionnaireData?.userResponse?.map((item, index) => {
                                    return (
                                        <Accordion className="mb-3" key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                style={{ fontWeight: 'bold', color: "black" }}

                                            >
                                                Q. {item?.tQuestion?.content}
                                            </AccordionSummary>
                                            <div style={{ display: "flex", direction: "column" }}>
                                                <AccordionDetails style={{ flex: '60%' }}>
                                                    {
                                                        item?.tQuestion?.question_type === "image" ? (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>Ans. </span>


                                                                {
                                                                    item?.selectedOptions?.map((option, index) => {
                                                                        return (
                                                                            // <Avatar
                                                                            //     src={option?.image}
                                                                            //     variant="square"
                                                                            //     alt="ans"
                                                                            //     style={{minWidth:'5rem',minHeight:'5rem',border:'0.1rem solid black'}}
                                                                            //     sx={{
                                                                            //         width: "250px",
                                                                            //         height: "250px"
                                                                            //     }}
                                                                            // />
                                                                            <Avatar
                                                                                src={option?.image}
                                                                                alt="ans"
                                                                                className="shadow-md rounded-xl"
                                                                                sx={{
                                                                                    width: 320,
                                                                                    height: 320,
                                                                                }}
                                                                                variant="rounded"
                                                                                style={{
                                                                                    minWidth: '5.5rem',
                                                                                    minHeight: '5.5rem',
                                                                                }}
                                                                                key={index}
                                                                            />
                                                                        )
                                                                    })
                                                                }

                                                            </>
                                                        ) :
                                                            (
                                                                item?.tQuestion?.option_type === "input" ? (
                                                                    <>
                                                                        <span style={{ fontWeight: 'bold' }}>Ans. </span>
                                                                        {item.sAnswer}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span style={{ fontWeight: 'bold' }}>Ans. </span>
                                                                        {
                                                                            item?.selectedOptions?.map((option, index) => {
                                                                                return (
                                                                                    <p key={index}>{option?.text}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            )
                                                    }
                                                </AccordionDetails>
                                            </div>

                                        </Accordion>
                                    )
                                })
                            }


                        </div>
                    </Paper>
                )
            }

            <div className="pt-[1rem] mb-3">
                <p className="text-2xl mt-4 pl-5 pb-5 font-bold text-blue-800">{productsData.length > 0 ? "Prescribed Medication" : ""}</p>
                <div className="flex flex-wrap gap-x-2  pl-5 pr-5">
                    {
                        productsData?.map((item, index) => {
                            return (
                                <Paper square={false} key={index} className="mb-4" >
                                    <CartCard
                                        key={index}
                                        name={item?.name}
                                        description={item?.description}
                                        img={apiUrl + "/uploads/" + item?.image}
                                        price={item?.price}
                                    />
                                </Paper>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewBookings;