import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Checkbox,
  IconButton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import blankProfile from "../assets/blank-profile.jpg";

const Answer = ({
  type,
  menuItems,
  handleAdd,
  handleImageAdd,
  optionType,
  options,
}) => {
  const fileRef = useRef();
  const pickImageHandler = () => {
    fileRef.current.click();
  };
  const [img, setImg] = useState("");
  const [imgValue, setImgValue] = useState("");
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const [lastQuestion, setLastQuestion] = useState(false);
  const [breakpoint, setBreakpoint] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [disabledSetAnswer, setDisabledSetAnswer] = useState(false);
  const handleAnswer = (event) => {
    setAnswer(event.target.value);
  };

  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const handleLastQuestion = (event) => {
    setLastQuestion(event.target.checked);
  };

  const handleBreakpoint = (event) => {
    setBreakpoint(event.target.checked);
  };
  const handleCorrectAnswer = (event) => {
    const isChecked = event.target.checked;
    setIsAnswer(isChecked);
  };

  useEffect(() => {
    setAnswerForSingleOption();
  }, [options]);

  const setAnswerForSingleOption = async () => {
    if (optionType === "single choice") {
      const hasCorrectAnswer = options.some(
        (item) => item.quest && item.quest.isAnswer
      );
      setDisabledSetAnswer(hasCorrectAnswer);
    }
  };

  const submitOption = () => {
    var ans = answer;
    var last = lastQuestion;
    var breakPoint = breakpoint;
    var quest = { question, breakPoint, isAnswer, last };
    setAnswer("");
    setQuestion("");
    setLastQuestion(false);
    setBreakpoint(false);
    setIsAnswer(false);
    handleAdd({ ans, quest });
  };

  const submitImageOption = () => {
    var ans = imgValue;
    var quest = { question, breakpoint, isAnswer };
    setImg(null);
    setImgValue("");
    setAnswer("");
    setQuestion("");
    setLastQuestion(false);
    setBreakpoint(false);
    setIsAnswer(false);
    handleImageAdd({ ans, quest });
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImg(reader.result);
        setImgValue(reader.result);
      };
    }
  };

  return (
    <div className=" pb-8">
      <div className="pl-4 flex items-center justify-between">
        {type === "text" && (
          <div className=" flex flex-col mb-4">
            <label className=" text-blue-800 font-bold mb-2" htmlFor="answer">
              Answer Option
            </label>
            <input
              id="answer"
              type="text"
              placeholder="Enter Option"
              value={answer}
              onChange={handleAnswer}
              className={`font-bold text-blue-900 p-3 rounded-md border mr-5 w-[950px] border-gray-400 outline-blue-600`}
            />
          </div>
        )}
        {type === "image" && (
          <div className="">
            <input
              id="photo"
              name="photo"
              type="file"
              accept=".jpg, .png, .jprg"
              ref={fileRef}
              hidden
              onChange={handleImageChange}
            />
            <div className=" flex justify-center items-center gap-16">
              <div>
                {img ? (
                  <Avatar
                    src={img}
                    alt="preview"
                    sx={{ height: 150, width: 150 }}
                  />
                ) : (
                  <Avatar
                    src={blankProfile}
                    alt="Blank Profile"
                    sx={{ height: 150, width: 150 }}
                  />
                )}
                {/* {errors.photo&& touched.photo && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.photo}</p>} */}
              </div>
              <IconButton onClick={pickImageHandler}>
                <AddAPhotoIcon sx={{ fontSize: "50px" }} color="primary" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
      <div className=" flex mb-2">
        <div className=" flex pl-4 items-center">
          <Checkbox
            checked={lastQuestion}
            onChange={handleLastQuestion}
            inputProps={{ "aria-label": "controlled" }}
            color="secondary"
          />
          <p className=" text-blue-800 font-bold">Last Question</p>
        </div>
        <div className=" flex pl-4 items-center">
          <Checkbox
            checked={breakpoint}
            onChange={handleBreakpoint}
            inputProps={{ "aria-label": "controlled" }}
            color="secondary"
          />
          <p className=" text-blue-800 font-bold">Breakpoint</p>
        </div>
        <div className=" flex pl-4 items-center">
          {optionType !== "single choice" && (
            <>
              <Checkbox
                checked={isAnswer}
                onChange={handleCorrectAnswer}
                inputProps={{ "aria-label": "controlled" }}
                color="secondary"
                disabled={disabledSetAnswer}
              />
              <p className=" text-blue-800 font-bold">Correct Answer</p>
            </>
          )}
          {optionType === "single choice" && !disabledSetAnswer && (
            <>
              <Checkbox
                checked={isAnswer}
                onChange={handleCorrectAnswer}
                inputProps={{ "aria-label": "controlled" }}
                color="secondary"
              />
              <p className=" text-blue-800 font-bold">Correct Answer</p>
            </>
          )}
        </div>
      </div>
      {!breakpoint && !lastQuestion && (
        <div className=" flex pl-4 items-center justify-between">
          <FormControl className="w-[950px]">
            <InputLabel id="demo-simple-select-label">Next Question</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={question}
              label="Next Question"
              onChange={handleQuestion}
              fullWidth
            >
                {menuItems.map((item) => {
                  return (
                      <MenuItem value={item.identity} key={item.identity.id}  style={{maxWidth:'60rem',textWrap:'balance'}}>
                        {item.option}
                      </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="mt-4 pl-10">
        <Button
          onClick={type == "text" ? submitOption : submitImageOption}
          variant="contained"
        >
          Add Option
        </Button>
      </div>
    </div>
  );

};

export default Answer;
