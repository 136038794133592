import { Button, IconButton, Paper } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
const EditCard = ({ option, nextQuestion, handleOpen,deleteOption, isCorrectAnswer, image, isStopSurvey, isBreakPoint }) => {

    return (
        <Paper
            className=" mb-4 p-4"
            sx={{
                '&.MuiPaper-root': {
                    backgroundColor: "#99DBF5"
                }
            }}
        >
            <div className="flex justify-end">
                <IconButton
                    onClick={deleteOption}
                    style={{
                        color: "#EE4B4B"
                    }}
                >
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </div>
            <div>
                <div className=" flex gap-x-4">
                    <p className=" text-blue-800 font-blod">Option</p>
                    {
                        option && (
                            <p className="text-blue-800 font-blod">{option}</p>
                        )
                    }
                    {
                        image && (
                            <img src={image} width="120px" height="120px" className="mt-2 mb-2" style={{ borderRadius: '1rem' }} alt="img" />
                        )
                    }
                </div>
                <div className=" flex gap-x-4">
                    <p className=" text-blue-800 font-blod">Next Questions: </p>
                    <p className=" text-blue-800 font-blod">{nextQuestion}</p>
                </div>
                {
                    isCorrectAnswer && (
                        <div className=" flex gap-x-4">
                            <p className=" text-blue-800 font-blod">Correct Answer </p>
                        </div>
                    )
                }
                {
                    isBreakPoint && (
                        <div className=" flex gap-x-4">
                            <p className=" text-blue-800 font-blod">Stop Test</p>
                        </div>
                    )
                }
            </div>
            <div className="pl-4 pt-2">
                <Button variant="contained" onClick={handleOpen}>Edit</Button>
            </div>
        </Paper>
    )
}

export default EditCard;