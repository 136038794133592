import * as React from 'react';
import { useState, useRef, useEffect, Fragment } from "react";
import * as yup from "yup";
import { FieldArray, getIn, Formik } from "formik";

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Step,
  Stepper,
  StepLabel,
  TextField,
  Typography,
  Paper,
  IconButton
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ImageUpload } from "../../../../Ui/imageUpload";
import { errorAlert, successAlert } from "../../../../Ui/Alert";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';


const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required."),
  category: yup.string().required("Category is required."),
  subTitle: yup.string().required("Subtitle is required."),
  color: yup.string().required("Background Color is required."),
  bgCoverImage: yup.mixed().nullable(),
  points: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Key Point is Required"),
    })
  ),
  contents: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is Required"),
      description: yup.string().required("Description is Required"),
      image: yup.string().required("Image is Required"),
      color: yup.string().required("Background Color is required."),
    })
  ),
  tagline: yup.string().required("Tagline is required."),
  heading: yup.string().required("Heading is required."),
  expectCards: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is Required"),
      description: yup.string().required("Description is Required"),
      image: yup.string().required("Image is Required"),
    })
  ),
  workingDescription: yup.string().required("Description is required."),
  workingTitle: yup.string().required("Title is required."),
  workingProcedure: yup.array().of(
    yup.object().shape({
      procedureName: yup.string().required("Title is Required"),
      procedures: yup.string().required("Description is Required"),
      image: yup.string().required("Image is Required"),
    })
  ),
})

const steps = ['Basic Details', 'Content Section', 'Expect Section', 'Working Procedure'];
const apiUrl = process.env.REACT_APP_API_BASE_URL


export default function EditStepper() {
  const { state: data } = useLocation()
  const [category, setCategory] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [activeStep, setActiveStep] = React.useState(0);
  const [coverImg, setCoverImg] = useState({ url: `${apiUrl}/uploads/${data?.bgCoverImage}` });
  const [selectedImages, setSelectedImages] = useState([]);
  const [procedureImages, setProcedureImages] = useState([]);
  const [contentImages, setContentImages] = useState([]);
  const fileInputRef = useRef("");
  const navigate = useNavigate()
  const inputRefs = React.useRef([]);
  const contentRefs = React.useRef([])

  useEffect(() => {
    getCategories()
    data?.contents.map((item) => {
      contentImages.push({ url: `${apiUrl}/uploads/${item.image}`, name: item.image })
    })
    data?.expectSection?.expectCards.map((item) => {
      selectedImages.push({ url: `${apiUrl}/uploads/${item.image}`, name: item.image })
    })
    data?.workingProcedure?.workingProcedures.map((item) => {
      procedureImages.push({ url: `${apiUrl}/uploads/${item.image}`, name: item.image })
    })
  }, [data])

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result.split(',')[1]); // Extracting the base64 data from the result
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (values, actions) => {
    const treatData = new FormData();
    treatData.append('title', values.title);
    treatData.append('subTitle', values.subTitle);
    treatData.append('bgCoverImage', coverImg?.file);
    treatData.append('category', values.category);
    treatData.append('color', values.color);

    // Append points as a string, not an array
    values?.points.forEach((item, index) => {
      treatData.append(`points[${index}]`, item.name);
    })

    // Append contents data
    values?.contents.forEach((item, index) => {
      treatData.append(`contents[${index}][title]`, item.title);
      treatData.append(`contents[${index}][description]`, item.description);
      treatData.append(`contents[${index}][color]`, item.color);
      // treatData.append(`contents[${index}][image]`, contentImages[index]?.url);
    });
    contentImages.forEach((item, index) => {
      if (item.file) {
        treatData.append(`contentsImage`, item.file);
      }
      else treatData.append(`contentsImage`, item.name)
    });

    // Append expectCards data
    treatData.append('expectSection[heading]', values?.heading);
    treatData.append('expectSection[tagline]', values?.tagline);

    // Append expectCards data within expectSection
    values?.expectCards.forEach((item, index) => {
      treatData.append(`expectSection[expectCards][${index}][title]`, item.title);
      treatData.append(`expectSection[expectCards][${index}][description]`, item.description);
    });
    selectedImages.forEach((item, index) => {
      if (item.file) {
        treatData.append(`expectImages`, item.file);
      }
      else treatData.append(`expectImages`, item.name);
    });

    // Append workingProcedure data
    treatData.append('workingProcedure[workingTitle]', values?.workingTitle);
    treatData.append('workingProcedure[workingDescription]', values?.workingDescription);

    // Append workingProcedures data within workingProcedure
    values?.workingProcedure.forEach((item, index) => {
      treatData.append(`workingProcedure[workingProcedures][${index}][procedureName]`, item?.procedureName);
      treatData.append(`workingProcedure[workingProcedures][${index}][procedures]`, item?.procedures);
    });
    procedureImages.forEach((item, index) => {
      if (item.file) {
        treatData.append(`workingProcedureImages`, item.file);
      }
      else treatData.append(`workingProcedureImages`, item.name);
    });
    await axios.put(`${apiUrl}/api/template1/categoryCard/${data?._id}`, treatData).then((res) => {
      if (res && res.data && Object.keys(res.data).length > 0) {
        successAlert("Content Edited Successfully")
        navigate('/cm/what-we-treat')
      }
    }).catch((error) => {
      errorAlert(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleClick = (element) => {
    inputRefs.current[element].click();
  };

  const selectFile = (element) => {
    contentRefs.current[element].click();
  }

  const onFileSelectForContent = (event, index) => {
    const files = event.target.files;
    if (files[0]) {
      const newContentImages = [...contentImages];
      newContentImages[index] = {
        name: files[0].name,
        url: URL.createObjectURL(files[0]),
        file: files[0]
      };
      setContentImages(newContentImages);
    }
  }

  const onFileSelectForProcedure = (event, index) => {
    const files = event.target.files;
    if (files[0]) {
      const newProcedureImages = [...procedureImages];
      newProcedureImages[index] = {
        name: files[0].name,
        url: URL.createObjectURL(files[0]),
        file: files[0]
      };
      setProcedureImages(newProcedureImages);
    }
  }

  const onFileSelect = (event) => {
    const files = event.target.files;
    if (files[0]) {
      setCoverImg(
        {
          name: files[0].name,
          url: URL.createObjectURL(files[0]),
          file: files[0]
        }
      )
    }
  }

  const onFileSelectForExpect = (event, index) => {
    const files = event.target.files;
    if (files[0]) {
      const newSelectedImages = [...selectedImages];
      newSelectedImages[index] = {
        name: files[0].name,
        url: URL.createObjectURL(files[0]),
        file: files[0]
      };
      setSelectedImages(newSelectedImages);
    }
  }


  const getCategories = async () => {
    await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
      if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
        setCategory(res.data)
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Paper className='p-6 mb-12 w-full'>
      <Formik
        initialValues={{
          title: data?.title,
          category: data?.category?._id,
          bgCoverImage: data?.bgCoverImage,
          subTitle: data?.subTitle,
          color: data?.color,
          points: data?.points.map((item) => ({ name: item })),
          contents: data?.contents.map((item) => ({ ...item, image: { url: `${apiUrl}/uploads/${item.image}` } })),
          tagline: data?.expectSection?.tagline,
          heading: data?.expectSection?.heading,
          expectCards: data?.expectSection?.expectCards.map((item) => ({ ...item, image: { url: `${apiUrl}/uploads/${item.image}` } })),
          workingTitle: data?.workingProcedure?.workingTitle,
          workingDescription: data?.workingProcedure?.workingDescription,
          workingProcedure: data?.workingProcedure?.workingProcedures.map((item) => ({ ...item, image: { url: `${apiUrl}/uploads/${item.image}` } }))
        }}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {
          (formik) => (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={index} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={() => setActiveStep(0)}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {
                      activeStep === 0
                      &&
                      <>
                        <h1 className=' text-blue-800 font-bold mt-2'>Fill the basic details.</h1>
                        <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                          <ImageUpload
                            image={coverImg}
                            onRemove={() => setCoverImg("")}
                          />
                          <input
                            id="bgCoverImage"
                            name="bgCoverImage"
                            type="file"
                            accept=".jpg, .png, .jpeg"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={async (e) => {
                              const base64String = await convertFileToBase64(e?.target?.files[0]);
                              formik.setFieldValue(`bgCoverImage`, base64String);
                              onFileSelect(e);
                            }}
                          />
                          <div
                            className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                            onClick={() => fileInputRef.current && fileInputRef.current.click()}
                          >
                            <p className=" font-extrabold text-sm ">Cover image</p>
                            <AddPhotoAlternateIcon className="" fontSize="small" />
                          </div>
                        </div>
                        <div className=" grid grid-cols-2 space-x-12 mb-8">
                          <FormControl>
                            <TextField
                              id="title"
                              name="title"
                              label="Title"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={Boolean(formik.errors.title && formik.touched.title)}
                              helperText={
                                formik.errors.title &&
                                formik.touched.title &&
                                String(formik.errors.title)
                              }
                              required={true}
                              sx={{
                                fieldset: {
                                  borderWidth: "3px",
                                  borderColor: "#526D82"
                                },
                                input: {
                                  fontWeight: "medium",
                                },
                                label: {
                                  color: "#526D82"
                                }
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              id="category"
                              name="category"
                              select
                              label="Category"
                              onChange={formik.handleChange}
                              value={formik.values.category}
                              onBlur={formik.handleBlur}
                              error={Boolean(formik.errors.category && formik.touched.category)}
                              helperText={
                                formik.errors.category &&
                                formik.touched.category &&
                                String(formik.errors.category)
                              }
                              required={true}
                              sx={{
                                fieldset: {
                                  borderWidth: "3px",
                                  borderColor: "#526D82"
                                },
                                input: {
                                  fontWeight: "medium",
                                },
                                label: {
                                  color: "#526D82"
                                }
                              }}
                            >
                              {category.map((option, index) => (
                                <MenuItem key={index} value={option._id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </div>
                        <div className=" grid grid-cols-2 space-x-12 mb-8">
                          <FormControl>
                            <TextField
                              id="subTitle"
                              name="subTitle"
                              label="Subtitle"
                              value={formik.values.subTitle}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={Boolean(formik.errors.subTitle && formik.touched.subTitle)}
                              helperText={
                                formik.errors.subTitle &&
                                formik.touched.subTitle &&
                                String(formik.errors.subTitle)
                              }
                              required={true}
                              sx={{
                                fieldset: {
                                  borderWidth: "3px",
                                  borderColor: "#526D82"
                                },
                                input: {
                                  fontWeight: "medium",
                                },
                                label: {
                                  color: "#526D82"
                                }
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              id="color"
                              name="color"
                              label="Background Color"
                              onChange={formik.handleChange}
                              value={formik.values.color}
                              onBlur={formik.handleBlur}
                              error={Boolean(formik.errors.color && formik.touched.color)}
                              helperText={
                                formik.errors.color &&
                                formik.touched.color &&
                                String(formik.errors.color)
                              }
                              required={true}
                              sx={{
                                fieldset: {
                                  borderWidth: "3px",
                                  borderColor: "#526D82"
                                },
                                input: {
                                  fontWeight: "medium",
                                },
                                label: {
                                  color: "#526D82"
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                        <FieldArray
                          name="points"
                          render={
                            (arrayHelpers, i) => {
                              return (
                                <div key={i}>
                                  {formik.values.points?.map((item, index) => {
                                    const touchedName = getIn(formik.touched, `points.${index}.name`);
                                    const errorName = getIn(formik.errors, `points.${index}.name`);
                                    return (
                                      <Fragment key={index}>
                                        <div className="mt-2">
                                          {
                                            <div key={index}>
                                              {index > 0 &&
                                                <div>
                                                  <IconButton
                                                    color="error"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                  >
                                                    <DeleteOutlinedIcon />
                                                  </IconButton>
                                                </div>
                                              }
                                              <div className="grid grid-cols-1 space-x-12 mb-8">
                                                <FormControl>
                                                  <TextField
                                                    name={`points.${index}.name`}
                                                    label="Key Point"
                                                    value={item.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(touchedName && errorName)}
                                                    helperText={
                                                      errorName &&
                                                      touchedName &&
                                                      String(errorName)
                                                    }
                                                    required
                                                    sx={{
                                                      fieldset: {
                                                        borderWidth: "3px",
                                                        borderColor: "#526D82"
                                                      },
                                                      input: {
                                                        fontWeight: "medium",
                                                      },
                                                      label: {
                                                        color: "#526D82"
                                                      }
                                                    }}
                                                  />
                                                </FormControl>

                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </Fragment>)
                                  })}
                                  <div className=" ml-6">
                                    <Button
                                      endIcon={<AddIcon />}
                                      variant="contained"
                                      onClick={() => arrayHelpers.insert(
                                        formik.values.points.length + 1,
                                        { name: "" }
                                      )
                                      }
                                    >Add Key Point</Button>
                                  </div>
                                </div>
                              )
                            }
                          }
                        />
                      </>
                    }

                    {
                      activeStep === 1
                      &&
                      <>
                        <h1 className='text-blue-800 font-bold mt-2'>Add Content</h1>
                        <div className="mt-4 mb-8 mx-auto">
                          <FieldArray
                            name="contents"
                            render={
                              (arrayHelpers, i) => {
                                return (
                                  <div key={i} className='mt-2'>
                                    {formik.values.contents?.map((content, index) => {
                                      const touchedTitle = getIn(formik.touched, `contents.${index}.title`);
                                      const errorTitle = getIn(formik.errors, `contents.${index}.title`);

                                      const touchedDescription = getIn(formik.touched, `contents.${index}.description`);
                                      const errorDescription = getIn(formik.errors, `contents.${index}.description`);

                                      const touchedColor = getIn(formik.touched, `contents.${index}.color`);
                                      const errorColor = getIn(formik.errors, `contents.${index}.color`)

                                      return (
                                        <Fragment key={index}>

                                          <div className="mt-2">
                                            {
                                              <div key={index}>
                                                {index > 0 &&
                                                  <div>
                                                    <IconButton
                                                      color="error"
                                                      onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                      <DeleteOutlinedIcon />
                                                    </IconButton>
                                                  </div>
                                                }
                                                <div className="mt-2 flex flex-col items-center w-fit">
                                                  <ImageUpload
                                                    image={contentImages[index]}
                                                    onRemove={() => {
                                                      const newContentImages = [...contentImages];
                                                      newContentImages[index] = "";
                                                      setContentImages(newContentImages);
                                                    }}
                                                  />
                                                  <input
                                                    id={`contents.${index}.image`}
                                                    name={`contents.${index}.image`}
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    className="hidden"
                                                    ref={(element) => contentRefs.current[index] = element}
                                                    onChange={async (e) => {
                                                      const base64String = await convertFileToBase64(e.target?.files[0]);
                                                      formik.setFieldValue(`contents.${index}.image`, base64String);
                                                      onFileSelectForContent(e, index);
                                                    }}
                                                  />
                                                  <div
                                                    className="flex bg-blue-800 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                    onClick={() => selectFile(index)}
                                                  >
                                                    <p className=" font-extrabold text-sm ">Image</p>
                                                    <AddPhotoAlternateIcon className="" fontSize="small" />
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-2 space-x-12 mb-4 mt-10">
                                                  <FormControl>
                                                    <TextField
                                                      name={`contents.${index}.title`}
                                                      label="Title"
                                                      value={content.title}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      error={Boolean(touchedTitle && errorTitle)}
                                                      helperText={
                                                        errorTitle &&
                                                        touchedTitle &&
                                                        String(errorTitle)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>
                                                  <FormControl>
                                                    <TextField
                                                      name={`contents.${index}.color`}
                                                      label="Background Color"
                                                      value={content.color}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      error={Boolean(touchedColor && errorColor)}
                                                      helperText={
                                                        errorColor &&
                                                        touchedColor &&
                                                        String(errorColor)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>

                                                </div>
                                                <div className="grid grid-cols-1 space-x-12 mb-4 mt-4">
                                                  <FormControl>
                                                    <TextField
                                                      name={`contents.${index}.description`}
                                                      label="Description"
                                                      value={content.description}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      error={Boolean(touchedDescription && errorDescription)}
                                                      helperText={
                                                        errorDescription &&
                                                        touchedDescription &&
                                                        String(errorDescription)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        </Fragment>)
                                    })}
                                    <div className="ml-6">
                                      <Button
                                        endIcon={<AddIcon />}
                                        variant="contained"
                                        onClick={() => arrayHelpers.insert(
                                          formik.values.contents.length + 1,
                                          { title: "", description: "", image: "", color: "" }
                                        )
                                        }
                                      >Add Content</Button>
                                    </div>
                                  </div>
                                )
                              }
                            }
                          />
                        </div>
                      </>
                    }

                    {
                      activeStep === 2
                      &&
                      <>
                        <h1 className=' text-blue-800 font-bold mt-2'>Add What to Expect</h1>
                        <div className="mt-4 mb-8 mx-auto">
                          <div className=" grid grid-cols-2 space-x-12 mb-8">
                            <FormControl>
                              <TextField
                                id="heading"
                                name="heading"
                                label="Heading"
                                value={formik.values.heading}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.heading && formik.touched.heading)}
                                helperText={
                                  formik.errors.heading &&
                                  formik.touched.heading &&
                                  String(formik.errors.heading)
                                }
                                required={true}
                                sx={{
                                  fieldset: {
                                    borderWidth: "3px",
                                    borderColor: "#526D82"
                                  },
                                  input: {
                                    fontWeight: "medium",
                                  },
                                  label: {
                                    color: "#526D82"
                                  }
                                }}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                id="tagline"
                                name="tagline"
                                label="Tagline"
                                value={formik.values.tagline}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.tagline && formik.touched.tagline)}
                                helperText={
                                  formik.errors.tagline &&
                                  formik.touched.tagline &&
                                  String(formik.errors.tagline)
                                }
                                required={true}
                                sx={{
                                  fieldset: {
                                    borderWidth: "3px",
                                    borderColor: "#526D82"
                                  },
                                  input: {
                                    fontWeight: "medium",
                                  },
                                  label: {
                                    color: "#526D82"
                                  }
                                }}
                              />
                            </FormControl>
                          </div>
                          <FieldArray
                            name="expectCards"
                            render={
                              (arrayHelpers, i) => {
                                return (
                                  <div key={i} className='mt-2'>
                                    {formik.values.expectCards?.map((card, index) => {
                                      const touchedTitle = getIn(formik.touched, `expectCards.${index}.title`);
                                      const errorTitle = getIn(formik.errors, `expectCards.${index}.title`);
                                      const touchedDescription = getIn(formik.touched, `expectCards.${index}.description`);
                                      const errorDescription = getIn(formik.errors, `expectCards.${index}.description`);

                                      return (
                                        <Fragment key={index}>
                                          <div className="mt-2">
                                            {
                                              <div key={index}>
                                                {index > 0 &&
                                                  <div>
                                                    <IconButton
                                                      color="error"
                                                      onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                      <DeleteOutlinedIcon />
                                                    </IconButton>
                                                  </div>
                                                }
                                                <div className="grid grid-cols-2 space-x-12 mb-4 mt-10">
                                                  <FormControl>
                                                    <TextField
                                                      name={`expectCards.${index}.title`}
                                                      label="Title"
                                                      value={card.title}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      error={Boolean(touchedTitle && errorTitle)}
                                                      helperText={
                                                        errorTitle &&
                                                        touchedTitle &&
                                                        String(errorTitle)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>
                                                  <FormControl>
                                                    <TextField
                                                      name={`expectCards.${index}.description`}
                                                      label="Description"
                                                      value={card.description}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      error={Boolean(touchedDescription && errorDescription)}
                                                      helperText={
                                                        errorDescription &&
                                                        touchedDescription &&
                                                        String(errorDescription)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>
                                                </div>
                                                <div className="mt-4 mb-4 flex flex-col items-center w-fit">
                                                  <ImageUpload
                                                    image={selectedImages[index]}
                                                    onRemove={() => {
                                                      const newSelectedImages = [...selectedImages];
                                                      newSelectedImages[index] = "";
                                                      setSelectedImages(newSelectedImages);
                                                    }}
                                                  />
                                                  <input
                                                    id={`expectCards.${index}.image`}
                                                    name={`expectCards.${index}.image`}
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    ref={(element) => inputRefs.current[index] = element}
                                                    className="hidden"
                                                    onChange={async (e) => {
                                                      const base64String = await convertFileToBase64(e.target?.files[0]);
                                                      formik.setFieldValue(`expectCards.${index}.image`, base64String);
                                                      onFileSelectForExpect(e, index);
                                                    }}
                                                  />
                                                  <div
                                                    className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                    onClick={() => handleClick(index)}
                                                  >
                                                    <p className=" font-extrabold text-sm ">Image</p>
                                                    <AddPhotoAlternateIcon className="" fontSize="small" />
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                          </div>

                                        </Fragment>)
                                    })}
                                    <div className=" ml-6">
                                      <Button
                                        endIcon={<AddIcon />}
                                        variant="contained"
                                        onClick={() => arrayHelpers.insert(
                                          formik.values.expectCards.length + 1,
                                          { title: "", description: "", image: "" }
                                        )
                                        }
                                      >Add More</Button>
                                    </div>
                                  </div>
                                )
                              }
                            }
                          />
                        </div>
                      </>
                    }
                    {
                      activeStep === 3
                      &&
                      <>
                        <h1 className=' text-blue-800 font-bold mt-2'>Edit Working Procedure</h1>
                        <div className="mt-4 mb-8 mx-auto">
                          <div className=" grid grid-cols-1 space-x-12 mb-8">
                            <FormControl>
                              <TextField
                                id="workingTitle"
                                name="workingTitle"
                                label="Title"
                                value={formik.values.workingTitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.workingTitle && formik.touched.workingTitle)}
                                helperText={
                                  formik.errors.workingTitle &&
                                  formik.touched.workingTitle &&
                                  String(formik.errors.workingTitle)
                                }
                                required={true}
                                sx={{
                                  fieldset: {
                                    borderWidth: "3px",
                                    borderColor: "#526D82"
                                  },
                                  input: {
                                    fontWeight: "medium",
                                  },
                                  label: {
                                    color: "#526D82"
                                  }
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className=" grid grid-cols-1 space-x-12 mb-8">
                            <FormControl>
                              <TextField
                                id="workingDescription"
                                name="workingDescription"
                                label="Description"
                                multiline
                                minRows={2}
                                value={formik.values.workingDescription}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.workingDescription && formik.touched.workingDescription)}
                                helperText={
                                  formik.errors.workingDescription &&
                                  formik.touched.workingDescription &&
                                  String(formik.errors.workingDescription)
                                }
                                required={true}
                                sx={{
                                  fieldset: {
                                    borderWidth: "3px",
                                    borderColor: "#526D82"
                                  },
                                  input: {
                                    fontWeight: "medium",
                                  },
                                  label: {
                                    color: "#526D82"
                                  }
                                }}
                              />
                            </FormControl>
                          </div>
                          <FieldArray
                            name="workingProcedure"
                            render={
                              (arrayHelpers, i) => {
                                return (
                                  <div key={i} className='mt-2'>
                                    <p>{i}</p>
                                    {formik.values.workingProcedure?.map((procedure, index) => {
                                      const touchedTitle = getIn(formik.touched, `workingProcedure.${index}.procedureName`);
                                      const errorTitle = getIn(formik.errors, `workingProcedure.${index}.procedureName`);

                                      const touchedDescription = getIn(formik.touched, `workingProcedure.${index}.procedures`);
                                      const errorDescription = getIn(formik.errors, `workingProcedure.${index}.procedures`);
                                      return (
                                        <Fragment key={index}>
                                          <div className="mt-2">
                                            {
                                              <div key={index}>
                                                {index > 0 &&
                                                  <div>
                                                    <IconButton
                                                      color="error"
                                                      onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                      <DeleteOutlinedIcon />
                                                    </IconButton>
                                                  </div>
                                                }

                                                <div className="grid grid-cols-2 space-x-12 mb-4 mt-10">
                                               
                                                  <FormControl>
                                                    <TextField
                                                      name={`workingProcedure.${index}.procedureName`}
                                                      label="Step Name"
                                                      value={procedure.procedureName}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      error={Boolean(touchedTitle && errorTitle)}
                                                      helperText={
                                                        errorTitle &&
                                                        touchedTitle &&
                                                        String(errorTitle)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>
                                                </div>
                                                <div className="grid grid-cols-1 space-x-12 mb-4 mt-2">
                                                  <FormControl>
                                                    <TextField
                                                      name={`workingProcedure.${index}.procedures`}
                                                      label="Working Methods"
                                                      value={procedure.procedures}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      multiline
                                                      minRows={3}
                                                      error={Boolean(touchedDescription && errorDescription)}
                                                      helperText={
                                                        errorDescription &&
                                                        touchedDescription &&
                                                        String(errorDescription)
                                                      }
                                                      required
                                                      sx={{
                                                        fieldset: {
                                                          borderWidth: "3px",
                                                          borderColor: "#526D82"
                                                        },
                                                        input: {
                                                          fontWeight: "medium",
                                                        },
                                                        label: {
                                                          color: "#526D82"
                                                        }
                                                      }}
                                                    />
                                                  </FormControl>
                                                </div>
                                                <div className="mb-4 flex flex-col items-center w-fit">
                                                  <ImageUpload
                                                    image={procedureImages[index]}
                                                    onRemove={() => {
                                                      const newProcedureImages = [...procedureImages];
                                                      newProcedureImages[index] = "";
                                                      setProcedureImages(newProcedureImages);
                                                    }}
                                                  />
                                                  <input
                                                    id={`workingProcedure.${index}.image`}
                                                    name={`workingProcedure.${index}.image`}
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    ref={(element) => inputRefs.current[index] = element}
                                                    className="hidden"
                                                    onChange={async (e) => {
                                                      const base64String = await convertFileToBase64(e.target?.files[0]);
                                                      formik.setFieldValue(`workingProcedure.${index}.image`, base64String);
                                                      onFileSelectForProcedure(e, index);
                                                    }}
                                                  />
                                                    <div
                                                      className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                      onClick={() => handleClick(index)}
                                                    >
                                                      <p className=" font-extrabold text-sm ">Image</p>
                                                      <AddPhotoAlternateIcon className="" fontSize="small" />
                                                    </div>
                                                  </div>
                                              </div>
                                            }
                                          </div>

                                        </Fragment>)
                                    })}
                                    <div className=" ml-6">
                                      <Button
                                        endIcon={<AddIcon />}
                                        variant="contained"
                                        onClick={() => arrayHelpers.insert(
                                          formik.values.workingProcedure?.length??0 + 1,
                                          { procedureName: "", procedures: "", image: "" }
                                        )
                                        }
                                      >{formik.values.workingProcedure?.length>0?"Add More":"Add Working Procedure"}</Button>
                                    </div>
                                  </div>
                                )
                              }
                            }
                          />
                        </div>
                      </>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {
                        activeStep === steps.length - 1
                        &&
                        <Button
                          type='submit'
                          disabled={formik.isSubmitting}
                        >
                          Submit
                        </Button>
                      }
                      {
                        activeStep !== steps.length - 1
                        &&
                        <Button
                          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                        >
                          Next
                        </Button>
                      }
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </form>
          )
        }
      </Formik>
    </Paper>
  );
}

