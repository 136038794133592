import { createSlice } from "@reduxjs/toolkit";
import { addNewUser, deleteUser, getSuspendedUsers, getallusers, suspendUser, unSuspendUser, updateUser } from "../actions/usersActions";


const initialState = {
    loading:false,
    usersdata:[],
    suspendlist:[],
    error:null,
    success:false


}





const usersSlice = createSlice({
    name:'users',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getallusers.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getallusers.fulfilled]: (state,{payload}) => {
            state.loading = false;
            state.usersdata = payload;
            state.success = true;

        },
        [getallusers.rejected]:(state,{payload}) => {
            state.loading=false;
            state.error = payload;

        },
        [addNewUser.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [addNewUser.fulfilled]: (state,{payload}) => {
            state.loading= false;
            state.success = true;
        },
        [addNewUser.rejected]:(state,{payload}) => {
            state.loading = false;
            state.error = payload;
            
        },
        [updateUser.pending]:(state) => {
            state.loading = true;
            state.error = null
        },
        [updateUser.fulfilled]: (state ,{payload}) => {
              state.loading = false;
              state.success = true;
        }
        ,
        [updateUser.error]:(state,{payload}) => {
            state.loading = false;
            state.error = payload;

        },
        [deleteUser.pending]:(state) => {
            state.loading = true;
            state.error = null;

        },
        [deleteUser.fulfilled]:(state,{payload}) => {
            state.loading = false;
            state.success = true;
        },
        [deleteUser.error]: (state , {payload}) => {
            state.loading = false;
            state.error = payload;
        }
        ,
        [getSuspendedUsers.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getSuspendedUsers.fulfilled]: (state,{payload}) => {
            state.loading = false;
            state.suspendlist = payload;
            state.success = true;

        },
        [getSuspendedUsers.rejected]:(state,{payload}) => {
            state.loading=false;
            state.error = payload;

        },
        [suspendUser.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [suspendUser.fulfilled]: (state,{payload}) => {
            state.loading = false;
          
            state.success = true;

        },
        [suspendUser.rejected]:(state,{payload}) => {
            state.loading=false;
            state.error = payload;

        },
        [unSuspendUser.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [unSuspendUser.fulfilled]: (state,{payload}) => {
            state.loading = false;
          
            state.success = true;

        },
        [unSuspendUser.rejected]:(state,{payload}) => {
            state.loading=false;
            state.error = payload;

        },
    
    



    }
})




export default usersSlice.reducer;
