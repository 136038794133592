import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../../Ui/topHeader";
import edit from "../Assets/edit.png";
import Table from "../../../Ui/table";
import LoadingBackdrop from "../../../Ui/LoadinSpinner";
import { errorAlert, successAlert } from "../../../Ui/Alert";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL
const Category = () => {

    const navigate = useNavigate();
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [categoryId, setCategoryId] = useState('');

    const handleClose = () => {
        setOpen(!open)
        setCategoryId('')
    }

    const handleOpen = (id) => {
        setCategoryId(id);
        setOpen(true);
    }
    const handleDelete = async () => {
        await axios.delete(`${apiUrl}/api/category/delete/${categoryId}`).then(async(res)=>{
            successAlert("Category Deleted Successfully")
            await getCategories()
        }).catch((error)=>{
            errorAlert("Unable to delete category")
        })
        setOpen(!open)
    }

    useEffect(() => {
        getCategories()
    }, [])

    const handleEdit = (data) => {
        navigate('/cm/edit-category', { state: data })
    }

    const getCategories = async () => {
        await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                const data = res.data.map((item, index) => {
                    return {
                        srNo: index + 1,
                        ...item
                    }
                })
                setCategories(data)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const columns = [
        {
            name: "Sr. No",
            selector: row => row.srNo,
            width: "100px"
        },
        {
            name: "Image",
            cell: (row) => (
                <Avatar 
                    src={row?.image}
                    variant="square"
                    alt={row?.title}
                />
                ),
        },
        {
            name: "Category",
            selector: row => row?.name,
        },
        {
            name: "Background Color",
            selector: row => row?.color,
        },
        {
            name: "Description",
            selector: row => row?.description?.substr(0, 80),
            wrap: true
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="">
                    <IconButton
                        style={{
                            color: "#2C3E50",
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <img src={edit} alt="edit" />
                    </IconButton>
                    <IconButton
                        style={{ color: "#EE4B4B" }}
                        onClick={() => handleOpen(row._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
            style: {marginLeft: "5px"}
        }
    ]

    return (
        <div>
            <TopHeader />

            <div>
                <div className=" flex justify-between items-center pt-4 pl-5 pb-4">
                    <p className=" text-2xl font-bold pt-4 pl-5 text-blue-950">All Categories</p>

                    <Button
                        onClick={() => navigate("/cm/add-category")}
                        variant="contained"
                        size="large"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            }
                        }}
                    >New Category</Button>
                </div>
                <div className=" pl-5 pr-5">
                    {isLoading && (
                        <LoadingBackdrop isloading={isLoading}/>
                    )}
                    <Table
                        columns={columns}
                        data={categories}
                        placeholder="Search Category"
                    />
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            Do you want to delete the category?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleClose} variant="outlined">No</Button>
                            <Button
                                onClick={() => handleDelete()}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Category;