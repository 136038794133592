import {
    Button, Chip, IconButton
} from "@mui/material";

import TopHeader from "../../Ui/topHeader";
import ClientField from "../../Ui/clientField";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useState } from "react";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from "../../Ui/table";
import { orderStatus,paymentMode } from "../../utils/constant";

const backendUrl = process.env.REACT_APP_API_BASE_URL

function ViewClient() {

    const loading = useSelector(
        (state) => state.clients.loading
    )
    const status = useSelector(
        (state) => state.clients.status
    )
    const { state: data } = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState('one');
    const [consultationData, setConsultationData] = useState([]);
    const [purchasedProductData, setPurchasedProductData] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getTableData()
    }, [])

    const getTableData = async () => {
        if (data?.consultation) {
            const consultations = data?.consultation.map((item) => ({
                date: item?.date,
                eStatus: item?.eStatus,
                meetingLink: item?.meetingLink,
                startTime: item?.startTime,
                category: item?.selectedCategory?.name,
                survey:item?.survey,
                notes:item?.notes,
                prescription:item?.prescription,
                doctor: `${item?.doctor?.personalInfo?.first_name??"--"} ${item?.doctor?.personalInfo?.last_name??"--"}`
            }))
            setConsultationData(consultations);
        }
        const products = []
        data.orders?.map((item, index) => {
            item?.orderProducts?.map((productItem) => {
                products.push({
                    orderId: item?.orderId,
                    orderDate: item?.orderedDate,
                    totalPrice: item?.totalPrice,
                    name: productItem?.product?.name,
                    quantity: productItem?.quantity,
                    orderStatus: item?.status,
                    sMethod: paymentMode[item?.paymentDetails?.paymentMode]

                })
            })
            item?.orderPackages?.map((packageItem) => {
                products.push({
                    orderId: item?.orderId,
                    orderDate: item?.orderedDate,
                    totalPrice: item?.totalPrice,
                    name: packageItem?.package?.name ?? "---",
                    quantity: 1,
                    orderStatus: item?.status
                })
            })
        })
        setPurchasedProductData(products)
    }

    const gotowallet = () => {
        navigate("/crm/wallet", {
            state: {
                userId: data?.user?._id
            }
        })
    }

    const viewConsultationDetails = (consultationData) => {
        const name = `${data.user?.personalInfo?.first_name} ${data.user?.personalInfo?.last_name}`;
        const email = data?.user?.email;
        const contact = (data?.user?.personalInfo?.country_code??"")+data?.user?.personalInfo?.phone_number;
        const treatment = data.treatment;
        const consultation = data.consultation;
        const history = data.history;
        const results = data.results;
        const status = data.status;
        const notes = consultationData?.notes
        const doctorNotes = consultationData?.doctorNotes
        const category = consultationData?.category
        const meetingLink = consultationData?.meetingLink
        const profile = backendUrl+"/uploads/"+data?.user?.imageUrl
        const prescriptionId = consultationData?.prescription
        const survey = consultationData?.survey

        navigate('/consult/viewbooking', {
            state: {
                name,
                email,
                contact,
                treatment,
                consultation,
                history,
                results,
                status,
                notes,
                doctorNotes,
                category,
                meetingLink,
                profile,
                prescriptionId,
                survey
            }
        })
    }

    const consultationColumn = [
        {
            name: 'Doctor',
            selector: consultationData => `${consultationData?.doctor??"---"}`,
            width: "200px",
            wrap: true,
            center: "true"
        },
        {
            name: 'Consultation Date',
            selector: consultationData => `${consultationData?.date}`,
            width: "180px",
            center: "true"
        },
        {
            name: 'Category',
            selector: consultationData => `${consultationData?.category}`,
            width: "200px",
            wrap: true,
            center: "true"
        },
        {
            name: 'Meeting Link',
            selector: consultationData => {
                if (consultationData?.meetingLink) {
                    return <a href={consultationData?.meetingLink} rel="noreferrer" target="_blank" style={{ color: 'blue', textDecoration: 'underline' }}>{consultationData?.meetingLink}</a>;
                } else {
                    return "Link not generated yet";
                }
            },
            width: "280px",
            center: "true"
        },
        {
            name: 'Status',
            selector: consultationData => `${consultationData?.eStatus}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Action',
            cell: (row) => (
                <IconButton
                    style={{
                        color: "#2C3E50",
                        marginLeft:'0.5rem'
                    }}
                    onClick={() => viewConsultationDetails(row)}
                >
                    <RemoveRedEyeOutlinedIcon />
                </IconButton>
            ),
            center: "true"
        },
    ]
    const getStatus = (status) => {
        if (status === orderStatus.Delivered) {
            return "success"
        }
        if (status === orderStatus.Canceled) {
            return "error"
        }
        if (status === orderStatus.Ongoing) {
            return "primary"
        }
        if (status === orderStatus.Ordered) {
            return "warning"
        }
        if (status === orderStatus.Out_of_Delivery) {
            return "secondary"
        }
    }
    const purchasedProductColumn = [
        {
            name: 'OrderID',
            selector: purchasedProductData => `${purchasedProductData?.orderId}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Product',
            selector: purchasedProductData => `${purchasedProductData?.name}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Quantity',
            selector: purchasedProductData => `${purchasedProductData?.quantity}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Total Price',
            selector: purchasedProductData => `${purchasedProductData?.totalPrice}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Payment Method',
            selector: purchasedProductData => `${purchasedProductData?.sMethod}`,
            width: "200px",
            center: "true"
        },
        {
            name: 'Order Status',
            cell: (row) => (
                <Chip label={row.orderStatus} color={getStatus(row.orderStatus)} />
            ),
            center: "true",

        },
    ]



    return (
        <div>
            <TopHeader />
            <div>

                {status === 'loading'} <LoadingBackdrop isloading={loading}></LoadingBackdrop>
                <>
                    <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                        <p className=" text-2xl font-bold text-blue-950">Client Details</p>
                        {
                            !data?.isDoctor && (
                                <div className="flex gap-x-4">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            "&.MuiButton-root": {
                                                backgroundColor: "#19398B",
                                                color: "white",
                                                fontWeight: "bold"
                                            },
                                            marginRight: "30px"
                                        }}
                                        onClick={() => gotowallet()}
                                    >WALLET</Button>
                                </div>
                            )
                        }
                    </div>
                    <div>
                        <div className=" flex pl-5">
                            <div className=" grid grid-cols-1 justify-items-center bg-white p-8 shadow-md">
                                <div className=" pb-4">
                                    <img
                                        src={backendUrl + "/uploads/" + data?.user?.imageUrl}
                                        alt="person"
                                        height={100}
                                        width={100}
                                        className=" shadow-black shadow-sm" />
                                </div>
                                <p className=" font-black text-xl text-blue-800 ">{data?.user?.personalInfo?.first_name + " " + data?.user?.personalInfo?.last_name}</p>
                                <p className=" font-thin text-sm text-blue-800">Hair Loss Problem</p>
                            </div>
                            <div>
                                <div className="grid grid-cols-3 gap-4 justify-evenly pt-2 pl-8">
                                    <ClientField
                                        coloured={false}
                                        label="Name"
                                        value={data?.user?.personalInfo?.first_name + " " + data?.user?.personalInfo?.last_name}
                                    />
                                    <ClientField
                                        coloured={false}
                                        label="Phone No."
                                        value={(data?.user?.personalInfo?.country_code??"")+data?.user?.personalInfo?.phone_number}
                                    />
                                    <ClientField
                                        coloured={false}
                                        label="Email Address"
                                        value={data?.user?.email} />
                                    <ClientField
                                        coloured={false}
                                        label="Number Of Consultation"
                                        value={data?.consultation?.length} />
                                    <ClientField
                                        coloured={false}
                                        label="Total Purchase Product"
                                        value={data?.totalPurchaseProduct} />
                                    <ClientField
                                        coloured={false}
                                        label="Total Purchase Package"
                                        value={data?.totalOrderPackages}
                                        wrap={true} />
                                </div>
                            </div>
                        </div>
                        <div className=" pl-5 pt-8 pb-2">
                            <Box sx={{ width: '100%' }} >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="disabled tabs example"
                                >
                                    <Tab value="one" sx={{ p: '2rem' }} label="Consultations" />
                                    {
                                        !data?.isDoctor && (
                                            <Tab value="two" label="Purchased Products" />
                                        )
                                    }


                                </Tabs>
                            </Box>
                        </div>
                        {value === 'two' && (<div className="p-5">
                            <Table
                                columns={purchasedProductColumn}
                                data={purchasedProductData}
                                placeholder="Search Purchased Product"
                            />
                        </div>
                        )}
                        {value === 'one' && (<div className="p-5">
                            <Table
                                columns={consultationColumn}
                                data={consultationData}
                                placeholder="Search Consultation"
                            />
                        </div>
                        )}
                    </div>
                </>
            </div>
        </div>
    )
}
export default ViewClient;