import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authreducer from '../store/Slices/AuthSlice';
import usersreducer from '../store/Slices/usersSlice';
import clientsreducer from '../store/Slices/ClientSlice';
import subscriberreducer from '../store/Slices/SubscriberSlice';
import pagesreducer from '../store/Slices/PageSlice';
import cardsreducer from '../store/Slices/MedicalCardSlice';
import productsreducer from '../store/Slices/ProductSlice';
import permissionsreducer from '../store/Slices/PermissionSlice';
import questionreducer from '../store/Slices/QuestionsSlice';
import ordersreducer from '../store/Slices/OrderSlice';
import packagereducer from '../store/Slices/PackageSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from "redux-persist";
import faqreducer from '../store/Slices/FaqSlice'
import batchreducer from '../store/Slices/BatchSlice'
import blogreducer from '../store/Slices/BlogSlice';


const persistConfig = {
   key:'root',
   version:1,
   storage
};




const reducer = combineReducers({
auth: authreducer,
users: usersreducer,
clients:clientsreducer,
subscriber:subscriberreducer,
pages:pagesreducer,
cards:cardsreducer,
products:productsreducer,
permissions:permissionsreducer,
orders:ordersreducer,
questions: questionreducer,
faqs:faqreducer ,
batches:batchreducer,
packages:packagereducer,
blogs:blogreducer,

})

const persistedReducer = persistReducer(persistConfig,reducer);


const store = configureStore( {
   reducer:persistedReducer
})

export default store;
