import React, { Fragment, useEffect, useState } from "react";
import * as yup from "yup";
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FieldArray, Formik, getIn } from "formik";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { errorAlert, successAlert } from "../../../Ui/Alert";
import { useNavigate } from "react-router-dom";
import LoadingBackdrop from "../../../Ui/LoadinSpinner";


const validationSchema = yup.object().shape({
    // products: yup.array().of(
    //     yup.object().shape({
    //         tProduct: yup.string().required("Product is required"),
    //         duration: yup.number().required("Product Duration required"),
    //         dose: yup.number().required("Dose is required."),
    //         frequency: yup.string().required("Frequency required."),
    //     })
    // ),
    // packages: yup.array().of(
    //     yup.object().shape({
    //         tPackage: yup.string().required("Package is required"),
    //         duration: yup.number().required("Duration is required."),
    //     })
    // ),
    notes: yup.string(),
    treatmentDuration: yup.string().required("Treatment Duration required."),
    doctorNotes: yup.string(),

})
const steps = ['Product Details', 'Package Details', 'Other Details']
const apiUrl = process.env.REACT_APP_API_BASE_URL
const EditMedicationStepper = ({ data }) => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const [inventory, setInventory] = useState([])
    const [treatmentPackages, setTreatmentPackages] = useState([])
    const [selectedPackage, setSelectedPackage] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {
        try {
            const [inventoryRes, packagesRes] = await Promise.all([
                axios.get(`${apiUrl}/api/inventory/filter?category=${data?.selectedCategory?._id}`),
                axios.get(`${apiUrl}/api/treatment-packages/filter?category=${data?.selectedCategory?._id}`)
            ]);

            if (inventoryRes.data && Array.isArray(inventoryRes.data)) {
                setInventory(inventoryRes.data);
            }

            if (packagesRes.data && Array.isArray(packagesRes.data)) {
                setTreatmentPackages(packagesRes.data);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    }

    const handleSubmit = async (values, actions) => {
        const packages=values.packages.filter((item)=>(item.tPackage!=""))
        const products=values.products.filter((item)=>(item.tProduct!=""))
        if(data.prescription && Object.keys(data.prescription).length>0){
            await axios.patch(`${apiUrl}/api/prescription/update/${data.prescription?._id}`, { ...values,packages:packages,products:products, category: data?.selectedCategory?._id, user: data?.user?._id }).then(async (res) => {
                if (res.data && res.data?.data._id) {
                    successAlert("Prescription Modified Successfully")
                    await updateConsultation({ prescription: res.data?.data._id })
                    navigate(-1);
                    actions.resetForm();
                }
            }).catch((error) => {
                console.log(error)
                errorAlert(error.message)
            })
        }
        else{
            await axios.post(`${apiUrl}/api/prescription/create`, { ...values,packages:packages,products:products, category: data?.selectedCategory?._id, user: data?.user?._id, remainingDuration: values?.treatmentDuration }).then(async (res) => {
                if (res.data && res.data?.data._id) {
                    successAlert("Prescription Added")
                    await updateConsultation({ prescription: res.data?.data._id })
                    navigate(-1);
                    actions.resetForm();
                }
            }).catch((error) => {
                errorAlert(error.message)
            }) 
        }
    }

    const updateConsultation = async (consultation) => {
        await axios.put(`${apiUrl}/api/update-appointment/${data._id}`, consultation).then((res) => {
        }).catch((error) => {
            errorAlert("Unable to update")
        })
    }

    return (
        <Paper className="p-6 mb-2 w-full">
            {
                isLoading ? (
                    <LoadingBackdrop isloading={isLoading} />
                ) : (
                    <Formik
                        initialValues={{
                            products: data?.prescription?.products?.map((item) => ({ tProduct: item?.tProduct?._id, duration: item?.duration, dose: item?.dose, frequency: item?.frequency })),
                            packages: data?.prescription?.packages?.map((item) => ({ tPackage: item?.tPackage?._id, duration: item?.duration, price: item?.price,optionId:item?.optionId })),
                            notes: data?.prescription?.notes,
                            treatmentDuration: data?.prescription?.treatmentDuration,
                            doctorNotes: data?.prescription?.doctorNotes
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}

                    >
                        {
                            (formik) => (
                                <form onSubmit={formik?.handleSubmit} autoComplete="off">
                                    <Box sx={{ width: "100%" }}>
                                        <Stepper activeStep={activeStep}>
                                            {
                                                steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};
                                                    return (
                                                        <Step key={index} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    )
                                                })
                                            }
                                        </Stepper>
                                        {
                                            activeStep === steps.length
                                                ?
                                                (
                                                    <Fragment>
                                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                                            All steps completed - you&apos;re finished
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                            <Box sx={{ flex: '1 1 auto' }} />
                                                            <Button onClick={handleReset}>Reset</Button>
                                                        </Box>
                                                    </Fragment>
                                                )
                                                :
                                                (
                                                    <Fragment>
                                                        {
                                                            activeStep === 0
                                                            &&
                                                            <>
                                                                <h1 className="text-neutral-500 font-bold mt-4">Fill the Product details.</h1>
                                                                <div className="mt-8 mb-6 w-full">
                                                                    <FieldArray
                                                                        name="products"
                                                                        render={
                                                                            (arrayHelpers, i) => {
                                                                                return (
                                                                                    <div key={i}>
                                                                                        {formik?.values?.products?.map((product, index) => {
                                                                                            const touchedProduct = getIn(formik?.touched, `products.${index}.tProduct`);
                                                                                            const errorProduct = getIn(formik?.errors, `products.${index}.tProduct`);

                                                                                            const touchedDuration = getIn(formik?.touched, `products.${index}.duration`);
                                                                                            const errorDuration = getIn(formik?.errors, `products.${index}.duration`);

                                                                                            const touchedDose = getIn(formik?.touched, `products.${index}.dose`);
                                                                                            const errorDose = getIn(formik?.errors, `products.${index}.dose`);

                                                                                            const touchedFrequency = getIn(formik?.touched, `products.${index}.frequency`);
                                                                                            const errorFrequency = getIn(formik?.errors, `products.${index}.frequency`);
                                                                                            return (
                                                                                                <Fragment key={index}>
                                                                                                    <div className="mt-2">
                                                                                                        {
                                                                                                            <div key={index}>
                                                                                                                {index > 0 &&
                                                                                                                    <div>
                                                                                                                        <IconButton
                                                                                                                            color="error"
                                                                                                                            onClick={() => arrayHelpers?.remove(index)}
                                                                                                                        >
                                                                                                                            <DeleteOutlinedIcon />
                                                                                                                        </IconButton>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <div className="grid grid-cols-2 space-x-12 mb-8">
                                                                                                                    <FormControl>
                                                                                                                        <Autocomplete
                                                                                                                            id={`products.${index}.tProduct`}
                                                                                                                            name={`products.${index}.tProduct`}
                                                                                                                            options={inventory || []}
                                                                                                                            getOptionLabel={(option) => `${option?.name} - ${option?._id}`}
                                                                                                                            value={inventory.find((item) => item?._id == product?.tProduct)}
                                                                                                                            onChange={(e, value) => {
                                                                                                                                formik.setFieldValue(`products.${index}.tProduct`, value?._id || "");
                                                                                                                            }}
                                                                                                                            onBlur={formik?.handleBlur}
                                                                                                                            renderInput={(params) => (
                                                                                                                                <TextField {...params}
                                                                                                                                    label="Choose Product"
                                                                                                                                    value={product?.tProduct}
                                                                                                                                    onChange={formik?.handleChange}
                                                                                                                                    error={Boolean(touchedProduct && errorProduct)}
                                                                                                                                    helperText={
                                                                                                                                        errorProduct &&
                                                                                                                                        touchedProduct &&
                                                                                                                                        String(errorProduct)
                                                                                                                                    }
                                                                                                                                    sx={{
                                                                                                                                        fieldset: {
                                                                                                                                            borderWidth: "3px",
                                                                                                                                            borderColor: "#526D82"
                                                                                                                                        },
                                                                                                                                        input: {
                                                                                                                                            fontWeight: "medium",
                                                                                                                                        },
                                                                                                                                        label: {
                                                                                                                                            color: "#526D82"
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </FormControl>
                                                                                                                    <FormControl>
                                                                                                                        <TextField
                                                                                                                            name={`products.${index}.duration`}
                                                                                                                            label="Product Duration"
                                                                                                                            type="number"
                                                                                                                            value={product?.duration}
                                                                                                                            onChange={formik?.handleChange}
                                                                                                                            onBlur={formik?.handleBlur}
                                                                                                                            error={Boolean(touchedDuration && errorDuration)}
                                                                                                                            helperText={
                                                                                                                                errorDuration &&
                                                                                                                                touchedDuration &&
                                                                                                                                String(errorDuration)
                                                                                                                            }
                                                                                                                            InputProps={{
                                                                                                                                startAdornment: <InputAdornment position="start">Days</InputAdornment>
                                                                                                                            }}
                                                                                                                            required
                                                                                                                            sx={{
                                                                                                                                fieldset: {
                                                                                                                                    borderWidth: "3px",
                                                                                                                                    borderColor: "#526D82"
                                                                                                                                },
                                                                                                                                input: {
                                                                                                                                    fontWeight: "medium",
                                                                                                                                },
                                                                                                                                label: {
                                                                                                                                    color: "#526D82"
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </FormControl>
                                                                                                                </div>
                                                                                                                <div className="grid grid-cols-2 space-x-12 mb-8">
                                                                                                                    <FormControl>
                                                                                                                        <TextField
                                                                                                                            name={`products.${index}.dose`}
                                                                                                                            type="number"
                                                                                                                            label="Dose"
                                                                                                                            value={product?.dose}
                                                                                                                            onChange={formik?.handleChange}
                                                                                                                            onBlur={formik?.handleBlur}
                                                                                                                            error={Boolean(touchedDose && errorDose)}
                                                                                                                            helperText={
                                                                                                                                errorDose &&
                                                                                                                                touchedDose &&
                                                                                                                                String(errorDose)
                                                                                                                            }
                                                                                                                            InputProps={{
                                                                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>
                                                                                                                            }}
                                                                                                                            required
                                                                                                                            sx={{
                                                                                                                                fieldset: {
                                                                                                                                    borderWidth: "3px",
                                                                                                                                    borderColor: "#526D82"
                                                                                                                                },
                                                                                                                                input: {
                                                                                                                                    fontWeight: "medium",
                                                                                                                                },
                                                                                                                                label: {
                                                                                                                                    color: "#526D82"
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </FormControl>

                                                                                                                    <FormControl>
                                                                                                                        <TextField
                                                                                                                            name={`products.${index}.frequency`}
                                                                                                                            label="Frequency"
                                                                                                                            value={product?.frequency}
                                                                                                                            onChange={formik?.handleChange}
                                                                                                                            onBlur={formik?.handleBlur}
                                                                                                                            error={Boolean(touchedFrequency && errorFrequency)}
                                                                                                                            helperText={
                                                                                                                                errorFrequency &&
                                                                                                                                touchedFrequency &&
                                                                                                                                String(errorFrequency)
                                                                                                                            }
                                                                                                                            required
                                                                                                                            InputProps={{
                                                                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>
                                                                                                                            }}
                                                                                                                            sx={{
                                                                                                                                fieldset: {
                                                                                                                                    borderWidth: "3px",
                                                                                                                                    borderColor: "#526D82"
                                                                                                                                },
                                                                                                                                input: {
                                                                                                                                    fontWeight: "medium",
                                                                                                                                },
                                                                                                                                label: {
                                                                                                                                    color: "#526D82"
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </FormControl>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        formik?.values?.products?.length - 1 != index && (
                                                                                                            <Divider variant="" sx={{ borderBottomWidth: "4px", borderBottomColor: "#737373" }} />
                                                                                                        )
                                                                                                    }
                                                                                                </Fragment>)
                                                                                        })}
                                                                                        <div className=" ml-6">
                                                                                            <Button
                                                                                                endIcon={<AddIcon />}
                                                                                                variant="contained"
                                                                                                onClick={() => arrayHelpers.insert(
                                                                                                    formik?.values?.products?.length??0 + 1,
                                                                                                    { tProduct: "", duration: "", dose: "", frequency: "" }
                                                                                                )
                                                                                                }
                                                                                            >Add Product</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            activeStep === 1
                                                            &&
                                                            <>
                                                                <h1 className="text-neutral-500 font-bold mt-4">Fill the Package details.</h1>
                                                                <div className="mt-8 mb-6 w-full">
                                                                    <FieldArray
                                                                        name="packages"
                                                                        render={
                                                                            (arrayHelpers, i) => {
                                                                                return (
                                                                                    <div key={i}>
                                                                                        {formik.values.packages?.map((pack, index) => {
                                                                                            const touchedPackage = getIn(formik.touched, `packages.${index}.tPackage`);
                                                                                            const errorPackage = getIn(formik.errors, `packages.${index}.tPackage`);

                                                                                            const touchedDuration = getIn(formik.touched, `packages.${index}.duration`);
                                                                                            const errorDuration = getIn(formik.errors, `packages.${index}.duration`);

                                                                                            return (
                                                                                                <Fragment key={index}>
                                                                                                    <div className="mt-2">
                                                                                                        {
                                                                                                            <div key={index}>
                                                                                                                {index > 0 &&
                                                                                                                    <div>
                                                                                                                        <IconButton
                                                                                                                            color="error"
                                                                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                                                                        >
                                                                                                                            <DeleteOutlinedIcon />
                                                                                                                        </IconButton>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <div className="grid grid-cols-2 space-x-12 mb-8">
                                                                                                                    <FormControl>
                                                                                                                        <Autocomplete
                                                                                                                            id={`packages.${index}.tPackage`}
                                                                                                                            name={`packages.${index}.tPackage`}
                                                                                                                            options={treatmentPackages || []}
                                                                                                                            getOptionLabel={(option) => `${option?.name} - ${option?._id}`}
                                                                                                                            value={treatmentPackages.find((item) => item?._id == pack?.tPackage)}
                                                                                                                            onChange={(e, value) => {
                                                                                                                                // getPackageDetails(value?._id)
                                                                                                                                formik?.setFieldValue(`packages.${index}.tPackage`, value?._id || "");
                                                                                                                            }}
                                                                                                                            onBlur={formik?.handleBlur}
                                                                                                                            renderInput={(params) => (
                                                                                                                                <TextField {...params}
                                                                                                                                    label="Choose Package"
                                                                                                                                    name={`packages.${index}.tPackage`}
                                                                                                                                    onChange={formik?.handleChange}
                                                                                                                                    value={pack?.tPackage}
                                                                                                                                    error={Boolean(touchedPackage && errorPackage)}
                                                                                                                                    helperText={
                                                                                                                                        touchedPackage &&
                                                                                                                                        errorPackage &&
                                                                                                                                        String(errorPackage)
                                                                                                                                    }
                                                                                                                                    required={true}
                                                                                                                                    sx={{
                                                                                                                                        fieldset: {
                                                                                                                                            borderWidth: "3px",
                                                                                                                                            borderColor: "#526D82"
                                                                                                                                        },
                                                                                                                                        input: {
                                                                                                                                            fontWeight: "medium",
                                                                                                                                        },
                                                                                                                                        label: {
                                                                                                                                            color: "#526D82"
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </FormControl>
                                                                                                                    <TextField
                                                                                                                        name={`packages.${index}.optionId`}
                                                                                                                        label="Package Duration"
                                                                                                                        select
                                                                                                                        onChange={(event) => {
                                                                                                                            formik.setFieldValue(`packages.${index}.optionId`, event.target.value)
                                                                                                                            const packageData = treatmentPackages.find((item) => item._id == pack.tPackage)?.packagesCost.find(option => option._id == event.target.value);
                                                                                                                            console.log(packageData)
                                                                                                                            if (packageData) {
                                                                                                                                formik.setFieldValue(`packages.${index}.duration`, packageData?.packageDuration ?? 0)
                                                                                                                                formik.setFieldValue(`packages.${index}.price`, packageData?.price ?? 0)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        value={pack.optionId}
                                                                                                                        onBlur={formik.handleBlur}
                                                                                                                        error={Boolean(touchedDuration && errorDuration)}
                                                                                                                        helperText={
                                                                                                                            errorDuration &&
                                                                                                                            touchedDuration &&
                                                                                                                            String(errorDuration)
                                                                                                                        }
                                                                                                                        required
                                                                                                                        // InputProps={{
                                                                                                                        //     startAdornment: <InputAdornment position="start">Months</InputAdornment>
                                                                                                                        // }}
                                                                                                                        sx={{
                                                                                                                            fieldset: {
                                                                                                                                borderWidth: "3px",
                                                                                                                                borderColor: "#526D82"
                                                                                                                            },
                                                                                                                            input: {
                                                                                                                                fontWeight: "medium",
                                                                                                                            },
                                                                                                                            label: {
                                                                                                                                color: "#526D82"
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <MenuItem value="Select Duration" disabled>
                                                                                                                            Select Duration
                                                                                                                        </MenuItem>
                                                                                                                        {treatmentPackages.find((item) => item._id == pack.tPackage)?.packagesCost && treatmentPackages.find((item) => item._id == pack.tPackage)?.packagesCost?.map((option, index) => (
                                                                                                                            <MenuItem key={index} value={option?._id}>
                                                                                                                                For {option?.packageDuration} Months - Price {option?.price}
                                                                                                                            </MenuItem>
                                                                                                                        ))}
                                                                                                                    </TextField>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        formik.values.packages.length - 1 != index && (
                                                                                                            <Divider variant="" sx={{ borderBottomWidth: "4px", borderBottomColor: "#737373" }} />
                                                                                                        )
                                                                                                    }
                                                                                                </Fragment>)
                                                                                        })}
                                                                                        <div className=" ml-6">
                                                                                            <Button
                                                                                                endIcon={<AddIcon />}
                                                                                                variant="contained"
                                                                                                onClick={() => arrayHelpers.insert(
                                                                                                    formik.values?.packages?.length + 1,
                                                                                                    { tPackage: "", duration: "" }
                                                                                                )
                                                                                                }
                                                                                            >Add Package</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            activeStep === 2
                                                            &&
                                                            <>
                                                                <h1 className=" text-neutral-500 font-bold mt-2">Other Details</h1>
                                                                <div className=" mt-4 mb-2">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="treatmentDuration"
                                                                            name="treatmentDuration"
                                                                            type="number"
                                                                            multiline
                                                                            minRows={1}
                                                                            label="Treatment Duration"
                                                                            value={formik.values.treatmentDuration}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            error={Boolean(formik.errors.treatmentDuration && formik.touched.treatmentDuration)}
                                                                            helperText={
                                                                                (formik.errors.treatmentDuration &&
                                                                                    formik.touched.treatmentDuration) ?
                                                                                    String(formik.errors.treatmentDuration)
                                                                                    :
                                                                                    "Mention Treatment Duration here."
                                                                            }
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">Months</InputAdornment>
                                                                            }}
                                                                            required={true}
                                                                            sx={{
                                                                                fieldset: {
                                                                                    borderWidth: "3px",
                                                                                    borderColor: "#526D82",
                                                                                },
                                                                                input: {
                                                                                    fontWeight: "medium",
                                                                                },
                                                                                label: {
                                                                                    color: "#526D82"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                <div className=" mt-4 mb-8">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="notes"
                                                                            name="notes"
                                                                            multiline
                                                                            minRows={4}
                                                                            label="Notes"
                                                                            value={formik.values.notes}
                                                                            onChange={formik.handleChange}
                                                                            // onBlur={formik.handleBlur}
                                                                            // error={Boolean(formik.errors.notes && formik.touched.notes)}
                                                                            helperText={
                                                                                (formik.errors.notes &&
                                                                                    formik.touched.notes) ?
                                                                                    String(formik.errors.notes)
                                                                                    :
                                                                                    "Type your notes Here."
                                                                            }
                                                                            required={true}
                                                                            sx={{
                                                                                fieldset: {
                                                                                    borderWidth: "3px",
                                                                                    borderColor: "#526D82"
                                                                                },
                                                                                input: {
                                                                                    fontWeight: "medium",
                                                                                },
                                                                                label: {
                                                                                    color: "#526D82"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                <div className=" mt-4 mb-8">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="doctorNotes"
                                                                            name="doctorNotes"
                                                                            multiline
                                                                            minRows={4}
                                                                            label="Doctor Notes"
                                                                            value={formik.values.doctorNotes}
                                                                            onChange={formik.handleChange}
                                                                            // onBlur={formik.handleBlur}
                                                                            // error={Boolean(formik.errors.doctorNotes && formik.touched.doctorNotes)}
                                                                            helperText={
                                                                                (formik.errors.notes &&
                                                                                    formik.touched.notes) ?
                                                                                    String(formik.errors.notes)
                                                                                    :
                                                                                    "Type your notes Here."
                                                                            }
                                                                            required={true}
                                                                            sx={{
                                                                                fieldset: {
                                                                                    borderWidth: "3px",
                                                                                    borderColor: "#526D82"
                                                                                },
                                                                                input: {
                                                                                    fontWeight: "medium",
                                                                                },
                                                                                label: {
                                                                                    color: "#526D82"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </>
                                                        }
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                            <Button
                                                                color="inherit"
                                                                disabled={activeStep === 0}
                                                                onClick={handleBack}
                                                                sx={{ mr: 1 }}
                                                            >
                                                                Back
                                                            </Button>
                                                            <Box sx={{ flex: '1 1 auto' }} />
                                                            {
                                                                activeStep === steps.length - 1
                                                                &&
                                                                <Button
                                                                    type='submit'
                                                                    disabled={formik?.isSubmitting}
                                                                    sx={{
                                                                        "&.MuiButton-root": {
                                                                            backgroundColor: "#E9B84A",
                                                                            color: "black",
                                                                            fontWeight: "bold"
                                                                        },
                                                                    }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            }
                                                            {
                                                                activeStep !== steps.length - 1
                                                                &&
                                                                <Button
                                                                    onClick={handleNext}
                                                                >
                                                                    Next
                                                                </Button>
                                                            }
                                                        </Box>
                                                    </Fragment>
                                                )
                                        }
                                    </Box>
                                </form>
                            )
                        }
                    </Formik>
                )
            }

        </Paper>
    )
}

export default EditMedicationStepper;