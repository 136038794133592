import { useNavigate } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import edit from "./Assets/edit.png";
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import { useState } from "react";
import { useEffect } from "react";
import { deleteCard, getAllCards } from "../../store/actions/medicalCardaction";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import LoadingBackdrop from "../../Ui/LoadinSpinner";

const MedicalCard = () => {

    const [open, setOpen] = useState(false);
   const [cardid,setcardid] = useState();
   const dispatch = useDispatch();
    const handleClickOpen = (cardid) => {
        setcardid(cardid);
        setOpen(true);
    }

  
    const handleClickClose = () => {
        setOpen(false);
    }
         

    const dataFetchedRef = useRef(false);

    const loading = useSelector(
        (state) => state.cards.loading
    )

    const cardsstatedata = useSelector(
        (state) => state.cards.cardsdata
    )

    useEffect(() => {


        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;     
        getallcards();
      

    }, [dispatch]);
   
    const [cardsdata,setcardsdata] = useState([]);
    const getallcards = async () => {

        const fetchedata = await dispatch(getAllCards());
        const data = fetchedata.payload.map(card => {
            return {
                id: card.cid,
                img: <div className="bg-black p-1 rounded-lg"><img src={card.pic_url} alt="Admin" /></div>,
                title: card.title,
                sub: card.subtitle,
                url: <Link>www.hairloss.com</Link>,
                page: card.page,
            }
        })


         setcardsdata(data);


    }



    const handledeletecards = async () => {
        await  dispatch(deleteCard(cardid));

        dispatch(getAllCards());
          setOpen(false);
        await getallcards();
        navigate('/cm/medcard');
      
           console.log("deleted successfully!!");
    }


    const columns = [
        {
            name: "Image",
            selector: row => row.img,
            // style: {paddingLeft: "12px"}
        },
        {
            name: 'Title',
            selector: row => row.title,
            // width: "150px"
        },
        {
            name: 'Sub Title',
            selector: row => row.sub,
            // width: "550px",
        },
        {
            name: 'URL',
            selector: row => row.url,
            // width: "550px",
        },
        {
            name: 'Page',
            selector: row => row.page
        },
        {
            name: "Action",
            cell: (row) => (
              <div className="flex items-center">
                <IconButton
                  style={{
                    // height: "32px",
                    // width: "41px",
                    color: "#2C3E50",
                  }}
                //   onClick={() => handleEdit(row)}
                >
                  <img src={edit} alt="edit" />
                </IconButton>
                <IconButton
                  style={{
                    color: "#EE4B4B",
                  }}
                  onClick={() => handleClickOpen(row.id)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </div>
            ),
            style: {marginLeft: "2em"}
          },
    ]
    
    
    const navigate = useNavigate();

    return(
        <div>
            <TopHeader />
            <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
            <h1 className=" text-3xl text-blue-900 font-bold pt-4 pl-5 pb-4">CREATE NEW CARD </h1>
                    
                    <div className=" flex gap-x-4">
                        <Button 
                            onClick={() => navigate("/cm/newcard")}
                            variant="contained" 
                            size="large"
                            sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                    marginLeft: "20px"
                                }}
                        >CREATE NEW CARD</Button>
                    </div>
                </div>
            <div className=" pl-5 pr-5">
            { loading && <LoadingBackdrop isloading={loading}></LoadingBackdrop>}
                {/* Table */}
                <Table
                    columns={columns}
                    data={cardsdata}
                    isSelectable="true"
                    placeholder="Search Cards"
                />

<Dialog 
                      open={open}
                      onClose={handleClickClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the user?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            
                                <Button onClick={handleClickClose} variant="outlined">No</Button>
                                <Button 
                                    onClick={() =>handledeletecards()}
                                    sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                    }}
                                >Yes</Button>
                            
                        </DialogActions>
                    </Dialog>

            </div>
        </div>
    )
}

export default MedicalCard;