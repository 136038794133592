import SweetAlert from "sweetalert2";
import checkimage from "../assets/check.png";
import warning from "../assets/warning.png";

const alertOptions = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: false,
  
};

const successAlertOptions = {
  ...alertOptions,
  icon:"success"
  
};

const errorAlertOption= {
  ...alertOptions,
  icon:"error"
};

export const errorAlert = (message) => {
  SweetAlert.mixin(errorAlertOption).fire({
    title: message,
  });
};

export const successAlert = (message) => {
  SweetAlert.mixin(successAlertOptions).fire({
    title: message,
  });
};
