import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    Avatar,
    Button,
    IconButton,
} from '@mui/material';
import TopHeader from "../../Ui/topHeader";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PreviewImage from "../../Ui/previewImage";
import blankProfile from "../../assets/blank-profile.jpg"

const options = [
    {
        value: "",
        selecter: "Select user type",
        disabled: true
    },
    {
        value: "Admin",
        selecter: "Admin"
    },
    {
        value: "Manager",
        selecter: "Manager"
    },
    {
        value: "Patient",
        selecter: "Patient"
    },
    {
        value: "Doctor",
        selecter: "Doctor"
    }    
]

const newCardSchema = yup.object().shape({
    photo: yup
          .mixed()
          .nullable()
          .required("Photo is required"),
    title: yup
            .string()
            .required("Card title Required"),
    sub: yup
            .string()
            .required("Sub title required"),
    url: yup
          .string()
          .required("URL requried"),
    treatment: yup
            .string()
            .required("Treatment type required"),
})

const NewCard = () => {

    const fileRef = useRef();
    const navigate = useNavigate();
    const pickImageHandler = () => {
        fileRef.current.click();
    }

    const onSubmit = async(values, actions) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(-1);
    }

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            photo: "",
            title: "",
            sub: "",
            url: "",
            treatment: ""
        },
        validationSchema: newCardSchema,
        onSubmit,
    })

    return(
        <div>
            <TopHeader />
            <h1 className=" text-3xl text-blue-900 font-bold pt-4 pl-5 pb-4">Add New Card</h1>
            <div className=" mt-4 pl-5 pb-8">
                <form onSubmit={handleSubmit} autoComplete="off" >
                <div className="">
                    <input 
                        id="photo"
                        name="photo"
                        type="file"
                        accept=".jpg, .png, .jprg"
                        ref={fileRef}
                        hidden
                        onChange={ (event) => setFieldValue("photo", event.target.files[0]) }
                    />
                    <div className=" flex items-center gap-12">
                        <div>
                            {values.photo ? <PreviewImage file={values.photo} /> : <Avatar variant="square" src={blankProfile} alt="Blank Profile" sx={{ height: 150, width: 150 }} />}
                            {errors.photo&& touched.photo && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.photo}</p>}
                        </div>
                        <IconButton onClick={pickImageHandler} className="p-5">
                            <AddAPhotoIcon sx={{ fontSize: "50px" }} color="primary"/>
                        </IconButton>
                    </div>    
                </div>
                <div className=" flex flex-col mb-8 mt-4">
                    <label className=" text-blue-700 font-bold mb-2" htmlFor="title">Card Title</label>
                    <input 
                        id="title"
                        type="text"
                        placeholder="Enter Card Title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${errors.title && touched.title ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 p-3 rounded-md border mr-5 `}
                    />
                    {errors.title && touched.title && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.title}</p>}
                </div>
                <div className=" flex flex-col mb-8 mt-4">
                    <label className=" text-blue-700 font-bold mb-2" htmlFor="sub">Sub Title</label>
                    <input 
                        id="sub"
                        type="text"
                        placeholder="Enter Sub Title"
                        value={values.sub}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${errors.sub && touched.sub ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 p-3 rounded-md border mr-5 `}
                    />
                    {errors.sub && touched.sub && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.sub}</p>}
                </div>
                <div className=" grid grid-cols-2">
                    <div className=" flex flex-col mb-8">
                        <label className=" text-blue-700 font-bold mb-2" htmlFor="title">URL</label>
                        <input 
                            id="url"
                            type="text"
                            placeholder="Enter URL"
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.url && touched.url ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 p-3 rounded-md border mr-5 `}
                        />
                       {errors.url && touched.url && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.url}</p>}
                    </div>
                    <div className=" flex flex-col">
                            <label className=" text-blue-800 font-semibold mb-2" htmlFor="usertype">User Type</label>
                            <select 
                                id="usertype"
                                name="usertype"
                                value={values.usertype}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`${errors.usertype && touched.usertype ? ' border border-red-600 outline-red-600' : 'border border-gray-400  outline-blue-500'} text-blue-900 mr-5 h-12  p-3 rounded-md border `}
                            >
                                {
                                    options.map((option) => {
                                        return(
                                            <option 
                                              className=" first:text-transparent pt-5 border border-b-2 active:bg-blue-400 font-semibold"
                                              key={option.value}
                                              value={option.value}
                                              disabled={option.disabled}
                                            >{option.selecter}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.usertype && touched.usertype && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.usertype}</p>}
                        </div>
                </div>
                <div className="ml-5 flex gap-4">
                    <Button 
                        variant="contained" 
                        size="large"
                        type="submit"
                        disabled={isSubmitting}
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            },
                        }}
                    >Save</Button>
                       <Button 
                        variant="outlined" 
                        size="large"
                        type="submit"
                        disabled={isSubmitting}
                        sx={{
                            "&.MuiButton-root": {
                                fontWeight: "bold"
                            },
                        }}
                        onClick={() => navigate(-1)}
                    >Cancel</Button>
                </div>
                </form>
            </div>
        </div>
    )
}

export default NewCard;