import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../../Ui/topHeader";
import Table from "../../../Ui/table";
import LoadingBackdrop from "../../../Ui/LoadinSpinner";
import { errorAlert, successAlert } from "../../../Ui/Alert";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL
const ContactDetails = () => {
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [requestId, setRequestId] = useState('');

    const handleClose = () => {
        setOpen(!open)
        setRequestId('')
    }

    const handleOpen = (id) => {
        setRequestId(id);
        setOpen(true);
    }
    const handleDelete = async () => {
        await axios.delete(`${apiUrl}/api/contact-request/delete/${requestId}`).then(async (res) => {
            successAlert("Category Deleted Successfully")
            await getContactRequests()
        }).catch((error) => {
            errorAlert("Unable to delete category")
        })
        setOpen(!open)
    }

    useEffect(() => {
        getContactRequests()
    }, [])

    const getContactRequests = async () => {
        await axios.get(`${apiUrl}/api/contact-request`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                const data = res.data.map((item, index) => {
                    return {
                        srNo: index + 1,
                        ...item
                    }
                })
                setRequests(data)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const columns = [
        {
            name: "Sr. No",
            selector: row => row.srNo,
            center: true
        },
        {
            name: "User Name",
            selector: row => row?.name,
            center: true
        },
        {
            name: "User Email",
            selector: row => row?.email,
            center: true
        },
        {
            name: "Message",
            selector: row => row?.message,
            wrap: true
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex items-center">
                    <IconButton
                        style={{ color: "#EE4B4B" }}
                        onClick={() => handleOpen(row._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
            style: { marginLeft: "5px" },
            center: true
        }
    ]

    return (
        <div>
            <TopHeader />

            <div>
                <div className=" flex justify-between items-center pt-4 pl-5 pb-4">
                    <p className=" text-2xl font-bold pt-4 pl-5 text-blue-950">Contact Requests</p>
                </div>
                <div className=" pl-5 pr-5">
                    {isLoading && (
                        <LoadingBackdrop isloading={isLoading} />
                    )}
                    <Table
                        columns={columns}
                        data={requests}
                        placeholder="Search Request"
                    />
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the request?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleClose} variant="outlined">No</Button>
                            <Button
                                onClick={() => handleDelete()}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ContactDetails;