import React, {  useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Button,
  Checkbox,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";

import TopHeader from "../../Ui/topHeader";
import { errorAlert, successAlert } from "../../Ui/Alert";
import Table from "../../Ui/table";
import { accessModules } from "../../utils/constant";
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getPermissionById } from "../../store/actions/permissionaction";

const newRoleSchema = yup.object().shape({
  role: yup.string().required("Role Name is Required"),
  tag: yup.string().required("Role Tag  is Required"),
});
const backendUrl = process.env.REACT_APP_API_BASE_URL;
const NewPermission = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [moduleAccess, setModuleAccess] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [accessData, setAccessData] = useState(null);
  const dispatch = useDispatch();
  const moduleArray = Object.entries(accessModules).map(
    ([key, value], index) => {
      return {
        index: index + 1,
        label: key,
        value: value,
      };
    }
  );

  const handleCheckboxChange = (moduleName, moduleTag, isAccess) => {
    setModuleAccess((prevModuleAccess) => ({
      ...prevModuleAccess,
      [moduleName]: {
        moduleName: moduleName,
        moduleCode: moduleTag,
        isAccess: !isAccess,
      },
    }));
  }


  useEffect(() => {
    if (location.pathname.includes("editPermission")) {
      setIsEditMode(true);
      loadData();
    }
  }, []);

  const onSubmit = async (values, actions) => {
    const { role, tag } = values;
    const newPermission = {
      role: role,
      tag: tag,
      accessLevels: [
        ...Object.values(moduleAccess),
      ],
    };
    if (isEditMode) {
      await axios({
        method: "put",
        url: `${backendUrl}/api/roles/${id}`,
        data: newPermission,
      })
        .then(() => {
          successAlert("Permission Edited successfully!!");
          actions.resetForm();
          navigate(-1);
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            errorAlert(error.response.data.error);
          } else errorAlert(error.message);
        });
    } else {
      await axios({
        method: "post",
        url: `${backendUrl}/api/roles`,
        data: newPermission,
      })
        .then(() => {
          successAlert("Role created successfully!!");
          actions.resetForm();
          navigate(-1);
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            errorAlert(error.response.data.error);
          } else errorAlert(error.message);
        });
    }
  };
 
  const {
    values,
    errors,
    setValues,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      role: "",
      tag: "",
      accessLevels: [],
    },
    validationSchema: newRoleSchema,
    onSubmit,
  });

  const loadData = async () => {
    const data = await dispatch(getPermissionById(id));
    if (data && data.payload) {
      setValues(data.payload);
      setAccessData(data.payload);
      const updatedModuleAccess = {};
      data.payload?.accessLevels.forEach((item) => {
        updatedModuleAccess[item.moduleName] = {
          moduleName: item.moduleName,
          moduleCode: item.moduleCode,
          isAccess: item.isAccess,
        };
      });
      setModuleAccess(updatedModuleAccess);
    }
  };
  const columns = [
    {
      name: "Sr. N.",
      selector: (row) => {
        return <p>{row.index}.</p>;
      },
      center: 'true'
    },
    {
      name: "Module Name",
      selector: (row) => row.label.toString().split("_").join(" "),
      center: 'true'
    },
    {
      name: "Access",
      cell: (row) => (
        <Checkbox
          checked={
            moduleAccess[row.label]?.isAccess ?? false
          }
          onChange={() =>
            handleCheckboxChange(
              row.label,
              row.value,
              moduleAccess[row.label]?.isAccess
            )
          }
        />
      ),
      center: 'true'
    },
  ];


  return (
    <div>
      <TopHeader />
      <div className="mt-6 pl-5 mb-8 mr-4">
        <Paper className="mt-4 p-5">
          <p className="text-2xl font-bold text-blue-900">{isEditMode
            ? "Edit Role & Permission"
            : "Add New Role & Permission"}</p>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="grid grid-cols-2 space-x-10 mb-4 mt-6 pl-5">
              <FormControl>
                <TextField
                  id="role"
                  type="text"
                  name="role"
                  placeholder="Enter Role"
                  label="Role"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="filled"
                  required
                  fullWidth
                  error={Boolean(errors.role && touched.role)}
                  helperText={errors.role && touched.role && String(errors.role)}
                />
              </FormControl>

              <FormControl>
                <TextField
                  id="tag"
                  name="tag"
                  fullWidth
                  type="text"
                  placeholder="Enter Role tag"
                  label="Role Tag"
                  disabled={isEditMode}
                  value={values.tag}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="filled"
                  required
                  error={Boolean(errors.tag && touched.tag)}
                  helperText={errors.tag && touched.tag && String(errors.tag)}
                />
              </FormControl>
            </div>
            <div className="mb-4 mt-6 pl-5" >
              <Table
                columns={columns}
                data={moduleArray}
                isSearch={false}
                isPagination={true}
              />
            </div>
            <div className="ml-5 flex gap-4 mt-4 pl-4">
              <Button
                variant="contained"
                size="large"
                disabled={isSubmitting}
                type="submit"
                sx={{
                  "&.MuiButton-root": {
                    backgroundColor: "#E9B84A",
                    color: "black",
                    fontWeight: "bold"
                  },
                }}
              >Submit</Button>
              <Button
                variant="outlined"
                size="large"
                disabled={isSubmitting}
                sx={{
                  "&.MuiButton-root": {
                    fontWeight: "bold"
                  },
                }}
                onClick={() => navigate(-1)}
              >Back</Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default NewPermission;
