import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllClients = createAsyncThunk(
    'clients/getAllClients',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/clients`,

                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const deleteclient = createAsyncThunk(
    'clients/deleteclient',
    async (clientId) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.delete(
                `${backendUrl}/api/users/deleteUser/${clientId}`,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const getclientdetails = createAsyncThunk(
    'clients/getclientdetails',
    async (clientId) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const { data } = await axios.get(
                `${backendUrl}/clientdetails`,
                {
                    params: {
                        uid: clientId
                    }
                }
                ,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const getSuspendclients = createAsyncThunk(
    'clients/getSuspendclients',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }


            const { data } = await axios.get(
                `${backendUrl}/suspendedclients`,


                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)


export const SuspendClient = createAsyncThunk(
    'clients/suspendClient',
    async (id) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }

            const body = {
                suspend_reason: "test"
            }

            const { data } = await axios.put(
                `${backendUrl}/suspendclient`, body,
                {
                    params: {
                        uid: id,

                    },
                },
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)

export const UnSuspendClient = createAsyncThunk(
    'clients/unsuspendClient',
    async (id) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const clientid = parseInt(id)
            const { data } = await axios.put(
                `${backendUrl}/unsuspendclient`,

                {
                    params: {
                        uid: clientid,
                    },
                },
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)