import "./style.css";
import logoimage from "../assets/Ed_clinic_logo.png";
import SpeedIcon from "@mui/icons-material/Speed";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import Box from "@mui/material/Box";
import { Sidebar, SubMenu, Menu, MenuItem } from "react-pro-sidebar";
import { Avatar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSidebarContext } from "./SidebarContext";
import { useCallback, useState, useEffect } from "react";
import { getCurrentUser } from "../utils/getCurrentUser";
import Medication from "@mui/icons-material/Medication";
import { accessModules } from "../utils/constant";
import { ContactEmergencyOutlined } from "@mui/icons-material";
import AnnouncementIcon from "@mui/icons-material/Announcement";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

function Sidebars() {
    const { sidebarRTL } = useSidebarContext();
    const [currentUser, setCurrentUser] = useState({});
    const [moduleAccess, setModuleAccess] = useState([]);
    let user = JSON.parse(localStorage.getItem("userData"));

    const getUser = useCallback(async () => {
        let users = (await getCurrentUser()) || [];
        if (users.length > 0) {
            let userObject =
                (await users?.filter(
                    (profile) => profile.email === user?.userId
                )) || [];
            if (userObject.length > 0) setCurrentUser(userObject[0]);
            if (
                userObject[0]?.userType &&
                userObject[0]?.userType?.accessLevels
            ) {
                setModuleAccess(userObject[0]?.userType?.accessLevels);
            }
        }
    }, []);

    const checkHaveModuleAccess = (moduleTag) => {
        if (Array.isArray(moduleTag)) {
            return moduleAccess.some(
                (module) =>
                    moduleTag.includes(module.moduleCode) && module.isAccess
            );
        } else {
            const module = moduleAccess.find(
                (module) => module.moduleCode === moduleTag && module.isAccess
            );
            return !!module;
        }
    };

    useEffect(() => {
        getUser();
    }, [getUser]);

    return (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
            }}
        >
            <Sidebar backgroundColor="white" width="330px" rtl={sidebarRTL}>
                <Menu>
                    <MenuItem>
                        <Box display={"flex"} ml="40px">
                            <img
                                alt="logo"
                                width="103px"
                                height="36px"
                                src={logoimage}
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "15%",
                                    marginTop: "19px",
                                }}
                            />
                        </Box>
                    </MenuItem>
                    <Box mb={"25px"} mt={"20px"}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={"10px"}
                            mr={"8%"}
                        >
                            <Avatar
                                src={backendUrl + currentUser?.imageUrl}
                                alt={currentUser?.username}
                                variant="rounded"
                                style={{
                                    width: "80px",
                                    height: "80px",
                                }}
                            />
                        </Box>
                        <Box textAlign="center" mr={"8%"}>
                            <Typography
                                variant="h7"
                                color={"black"}
                                fontWeight="bold"
                                sx={{ m: "2px 0 0 0" }}
                            >
                                {currentUser?.personalInfo?.first_name +
                                    " " +
                                    currentUser?.personalInfo?.last_name}
                            </Typography>
                        </Box>
                    </Box>
                    <hr />
                </Menu>

                <Menu>
                    <MenuItem
                        component={<Link to={"/"}></Link>}
                        icon={<SpeedIcon></SpeedIcon>}
                    >
                        Dashboard
                    </MenuItem>
                    {checkHaveModuleAccess([accessModules.All_Users,accessModules.Suspend_User]) == true ? (
                        <SubMenu
                            label={"User Management"}
                            icon={<PersonOutlineOutlinedIcon />}
                        >
                            {
                                checkHaveModuleAccess([accessModules.All_Users]) && (
                                    <MenuItem
                                    component={<Link to={"/user"}></Link>}
                                    icon={<GroupOutlinedIcon />}
                                >
                                    All Users{" "}
                                </MenuItem>
                                )
                            }
                            {
                                checkHaveModuleAccess([accessModules.Suspend_User]) && (
                                    <MenuItem
                                    component={
                                        <Link to={"/user/suspendUser"}></Link>
                                    }
                                    icon={<PersonOffOutlinedIcon />}
                                >
                                    Suspend Users
                                </MenuItem>
                                )
                            }
                           
                        </SubMenu>
                    ) : (
                        <></>
                    )}
                    {checkHaveModuleAccess([
                        accessModules.All_Clients,
                        accessModules.Suspend_Clients,
                        accessModules.Subscription_Management,
                        accessModules.Contact_Request,
                    ]) && (
                            <SubMenu
                                label={"Customer Relationship"}
                                icon={<PeopleOutlineOutlinedIcon />}
                            >
                                {checkHaveModuleAccess([
                                    accessModules.All_Clients,
                                ]) && (
                                        <MenuItem
                                            component={<Link to={"/crm"} />}
                                            icon={<PeopleAltOutlinedIcon />}
                                        >
                                            All Clients{" "}
                                        </MenuItem>
                                    )}

                                {checkHaveModuleAccess([accessModules.Suspend_Clients]) && (
                                    <MenuItem
                                        component={<Link to={"/crm/suspendclients"} />}
                                        icon={<PersonOffOutlinedIcon />}
                                    >
                                        Suspend Clients
                                    </MenuItem>
                                )}

                                {checkHaveModuleAccess([accessModules.Subscription_Management]) && (
                                    <MenuItem
                                        component={<Link to={"/crm/subscription"} />}
                                        icon={<CardMembershipOutlinedIcon />}
                                    >
                                        Subscription Management
                                    </MenuItem>
                                )}

                                {
                                    checkHaveModuleAccess([accessModules.Contact_Request]) && (
                                        <MenuItem
                                            component={<Link to={"/crm/contact-request"} />}
                                            icon={<ContactEmergencyOutlined />}
                                        >
                                            Contact Requests
                                        </MenuItem>
                                    )
                                }

                            </SubMenu>
                        )}

                    {checkHaveModuleAccess([accessModules.All_Blogs, accessModules.What_We_Treat, accessModules.Category, accessModules.All_Faqs, accessModules.Questionnaire_Evaluation, accessModules.Privacy_Policy]) && (
                        <SubMenu
                            label={"Content Management"}
                            icon={<ContentCopyOutlinedIcon />}
                        >
                            {
                                checkHaveModuleAccess([accessModules.All_Blogs]) && (
                                    <MenuItem
                                        component={<Link to={"/cm"} />}
                                        icon={<BookOutlinedIcon />}
                                    >
                                        All Blogs
                                    </MenuItem>
                                )
                            }
                            {
                                checkHaveModuleAccess([accessModules.What_We_Treat]) && (
                                    <MenuItem
                                        component={<Link to={"/cm/what-we-treat"} />}
                                        icon={<ListAltOutlinedIcon />}
                                    >
                                        What We Treat
                                    </MenuItem>
                                )
                            }
                            {
                                checkHaveModuleAccess([accessModules.Questionnaire_Evaluation]) && (
                                    <MenuItem
                                        component={<Link to={"/cm/questionnaire"} />}
                                        icon={<QuizOutlinedIcon />}
                                    >
                                        Questionnaire Evaluation
                                    </MenuItem>
                                )
                            }
                            {
                                checkHaveModuleAccess([accessModules.All_Faqs]) && (
                                    <MenuItem
                                        component={<Link to={"/cm/faqs"} />}
                                        icon={<LiveHelpOutlinedIcon />}
                                    >
                                        All Faqs
                                    </MenuItem>
                                )
                            }
                            {
                                checkHaveModuleAccess([accessModules.Category]) && (
                                    <MenuItem
                                        component={<Link to={"/cm/category"} />}
                                        icon={<TopicOutlinedIcon />}
                                    >
                                        Category
                                    </MenuItem>
                                )
                            }
                            {
                                checkHaveModuleAccess([accessModules.Privacy_Policy]) && (
                                    <MenuItem
                                        component={<Link to={"/cm/privacypolicy"} />}
                                        icon={<PrivacyTipOutlinedIcon />}
                                    >
                                        Privacy Policy
                                    </MenuItem>
                                )
                            }
                        </SubMenu>
                    )}

                    {checkHaveModuleAccess([accessModules.Crud_Packages, accessModules.Crud_Products]) && (
                        <SubMenu
                            label={"Inventory Management"}
                            icon={<Inventory2OutlinedIcon />}
                        >
                            {
                                checkHaveModuleAccess([accessModules.Crud_Products]) && (
                                    <MenuItem
                                        component={<Link to={"/ivm"} />}
                                        icon={<AppRegistrationOutlinedIcon />}
                                    >
                                        CRUD Product
                                    </MenuItem>
                                )
                            }

                            {
                                checkHaveModuleAccess([accessModules.Crud_Packages]) && (
                                    <MenuItem
                                        component={<Link to={"/ivm/packages"} />}
                                        icon={<ListAltOutlinedIcon />}
                                    >
                                        CRUD Packages
                                    </MenuItem>
                                )
                            }

                        </SubMenu>
                    )}
                    {checkHaveModuleAccess([accessModules.All_Bookings, accessModules.Add_Prescription, accessModules.Consultation_Booking]) && (
                        <SubMenu
                            label={"Consultation Management"}
                            icon={<AddIcCallOutlinedIcon />}
                        >
                            {
                                checkHaveModuleAccess([accessModules.All_Bookings]) && (
                                    <MenuItem
                                        component={<Link to={"/consult"} />}
                                        icon={<CalendarMonthOutlinedIcon />}
                                    >
                                        All Bookings
                                    </MenuItem>
                                )
                            }

                            {
                                checkHaveModuleAccess([accessModules.Add_Prescription]) && (
                                    <MenuItem
                                        component={
                                            <Link to={"/consult/prescription"} />
                                        }
                                        icon={<Medication />}
                                    >
                                        Add Prescription
                                    </MenuItem>
                                )
                            }

                            {
                                checkHaveModuleAccess([accessModules.Consultation_Booking]) && (
                                    <MenuItem
                                        component={<Link to={"/consult/bookings"} />}
                                        icon={<EventAvailableOutlinedIcon />}
                                    >
                                        Consultation Booking
                                    </MenuItem>
                                )
                            }

                        </SubMenu>
                    )}
                    {checkHaveModuleAccess([accessModules.Roles_And_Permissions]) && (
                        <MenuItem
                            component={<Link to={"/pr"} />}
                            icon={<BookmarkBorderOutlinedIcon />}
                        >
                            Permission and Role
                        </MenuItem>
                    )}

                    {checkHaveModuleAccess([accessModules.Order_Management]) && (
                        <MenuItem
                            component={<Link to={"/om"} />}
                            icon={<ShoppingCartOutlinedIcon />}
                        >
                            Order Management{" "}
                        </MenuItem>
                    )}
                    {checkHaveModuleAccess([accessModules.News_Letter_Subscription]) && (
                        <MenuItem
                            component={<Link to={"/news"} />}
                            icon={<AnnouncementIcon />}
                        >
                            News Letter Subscription
                        </MenuItem>
                    )}
                    {checkHaveModuleAccess([accessModules.Settings]) && (
                        <MenuItem
                            component={<Link to={"/settings"} />}
                            icon={<SettingsOutlinedIcon />}
                        >
                            Settings
                        </MenuItem>
                    )}
                </Menu>
            </Sidebar>
        </Box>
    );
}
export default Sidebars;
