import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Tab, Tabs } from "@mui/material";
import MyCalender from "../../Ui/Calender/calender";
import TopHeader from "../../Ui/topHeader";
import SpeedDail from "../../Ui/Calender/speedDail";
import axios from "axios";
import { errorAlert } from "../../Ui/Alert";
import { roleTag } from "../../utils/constant";
const event2 = [
  {
    id: 1,
    start: moment("2023-05-15T16:00:00").toDate(),
    end: moment("2023-05-15T16:30:00").toDate(),
    title: "Complete Hair Kit",
    name: "Adrian Smith",
    status: "Approved",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

function ConsultaionBookings() {
  const [index, setIndex] = useState(0);
  const [event, setEvent] = useState([]);
  const roleId = localStorage.getItem('userType')
  const userId = localStorage.getItem('userId')
  const [isDoctor, setIsDoctor] = useState(false)
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    checkUserIsDoctorOrNot()
  }, [roleId]);

  const handleTabChange = (event, index) => {
    setIndex(index);
  };
 
  const getAllBookings = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/filter/appointments?eStatus=Confirmed`);
      if (response.data && Array.isArray(response.data)) {
        getAllEvents(response.data)
      }
    } catch (error) {
      errorAlert("Unable to fetch data!!");
    }
  };

  const getAllBookingsByDoctor = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/filter/appointments?eStatus=Confirmed&doctor=${userId}`);
      if (response.data && Array.isArray(response.data)) {
        getAllEvents(response.data)
      }
    } catch (error) {
      errorAlert("Unable to fetch data!!");
    }
  };

  const getAllEvents=async(eventData)=>{
    const formattedEvents = eventData.map((item) => {
      try {
        const startDate = moment(
          `${item.date} ${item.startTime}`,
          "YYYY-MM-DD HH:mm"
        ).toDate();
        const endDate = moment(
          `${item.date} ${item.endTime}`,
          "YYYY-MM-DD HH:mm"
        ).toDate();
        if (!isNaN(startDate.getTime())) {
          return {
            id: item._id,
            status: item.isBooked ? "Approved" : "Pending",
            start: startDate,
            end: endDate,
            profileImg: item?.user?.imageUrl,
            name: `${item.user?.personalInfo?.first_name ?? "--"} ${item.user?.personalInfo?.last_name ?? "--"
              }`,
            title: item?.selectedCategory?.name,
            description: item.notes,
          };
        } else {
          console.error(
            `Invalid date or time format: ${item.date} ${item.start}`
          );
          return null; 
        }
      } catch (error) {
        console.error(`Error parsing date or time: ${error}`);
        return null;
      }
    });
    const validEvents = formattedEvents.filter((event) => event !== null);
    setEvent(validEvents);
  }

  const refreshBookings = async () => {
    await getAllBookings();
  };

  const goToAuthentication = async () => {
    navigate('/consult/newappoints')
  };

  const checkUserIsDoctorOrNot = async () => {
    if (roleId) {
        await axios.get(`${apiUrl}/api/roles/${roleId}`).then((res) => {
            if (res.data && res.data.tag && res.data.tag === roleTag.Doctor) {
                setIsDoctor(true)
                getAllBookingsByDoctor()
            }
            else getAllBookings()
        }).catch((error) => {
            console.log(error.message)
        })
    }
}

  return (
    <div>
      <TopHeader />
      <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
        <div className=" ml-5 mt-5">
          <div className=" flex flex-col justify-items-start">
            <h1 className=" text-4xl text-blue-800 font-bold">
              Consultation Bookings
            </h1>
            <p className=" text-sm text-neutral-600 font-bold ml-1">
              Create new bookings.
            </p>
          </div>
        </div>
        <div className=" flex gap-x-4">
          {/* <Button
            variant="contained"
            size="large"
            sx={{
              "&.MuiButton-root": {
                backgroundColor: "#19398B",
                color: "white",
                fontWeight: "bold",
              },
              marginLeft: "20px",
            }}
          >
            {" "}
            Request Approval
          </Button> */}
          <Button
            // onClick={handleNewUser}
            variant="contained"
            size="large"
            sx={{
              "&.MuiButton-root": {
                backgroundColor: "#E9B84A",
                color: "black",
                fontWeight: "bolder",
              },
              marginLeft: "20px",
            }}
          >
            Export
          </Button>
        </div>
      </div>
      <div className=" bg-white p-5 ml-5 mr-5 mb-12">
        <div className=" pl-4">
          <Tabs value={index} onChange={handleTabChange}>
            <Tab
              label="Teleconsultation Appointments"
              sx={{ marginLeft: "6px", fontWeight: "bold" }}
            />
            {/* <Tab
              label="Other Services Appointments"
              sx={{ fontWeight: "bold" }}
            /> */}
          </Tabs>
        </div>
        {index === 0 && (
          <>
            <MyCalender events={event} refreshBookings={refreshBookings} isDoctor={isDoctor}/>
          </>
        )}
        {index === 1 && <MyCalender events={event2} />}
      </div>
      <div
        className="fixed bottom-8 right-16"
        onClick={goToAuthentication}
        style={{ zIndex: 100 }}
      >
        <SpeedDail onclick={goToAuthentication} />
      </div>
    </div>
  );
}

export default ConsultaionBookings;
