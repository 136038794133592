import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllPackages = createAsyncThunk(
    'packages/getAllPackages',
    async () => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }
            const {data} = await axios.get(
                `${backendUrl}/api/treatment-packages`,
               
                config
            )       
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;
            }
        }    
    }
)

export const AddNewPackage = createAsyncThunk(
    'packages/AddNewPackage',
    async (packages) => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',
                           
                   }
            }
 
        
            const {data} = await axios.post(
                `${backendUrl}/api/treatment-packages`,packages,
               
                config
            )      
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)



export const deletePackage = createAsyncThunk(
    'packages/deletePackage',
    async (pid) => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.delete(
                `${backendUrl}/api/treatment-packages/`+pid,
               
               
                config
            )       
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)


export const updatePackage = createAsyncThunk(
    'packages/updatePackage',
    async (pid,packages) => {
        try {
            const config = {
                   headers: {
                    'Content-Type':'application/json',

                   }
            }

        
            const {data} = await axios.put(
                `${backendUrl}api/treatment-packages/`+pid,
                packages,
               
               
                config
            )       
          
            return data;

        } catch(error) {
            if(error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
                

            }
            else {
                console.log(error)
                return error.message;

            }

        }

    
    }
)