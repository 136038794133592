import { createSlice } from "@reduxjs/toolkit"
import { AddNewPackage, deletePackage, getAllPackages, updatePackage } from "../actions/packagesaction";

const initialState = {
    loading:false,
    packageData:[],
    error:null,
    success:false,


}
 

const PackageSlice = createSlice({
    name:'packages',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllPackages.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllPackages.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.packageData = payload;
        state.success = true;
     },
     [getAllPackages.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [deletePackage.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [deletePackage.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.packageData = payload;
        state.success = true;
     },
     [deletePackage.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddNewPackage.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [AddNewPackage.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.packageData = payload;
        state.success = true;
     },
     [AddNewPackage.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [updatePackage.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [updatePackage.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.packageData = payload;
        state.success = true;
     },
     [updatePackage.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default PackageSlice.reducer;

