import { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TopHeader from "../../Ui/topHeader";
import Table from "../../Ui/table";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../store/actions/usersActions";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import axios from "axios";
import { errorAlert, successAlert } from "../../Ui/Alert";
import { Person2Outlined, Troubleshoot } from "@mui/icons-material";
import exportFromJSON from 'export-from-json'
const backendUrl = process.env.REACT_APP_API_BASE_URL;

function SuspendUsers() {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(Troubleshoot)
    const dispatch = useDispatch();
    const [suspendedUsers, setSuspendUsers] = useState([]);
    const [deleteUserId, setDeleteUserId] = useState();
    const [isOpenForActive, setIsOpenForActive] = useState(false)
    useEffect(() => {
        getAllSuspendedUsers();
    }, [])

    const handleClickOpen = (userId) => {
        setDeleteUserId(userId);
        setOpen(true);
    }
    const handleClickClose = async () => {
        setIsOpenForActive(false)
        setDeleteUserId("")
        setOpen(false)
    }
    const getAllSuspendedUsers = async () => {
        await axios.get(`${backendUrl}/api/users/suspendedUser`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setSuspendUsers(res.data)
            }
        }).catch((error) => {
            errorAlert(error.message)
        }).finally(() => {
            setIsLoading(false)
        })

    }

    const handleDeleteUser = async () => {
        await dispatch(deleteUser(deleteUserId));
        setOpen(false);
        await getAllSuspendedUsers();
        successAlert("Deleted Successfully!!")
    }

    const handleOpenForActive = async (userId) => {
        setIsOpenForActive(true)
        setDeleteUserId(userId)
        setOpen(true)
    }

    const handleActiveUser = async () => {
        await axios.patch(`${backendUrl}/api/users/activate/${deleteUserId}`).then(async (res) => {
            if (res && res.data && res.data.success == true) {
                successAlert("User is Activated successfully")
                setOpen(false)
                setIsOpenForActive(false)
                setDeleteUserId(null)
                await getAllSuspendedUsers();
            }
        }).catch((error) => {
            errorAlert(error.message)
        })
    }
    const exportData = () => {
        const fileName = 'suspended users'
        const exportType = exportFromJSON.types.csv
        const serializedData = suspendedUsers.map((item, index) => ({
            sr: index + 1,
            Name: `${item?.personalInfo?.first_name ?? "--"} ${item?.personalInfo?.last_name ?? "--"}`,
            Contact: `=${item?.personalInfo?.phone_number ? `TEXT("${item?.personalInfo?.phone_number}", "0")` : '""'}`,
            Gender: `${item?.personalInfo?.sex ?? "---"}`,
            Address: `${item?.deliveryAddress ? item?.deliveryAddress[0]?.address : "---"}`,
            Email: item.email ?? '---',
            DOB: `=TEXT("${item?.personalInfo?.dob?.split('T')[0]}", "yyyy-mm-dd")`,
            UserType: item?.userType?.role,
            ReasonForInactive: item?.reasonForInActive
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }

    const columns = [
        {
            name: 'Photo',
            selector: row => <Avatar src={backendUrl + row.imageUrl} alt="profile" />,
        },
        {
            name: 'Username',
            selector: row => row.username
        },
        {
            name: 'Email Address',
            selector: row => row?.email,
            width: "200px"
        },
        {
            name: 'Role',
            selector: row => row?.userType?.role,
            width: "100px"
        },
        {
            name: "Suspend Reason",
            selector: row => row?.reasonForInActive,
            width: "300px"
        },
        {
            name: "Action",
            cell: (row) => (
                <div>
                    <IconButton
                        title="Active"
                        style={{
                            color: "#39BFAF",
                        }}
                        onClick={() => handleOpenForActive(row._id)}
                    >
                        <Person2Outlined />
                    </IconButton>
                    <IconButton
                        title="Delete"
                        style={{
                            color: "#EE4B4B",
                        }}
                        onClick={() => handleClickOpen(row._id)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            ),
        },
    ]

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                    <p className=" text-2xl font-bold text-blue-950">Suspend User List</p>
                    <div className=" flex gap-x-4">
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                                marginLeft: "20px"
                            }}
                            onClick={exportData}
                        >EXPORT ALL</Button>
                    </div>
                </div>

                <div className="pl-5 pr-5">
                    {isLoading && <LoadingBackdrop isloading={isLoading}></LoadingBackdrop>}
                    {/* Table */}
                    <Table
                        columns={columns}
                        data={suspendedUsers}
                    />
                    <Dialog
                        open={open}
                        onClose={handleClickClose}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {
                                    !isOpenForActive ? "Do you want to delete this user?" : "Do you want to active this user?"
                                }

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>

                            <Button onClick={() => setOpen(false)} variant="outlined">No</Button>
                            <Button
                                onClick={isOpenForActive ? handleActiveUser : handleDeleteUser}
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: "#E9B84A",
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }}
                            >Yes</Button>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default SuspendUsers;