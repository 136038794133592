import { useState, useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import {
    Button,
    FormControl,
    MenuItem,
    TextField
} from '@mui/material';

import TopHeader from "../../Ui/topHeader";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ImageUpload } from "../../Ui/imageUpload";
import { CoverImage } from "../../Ui/coverImage";
import { AddNewBlog } from "../../store/actions/blogaction";
import { errorAlert, successAlert } from "../../Ui/Alert";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL
const newPageSchema = yup.object().shape({
    thumbnailimage: yup
        .mixed()
        .nullable()
        .required("Thumbnail  is required"),
    coverImage: yup
        .mixed()
        .nullable()
        .required("Cover Image is required"),
    title: yup
        .string()
        .required("Blog title Required"),
    description: yup
        .string()
        .required("Blog Description is required"),
    content: yup
        .string()
        .required("Content is required"),
    category: yup
        .string()
        .required("Category required"),
    status: yup
        .string()
        .required("Status required"),
})

const NewPage = () => {

    const fileInputRef = useRef();
    const coverInputRef = useRef();
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [cover, setCover] = useState(null);
    const [category, setCategory] = useState([])
    const [isLoading,setIsLoading]=useState(true)
    const dispatch = useDispatch();
    
    useEffect(() => {
        getCategories()
    }, [])
    

    const selectCover = () => {
        coverInputRef.current.click()
    }
    const selectFile = () => {
        fileInputRef.current.click();
    }

    let status = [
        {
            label: "private",
            value: "private"
        },
        {
            label: "public",
            value: "public"
        }
    ];


    const onSubmit = async (values, actions) => {
        const blogFormData = new FormData();
        blogFormData.append('title', values.title);
        blogFormData.append('description', values.description);
        blogFormData.append('thumbnailimage', image.file);
        blogFormData.append('coverimage', cover.file);
        blogFormData.append('content', values.content);
        blogFormData.append('category', values.category);
        blogFormData.append('status', values.status);

        if(image==null || cover==null){
            errorAlert('Please upload image');
            return
        }
        const response = await dispatch(AddNewBlog(blogFormData));

        if (response.type === 'blogs/AddNewBlog/fulfilled') {
            successAlert('Blog Created SuccessFully');
            navigate(-1);
        }
        else {
            errorAlert('Some Error Occurred!!!');
        }
    }

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit,setFieldValue } = useFormik({
        initialValues: {
            thumbnailimage: "",
            title: "",
            description: "",
            content: "",
            category: "",
            status: "",
            coverImage: ""
        },
        validationSchema: newPageSchema,
        onSubmit,
    })
    const onFileSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setFieldValue("thumbnailimage",event.target.files[0])
            setImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            );
        }
    }
    const onCoverSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setFieldValue("coverImage",event.target.files[0])
            setCover({
                name: files[0].name,
                url: URL.createObjectURL(files[0]),
                file: files[0]
            })
        }
    }

    const getCategories = async () => {
        await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
            if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                setCategory(res.data)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <div>
            <TopHeader />
            <div className=" flex flex-col justify-start pt-4 pl-5 pb-4">
                <h1 className=" text-3xl text-blue-900 font-bold">Create New Blog</h1>
                <p className=" text-neutral-500 font-semibold">Please provide the below details to add a new blog.</p>
            </div>
            <div className=" mt-4 pl-5 pb-8">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                        <ImageUpload
                            image={image}
                            onRemove={() => setImage("")}
                        />
                        <input
                            id="thumbnailimage"
                            name="thumbnailimage"
                            type="file"
                            accept=".jpg, .png, .jpeg"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={onFileSelect}
                        />
             
                        <div
                            className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                            onClick={selectFile}
                        >
                            <p className=" font-extrabold text-sm ">Thumbnail</p>
                            <AddPhotoAlternateIcon className="" fontSize="small" />
                        </div>
                            {errors.thumbnailimage&& touched.thumbnailimage && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.thumbnailimage}</p>}
                       
                    </div>
                    <div className=" grid grid-cols-3 space-x-8 mb-8 mr-4">
                        <FormControl>
                            <TextField
                                id="title"
                                name="title"
                                label="Blog title"
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.title && touched.title)}
                                helperText={
                                    errors.title &&
                                    touched.title &&
                                    String(errors.title)
                                }
                                required={true}
                                sx={{
                                    fieldset: {
                                        borderWidth: "3px",
                                        borderColor: "#526D82"
                                    },
                                    input: {
                                        fontWeight: "medium",
                                    },
                                    label: {
                                        color: "#526D82"
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="category"
                                name="category"
                                select
                                label="Blog Category"
                                onChange={handleChange}
                                value={values.category}
                                onBlur={handleBlur}
                                error={Boolean(errors.category && touched.category)}
                                helperText={
                                    errors.category &&
                                    touched.category &&
                                    String(errors.category)
                                }
                                required={true}
                                sx={{
                                    fieldset: {
                                        borderWidth: "3px",
                                        borderColor: "#526D82"
                                    },
                                    input: {
                                        fontWeight: "medium",
                                    },
                                    label: {
                                        color: "#526D82"
                                    }
                                }}
                            >
                                {category.map((option, i) => (
                                    <MenuItem key={i} value={option?.name}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="status"
                                name="status"
                                select
                                label="Blog Status"
                                onChange={handleChange}
                                value={values.status}
                                onBlur={handleBlur}
                                error={Boolean(errors.status && touched.status)}
                                helperText={
                                    errors.status &&
                                    touched.status &&
                                    String(errors.status)
                                }
                                required={true}
                                sx={{
                                    fieldset: {
                                        borderWidth: "3px",
                                        borderColor: "#526D82"
                                    },
                                    input: {
                                        fontWeight: "medium",
                                    },
                                    label: {
                                        color: "#526D82"
                                    }
                                }}
                            >
                                {status.map((option, i) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                    <div className=" mt-4 mb-8 mr-4">
                        <FormControl fullWidth>
                            <TextField
                                id="description"
                                name="description"
                                multiline
                                minRows={2}
                                label="Blog description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.description && touched.description)}
                                helperText={
                                    (errors.description &&
                                        touched.description) ?
                                        String(errors.description)
                                        :
                                        "Please enter the description of the blog."
                                }
                                required={true}
                                sx={{
                                    fieldset: {
                                        borderWidth: "3px",
                                        borderColor: "#526D82"
                                    },
                                    input: {
                                        fontWeight: "medium",
                                    },
                                    label: {
                                        color: "#526D82"
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className=" mt-4 mb-8 mr-4">
                        <FormControl fullWidth>
                            <TextField
                                id="content"
                                name="content"
                                multiline
                                minRows={5}
                                label="Blog Content"
                                value={values.content}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.content && touched.content)}
                                helperText={
                                    (errors.content &&
                                        touched.content) ?
                                        String(errors.content)
                                        :
                                        "Please enter the content of the blog."
                                }
                                required={true}
                                sx={{
                                    fieldset: {
                                        borderWidth: "3px",
                                        borderColor: "#526D82"
                                    },
                                    input: {
                                        fontWeight: "medium",
                                    },
                                    label: {
                                        color: "#526D82"
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                        <CoverImage
                            image={cover}
                            onRemove={() => setCover("")}
                        />
                        <input
                            id="coverImage"
                            name="coverImage"
                            type="file"
                            accept=".jpg, .png, .jpeg"
                            ref={coverInputRef}
                            className="hidden"
                            onChange={onCoverSelect}
                        />
                        <div
                            className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                            onClick={selectCover}
                        >
                            <p className=" font-extrabold text-sm ">Cover Image</p>
                            <AddPhotoAlternateIcon className="" fontSize="small" />
                        </div>
                        {errors.coverImage&& touched.coverImage && <p className=" mt-1 pl-2 text-xs text-red-600 font-semibold">{errors.coverImage}</p>}
                    </div>
                    <div className="ml-5">
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            disabled={isSubmitting}
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: "#E9B84A",
                                    color: "black",
                                    fontWeight: "bold"
                                },
                            }}
                        >Save</Button>
                        <Button
                            variant="text"
                            color="info"
                            onClick={() => navigate(-1)}
                            sx={{
                                "&.MuiButton-root": {
                                    fontWeight: "bold"
                                },
                            }}
                        >Back</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewPage;