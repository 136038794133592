import { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import CheckIcon from '@mui/icons-material/Check';
import moment from "moment/moment";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Box,
} from "@mui/material";
import Table from "../../Ui/table";
import TopHeader from "../../Ui/topHeader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from "axios";
import { errorAlert, successAlert } from "../../Ui/Alert";
import exportFromJSON from 'export-from-json'
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import edit from "./Assets/edit.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { consultationStatus, orderStatus, paymentStatus, roleTag } from "../../utils/constant";
import AllBookingsFilterTable from "../../Ui/CustomTable/AllBookingsFilterTable";

const apiUrl = process.env.REACT_APP_API_BASE_URL

const dialogModalSchema = yup.object().shape({
    doctor: yup.string().required(" Doctor must be selected "),
    date: yup.string().required(" Date of consultation required "),
    startTime: yup.string().required("Start time required"),
    endTime: yup.string()
        .required("End time required")
        .test('is-greater', 'End time must be greater than Start time', function (value) {
            const { startTime } = this.parent;
            return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
        }),
    notes: yup.string().required(" Notes Required ")

})

const AllBookings = () => {
    const [bookingData, setBookingData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [appointmentId, setAppointmentId] = useState();
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [doctorData, setDoctorData] = useState([]);
    const [currentData, setCurrentData] = useState({});
    const [consultationStatusDialog, setConsultationStatusDialog] = useState(false);

    const gapi = window.gapi;
    const CLIENT_ID = process.env.REACT_APP_CALENDER_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_CALENDER_API_KEY;
    const DISCOVERY_DOC = process.env.REACT_APP_CALENDER_DISCOVERY_DOC;
    const SCOPES = "https://www.googleapis.com/auth/calendar";
    const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token"))
    const [expiresIn, setExpiresIn] = useState(localStorage.getItem("expires_in"))
    const [status, setStatus] = useState("Completed")
    const [tokenClient, setTokenClient] = useState(null);
    const [isDoctor, setIsDoctor] = useState(false)
    const roleId = localStorage.getItem('userType')

    useEffect(() => {
        gapiLoaded();
        gisLoaded();
    }, []);

    useEffect(() => {
        getAllBookings()
        getAllDoctors();
        checkUserIsDoctorOrNot()
    }, []);

    useEffect(() => {
        setAccessToken(localStorage.getItem("access_token"))
        setExpiresIn(localStorage.getItem("expires_in"))
    }, [localStorage.getItem("access_token"), localStorage.getItem("expires_in")])

    function gapiLoaded() {
        gapi.load("client", initializeGapiClient);
    }

    function gisLoaded() {
        if (window.google && window.google.accounts && window.google.accounts.oauth2) {
            setTokenClient(
                window.google.accounts.oauth2.initTokenClient({
                    client_id: CLIENT_ID,
                    scope: SCOPES,
                    DISCOVERY_DOC: "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
                    callback: "http://localhost:3000",
                })
            );
        }
    }

    async function initializeGapiClient() {
        await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
        });
        if (accessToken && expiresIn) {
            gapi.client.setToken({
                access_token: accessToken,
                expires_in: expiresIn,
            });
        }
    }

    const handleAuthClick = async () => {
        tokenClient.callback = async (resp) => {
            if (resp.error) {
                throw resp;
            }
            const { access_token, expires_in } = gapi.client.getToken();
            setAccessToken(access_token)
            setExpiresIn(expires_in)
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("expires_in", expires_in);
            setOpenApproveDialog(true)
        };
        if (!(accessToken && expiresIn)) {
            tokenClient.requestAccessToken({ prompt: "consent" });
        } else {
            tokenClient.requestAccessToken({ prompt: "" });
        }
    }

    const approveBooking = async (values) => {
        var event = {
            kind: "calendar#event",
            summary: `${currentData?.selectedCategory} Consultation`,
            location: "Singapore",
            description: values.notes,
            start: {
                dateTime: moment(`${values.date} ${values.startTime}`).toISOString(),
                timeZone: "UTC",
            },
            end: {
                dateTime: moment(`${values.date} ${values.endTime}`).toISOString(),
                timeZone: "UTC",
            },
            attendees: [
                { email: `${currentData?.user?.email}` },
            ],
            reminders: {
                useDefault: true,
            },
            guestsCanSeeOtherGuests: true,
            conferenceData: {
                createRequest: {
                    requestId: "gjnjnjy",
                },
            },
        };

        if (currentData.calendarEventId) {
            await deleteEvent(currentData.calendarEventId)
        }
        var request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
            sendNotifications: true,
            conferenceDataVersion: 1,
        });

        request.execute(
            async (event) => {
                await updateConsultation({ meetingLink: event.hangoutLink, calendarEventId: event.id, eStatus: "Confirmed", ...values })
                setCurrentData({})
                // setOpenApproveDialog(false);
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const updateConsultation = async (data) => {
        await axios.put(`${apiUrl}/api/update-appointment/${currentData._id}`, data).then(async (res) => {
            if (res.data && res.data.success) {
                setOpenApproveDialog(false)
                setConsultationStatusDialog(false)
                successAlert("Consultation updated")
                setCurrentData({});
                await getAllBookings();
                if(data.eStatus===consultationStatus.Completed){
                    await updateOrder({consultationId:currentData._id,status:orderStatus.Delivered,deliveryDate:new Date()})
                }
                else if(data.eStatus===consultationStatus.Canceled){
                    await updateOrder({consultationId:currentData._id,status:orderStatus.Canceled,paymentDetails: { eStatus: paymentStatus.Refunded, dateTime: new Date() } })
                }
            }
        }).catch((error) => {
            errorAlert("Unable to update")
        })
    }

    const updateOrder = async (orderData) => {
        await axios.put(`${apiUrl}/api/order/filter/update?consultationId=${orderData?.consultationId}`, orderData).then((res) => {
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const deleteEvent = async (eventId) => {
        try {
            await gapi.client.calendar.events.delete({
                calendarId: "primary",
                eventId,
            });
        } catch (error) {
            console.error("Error deleting event:", error.message);
        }
    }

    const columns = [
        {
            name: 'Client Email',
            selector: row => row.clientId,
            width: "250px",
            wrap: "true",
            center: "true"
        },
        {
            name: "Consultation Date",
            selector: row => row.date,
            width: "200px",
            center: "true"
        },
        {
            name: "Category",
            selector: row => row?.selectedCategory,
            width: "200px",
            center: "true"
        },
        {
            name: "Meeting Link",
            selector: row => checkMeetingLink(row.meetingLink),
            wrap: "true",
            width: "300px",
            center: "true"
        },
        {
            name: "Payment Status",
            selector: row => <p style={{ textTransform: 'lowercase' }}>{row?.paymentDetails?.paymentStatus}</p>,
            width: "130px",
            center: "true"
        },
        {
            name: "Status",
            selector: row => (row.eStatus),
            width: "130px",
            center: "true"
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex items-center justify-center">
                    <IconButton
                        sx={{
                            color: 'blue'
                        }}
                        onClick={() => handleConsultationStatus(row)}
                        disabled={row.eStatus === "Completed"}
                    >
                        <img src={edit} alt=""></img>
                    </IconButton>
                    <IconButton
                        onClick={() => handleApproveDialogOpen(row)}
                        style={{
                            color: "green",
                        }}
                        disabled={row.eStatus === "Confirmed" || row.eStatus === "Completed"}
                    >
                        <CheckCircleIcon sx={{ color: "green" }} />
                    </IconButton>
                    {
                        !isDoctor && (
                            <IconButton
                                style={{
                                    color: "#EE4B4B",
                                }}

                                onClick={() => handleClickOpen(row._id)}
                            >
                                <DeleteOutlinedIcon />
                            </IconButton>

                        )
                    }
                </div>
            ),
            center: "true",
            style: { marginLeft: "1.7em" }
        },
    ]

    const data = bookingData.map((item, index) => {
        return {
            sr: index + 1,
            ...item,
            clientId: `${item.user?.email ?? "--"}`,
            meetingLink: item.meetingLink ?? "---",
            selectedCategory: item?.selectedCategory?.name,
            categoryId: item?.selectedCategory?._id,
            paymentDetails: item?.paymentDetails
        }
    })

    const exportData = () => {
        const fileName = 'booking'
        const exportType = exportFromJSON.types.csv
        const serializedData = bookingData.map((item, index) => ({
            sr: index + 1,
            Name: `${item.user?.personalInfo?.first_name ?? "--"} ${item.user?.personalInfo?.last_name ?? "--"}`,
            Doctor: `${item.doctor?.personalInfo?.first_name ?? "--"} ${item.doctor?.personalInfo?.last_name ?? "--"}`,
            Email: `${item.user?.email ?? "--"} `,
            Date: `=TEXT("${item.date}", "yyyy-mm-dd")`,
            MeetingLink: `=HYPERLINK("${item?.meetingLink}")`,
            Status: item.eStatus,
            Category: item?.selectedCategory?.name,
            notes: item.notes,
        }));
        exportFromJSON({ data: serializedData, fileName, exportType })
    }
    const getAllBookings = async () => {
        await axios.get(`${apiUrl}/api/booked-appointments`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                setBookingData(res.data.reverse())
            }
        }).catch((error) => {
            errorAlert("Unable to fetch data!!");
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const handleDelete = async () => {
        await axios.delete(`${apiUrl}/api/delete-appointment/${appointmentId}`).then(async (res) => {
            if (res) {
                handleClickClose()
                successAlert("Consultation is deleted successfully")
                if (res.data && res.data.calendarEventId) {
                    await deleteEvent(res.data.calendarEventId)
                }
                await getAllBookings()
            }
        }).catch((error) => {
            errorAlert("Unable to delete Consultation")
        })
    }
    const getAllDoctors = async () => {
        await axios.get(`${apiUrl}/api/users/getUserByUserType?tag=${roleTag.Doctor}`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setDoctorData(res.data)
            }
        }).catch((error) => {
            errorAlert("Unable to fetch data!!");
        })
    }

    const handleClickOpen = (id) => {
        setAppointmentId(id);
        setShowModal(true);
    }
    const handleClickClose = () => {
        setShowModal(false);
        setAppointmentId("")
    }

    const handleApproveDialogOpen = (data) => {
        setCurrentData(data);
        if (accessToken && expiresIn) {
            setOpenApproveDialog(true);
        }
        else {
            handleAuthClick()
        }
    }

    const checkMeetingLink = (meetingLink) => {
        return (
            meetingLink !== "---" ? (
                <a
                    href={meetingLink}
                    target="_blank"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    rel="noreferrer"
                >
                    {meetingLink}
                </a>
            ) : (
                'null'
            )
        )
    }

    const closeApproveDialog = async () => {
        setOpenApproveDialog(false)
    }

    const handleConsultationStatus = (consultationData) => {
        setConsultationStatusDialog(true);
        setCurrentData(consultationData)
    }

    const handleStatusUpdate = async () => {
        await updateConsultation({ eStatus: status });
    }

    const checkUserIsDoctorOrNot = async () => {
        if (roleId) {
            await axios.get(`${apiUrl}/api/roles/${roleId}`).then((res) => {
                if (res.data && res.data.tag && res.data.tag === roleTag.Doctor) {
                    setIsDoctor(true)
                }
            }).catch((error) => {
                console.log(error.message)
            })
        }
    }

    return (
        <div>
            <TopHeader />
            <div>
                {/* subheader */}
                <div className=" flex justify-between pt-2 pl-5 pb-4 items-center">
                    <div className=" ml-5 mt-5">
                        <div className=' flex flex-col justify-items-start'>
                            <h1 className=" text-4xl text-blue-800 font-bold">All Bookings</h1>
                            <p className=" text-sm text-neutral-600 font-bold ml-1">View all your bookings with your doctor.</p>
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            },
                            marginLeft: "20px"
                        }}
                        onClick={exportData}
                    >
                        Export
                    </Button>
                </div>
                <div className=" pl-5 pr-5">
                    {
                        isLoading && (
                            <LoadingBackdrop isloading={isLoading} />
                        )
                    }
                    {/* <Table align="center"
                        columns={columns}
                        data={data}
                        placeholder="Search Bookings"
                    /> */}
                    <AllBookingsFilterTable
                        columns={columns}
                        data={data}
                        placeholder="Search Bookings"
                    />
                </div>
            </div>
            <Dialog
                open={showModal}
                onClose={handleClickClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete the booking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleClickClose} variant="outlined">No</Button>
                    <Button
                        onClick={() => handleDelete()}
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            },
                        }}
                    >Yes</Button>
                </DialogActions>
            </Dialog>

            {/* Consultation Approve Button */}
            <div className=" mt-6">
                <Dialog
                    open={openApproveDialog}
                    onClose={closeApproveDialog}
                >
                    <Formik
                        initialValues={{
                            doctor: currentData?.doctor,
                            date: currentData?.date,
                            startTime: currentData?.startTime,
                            endTime: currentData?.endTime,
                            notes: currentData?.notes
                        }}
                        validationSchema={dialogModalSchema}
                        onSubmit={approveBooking}>
                        {
                            (formik) => (
                                <form onSubmit={formik.handleSubmit}>
                                    <DialogTitle id='alert-dialog-title' sx={{ m: 0, p: 3 }}>
                                        <div className=" text-2xl text-blue-800 font-bold">
                                            Consultation Approve Form
                                        </div>
                                    </DialogTitle>
                                    <DialogContent >
                                        <DialogContentText>

                                            <Box sx={{ width: "100%", marginTop: '2rem' }}>
                                                <div className=" grid grid-cols-2 space-x-12 mt-4 mb-8">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Doctor</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="doctor"
                                                            name="doctor"
                                                            value={formik.values.doctor}
                                                            label="Doctor"
                                                            onChange={formik.handleChange}
                                                            error={formik.touched.doctor && formik.errors.doctor}
                                                        >
                                                            {doctorData.map((item, index) => (
                                                                <MenuItem key={index} value={item?._id}>
                                                                    {`${item?.personalInfo?.first_name} ${item?.personalInfo?.last_name}`}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            id="date"
                                                            name="date"
                                                            label='Date'
                                                            type="date"
                                                            InputLabelProps={{ shrink: true, required: true }}
                                                            value={formik.values.date}
                                                            onChange={formik.handleChange}
                                                            helperText={formik.touched.date && formik.errors.date}
                                                            error={formik.touched.date && formik.errors.date}
                                                            inputProps={{
                                                                min: moment().add(1, 'days').format('YYYY-MM-DD') // Set the minimum date to tomorrow
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className=" grid grid-cols-2 space-x-12 mb-8">
                                                    <FormControl>
                                                        <TextField
                                                            id="startTime"
                                                            name="startTime"
                                                            label='Start Time'
                                                            type="time"
                                                            InputLabelProps={{ shrink: true, required: true }}
                                                            value={formik.values.startTime}
                                                            onChange={formik.handleChange}
                                                            helperText={formik.touched.startTime && formik.errors.startTime}
                                                            error={formik.touched.startTime && formik.errors.startTime}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField
                                                            id="endTime"
                                                            name="endTime"
                                                            label='End Time'
                                                            type="time"
                                                            InputLabelProps={{ shrink: true, required: true }}
                                                            value={formik.values.endTime}
                                                            onChange={formik.handleChange}
                                                            helperText={formik.touched.endTime && formik.errors.endTime}
                                                            error={formik.touched.endTime && formik.errors.endTime}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="grid grid-cols-1 space-x-12 mb-8">
                                                    <FormControl>
                                                        <TextField
                                                            id="notes"
                                                            name="notes"
                                                            label='Type Your Notes Here'
                                                            InputLabelProps={{ shrink: true, required: true }}
                                                            value={formik.values.notes}
                                                            onChange={formik.handleChange}
                                                            helperText={formik.touched.notes && formik.errors.notes}
                                                            error={formik.touched.notes && formik.errors.notes}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </Box>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div className="flex space-x-5 items-center justify-center mb-4">
                                            <Button type="submit" variant="contained"> Approve </Button>
                                            <Button sx={{
                                                "&.MuiButton-root": {
                                                    backgroundColor: "#E9B84A",
                                                    color: "black",
                                                    fontWeight: "bold"
                                                },
                                            }}
                                                variant="contained" onClick={() => { setOpenApproveDialog(false) }}
                                            > Close </Button>
                                        </div>
                                    </DialogActions>
                                </form>
                            )
                        }
                    </Formik>
                </Dialog>
            </div>

            <Dialog
                open={consultationStatusDialog}
                onClose={() => setConsultationStatusDialog(false)}
            >
                <DialogTitle style={{ fontWeight: "bold", padding: "2rem" }}>Update Consultation Status</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        <Box sx={{ width: "100%", marginTop: "1rem" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="eStatus"
                                    name="eStatus"
                                    label="Status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}

                                >
                                    <MenuItem value="Completed">Completed</MenuItem>
                                    <MenuItem value="Canceled">Canceled</MenuItem>
                                    <MenuItem value="Booked">Booked</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                    <Button
                        onClick={handleStatusUpdate}
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: "#E9B84A",
                                color: "black",
                                fontWeight: "bold"
                            },
                        }}
                    >
                        Done
                    </Button>
                    <Button onClick={() => setConsultationStatusDialog(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AllBookings;