const ClientField = ({ coloured, label, value }) => {
    return (
        <div className="flex flex-col  pb-5">
            <p className=" text-gray-500 text-sm font-semibold pb-2">{label}</p>
            {
                label === "Meet with Us" ? (
                    <a href={value} className="text-sm text-blue-800 font-bold" style={{textDecoration:"underline"}}>{value}</a>
                ) : (

                    coloured ? <p className=" text-sm text-blue-800 font-bold">{value}</p>
                        : <p className=" text-black text-sm font-bold pl-0">{value}</p>
                )
            }

        </div>
    )
}

export default ClientField;