import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    Breadcrumbs,
    Button,
    FormControl,
    IconButton,
    Link,
    MenuItem,
    TextField
} from "@mui/material";
import TopHeader from "../../Ui/topHeader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import Table from "../../Ui/table";
import edit from "./Assets/edit.png"
import { DeleteQuestion } from "../../store/actions/questionaction";
import { errorAlert, successAlert } from "../../Ui/Alert";
import axios from "axios";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
const apiUrl = process.env.REACT_APP_API_BASE_URL
const Questions = () => {

    const [questions, setQuestions] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [category, setCategory] = useState([])

    useEffect(() => {
        if (categoryId) {
            getQuestions();
        }
        // dispatch(getQuestionByCategory(questions));
    }, [categoryId])

    useEffect(() => {
        getCategories()
    }, [])


    const getQuestions = async () => {
        setIsLoading(true)
        await axios.get(`${apiUrl}/api/quiz/questions/Category/${categoryId}`).then((res) => {
            if (res && res.data && Array.isArray(res.data)) {
                setQuestions(res.data)
            }
        }).catch((error) => {
            errorAlert("Unable to fetch question")
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getCategories = async () => {
        await axios.get(`${apiUrl}/api/category/get-all`).then((res) => {
            if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                res.data=res.data.filter((category)=>(category?.hasSurvey==true))
                setCategory(res.data)
                setCategoryId(res.data[0]._id)
                setCategoryName(res.data[0].name)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleDelete = async (qid) => {
        await dispatch(DeleteQuestion(qid))
        await getQuestions()
        successAlert("Question deleted successfully.");
    }

    const handleEdit = (data) => {
        const id = data._id;
        navigate('/cm/updatequestion', { state: { id: id, question: data, category: categoryId } })
    }
    const handleCategory = (event) => {
        setCategoryId(event.target.value)
        const object = category.find((item) => item._id == event.target.value)
        setCategoryName(object.name)
    }

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="inherit"
            href=""
            sx={{ color: "darkslateblue" }}
        >
            <p className="text-3xl text-blue-800 font-black">Questionnaire Evaluation</p>
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            href=""
            sx={{ color: "darkslateblue" }}
        >
            {categoryName}
        </Link>
    ];

    const columns = [
        {
            name: "Type",
            selector: row => row.question_type,
            width: "120px",

        },
        {
            name: "Question",
            selector: row => row.content,
            wrap: "true",
            width: "360px"
        },
        {
            name: "Category",
            selector: row => row?.selectedCategory?.name,
        },
        {
            name: "Question Type",
            selector: row => row.option_type,
            wrap: "true",
        },
        {
            name: "Action",
            cell: (row) => (
                <div className=" flex items-center">
                    <IconButton
                        style={{
                            color: "#2C3E50"
                        }}
                        onClick={() => handleEdit(row)}
                    >
                        <img src={edit} alt="edit" />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#EE4B4B"
                        }}
                        onClick={() => { handleDelete(row._id) }}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </div>
            )
        }
    ]





    return (
        <div className="mb-12">
            <TopHeader />
            <div className=" pl-5 pt-5 pb-4">
                <Breadcrumbs
                    separator=">"
                    sx={{
                        fontWeight: "bolder",
                        color: "darkslateblue",
                        fontSize: "25px"
                    }}
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </div>
            <div className=" flex justify-between pt-4 pl-5 pb-4 items-center">
                <FormControl>
                    <TextField
                        id="category"
                        name="category"
                        select
                        label="Category"
                        value={categoryId}
                        onChange={handleCategory}
                        sx={{
                            fieldset: {
                                borderWidth: "3px",
                                borderColor: "#526D82"
                            },
                            input: {
                                fontWeight: "medium",
                            },
                            label: {
                                color: "#526D82"
                            }
                        }}
                    >
                        {category.map((item, index) => (
                            <MenuItem
                                key={index}
                                value={item._id}
                                name={item.name}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>

                <Button
                    onClick={() => navigate("/cm/newQuestion", { state: { category: categoryId, questions: questions } })}
                    variant="contained"
                    size="large"
                    sx={{
                        "&.MuiButton-root": {
                            backgroundColor: "#E9B84A",
                            color: "black",
                            fontWeight: "bold"
                        }
                    }}
                >
                    Add new Question
                </Button>
            </div>
            {
                isLoading && <LoadingBackdrop isloading={isLoading} />
            }
            <div className="pl-5 pr-4">
            <Table
                columns={columns}
                data={questions}
                isSelectable="true"
                placeholder="Search Questions"
            />
            </div>
        </div>
    )
}

export default Questions;