import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_BASE_URL;

export const getAllCards = createAsyncThunk(
    'cards/getAllCards',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const { data } = await axios.get(
                `${backendUrl}/api/template2`,
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;


            }
            else {
                console.log(error)
                return error.message;

            }

        }


    }
)



export const deleteCard = createAsyncThunk(
    'cards/deleteCard',
    async (cardid) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',

                }
            }

            const { data } = await axios.delete(
                `${backendUrl}/api/deletcard`,
                {
                    params: {
                        cid: cardid
                    }
                },
                config
            )
            return data;

        } catch (error) {
            if (error.response && error.response.data.message) {
                console.log(error);
                return error.response.data.message;
            }
            else {
                console.log(error)
                return error.message;
            }
        }
    }
)
