import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const Search = ({ placeholder, filterText, onClear, onFilter }) => {

    return(
        <div className="flex items-center gap-4
            rounded-lg w-1/3 p-4 shadow-md h-12 bg-blue-100 mt-4 mb-4">
                <input 
                    id="search"
                    value={filterText}
                    onChange={onFilter}
                    autoComplete='off'
                    className="outline-none mr-2 flex-grow w-6 bg-blue-100 text-lg text-gray-600 placeholder-gray-400" type="text" placeholder={placeholder}
                />
                {
                    !filterText 
                    ?
                    <IconButton>
                        <SearchIcon className='w-22 cursor-pointer text-blue-900' />
                    </IconButton>
                    :
                    <IconButton onClick={onClear}>
                        <CloseIcon className='w-22 cursor-pointer text-blue-900' />
                    </IconButton>
                }
            </div>
    )
}

export default Search;