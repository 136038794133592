import { createSlice } from "@reduxjs/toolkit"

import { AddNewQuestion, DeleteQuestion, UpdateQuestion, getQuestionByCategory } from "../actions/questionaction";




const initialState = {
    loading:false,
    questionsdata:[
      {
         option: "Breakdown",
         identity: {
             id: 0,
             value: "Breakdown"
         }
     },
     {
         option: "Last Question",
         identity: {
             id: -1,
             value: "Last Question"
         }
     },
    ],
    error:null,
    success:false,


}
 

const QuestionsSlice = createSlice({
    name:'products',
    initialState,
    reducers: {},
    extraReducers: {
     [getQuestionByCategory.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getQuestionByCategory.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.questionsdata = payload;
        state.success = true;
     },
     [getQuestionByCategory.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddNewQuestion.pending] : (state) => {
      state.loading = true;
      state.error = null;
   },
   [AddNewQuestion.fulfilled]:(state, {payload}) => {
      state.loading = false;
     
      state.success = true;
   },
   [AddNewQuestion.error]: (state,{payload}) =>  {
      state.loading = false;
      state.error = payload;
   },
   [DeleteQuestion.pending] : (state) => {
      state.loading = true;
      state.error = null;
   },
   [DeleteQuestion.fulfilled]:(state, {payload}) => {
      state.loading = false;
     
      state.success = true;
   },
   [DeleteQuestion.error]: (state,{payload}) =>  {
      state.loading = false;
      state.error = payload;
   },
   [UpdateQuestion.pending] : (state) => {
      state.loading = true;
      state.error = null;
   },
   [UpdateQuestion.fulfilled]:(state, {payload}) => {
      state.loading = false;
     
      state.success = true;
   },
   [UpdateQuestion.error]: (state,{payload}) =>  {
      state.loading = false;
      state.error = payload;
   },
    
    }
})

export default  QuestionsSlice.reducer;


