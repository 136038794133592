import { createSlice } from "@reduxjs/toolkit"
import { AddNewBatch, deleteBatch, getAllBatches, updateBatch } from "../actions/batchesaction";





const initialState = {
    loading:false,
    batchesdata:[],
    error:null,
    success:false,


}
 

const BatchesSlice = createSlice({
    name:'batches',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllBatches.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllBatches.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.batchesdata = payload;
        state.success = true;
     },
     [getAllBatches.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [deleteBatch.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [deleteBatch.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [deleteBatch.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [AddNewBatch.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [AddNewBatch.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [AddNewBatch.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
     [updateBatch.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [updateBatch.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.faqdata = payload;
        state.success = true;
     },
     [updateBatch.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
   
    }
})

export default BatchesSlice.reducer;

