export const accessModules={
    // User_Management: "atTagUser",
    // Customer_RelationShip:"atTagCustomerRelation",
    // Content_Management: "atTagContent",
    // Inventory_Management: "atTagInventory",
    // Consultation_Management: "atTagConsultation",
    // All_Bookings:"atTagAllBookings",
    All_Users:'atTagAllUsers',
    Suspend_User:'atTagSuspendUser',
    All_Clients:'atTagAllClients',
    Suspend_Clients:'atTagSuspendClients',
    Subscription_Management:'atTagSubscriptionManagement',
    Contact_Request:'atTagContactRequest',
    All_Blogs:'atTagAllBlogs',
    What_We_Treat:'atTagWhatWeTreat',
    Questionnaire_Evaluation:'atTagQuestionnaireEvaluation',
    All_Faqs:'atTagAllFaq',
    Category:'atTagCategory',
    Privacy_Policy:'atTagPrivacyPolicy',
    Crud_Products:'atTagCrudProducts',
    Crud_Packages:'atTagCrudPackages',
    All_Bookings:'atTagAllBookings',
    Add_Prescription:'atTagAddPrescription',
    Consultation_Booking:'atTagConsultationBooking',
    Roles_And_Permissions: "atTagPermission",
    Order_Management:"atTagOrderManagement",
    News_Letter_Subscription:'atTagNewsLetterSubscription',
    Settings:"atTagSettings"
}
export const questionType={
    text: "text",
    image: "image",
    file: "file",
    date: "date",
    number: "number"
}
export const SettingsKey={
    Consultation_Fee:"Consultation Fee",
    SMTP_Email:"SMTP Email",
    SMTP_Password:"SMTP Password",
    Support_Email:"Support Email",
    Support_Phone:"Support Phone",
    Address:"Address",
    Point_Conversion:"Point Conversion (%)"
}
export const UiSettingsKey={
    What_We_Treat:"What We Treat",
    Knowledge:"Knowledge",
    Get_Started:"Get Started",
    Login:"Login",
    Logo:"logo",
}
export const UiFooterSettingKey={
    Contact_Us: "Contact Us",
    Quick_Links: "Quick Links",
    Home: "Home",
    Treatments: "Treatments",
    About_Us: "About Us",
    Resources: "Resources",
    Hairloss: "Hairloss",
    Supplements: "Supplements",
    Information: "Information",
    Privacy_Policy: "Privacy Policy",
    Terms_and_Conditions: "Terms and Conditions",
    FooterLogo:"Footer logo"
}
export const UiQuickLinksKey={
    Home: "Home",
    Treatments: "Treatments",
    About_Us: "About Us",
    Resources: "Resources"
}
export const SocialMediaKey={
    facebook:"Facebook",
    linkedin:"Linkedin",
    twitter:"Twitter",
    Youtube:"Youtube"
}
export const SettingKeyNumberType=["Consultation_Fee","Support_Phone"]
export const measurements = [
    {
        label: "BOTT",
        value: "BOTT"
    },
    {
        label: "BOX",
        value: "BOX"
    },
    {
        label: "CAP/S",
        value: "CAP/S"
    },
    {
        label: "DOSE",
        value: "DOSE"
    },
    {
        label: "DROP/S",
        value: "DROP/S"
    },
    {
        label: "GRAM",
        value: "GRAM"
    },
    {
        label: "LOZENGE",
        value: "LOZENGE"
    },
    {
        label: "ML/S",
        value: "ML/S"
    },
    {
        label: "PC/S",
        value: "PC/S"
    },
    {
        label: "PUFF",
        value: "PUFF"
    },
    {
        label: "SACHET",
        value: "SACHET"
    },
    {
        label: "TAB/S",
        value: "TAB/S"
    },
    {
        label: "TABLE SPOON",
        value: "TABLES SPOON"
    },
    {
        label: "TEAS SPOON",
        value: "TEASSPOON"
    },
    {
        label: "THINLY",
        value: "THINLY"
    },
    {
        label: "TUBE",
        value: "TUBE"
    },
    {
        label: "UNIT",
        value: "UNIT"
    },
    {
        label: "VIAL",
        value: "VIAL"
    },
    {
        label: "KIT",
        value: "KIT"
    },
    {
        label: "PACKS",
        value: "PACKS"
    },
    {
        label: "PACKETS",
        value: "PKTS"
    },
    {
        label: "PUMP",
        value: "PUMP"
    },
    {
        label: "ROLL",
        value: "ROLL"
    },
    {
        label: "TEST",
        value: "TEST"
    },
    {
        label: "APPLICATION",
        value: "APPLICATION"
    },
    {
        label: "SPRAY/S",
        value: "SPRAY/S"
    },
    {
        label: "GARGLE",
        value: "GARGLE"
    },
    {
        label: "TUB",
        value: "TUB"
    }
]
export const sex = [
    {
        label: "Male",
        value: "male"
    },
    {
        label: "Female",
        value: "female"
    },
    {
        label: "Other",
        value: "other"
    }
];
export const roleTag={
    Patient:"atTagPatient",
    Admin:"atTagAdmin",
    Doctor:"atTagDoctor",
    Member:"atTagMember"
}
export const paymentMode = {
    Paypal: "Paypal",
    Hybrid: "Wallet & Paypal",
    Wallet: "Wallet"
}
export const paymentStatus = {
    Success: "Success",
    Failed: "Failed",
    Pending: "Pending",
    Refunded: "Refunded"
}
export const orderStatus = {
    Ordered: "Ordered",
    Ongoing: "Ongoing",
    Out_of_Delivery: "Out of Delivery",
    Delivered:"Delivered",
    Canceled:"Canceled"
}
export const consultationStatus = {
    Booked: "Booked",
    Confirmed: "Confirmed",
    Completed: "Completed",
    Canceled:"Canceled",
    Rescheduled:"Rescheduled"
}
export const orderFor = {
    Product: "Product",
    Consultation: "Consultation",
    Prescription: "Prescription"
}