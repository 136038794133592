import {
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Paper,
    Tab,
    Tabs,
    TextField,
    TextareaAutosize
} from "@mui/material";
import TopHeader from "../../Ui/topHeader";
import axios from "axios";
import { SettingKeyNumberType, SettingsKey, SocialMediaKey, UiFooterSettingKey, UiSettingsKey } from "../../utils/constant";
import { useEffect, useRef, useState } from "react";
import { errorAlert, successAlert } from "../../Ui/Alert";
import LoadingBackdrop from "../../Ui/LoadinSpinner";
import { ImageUpload } from "../../Ui/imageUpload";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const backendUrl = process.env.REACT_APP_API_BASE_URL;
const AboutsUsSchema = yup.object().shape({
    photo: yup.mixed().nullable(),
    title: yup.string().required("Title required."),
    tagLine: yup.string().required("Tag Line Required"),
    description: yup.string().required("Description Required.")
})

const Settings = () => {
    const fileInputRef = useRef(null);
    const footerFileInputRef = useRef(null);
    const [aboutUsData, setAboutUsData] = useState();
    const [settingsFormData, setSettingsFormData] = useState({})
    const [uiSettingsFormData, setUiSettingsFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [index, setIndex] = useState(0);
    const [image, setImage] = useState();
    const [updatedImage, setUpdatedImage] = useState("");
    const [uiHeaderLogo, setUiHeaderLogo] = useState();
    const [uiFooterLogo, setUiFooterLogo] = useState();
    const [socialMediaData, setSocialMediaData] = useState({});

    useEffect(() => {
        loadSettingsData()
        loadUiSettingsData()
        getAboutUsData()
        loadSocialMediaData()
    }, [])

    const selectFile = () => {
        fileInputRef.current.click();
    }
    const onFooterSelectFile = () => {
        footerFileInputRef.current.click();
    }

    const onFileSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            )
            setUpdatedImage(
                {
                    name: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            )
        }
    }

    const onLogoSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setUiHeaderLogo(
                {
                    filename: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            )
        }
    }
    const onFooterLogoSelect = (event) => {
        const files = event.target.files;
        if (files[0]) {
            setUiFooterLogo(
                {
                    filename: files[0].name,
                    url: URL.createObjectURL(files[0]),
                    file: files[0]
                }
            )
        }
    }
    const handleTabChange = (event, index) => {
        setIndex(index);
    }

    const getAboutUsData = async () => {
        await axios.get(`${backendUrl}/api/about`).then((res) => {
            if (res && res.data) {
                setAboutUsData(res.data.data);
                setImage({ url: backendUrl + "/uploads/" + res.data.data?.bannerImg, name: res.data.data?.bannerImg })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const aboutUsFormSubmit = async (values, actions) => {
        var photo;
        if (image?.url === aboutUsData?.bannerImg) {
            photo = image?.url;
        } else {
            photo = updatedImage?.file;
        }
        const aboutUsFormData = new FormData();
        aboutUsFormData.append('heading', values?.title);
        aboutUsFormData.append('tagline', values?.tagLine);
        aboutUsFormData.append('description', values?.description);
        aboutUsFormData.append('bannerImg', photo);
        await axios.post(`${backendUrl}/api/about/upsert`, aboutUsFormData).then((res) => {
            if (res) {
                successAlert('About Us Updated Successfully')
            }
        }).catch((error) => {
            console.log(error);
            errorAlert("Error occurred while Updating!!");
        })
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        const settings = []
        Object.entries(settingsFormData).map(([key, value]) => {
            settings.push({ sKey: key, sValue: value })
        })
        await axios.post(`${backendUrl}/api/settings/create`, settings).then((res) => {
            if (res.data && res.data.success) {
                successAlert("Settings Updated Successfully");
            }
        }).catch((error) => {
            console.log(error)
            errorAlert("Unable to Update Setting");
        })
    }

    const uiSettingsSubmit = async (event) => {
        event.preventDefault();
        const uiSettings = []
        Object.entries(uiSettingsFormData).map(([key, value]) => {
            uiSettings.push({ sKey: key, displayName: value })
        })
        await axios.post(`${backendUrl}/api/ui-settings/create`, uiSettings).then((res) => {
            if (res.data && res.data.success) {
                successAlert("Settings Updated Successfully");
            }
        }).catch((error) => {
            console.log(error)
            errorAlert("Unable to Update Setting");
        })
    }

    const uiSettingsLogoSubmit = async (event, name, file) => {
        event.preventDefault();
        const uiLogoFormData = new FormData();
        uiLogoFormData.append(name, file);
        await axios.post(`${backendUrl}/api/ui-settings/upload-logo`, uiLogoFormData).then((res) => {
            if (res.data) {
                successAlert("Logo Updated Successfully");
            }
        }).catch((error) => {
            console.log(error)
            errorAlert("Unable to Update UI Logo");
        })
    }

    const loadSettingsData = async () => {
        await axios.get(`${backendUrl}/api/settings`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                const newSetting = {}
                res.data.map((item) => {
                    if (Object.values(SettingsKey).includes(item.sKey)) {
                        newSetting[item.sKey] = item.sValue;
                    }
                })
                setSettingsFormData(newSetting)
            }
        }).catch((error) => {
            console.log("Error", error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const settingsInputHandle = async (event) => {
        const { name, value } = event.target;
        setSettingsFormData((prevSetting) => ({
            ...prevSetting,
            [name]: value,
        }))
    };

    const loadUiSettingsData = async () => {
        await axios.get(`${backendUrl}/api/ui-settings`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                const newSetting = {}
                res.data.map((item) => {
                    if (item.sKey === UiSettingsKey.Logo) {
                        setUiHeaderLogo({ url: backendUrl + "/uploads/" + item?.displayName, name: item?.displayName })
                    }
                    if (item.sKey === UiFooterSettingKey.FooterLogo) {
                        setUiFooterLogo({ url: backendUrl + "/uploads/" + item?.displayName, name: item?.displayName })
                    }
                    else if (Object.values(UiSettingsKey).includes(item.sKey) || Object.values(UiFooterSettingKey).includes(item.sKey)) {
                        newSetting[item.sKey] = item.displayName
                    }
                })
                setUiSettingsFormData(newSetting);
            }
        }).catch((error) => {
            console.log("Error", error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const uiSettingsInputHandle = async (event) => {
        const { name, value } = event.target;
        setUiSettingsFormData((prevSetting) => ({
            ...prevSetting,
            [name]: value,
        }))
    };

    const submitSocialMediaLink = async (event) => {
        event.preventDefault();
        const socialMediaLinks = []
        Object.entries(socialMediaData).map(([key, value]) => {
            socialMediaLinks.push({ sKey: key, redirectUrl: value })
        })
        await axios.post(`${backendUrl}/api/social/create`, socialMediaLinks).then((res) => {
            if (res.data && res.data.success) {
                successAlert("Social Media Links Updated Successfully");
            }
        }).catch((error) => {
            console.log(error)
            errorAlert("Unable to Update Social Media Links");
        })
    }
    const loadSocialMediaData = async () => {
        await axios.get(`${backendUrl}/api/social`).then((res) => {
            if (res.data && Array.isArray(res.data)) {
                const newSocialMediaData = {}
                res.data.map((item) => {
                    if (Object.values(SocialMediaKey).includes(item.sKey)) {
                        newSocialMediaData[item.sKey] = item.redirectUrl
                    }
                })
                setSocialMediaData(newSocialMediaData)
            }
        }).catch((error) => {
            console.log("Error", error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const socialMediaLinkInputHandle = async (event) => {
        const { name, value } = event.target;
        setSocialMediaData((prevValue) => ({
            ...prevValue,
            [name]: value,
        }))
    };

    return (
        <div>
            <TopHeader />
            <div>
                <p className=" text-4xl font-bold  pt-4 pl-4 pb-5 text-blue-800">Configuration and Settings</p>
            </div>
            <div className="mt-4 p-3">
                <Paper className="p-6 mb-12 max-w-6xl">
                    {
                        isLoading && (
                            <LoadingBackdrop isloading={isLoading} />
                        )
                    }
                    <Tabs value={index} onChange={handleTabChange}>
                        <Tab label="General Settings" sx={{ marginLeft: "4px", fontWeight: "bold" }} />
                        <Tab label="Frontend Settings" sx={{ fontWeight: "bold" }} />
                        <Tab label="About Us" sx={{ fontWeight: "bold" }} />
                        <Tab label="Social Media" sx={{ fontWeight: "bold" }} />
                    </Tabs>
                    {

                        index === 0
                        && (
                            <div className="mt-10" >
                                <form autoComplete="off">
                                    <div className="flex flex-wrap justify-space-between">
                                        {Object.entries(SettingsKey).map(([key, value], index) => (
                                            <div className="flex flex-col me-4 mb-8" key={index}>
                                                <label className=" text-blue-800 font-semibold mb-2" htmlFor={key}>{value}</label>
                                                <input
                                                    id={key}
                                                    type={
                                                        SettingKeyNumberType.includes(key)
                                                            ? "number"
                                                            : "text"
                                                    }
                                                    placeholder={`Enter ${value}`}
                                                    name={value}
                                                    value={SettingKeyNumberType.includes(value) ? (isNaN(parseInt(settingsFormData[value])) ? '' : parseInt(settingsFormData[value])) : (settingsFormData[value] ? settingsFormData[value].toString() : '')}
                                                    onChange={settingsInputHandle}
                                                    className="border border-gray-400  outline-blue-500 text-blue-900 w-[400px] p-3 rounded-md border "
                                                />

                                            </div>
                                        ))}
                                    </div>
                                    <div className=" ml-5">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            sx={{
                                                "&.MuiButton-root": {
                                                    backgroundColor: "#E9B84A",
                                                    color: "black",
                                                    fontWeight: "bolder",
                                                },
                                            }}
                                            onClick={onSubmit}
                                        >Update</Button>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                    {

                        index === 1
                        && (
                            <div className="mt-10">
                                <form autoComplete="off">
                                    <div className="mt-8 mb-6 flex flex-wrap items-center w-fit">
                                        <div className="mr-10 flex flex-col items-center w-fit">
                                            <ImageUpload
                                                image={uiHeaderLogo}
                                                onRemove={() => setUiHeaderLogo("")}
                                            />
                                            <input
                                                id="photo"
                                                name={uiHeaderLogo?.name}
                                                type="file"
                                                accept=".jpg, .png, .jpeg"
                                                ref={fileInputRef}
                                                className="hidden"
                                                onChange={onLogoSelect}
                                            />
                                            <div className="flex flex-wrap gap-3 mt-4">
                                                <div
                                                    className="flex bg-blue-800 gap-2 h-fit w-fit p-3 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                    onClick={selectFile}
                                                >
                                                    <p className=" font-extrabold text-sm ">Logo</p>
                                                    <AddPhotoAlternateIcon className="" fontSize="small" />
                                                </div>
                                                <div className="ml-4">
                                                    <Button
                                                        className="me-2"
                                                        variant="contained"
                                                        size="sm"
                                                        type="submit"
                                                        sx={{
                                                            "&.MuiButton-root": {
                                                                backgroundColor: "#E9B84A",
                                                                color: "black",
                                                                fontWeight: "bolder",
                                                                borderRadius: "0.75rem"
                                                            },
                                                        }}
                                                        style={{ width: '4rem', padding: "0.62rem", }}
                                                        onClick={(e) => uiSettingsLogoSubmit(e, 'logo', uiHeaderLogo?.file)}
                                                    >Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center w-fit">
                                            <ImageUpload
                                                image={uiFooterLogo}
                                                onRemove={() => setUiFooterLogo("")}
                                            />
                                            <input
                                                id="footerPhoto"
                                                name={uiFooterLogo?.name}
                                                type="file"
                                                accept=".jpg, .png, .jpeg"
                                                ref={footerFileInputRef}
                                                className="hidden"
                                                onChange={onFooterLogoSelect}
                                            />
                                            <div className="flex flex-wrap gap-3 mt-4">
                                                <div
                                                    className="flex bg-blue-800 gap-2 h-fit w-fit p-3 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                    onClick={onFooterSelectFile}
                                                >
                                                    <p className=" font-extrabold text-sm ">Footer Logo</p>
                                                    <AddPhotoAlternateIcon className="" fontSize="small" />
                                                </div>
                                                <div className="ml-4">
                                                    <Button
                                                        className="me-2"
                                                        variant="contained"
                                                        size="sm"
                                                        type="submit"
                                                        sx={{
                                                            "&.MuiButton-root": {
                                                                backgroundColor: "#E9B84A",
                                                                color: "black",
                                                                fontWeight: "bolder",
                                                                borderRadius: "0.75rem"
                                                            },
                                                        }}
                                                        style={{ width: '4rem', padding: "0.62rem", }}
                                                        onClick={(e) => uiSettingsLogoSubmit(e, 'footerLogo', uiFooterLogo?.file)}
                                                    >Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 w-fit mb-4">
                                        <div className=" flex items-center">
                                            <p className=" text-neutral-500 ml-1">Header Information</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap justify-space-between">
                                        {Object.entries(UiSettingsKey).map(([key, value], index) => {
                                            if (value != UiSettingsKey.Logo) {
                                                return (

                                                    <div className="flex flex-col me-4 mb-8" key={index}>
                                                        <label className=" text-blue-800 font-semibold mb-2" htmlFor={key}>{value}</label>
                                                        <input
                                                            id={key}
                                                            type="text"
                                                            placeholder={`Enter Display Name For ${value}`}
                                                            name={value}
                                                            value={uiSettingsFormData[value]?.toString()}
                                                            onChange={uiSettingsInputHandle}
                                                            className="border border-gray-400  outline-blue-500 text-blue-900 w-[400px] p-3 rounded-md border "
                                                        />

                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className=" flex items-center space-x-4 bg-slate-100 rounded-lg p-2 w-fit mb-4">
                                        <div className=" flex items-center">
                                            <p className=" text-neutral-500 ml-1">Footer Information</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap justify-space-between">
                                        {Object.entries(UiFooterSettingKey).map(([key, value], index) => {

                                            if (value != UiFooterSettingKey.FooterLogo) {
                                                return (
                                                    <div className="flex flex-col me-4 mb-8" key={index}>
                                                        <label className=" text-blue-800 font-semibold mb-2 mr-5" htmlFor={key}>{value}</label>
                                                        <input
                                                            id={key}
                                                            type="text"
                                                            placeholder={`Enter Display Name For ${value}`}
                                                            name={value}
                                                            // disabled={footerEdit}
                                                            value={uiSettingsFormData[value]?.toString()}
                                                            onChange={uiSettingsInputHandle}
                                                            className="border border-gray-400  outline-blue-500 text-blue-900 w-[400px] p-3 rounded-md border "
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                        }
                                    </div>
                                    <div className=" ml-5">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            sx={{
                                                "&.MuiButton-root": {
                                                    backgroundColor: "#E9B84A",
                                                    color: "black",
                                                    fontWeight: "bolder",
                                                },
                                            }}
                                            onClick={uiSettingsSubmit}
                                        >Update</Button>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                    {
                        index === 2
                        && (
                            <div className="mt-10" >
                                <Formik
                                    initialValues={{
                                        photo: aboutUsData?.bannerImg,
                                        title: aboutUsData?.heading,
                                        tagLine: aboutUsData?.tagline,
                                        description: aboutUsData?.description,
                                    }}
                                    validationSchema={AboutsUsSchema}
                                    onSubmit={(values) => {
                                        aboutUsFormSubmit(values);
                                    }}
                                >
                                    {(formik) => (
                                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                                            <div className=" mt-8 mb-6 flex flex-col items-center w-fit">
                                                <ImageUpload
                                                    image={image}
                                                    onRemove={() => setImage("")}
                                                />
                                                <input
                                                    id="photo"
                                                    name="photo"
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    ref={fileInputRef}
                                                    className="hidden"
                                                    onChange={onFileSelect}
                                                />
                                                <div
                                                    className="flex bg-blue-800 mt-2 gap-2 w-fit p-4 rounded-xl text-white hover:text-blue-800 hover:shadow-md hover:bg-neutral-300 transition" role="button"
                                                    onClick={selectFile}
                                                >
                                                    <p className=" font-extrabold text-sm ">Image</p>
                                                    <AddPhotoAlternateIcon className="" fontSize="small" />
                                                </div>
                                            </div>
                                            <div className=" grid grid-cols-2 space-x-8 mb-8 mr-4">
                                                <FormControl >
                                                    <FormLabel style={{ fontWeight: "bold", color: "#333399", marginBottom: "0.8rem" }}>Title</FormLabel>
                                                    <TextField
                                                        fullWidth
                                                        id="title"
                                                        name="title"
                                                        placeholder="Title"
                                                        variant="outlined"
                                                        value={formik?.values?.title}
                                                        onChange={formik?.handleChange}
                                                        onBlur={formik?.handleBlur}
                                                        error={Boolean(formik?.errors?.title && formik?.touched?.title)}
                                                        helperText={
                                                            formik?.errors?.title &&
                                                            formik?.touched?.title &&
                                                            String(formik?.errors?.title)
                                                        }
                                                        required={true}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel style={{ fontWeight: "bold", color: "#333399", marginBottom: "0.8rem" }}>Tag Line</FormLabel>
                                                    <TextField
                                                        id="tagLine"
                                                        name="tagLine"
                                                        placeholder="Tag Line"
                                                        variant="outlined"
                                                        value={formik?.values?.tagLine}
                                                        onChange={formik?.handleChange}
                                                        onBlur={formik?.handleBlur}
                                                        error={Boolean(formik?.errors?.tagLine && formik?.touched?.tagLine)}
                                                        helperText={
                                                            formik?.errors?.tagLine &&
                                                            formik?.touched?.tagLine &&
                                                            String(formik?.errors?.tagLine)
                                                        }
                                                        required={true}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className=" grid space-x-8 mb-8 mr-4">
                                                <FormControl>
                                                    <FormLabel style={{ fontWeight: "bold", color: "#333399", marginBottom: "0.8rem" }}>Description</FormLabel>
                                                    <TextareaAutosize
                                                        id="description"
                                                        name="description"
                                                        minRows={5}
                                                        placeholder="Description"
                                                        value={formik?.values?.description}
                                                        onChange={formik?.handleChange}
                                                        onBlur={formik?.handleBlur}
                                                        error={Boolean(formik?.errors?.description && formik?.touched?.description)}
                                                        helperText={
                                                            formik?.errors?.description &&
                                                            formik?.touched?.description &&
                                                            String(formik?.errors?.description)
                                                        }
                                                        required={true}
                                                        style={{ borderWidth: "1px", borderColor: "#D3D3D3", paddingLeft: "0.8rem", paddingTop: "0.5rem" }}
                                                    ></TextareaAutosize>
                                                </FormControl>
                                            </div>
                                            <div className=" ml-5">
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    type="submit"
                                                    sx={{
                                                        "&.MuiButton-root": {
                                                            backgroundColor: "#E9B84A",
                                                            color: "black",
                                                            fontWeight: "bolder",
                                                        },
                                                    }}
                                                >Update</Button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        )
                    }
                    {

                        index === 3
                        && (
                            <div className="mt-10" >
                                <form autoComplete="off">
                                    <div className="flex flex-wrap justify-space-between">
                                        {Object.entries(SocialMediaKey).map(([key, value], index) => (
                                            <div className="flex flex-col me-4 mb-8" key={index}>
                                                <label className=" text-blue-800 font-semibold mb-2" htmlFor={key}>{value}</label>
                                                <input
                                                    id={key}
                                                    type="text"
                                                    placeholder={`Enter ${value} URL`}
                                                    name={value}
                                                    value={socialMediaData[value]?.toString()}
                                                    onChange={socialMediaLinkInputHandle}
                                                    className="border border-gray-400  outline-blue-500 text-blue-900 w-[400px] p-3 rounded-md border "
                                                />

                                            </div>
                                        ))}
                                    </div>
                                    <div className=" ml-5">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            sx={{
                                                "&.MuiButton-root": {
                                                    backgroundColor: "#E9B84A",
                                                    color: "black",
                                                    fontWeight: "bolder",
                                                },
                                            }}
                                            onClick={submitSocialMediaLink}
                                        >Update</Button>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                </Paper>
            </div>
        </div>
    );
}

export default Settings;