import { createSlice } from "@reduxjs/toolkit"
import { getAllOrders } from "../actions/orderaction";





const initialState = {
    loading:false,
    ordersdata:[],
    error:null,
    success:false,


}
 

const OrdersSlice = createSlice({
    name:'orders',
    initialState,
    reducers: {},
    extraReducers: {
     [getAllOrders.pending] : (state) => {
        state.loading = true;
        state.error = null;
     },
     [getAllOrders.fulfilled]:(state, {payload}) => {
        state.loading = false;
        state.ordersdata = payload;
        state.success = true;
     },
     [getAllOrders.error]: (state,{payload}) =>  {
        state.loading = false;
        state.error = payload;
     },
    
   
    }
})

export default OrdersSlice.reducer;


