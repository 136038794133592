import dots from "../assets/Dot.png"

const Intro = () => {
    return(
        <div>
            <img 
                src={dots}
                alt="dots"
            />
        </div>
    )
}

export default Intro;