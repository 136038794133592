import TopHeader from "../../../Ui/topHeader"
import LinearStepper from "./components/stepper";


const AddProduct = () => {

    return (
        <div>
            <TopHeader />
                <div className="pt-6 pl-5 pb-6">
                    <h1 className="text-3xl text-blue-900 font-black font-roboto">Add Product</h1>
                    <p className=" text-blue-950 font-bold font-roboto text-sm">Add new product to your list in a specified category.</p>
                </div>
                <div className="p-3">
                    <LinearStepper />
                </div>
        </div>
    )
}

export default AddProduct;