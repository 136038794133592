import React, { useEffect, useState } from 'react'
import TopHeader from '../../Ui/topHeader'
import { Button, Paper, Tab, Tabs } from '@mui/material'
import LoadingBackdrop from '../../Ui/LoadinSpinner';
import TextEditor from './Rich-Text-Editor/TextEditor';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_BASE_URL
const PrivacyPolicy = () => {
    const [index, setIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [termsCondition, setTermsCondition] = useState('');

    useEffect(()=>{
        getPrivacyPolicy()
    },[])

    const handleTabChange = (event, index) => {
        setIndex(index)
    }

    const config = {
        readonly: false,
        beautyHTML: true,
        autofocus: true,
        toolbarAdaptive: false,
        toolbarButtonSize: "medium",
        // spellcheck: true,
        height: 500,
        language: "en",
        buttons: [
            "undo",
            "redo",
            "|",
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "|",
            "superscript",
            "subscript",
            "|",
            "align",
            "|",
            "ul",
            "ol",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "brush",
            "paragraph",
            "|",
            "image",
            "link",
            "table",
            "|",
            "hr",
            "eraser",
            "copyformat",
            "|",
            "fullsize",
            "selectall",
            "print",
            "|",
            "source",
            "|"
        ]
    };

    const getPrivacyPolicy = async () => {
        await axios.get(`${apiUrl}/api/privacy-policy`).then((res) => {
            if (res.data && res.data.success && res.data.data) {
              setPrivacyPolicy(res.data?.data?.privacyPolicy)
              setTermsCondition(res.data?.data?.termsCondition)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const handleSubmit = async () => {
        await axios.put(`${apiUrl}/api/privacy-policy/upsert`,{privacyPolicy:privacyPolicy,termsCondition:termsCondition}).then((res) => {
            if (res.data) {
              getPrivacyPolicy()
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <div>
            <TopHeader />
            <div className=" flex flex-col justify-start pt-4 pl-5 pb-4">
                <h1 className=" text-4xl font-bold  pt-4 text-blue-800">Privacy Policy</h1>
                <p className=" text-neutral-500 font-semibold">View Privacy Policy and Terms & Conditions.</p>
            </div>
            <div className="mt-4 p-3">
                <Paper className="p-6 mb-12 max-w-6xl">
                    {
                        isLoading && (
                            <LoadingBackdrop isloading={isLoading} />
                        )
                    }
                    <Tabs value={index} onChange={handleTabChange}>
                        <Tab label="Privacy Policy" sx={{ marginLeft: "4px", fontWeight: "bold" }} />
                        <Tab label="Terms & Condition" sx={{ fontWeight: "bold" }} />
                    </Tabs>
                    {
                        index === 0 && (
                            <>
                                <div className='mt-10'>
                                    <TextEditor config={{ ...config, placeholder: "Enter Privacy Policy" }} content={privacyPolicy} setContent={setPrivacyPolicy} />
                                </div>
                                <div className="flex gap-5 pl-5 pb-8 mt-5">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        sx={{
                                            "&.MuiButton-root": {
                                                backgroundColor: "#E9B84A",
                                                color: "black",
                                                fontWeight: "bold"
                                            },
                                        }}
                                        onClick={handleSubmit}
                                    >Save Changes</Button>
                                </div>
                            </>
                        )
                    }
                    {
                        index === 1 && (
                            <>
                            <div className='mt-10'>
                                <TextEditor config={{ ...config, placeholder: "Enter Terms & Condition" }} content={termsCondition} setContent={setTermsCondition} />
                            </div>
                             <div className="flex gap-5 pl-5 pb-8 mt-5">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        sx={{
                                            "&.MuiButton-root": {
                                                backgroundColor: "#E9B84A",
                                                color: "black",
                                                fontWeight: "bold"
                                            },
                                        }}
                                        onClick={handleSubmit}
                                    >Save Changes</Button>
                                </div>
                            </>
                        )
                    }
                </Paper>
            </div>


        </div>
    )
}

export default PrivacyPolicy
