import TopHeader from "../../../Ui/topHeader";
import UpdatePackage from "./components/update";

const EditPackage = () => {
    return(
        <>
            <TopHeader />
            <div className="ml-5 mt-5">
                <div className="flex flex-col justify-start p-2">
                    <h1 className=" text-3xl text-blue-900 font-black font-roboto">Edit Package</h1>
                    <p className=" text-neutral-500 mt-2 font-bold font-roboto text-sm">Edit package according to your needs.</p>
                </div>
                <div className="p-3">
                    <UpdatePackage />
                </div>
            </div>
        </>
    )
}

export default EditPackage;