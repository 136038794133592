import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Search from "./search";
import { Checkbox, MenuItem, Select } from "@mui/material";

const tableCustomStyles = {

  headRow: {
    style: {
      color: '#FFFFFF',
      backgroundColor: '#526D82',
      fontWeight: "bold"
    },
  },
  rows: {
    style: {
      backgroundColor: "#DDE6ED",
      fontWeight: 600,
    },
    stripedStyle: {
      backgroundColor: "#F0FBFF"
    },
  },
  pagination: {
    style: {
      backgroundColor: "#9DB2BF",
      color: "black",
      fontWeight: "bold",
      fontSize: "15px"
    }
  },
  subHeader: {
    style: {
      backgroundColor: "#F4F9F9"
    }
  }
}

const Table = ({ columns, data, isSelectable, placeholder, isSearch = true, isPagination = true, conditionalRowStyles }) => {

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState("");
  let filteredItems = []
  if (data && Array.isArray(data)) {
    filteredItems = data?.filter(item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
    );
  }

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    }
    const handleFilterChange = (event) => {
      setFilterCriteria(event.target.value);
    }
    return (
      <Search
        onFilter={(event) => setFilterText(event.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder={placeholder}
        
      />

    );

  }, [filterText, placeholder, resetPaginationToggle])

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      striped
      selectableRows={isSelectable}
      selectableRowsComponent={Checkbox}
      subHeader
      subHeaderComponent={isSearch ? subHeaderComponent : <></>}
      customStyles={tableCustomStyles}
      pointerOnHover
      highlightOnHover
      selectableRowsHighlight
      pagination={isPagination}
      conditionalRowStyles={conditionalRowStyles ?? []}
    />
  )
}

export default Table;